import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: Roboto,sans-serif;
  }
  [role="button"] {
  cursor: pointer;
}

`;
export const Page = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  .panel-right {
    height: 100%;
    flex: ${(props) => (props.displayclientinfo ? "0 0 45%" : "70%")};
    overflow: hidden;
    position: relative;
    z-index: 100;
    border-left: 1px solid rgba(0, 0, 0, 0.09);
  }
  .panel-left {
    height: 100%;
    flex: ${(props) => (props.displayclientinfo ? "0 0 25%" : "30%")};
    overflow: hidden;
    position: relative;
    z-index: 100;
  }
  .panel-tree {
    height: 100%;
    flex: 30%;
    overflow: hidden;
    position: relative;
    z-index: 100;
    transition: 0.2s ease-in-out;
    transform: translateX(0px);
  }
  code {
    font-family: Consolas, Menlo, Monaco, "Lucida Console", Liberation Mono,
      DejaVu Sans Mono, Bitstream Vera Sans Mono, "Courier New", Courier,
      monospace;
  }
  input {
    margin: 0;
    font-family: Roboto, sans-serif;
  }
  button {
    border: 0;
    margin: 0;
    outline: 0;
    cursor: pointer;
    font-family: inherit;
  }
  pre {
    white-space: pre-wrap;
  }
  a {
    text-decoration: none;
    color: #039be5;
  }
  a,
  abbr,
  acronym,
  address,
  applet,
  article,
  aside,
  audio,
  b,
  big,
  blockquote,
  body,
  canvas,
  caption,
  center,
  cite,
  code,
  dd,
  del,
  details,
  dfn,
  div,
  dl,
  dt,
  em,
  embed,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  hgroup,
  html,
  i,
  iframe,
  img,
  ins,
  kbd,
  label,
  legend,
  li,
  mark,
  menu,
  nav,
  object,
  ol,
  output,
  p,
  pre,
  q,
  ruby,
  s,
  samp,
  section,
  small,
  span,
  strike,
  strong,
  sub,
  summary,
  sup,
  table,
  tbody,
  td,
  tfoot,
  th,
  thead,
  time,
  tr,
  tt,
  u,
  ul,
  var,
  video {
    margin: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    outline: 0;
  }
`;
