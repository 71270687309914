import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { HeaderPage } from "./style";

export default function Header() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const closeClientInfo = () => {
    dispatch({ type: "SET_RENDER_CLIENT_INFO", displayclientinfo: false });
  };
  return (
    <HeaderPage>
      <div className="_1D7Lo" data-animate-drawer-title="true">
        <div className="_3SrqU">
          <button className="t4a8o" onClick={closeClientInfo}>
            <span data-testid="x" data-icon="x" className>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
              >
                <path
                  fill="currentColor"
                  d="M19.1 17.2l-5.3-5.3 5.3-5.3-1.8-1.8-5.3 5.4-5.3-5.3-1.8 1.7 5.3 5.3-5.3 5.3L6.7 19l5.3-5.3 5.3 5.3 1.8-1.8z"
                />
              </svg>
            </span>
          </button>
        </div>
        <div className="_3vTfY">{t("HOME.pr.active.info.header.title")}</div>
      </div>
    </HeaderPage>
  );
}
