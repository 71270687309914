import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { Page, Content } from "./style.js";
import Divider from "@material-ui/core/Divider";

export default function AlertDialog({ func, ticket }) {
  const { t } = useTranslation();

  const handleClose = () => {
    func();
  };

  return (
    <Page>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Perfil do Cliente</DialogTitle>
        <DialogContent>
          <Content>
            <div className="infoclient">
              <div>
                <h4>Nome:</h4> {ticket.client.name}
              </div>
              <div>
                <h4>Número:</h4> {ticket.client.number}
              </div>
              <div>
                <h4>CPF:</h4> {ticket.client.cpf}
              </div>
              <div>
                <h4>Carteira:</h4>{" "}
                {ticket.client.wallet ? ticket.client.wallet.name : ""}
              </div>
              <div>
                <h4>Setor:</h4> {ticket.sector ? ticket.sector.name : ""}
              </div>
            </div>
            <Divider />
            <div className="clientcnpjs">
              {!!ticket.client.cliente_cnpjs.length &&
                ticket.client.cliente_cnpjs.map((cnpjs, index) => (
                  <div
                    className="_210SC"
                    style={{
                      zIndex: 2,
                      transition: "none 0s ease 0s",
                      transform: "translateY(0px)",
                    }}
                  >
                    <div tabIndex={-1} className>
                      <div className="eJ0yJ _1Mq-e">
                        <div className="_325lp">
                          <div
                            className="_1BjNO"
                            style={{ height: "49px", width: "49px" }}
                          >
                            <div className="_1V82l">
                              <span
                                data-testid="default-user"
                                data-icon="default-user"
                                className
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  id="bold"
                                  enableBackground="new 0 0 24 24"
                                  height="512px"
                                  viewBox="0 0 24 24"
                                  width="512px"
                                  className=""
                                >
                                  <g>
                                    <path
                                      d="m13.03 1.87-10.99-1.67c-.51-.08-1.03.06-1.42.39-.39.34-.62.83-.62 1.34v21.07c0 .55.45 1 1 1h3.25v-5.25c0-.97.78-1.75 1.75-1.75h2.5c.97 0 1.75.78 1.75 1.75v5.25h4.25v-20.4c0-.86-.62-1.59-1.47-1.73zm-7.53 12.88h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm5 9h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75z"
                                      data-original="#000000"
                                      className="active-path"
                                      data-old_color="#000000"
                                      fill="#FFFFFF"
                                    />
                                    <path
                                      d="m22.62 10.842-7.12-1.491v14.649h6.75c.965 0 1.75-.785 1.75-1.75v-9.698c0-.826-.563-1.529-1.38-1.71zm-2.37 10.158h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75z"
                                      data-original="#000000"
                                      className="active-path"
                                      data-old_color="#000000"
                                      fill="#FFFFFF"
                                    />
                                  </g>{" "}
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="_2kHpK">
                          <div className="_3dtfX">
                            <div className="_3CneP">
                              <span
                                dir="auto"
                                title="Você"
                                className="_3ko75 _5h6Y_ _3Whw5"
                              >
                                {cnpjs.rzsocial
                                  ? cnpjs.rzsocial
                                  : cnpjs.nmfantasia}
                              </span>
                            </div>
                          </div>
                          <div className="_1582E">
                            <div className="_3tBW6">
                              <span dir="auto" className="_3ko75 _3Whw5">
                                {cnpjs.cnpj}
                              </span>
                            </div>
                            <div className="m61XR">
                              <span />
                              <span />
                            </div>
                          </div>
                          <div className="_1582E">
                            <div className="_3tBW6">
                              <span dir="auto" className="_3ko75 _3Whw5">
                                {t("HOME.pr.active.info.establishment.info")} :{" "}
                                {cnpjs.cd_cliente_dislub}
                              </span>
                            </div>
                            <div className="m61XR">
                              <span />
                              <span />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Content>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            <Button variant="contained" color="primary">
              Fechar
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
