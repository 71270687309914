import React from "react";
import ticketsound from "./ticket.mp3";
import api from "../services/axios";
import socket from "../services/socket";
export default function NewMessageNotification() {
  const user = JSON.parse(localStorage.user);
  const [tickets, setTickets] = React.useState([]);
  function getTickets() {
    const idsector = [];
    if (user.users_has_sectors && user.users_has_sectors.length) {
      user.users_has_sectors.map((sector) => {
        idsector.push(sector.idsector);
      });
    }
    api
      .get("/ticket/findticketsfiltred", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          idsector,
          status: "PENDENTE",
        },
      })
      .then((response) => {
        const tickets = [];
        if (response.data.length) {
          response.data.map((ticket) => {
            if (ticket.client.idwallet) {
              if (user.users_has_wallets.length) {
                const index = user.users_has_wallets.findIndex(
                  (wallet) => wallet.idwallet === ticket.client.idwallet
                );
                if (index !== -1) {
                  tickets.push(ticket);
                }
              }
            }
            // else if (user.users_has_sectors[0].sub_sector) {
            //   if (
            //     user.users_has_sectors[0].sub_sector.sub_sectors_ddds.length
            //   ) {
            //     user.users_has_sectors[0].sub_sector.sub_sectors_ddds.map(
            //       (ddd) => {
            //         tickets.push(ticket.number.substring(2, 4) === ddd.cod);
            //       }
            //     );
            //   }
            // }
            else {
              tickets.push(ticket);
            }
          });
        }

        setTickets([...tickets]);
      });
  }
  React.useEffect(() => {
    socket.on(`${user.company.prefix}_newticket`, (data) => {
      if (data.idwallet) {
        if (user.users_has_wallets.length) {
          const index = user.users_has_wallets.findIndex(
            (wallet) => wallet.idwallet === data.idwallet
          );
          if (index !== -1) {
            getTickets();
          }
        }
      } else if (user.users_has_sectors && user.users_has_sectors.length) {
        const index = user.users_has_sectors.findIndex((sector) => {
          return sector.idsector === data.idsector;
        });
        if (index !== -1) {
          getTickets();
        }
      }
    });
  }, []);
  React.useEffect(() => {
    if (!!tickets.length) {
      document.getElementById("ticketstone").play();
    }
  }, [tickets]);
  return (
    <audio id="ticketstone" style={{ display: "none" }}>
      <source src={ticketsound} type="audio/mpeg"></source>
    </audio>
  );
}
