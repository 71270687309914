import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { EstablishmentPage } from "./style";

export default function Establishment() {
  const { t } = useTranslation();
  const client = useSelector((state) => state.panelRight.clientinfo);
  return (
    <EstablishmentPage>
      <div className="_2Bps4 _1mTqm">
        <div className="_1Gecv" role="button">
          <div className>
            <div className="_3mR1z">
              <div className="_3HPyS _1e77x">
                <span className="_2y8MV">
                  {client?.cliente_cnpjs.length}{" "}
                  {t("HOME.pr.active.info.establishment.count")}
                </span>
              </div>
              <div className="sb_Co">
                <span
                  data-testid="search"
                  data-icon="search"
                  className="_3lS1C"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                  >
                    <path
                      fill="currentColor"
                      d="M15.009 13.805h-.636l-.22-.219a5.184 5.184 0 0 0 1.256-3.386 5.207 5.207 0 1 0-5.207 5.208 5.183 5.183 0 0 0 3.385-1.255l.221.22v.635l4.004 3.999 1.194-1.195-3.997-4.007zm-4.808 0a3.605 3.605 0 1 1 0-7.21 3.605 3.605 0 0 1 0 7.21z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className style={{ pointerEvents: "auto" }}>
          <div className="-GlrD _2xoTX">
            {!!client.cliente_cnpjs.length &&
              client.cliente_cnpjs.map((cnpjs, index) => (
                <div
                  key={index}
                  className="_210SC"
                  style={{
                    zIndex: 2,
                    transition: "none 0s ease 0s",
                    transform: "translateY(0px)",
                  }}
                >
                  <div tabIndex={-1} className>
                    <div className="eJ0yJ _1Mq-e">
                      <div className="_325lp">
                        <div
                          className="_1BjNO"
                          style={{ height: "49px", width: "49px" }}
                        >
                          <div className="_1V82l">
                            <span
                              data-testid="default-user"
                              data-icon="default-user"
                              className
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="bold"
                                enableBackground="new 0 0 24 24"
                                height="512px"
                                viewBox="0 0 24 24"
                                width="512px"
                                className=""
                              >
                                <g>
                                  <path
                                    d="m13.03 1.87-10.99-1.67c-.51-.08-1.03.06-1.42.39-.39.34-.62.83-.62 1.34v21.07c0 .55.45 1 1 1h3.25v-5.25c0-.97.78-1.75 1.75-1.75h2.5c.97 0 1.75.78 1.75 1.75v5.25h4.25v-20.4c0-.86-.62-1.59-1.47-1.73zm-7.53 12.88h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm5 9h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75z"
                                    data-original="#000000"
                                    className="active-path"
                                    data-old_color="#000000"
                                    fill="#FFFFFF"
                                  />
                                  <path
                                    d="m22.62 10.842-7.12-1.491v14.649h6.75c.965 0 1.75-.785 1.75-1.75v-9.698c0-.826-.563-1.529-1.38-1.71zm-2.37 10.158h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75z"
                                    data-original="#000000"
                                    className="active-path"
                                    data-old_color="#000000"
                                    fill="#FFFFFF"
                                  />
                                </g>{" "}
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="_2kHpK">
                        <div className="_3dtfX">
                          <div className="_3CneP">
                            <span
                              dir="auto"
                              title="Você"
                              className="_3ko75 _5h6Y_ _3Whw5"
                            >
                              {cnpjs.rzsocial
                                ? cnpjs.rzsocial
                                : cnpjs.nmfantasia}
                            </span>
                          </div>
                        </div>
                        <div className="_1582E">
                          <div className="_3tBW6">
                            <span dir="auto" className="_3ko75 _3Whw5">
                              {cnpjs.cnpj}
                            </span>
                          </div>
                          <div className="m61XR">
                            <span />
                            <span />
                          </div>
                        </div>
                        <div className="_1582E">
                          <div className="_3tBW6">
                            <span dir="auto" className="_3ko75 _3Whw5">
                              {t("HOME.pr.active.info.establishment.info")} :{" "}
                              {cnpjs.cd_cliente_dislub}
                            </span>
                          </div>
                          <div className="m61XR">
                            <span />
                            <span />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </EstablishmentPage>
  );
}
