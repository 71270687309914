import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MicRecorder from "mic-recorder-to-mp3";
import { Page } from "./style";
import EmojisPanel from "./Emojis Panel/index";
import api from "../../../../../services/axios";
import DialogSendMedia from "../Dialog/DialogSendMedia";
import Mentions from "./Mentions";

const recorder = new MicRecorder({ bitRate: 128 });

export default function Initial(props) {
  const { client } = props;
  const { t } = useTranslation();
  const [media, setMedia] = useState("");
  const [send, setSend] = useState(true);
  const { addMessage, updateMessage } = props;
  const [time, setTime] = useState("0:00");
  const user = JSON.parse(localStorage.user);
  const [message, setMessage] = useState("");
  const [allowed, setAllowed] = useState(true);
  const [shortcuts, setShortcuts] = useState([]);
  const [inrecord, setInrecord] = useState(false);
  const [openMentions, setOpenMentions] = useState(false);
  const [intervaltime, setIntervaltime] = useState("0:00");
  const [showsendmedia, setShowsendmedia] = useState(false);
  const [openemojipanel, setOpenemojipanel] = useState(false);
  const [messagecontenteditable, setMessagecontenteditable] = useState(true);
  const [stream, setStream] = useState({
    access: false,
    recorder: null,
    stream: null,
    error: "",
  });

  const toTime = (duration) => {
    const seconds = parseInt((duration / 1000) % 60);
    const minutes = parseInt((duration / (1000 * 60)) % 60);
    const hours = parseInt((duration / (1000 * 60 * 60)) % 24);
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };
  const onChange = async (ev) => {
    const content = ev.currentTarget.innerText;
    if (ev.currentTarget.getElementsByTagName("img").length) {
      const uri = ev.currentTarget.getElementsByTagName("img")[0].src;
      const blob = await fetch(uri)
        .then((r) => r.blob())
        .catch((e) => {
          console.log(e);
          return false;
        });
      if (blob) {
        const media = new File([blob], "image.jpg", { type: blob.type });
        setMedia(media);
        setShowsendmedia(true);
      }
    } else {
      const regex = new RegExp(/([/][\w_-]+)/g);
      const matchs = Array.from(content.matchAll(regex), (x) => x[1]);
      setMessage(content);
      if (matchs.length) {
        setOpenMentions(true);
      } else {
        setOpenMentions(content[content.length - 1] === "/");
      }
    }
  };

  const toggleEmojiPanel = (_) => {
    setOpenemojipanel(!openemojipanel);
  };

  const sendEmoji = (emoji) => {
    document.getElementById("input-text").innerText =
      document.getElementById("input-text").innerText + emoji;
    setMessage(document.getElementById("input-text").innerText);
  };

  const sendMessage = () => {
    document.getElementById("input-text").innerText = "";

    if (!message) {
      return;
    }

    const messageobj = {
      idclient: client.idclient,
      idtelegram: client.idtelegram,
      number: client.number,
      message,
      iduser: user.idusers,
      company: user.company,
    };
    const id = Math.random();
    addMessage({
      content: message,
      type: "default",
      timestamp: new Date(),
      client_message: 0,
      pending: true,
      idmessages: id,
    });
    setMessage("");
    api
      .post("ticket/sendmessage", messageobj, {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        updateMessage(id, response.data.idmessages);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const startRecordMicrophoneTime = () => {
    const now = new Date();
    const interval = setInterval(() => {
      setTime(toTime(Date.now() - now));
    }, 100);
    setIntervaltime(interval);
  };

  const microphoneStartRecord = () => {
    if (!allowed) return window.alert("Permita o acesso ao microfone!");
    recorder
      .start()
      .then(() => {
        setMessagecontenteditable(false);
        setInrecord(true);
        startRecordMicrophoneTime();
      })
      .catch((e) => console.error(e));
  };
  const stopRecord = () => {
    recorder.stop();
  };
  const cancelRecord = () => {
    setMessagecontenteditable(true);
    setInrecord(false);
    clearInterval(intervaltime);
    setIntervaltime();
    setTime("0:00");
    stopRecord();
  };
  const confirmRecord = () => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, `audio-${new Date().getTime()}.mp3`, {
          type: blob.type,
          lastModified: Date.now(),
        });
        sendAudio(file);
        setMessagecontenteditable(true);
        setInrecord(false);
        clearInterval(intervaltime);
        setIntervaltime();
        setTime("0:00");
        setIntervaltime();
        console.log("record stopped");
      })
      .catch((e) => console.log(e, "erro"));
  };

  const sendMedia = async () => {
    setShowsendmedia(false);
    document.getElementById("input-text").innerText = "";
    setMessage("");
    const id = Math.random();
    addMessage({
      idmessages: id,
      content: "Mídia",
      type: "image",
      timestamp: new Date(),
      client_message: 0,
      pending: true,
      media: URL.createObjectURL(media),
    });

    const message = {
      idclient: client.idclient,
      idtelegram: client.idtelegram,
      number: client.number,
      iduser: user.idusers,
      company: JSON.stringify(user.company),
      userfile: media,
      type: "image",
      message: "Mídia",
    };
    const dataForm = new FormData();
    for (const key in message) {
      dataForm.append(key, message[key]);
    }
    try {
      api
        .post("ticket/sendmessagemedia", dataForm, {
          headers: {
            authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          updateMessage(id, response.data.idmessages);
        });
    } catch (e) {
      window.alert("Ocorreu um erro.");
      console.log(e);
    }
  };
  const sendAudio = (file) => {
    const { client } = props;
    const messageobj = {
      idclient: client.idclient,
      idtelegram: client.idtelegram,
      number: client.number,
      iduser: user.idusers,
      company: JSON.stringify(user.company),
      userfile: file,
      type: "audio",
    };
    const dataForm = new FormData();
    for (const key in messageobj) {
      dataForm.append(key, messageobj[key]);
    }
    const id = Math.random();
    addMessage({
      idmessages: id,
      content: "Mídia",
      type: "audio",
      timestamp: new Date(),
      client_message: 0,
      pending: true,
      media: URL.createObjectURL(file),
    });
    api
      .post("ticket/sendmessagemedia", dataForm, {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        document.getElementById("input-text").innerHTML = "";
        setShowsendmedia(false);
        setMedia("");
        updateMessage(id, response.data.idmessages);
      })
      .catch((e) => {
        setMedia("");
        window.alert("Ocorreu um erro.");
        console.log(e);
      });
  };
  const closeDialog = () => {
    document.getElementById("input-text").innerHTML = "";
    setShowsendmedia(false);
  };
  function handleReplaceMessage(newMessage) {
    document.getElementById("input-text").innerText = newMessage;
    setMessage(newMessage);
    setOpenMentions(false);
  }
  function fetchShortcuts() {
    api
      .get("shortcut", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        response.data.map((x) => {
          x.command = `/${x.command}`;
        });
        setShortcuts(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    fetchShortcuts();
    // navigator.getUserMedia(
    //   { audio: true },
    //   () => {
    //     setAllowed(true);
    //   },
    //   () => {
    //     console.log("Permission Denied");
    //   }
    // );
  }, []);

  return (
    <Page>
      {showsendmedia && (
        <DialogSendMedia
          close={closeDialog}
          media={media}
          sendMedia={sendMedia}
        />
      )}
      <div className="footer-content">
        {openemojipanel && (
          <EmojisPanel open={openemojipanel} sendEmoji={sendEmoji} />
        )}
        {openMentions && (
          <Mentions
            message={message}
            setMessage={handleReplaceMessage}
            shortcuts={shortcuts}
          />
        )}
      </div>
      <div className="_3oju3">
        <div tabIndex="-1" className="_2uQuX" data-tab="4">
          <button
            type="button"
            className="_2Fofa"
            id="btn-emojis"
            onClick={toggleEmojiPanel}
          >
            <span data-icon="smiley">
              <svg
                id="icon-emojis-open"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  opacity=".45"
                  fill="#263238"
                  d="M9.153 11.603c.795 0 1.439-.879 1.439-1.962s-.644-1.962-1.439-1.962-1.439.879-1.439 1.962.644 1.962 1.439 1.962zm-3.204 1.362c-.026-.307-.131 5.218 6.063 5.551 6.066-.25 6.066-5.551 6.066-5.551-6.078 1.416-12.129 0-12.129 0zm11.363 1.108s-.669 1.959-5.051 1.959c-3.505 0-5.388-1.164-5.607-1.959 0 0 5.912 1.055 10.658 0zM11.804 1.011C5.609 1.011.978 6.033.978 12.228s4.826 10.761 11.021 10.761S23.02 18.423 23.02 12.228c.001-6.195-5.021-11.217-11.216-11.217zM12 21.354c-5.273 0-9.381-3.886-9.381-9.159s3.942-9.548 9.215-9.548 9.548 4.275 9.548 9.548c-.001 5.272-4.109 9.159-9.382 9.159zm3.108-9.751c.795 0 1.439-.879 1.439-1.962s-.644-1.962-1.439-1.962-1.439.879-1.439 1.962.644 1.962 1.439 1.962z"
                />
              </svg>
              <svg
                id="icon-emojis-close"
                style={{ display: "none" }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="#263238"
                  fillOpacity=".45"
                  d="M22.499 6.977c0-.223-.151-.303-.338-.279l-9.911 7.223a.51.51 0 0 1-.673.098L1.831 6.803c-.186-.125-.334-.046-.334.177l.006 2.395c0 .224.151.509.339.632l9.75 7.206c.188.125.49.125.676 0l9.898-7.268a.84.84 0 0 0 .338-.63l-.005-2.338z"
                />
              </svg>
            </span>
          </button>
        </div>
        <div tabIndex="-1" className="_2bXVy">
          <div tabIndex="-1" className="_3F6QL _2WovP">
            {!message && (
              <div id="input-placeholder" className="_39LWd">
                {t("HOME.pr.active.footer.ph")}
              </div>
            )}
            <div
              id="input-text"
              className="_2S1VP copyable-text selectable-text"
              contentEditable={messagecontenteditable}
              data-tab="1"
              dir="ltr"
              spellCheck="true"
              onInput={onChange}
              onKeyDown={(e) => {
                if (e.keyCode === 13 && !e.shiftKey) {
                  e.preventDefault();
                  e.stopPropagation();
                  sendMessage();
                }
              }}
            />
          </div>
        </div>
        {inrecord && (
          <div id="record-microphone">
            <span>
              <div className="_1-hDo" style={{ opacity: "1" }}>
                <button
                  type="button"
                  id="btn-cancel-microphone"
                  className="cOcmP btn-cancel"
                  data-animate-btn-border="true"
                  style={{ transform: "scaleX(1) scaleY(1)", opacity: "1" }}
                  onClick={cancelRecord}
                >
                  <div className="ZBZ4i _1aVJp">
                    <span data-icon="round-x">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 35 35"
                        width="35"
                        height="35"
                      >
                        <path
                          opacity=".6"
                          fill="#E53935"
                          d="M23.983 12.314l-1.297-1.297-5.186 5.186-5.186-5.186-1.297 1.297 5.187 5.186-5.187 5.186 1.297 1.297 5.186-5.186 5.187 5.186 1.297-1.297-5.187-5.186 5.186-5.186z"
                        />
                        <path
                          opacity=".6"
                          fill="#E53935"
                          d="M17.5 34.75C7.988 34.75.25 27.012.25 17.5S7.988.25 17.5.25 34.75 7.988 34.75 17.5 27.012 34.75 17.5 34.75zm0-33.5C8.54 1.25 1.25 8.54 1.25 17.5S8.54 33.75 17.5 33.75s16.25-7.29 16.25-16.25S26.46 1.25 17.5 1.25z"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="ZBZ4i ZxQVp">
                    <span data-icon="round-x-inv">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 35 35"
                        width="35"
                        height="35"
                      >
                        <path
                          opacity=".6"
                          fill="#E53935"
                          d="M17.5.25C7.988.25.25 7.988.25 17.5S7.988 34.75 17.5 34.75s17.25-7.738 17.25-17.25S27.012.25 17.5.25zm6.483 22.436l-1.297 1.297-5.186-5.186-5.186 5.186-1.297-1.297 5.187-5.186-5.187-5.186 1.297-1.297 5.186 5.186 5.187-5.186 1.297 1.297-5.187 5.186 5.186 5.186z"
                        />
                      </svg>
                    </span>
                  </div>
                </button>
                <div
                  id="record-microphone-timer"
                  className="_30ie- record-time"
                  data-animate-ptt-counter="true"
                  style={{ transform: "scaleX(1) scaleY(1)", opacity: "1" }}
                >
                  {time}
                </div>
                <button
                  type="button"
                  id="btn-finish-microphone"
                  className="cOcmP btn-finish"
                  data-animate-btn-border="true"
                  style={{ transform: "scaleX(1) scaleY(1)", opacity: "1" }}
                  onClick={confirmRecord}
                >
                  <div className="ZBZ4i _1aVJp">
                    <span data-icon="round-send">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 35 35"
                        width="35"
                        height="35"
                      >
                        <path
                          opacity=".8"
                          fill="#09D261"
                          d="M17.5 34.75C7.988 34.75.25 27.012.25 17.5S7.988.25 17.5.25 34.75 7.988 34.75 17.5 27.012 34.75 17.5 34.75zm0-33.5C8.54 1.25 1.25 8.54 1.25 17.5S8.54 33.75 17.5 33.75s16.25-7.29 16.25-16.25S26.46 1.25 17.5 1.25z"
                        />
                        <path
                          opacity=".8"
                          fill="#09D261"
                          d="M14.3 21.4l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4-10.6 10.6z"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="ZBZ4i ZxQVp">
                    <span data-icon="round-send-inv">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 35 35"
                        width="35"
                        height="35"
                      >
                        <path
                          opacity=".8"
                          fill="#09D261"
                          d="M17.5.25C7.988.25.25 7.988.25 17.5S7.988 34.75 17.5 34.75s17.25-7.738 17.25-17.25S27.012.25 17.5.25zM14.3 24.2l-5.6-5.6 1.4-1.4 4.2 4.2 10.6-10.6 1.4 1.4-12 12z"
                        />
                      </svg>
                    </span>
                  </div>
                </button>
              </div>
            </span>
          </div>
        )}
        {/* <button
          className="_2lkdt"
          id="btn-send"
          type="button"
          onClick={sendMessage}
        >
          <span data-icon="send">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="#263238"
                fillOpacity=".45"
                d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
              />
            </svg>
          </span>
        </button> */}
        {inrecord ? (
          <div />
        ) : message.length ? (
          <button
            className="_2lkdt"
            id="btn-send"
            type="button"
            onClick={sendMessage}
          >
            <span data-icon="send">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="#263238"
                  fillOpacity=".45"
                  d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
                />
              </svg>
            </span>
          </button>
        ) : (
          <button
            className="_2lkdt"
            id="btn-send-microphone"
            type="button"
            style={{ display: "flex" }}
            onClick={microphoneStartRecord}
          >
            <span data-icon="ptt">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="#263238"
                  fillOpacity=".45"
                  d="M11.999 14.942c2.001 0 3.531-1.53 3.531-3.531V4.35c0-2.001-1.53-3.531-3.531-3.531S8.469 2.35 8.469 4.35v7.061c0 2.001 1.53 3.531 3.53 3.531zm6.238-3.53c0 3.531-2.942 6.002-6.237 6.002s-6.237-2.471-6.237-6.002H3.761c0 4.001 3.178 7.297 7.061 7.885v3.884h2.354v-3.884c3.884-.588 7.061-3.884 7.061-7.885h-2z"
                />
              </svg>
            </span>
          </button>
        )}
      </div>
    </Page>
  );
}
