import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DescriptionPage } from "./style";

export default function Description() {
  const { t } = useTranslation();
  const client = useSelector((state) => state.panelRight.clientinfofilter);
  return (
    <DescriptionPage>
      <div className="_1Gecv">
        <div className>
          <div className="_3mR1z">
            <div className="_3HPyS _1e77x">
              <span className="_2y8MV">
                {t("FC.chats.pr.active.info.description.title")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="_24nYt">
        <div tabIndex={-1} className="_2m-1n _1pY1V cPu3l _1YlWr">
          <div className="_1Rerq _1xryB">
            <div className="_3sKYI">
              <span
                dir="auto"
                className="_3Whw5 selectable-text invisible-space copyable-text"
              >
                {`${t("FC.chats.pr.active.info.description.name")}: ${
                  client.name
                }\n${t("FC.chats.pr.active.info.description.number")}: ${
                  client.number
                }\n${t("FC.chats.pr.active.info.description.wallet")}: ${
                  client.wallet ? client.wallet.name : ""
                }`}
              </span>
            </div>
            <span className="_3CkXi" />
            <span className="_1CUl1">
              <div className="_3-8bl" title="Editar">
                <span data-testid="pencil" data-icon="pencil" className>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                  >
                    <path
                      fill="currentColor"
                      d="M3.95 16.7v3.4h3.4l9.8-9.9-3.4-3.4-9.8 9.9zm15.8-9.1c.4-.4.4-.9 0-1.3l-2.1-2.1c-.4-.4-.9-.4-1.3 0l-1.6 1.6 3.4 3.4 1.6-1.6z"
                    />
                  </svg>
                </span>
              </div>
            </span>
          </div>
        </div>
      </div>
    </DescriptionPage>
  );
}
