import React from "react";
import { InfoContent } from "../style";
import InfoIcon from "@material-ui/icons/Info";
export function Info({ status }) {
  return (
    <InfoContent>
      <InfoIcon style={{ color: "#757575", width: "180px", height: "180px" }} />
      <div className="info">
        <div style={{ display: "flex", alignItems: "center" }}>
          A conexão com o Whatsapp se encontra:
          {status}.
        </div>
        Por favor, verifique a conexão.
      </div>
    </InfoContent>
  );
}
