import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTranslation } from "react-i18next";
import PersonIcon from "@material-ui/icons/Person";
import ActivityUsersTickets from "./ActivityUsersTickets";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: 600,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ActivityUsers({ close, users }) {
  const { t } = useTranslation();
  const [usersdata, setUsersdata] = React.useState(users);
  const [activityusertickets, setActivityusertickets] = React.useState({});
  const classes = useStyles();
  const handleClose = () => {
    close();
  };
  function handleOpenTickets(user) {
    setActivityusertickets(user);
  }
  return (
    <div>
      {activityusertickets.idusers && (
        <ActivityUsersTickets
          close={() => {
            setActivityusertickets({});
          }}
          user={activityusertickets}
        />
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open
        fullWidth
        maxWidth="md"
        className={classes.dialog}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("DB.dialogs.activityusers.title")}
        </DialogTitle>
        <DialogContent dividers>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                style={{ marginLeft: "57px" }}
              >
                {t("DB.dialogs.activityusers.description")}
              </ListSubheader>
            }
          >
            {!!usersdata.length &&
              usersdata.map((user, index) => (
                <>
                  <ListItem key={index}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <b>{user.username}</b> - {user.email}
                    </ListItemText>
                    <Button
                      onClick={() => {
                        handleOpenTickets(user);
                      }}
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                    >
                      Ver tickets ({user.ticketsaccepted})
                    </Button>
                  </ListItem>
                </>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
          >
            {t("DB.dialogs.activityusers.bt2")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
