import React from "react";
// import { Doughnut, Pie } from "react-chartjs-2";
import { Page } from "./style";
import HorizontalBarTickets from "./tickets";
import HorizontalBarAttendantTickets from "./attendantTickets";
import LineAttendantTimes from "./attendantTimes";
import UsersActive from "./usersActive";
import WalletsPie from "./walletsPie";
import WalletsTimer from "./walletsTimes";
// const data = {
//   labels: ["Red", "Green", "Yellow"],
//   datasets: [
//     {
//       data: [300, 50, 100],
//       backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
//       hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
//     },
//   ],
// };
// const datat = {
//   labels: ["January", "February", "March", "April", "May", "June", "July"],
//   datasets: [
//     {
//       label: "My First dataset",
//       backgroundColor: "rgba(255,99,132,0.2)",
//       borderColor: "rgba(255,99,132,1)",
//       borderWidth: 1,
//       hoverBackgroundColor: "rgba(255,99,132,0.4)",
//       hoverBorderColor: "rgba(255,99,132,1)",
//       data: [65, 59, 80, 81, 56, 55, 40],
//     },
//   ],
// };
export default function () {
  return (
    <Page>
      <div className="rowone">
        <UsersActive />
        <HorizontalBarTickets />
        {/* <WalletsPie /> */}
      </div>
      <div className="rowtwo">
        <HorizontalBarAttendantTickets />
      </div>
      <div className="rowtwo">
        <LineAttendantTimes />
      </div>
      <div className="rowtwo">
        <WalletsTimer />
      </div>
      {/* <div style={{ position: "relative", width: "30%" }}>
        <Pie data={data} />
        <Doughnut data={data} />
      </div> */}
    </Page>
  );
}
