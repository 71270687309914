import styled from "styled-components";

export const Page = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform: ${(props) => props.translate};
  transition: 0.3s ease-in-out;
  display: block;
  .infinite-scroll-component {
    overflow: hidden !important;
  }
  ._2fq0t {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
    flex-direction: column;
    pointer-events: auto;
    overflow: auto;
    background-color: #f7f7f7;
    ._1FroB {
      display: flex;
      flex: none;
      box-sizing: border-box;
      color: #fff;
      height: 108px;
      flex-direction: column;
      justify-content: flex-end;
      background-color: #00bfa5;
      padding-right: 20px;
      padding-left: 25px;
      .Ghmsz {
        align-items: center;
        display: flex;
        flex: none;
        width: 100%;
        text-align: initial;
        background-color: transparent;
        padding: 0;
        height: 59px;
        .SFEHG {
          flex: none;
          width: 54px;
          ._1aTxu {
            border: 0;
            margin: 0;
            background: 0 0;
            outline: 0;
            cursor: pointer;
            font-family: inherit;
            flex: none;
            margin-top: -2px;
            cursor: pointer;
            vertical-align: top;
          }
        }
        ._1xGbt {
          white-space: nowrap;
          -webkit-flex-grow: 1;
          flex-grow: 1;
          font-weight: 500;
          font-size: 19px;
          line-height: normal;
          text-overflow: ellipsis;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          overflow: hidden;
        }
      }
    }
    .alert {
      flex: none;
      transform-origin: top center;
      .alert-warning {
        background-color: #fed859;
        padding: 14px 0 13px;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        min-height: 62px;
        ._2fJ2T {
          flex: none;
          margin-left: 15px;
        }
        .l2BEH {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .alert-title {
            font-size: 16px;
            line-height: 21px;
            color: #37474f;
          }
          ._21zbN {
            font-size: 14px;
            line-height: 19px;
            color: #37474f;
            margin-top: 2px;
            opacity: 0.6;
            .alert-message {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
    .gQzdc {
      -webkit-flex: none;
      flex: none;
      height: 49px;
      box-sizing: border-box;
      position: relative;
      transition: box-shadow 0.18s ease-out, background-color 0.18s ease-out;
      transition-delay: 0.12s;
      padding: 0;
      background-color: transparent;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0px;
        background-color: rgba(255, 255, 255, 0.14);
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        left: 0;
        right: 0;
      }
      .form-add {
        padding: 10px;
        input {
          line-height: 30px;
        }
        button {
          display: block;
          background-color: #00bfa5;
          color: #fff;
          padding: 8px;
          width: 100%;
          font-size: 14px;
          margin-top: 26px;
          outline: none;
        }
        .field {
          display: flex;
          line-height: 25px;
          height: 25px;
          .jN-F5 {
            line-height: 20px;
            min-height: 20px;
            outline: 0;
            z-index: 1;
            -webkit-user-select: text;
            -moz-user-select: text;
            -ms-user-select: text;
            user-select: text;
            font-size: 15px;
            width: 100%;
            font-weight: 400;
            padding: 0;
            border: none;
            text-align: left;
            background-color: transparent;
          }
        }
      }
      .form-add ::-webkit-input-placeholder {
        color: #999 !important;
      }
    }
  }
  .copyable-area {
    user-select: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
  }
  ._210SC {
    z-index: 19;
    transition: none 0s ease 0s;
    height: 72px;
    transform: translateY(0px);
    .pr5ny {
      display: flex;
      justify-content: space-between;
      text-rendering: optimizeLegibility;
      -webkit-font-feature-settings: "kern";
      user-select: none;
      pointer-events: auto;
      font: inherit;
      vertical-align: initial;
      outline: none;
      margin: 0;
      border: 0;
      height: 72px;
      box-sizing: border-box;
      font-size: 16px;
      color: #009688;
      text-transform: uppercase;
      background-color: var(--background-default);
      padding: 30px 0 15px 32px;
      svg {
        color: #919191;
      }
    }
  }
`;
export const ContactList = styled.div`
  z-index: 326;
  height: 72px;
  transform: translate3d(0px, 0px, 0px);
  .contact-item {
    display: flex;
    flex-direction: row;
    height: 72px;
    position: relative;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #f4f5f5;
      ._3j7s9 {
        border-top-color: #f4f5f5;
      }
    }
    .dIyEr {
      margin-top: -1px;
      flex: none;
      display: flex;
      align-items: center;
      padding: 0 15px 0 13px;
      padding: 0 13px 0 15px;
      ._1WliW {
        height: 49px;
        width: 49px;
        cursor: pointer;
        background-color: #dfe5e7;
        border-radius: 50%;
        position: relative;
        img {
          display: none;
          width: 100%;
          height: 100%;
        }
        ._3ZW2E {
          position: absolute;
          top: 0;
          z-index: 1;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          .profilepic {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            display: block;
          }
          .source-icon {
            position: absolute;
            width: 24px;
            height: 24px;
            background-color: #dfe5e7;
            bottom: 0px;
            right: -5px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              display: block;
              width: 22px;
              height: 22px;
            }
          }
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    ._3j7s9 {
      flex-grow: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-top: 1px solid #f2f2f2;
      padding-right: 15px;
      ._2FBdJ {
        margin-top: -1px;
        align-items: center;
        line-height: normal;
        display: flex;
        text-align: left;
        ._25Ooe {
          display: flex;
          flex-grow: 1;
          font-size: 16px;
          line-height: 21px;
          overflow: hidden;
          text-align: left;
          font-weight: 400;
          ._1wjpf {
            display: flex;
            align-items: center;
            flex-grow: 1;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        ._3Bxar {
          margin-top: 4px;
          margin-left: 6px;
          line-height: 14px;
          color: rgba(0, 0, 0, 0.4);
          flex: none;
          font-size: 12px;
          max-width: 100%;
          overflow: hidden;
          ._3T2VG {
            text-transform: capitalize;
          }
        }
      }
      ._1AwDx {
        margin-top: 2px;
        align-items: center;
        color: rgba(0, 0, 0, 0.6);
        display: flex;
        font-size: 13px;
        line-height: 19px;
        min-height: 20px;
        ._itDl {
          text-align: left;
          background-color: transparent;
          flex-grow: 1;
          font-size: 14px;
          line-height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
          .vdXUe {
            font-weight: 400;
            color: #07bc4c;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          ._1wjpf {
            display: inline-block;
            overflow: hidden;
            flex-grow: 1;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          ._2_LEW {
            display: flex;
            align-items: center;
            .buttonaccept {
              button {
                outline: none;
                background: #34b7f1;
                border-radius: 5px;
                color: white;
                font-weight: 600;
                height: 25px;
              }
            }
            ._1VfKB {
              margin-right: 2px;
              vertical-align: top;
              flex: none;
              display: inline-block;
            }
            ._1wjpf {
              display: inline-block;
              overflow: hidden;
              flex-grow: 1;
              position: relative;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            /* ._3NFp9 {
                  display: flex;
                } */
            ._3Bxar {
              margin-top: 4px;
              margin-left: 6px;
              line-height: 14px;
              color: rgba(0, 0, 0, 0.4);
              flex: none;
              font-size: 12px;
              max-width: 100%;
              overflow: hidden;
              ._15G96 {
                vertical-align: top;
                display: inline-block;
                margin-right: 5px;
                margin-left: 5px;
                color: #fff;
                &:last-child {
                  margin-right: 0;
                }
                .OUeyt {
                  padding: 0.3em 0.4em 0.4em;
                  text-align: center;
                  background-color: #06d755;
                  border-radius: 0.6em;
                }
              }
            }
          }
        }
      }
    }
  }
  .contact-item.active:after,
  .contact-item:hover:after {
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 0;
    content: "";
  }
  .contact-item:hover:after {
    border-top: 1px solid #f4f5f5;
    left: 0;
    right: 0;
  }
`;
