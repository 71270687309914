import React from "react";

import ChatIcon from "@material-ui/icons/Chat";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useDispatch } from "react-redux";
import { Page } from "./style";
import whatsapp from "./img/whatsapp.png";
import api from "../../../../../services/axios";
import socket from "../../../../../services/socket";
import ico from "./img/ico.png";
import ClientDialog from "../../../Dialogs/client";
import History from "./history";
import Active from "./active";
// import Group from "./group";

import WhatsappPng from "./img/whatsapp.png";
import TelegramPng from "./img/telegram.png";

export default function Chats({ setRender }) {
  const { t } = useTranslation();
  const [openclientdialog, setOpenclientdialog] = React.useState(false);
  const [clientinfo, setClientinfo] = React.useState("");
  const [tickets, setTickets] = React.useState([]);
  const dispatch = useDispatch();
  const [disable, setDisable] = React.useState(false);
  const [chattype, setChatype] = React.useState(1);
  const user = JSON.parse(localStorage.user);
  function openChat(idclient, number) {
    dispatch({ type: "SET_USER_ACTIVE_CHAT", user: { idclient, number } });
    dispatch({ type: "SET_RENDER", display: "ACTIVECHAT" });
  }
  function acceptTicket(index) {
    setDisable(true);
    const ticket = tickets[index];
    ticket.status = "ACEITO";
    ticket.iduser = user.idusers;

    api
      .put("ticket/attend", ticket, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then(() => {
        openChat(ticket?.idclient, ticket?.number);
        setDisable(false);
      })
      .catch((e) => {
        setDisable(false);
        console.log(e);
      });
  }

  function getTickets() {
    const idsector = [];
    if (user.users_has_sectors && user.users_has_sectors.length) {
      user.users_has_sectors.map((sector) => {
        idsector.push(sector.idsector);
      });
    }
    api
      .get("/ticket/findticketsfiltred", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          idsector,
          status: "PENDENTE",
        },
      })
      .then((response) => {
        const tickets = [];
        if (response.data.length) {
          response.data.map((ticket) => {
            if (ticket.client.idwallet) {
              if (user.users_has_wallets.length) {
                const index = user.users_has_wallets.findIndex(
                  (wallet) => wallet.idwallet === ticket.client.idwallet
                );
                if (index !== -1) {
                  tickets.push(ticket);
                }
              }
            }
            //  else if (user.users_has_sectors[0].sub_sector) {
            //   if (
            //     user.users_has_sectors[0].sub_sector.sub_sectors_ddds.length
            //   ) {
            //     user.users_has_sectors[0].sub_sector.sub_sectors_ddds.map(
            //       (ddd) => {
            //         tickets.push(ticket.number.substring(2, 4) === ddd.cod);
            //       }
            //     );
            //   }
            // }
            else {
              tickets.push(ticket);
            }
          });
        }

        setTickets([...tickets]);
      });
  }

  function notify() {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    } else {
      const notification = new Notification("Novo Ticket", {
        icon: ico,
        body: "Você tem um novo ticket em aberto!",
      });
      notification.onclick = function () {
        window.open("http://138.0.196.120:5000/home");
      };
    }
  }

  React.useEffect(() => {
    getTickets();
    socket.on(`${user.company.prefix}_updateticket`, (data) => {
      console.log(data);
      if (data.idwallet) {
        if (user.users_has_wallets.length) {
          const index = user.users_has_wallets.findIndex(
            (wallet) => wallet.idwallet === data.idwallet
          );
          if (index !== -1) {
            getTickets();
          }
        }
      } else if (user.users_has_sectors && user.users_has_sectors.length) {
        const index = user.users_has_sectors.findIndex(
          (sector) => sector.idsector === data.idsector
        );
        if (index !== -1) {
          getTickets();
          notify();
        }
      }
    });
    socket.on(`${user.company.prefix}_newticket`, (data) => {
      if (data.idwallet) {
        if (user.users_has_wallets.length) {
          const index = user.users_has_wallets.findIndex(
            (wallet) => wallet.idwallet === data.idwallet
          );
          if (index !== -1) {
            getTickets();
            notify();
          }
        }
      } else if (user.users_has_sectors && user.users_has_sectors.length) {
        const index = user.users_has_sectors.findIndex(
          (sector) => sector.idsector === data.idsector
        );
        if (index !== -1) {
          getTickets();
          notify();
        }
      }
    });
    socket.on(`${user.idusers}_error`, (data) => {
      dispatch({
        type: "ADD_NOTIFY",
        notify: {
          type: "error",
          message: data.message,
          idclient: 0,
          read: false,
        },
      });
    });
  }, []);

  React.useEffect(() => {
    if (clientinfo) {
      setOpenclientdialog(true);
    } else {
      setOpenclientdialog(false);
    }
  }, [clientinfo]);
  return (
    <>
      {openclientdialog && clientinfo && (
        <ClientDialog
          func={() => {
            setClientinfo(false);
          }}
          ticket={clientinfo}
        />
      )}

      <Page id="scrollableDiv">
        <div tabIndex="-1" data-tab="3">
          <div>
            <div className="RLfQR">
              {!!tickets.length && (
                <div className="_210SC">
                  <div className="pr5ny">Tickets</div>
                </div>
              )}

              {tickets.map((ticket, index) => (
                <div id="contacts-messages-list" className="contact-list">
                  <div className="contact-item">
                    <div className="dIyEr">
                      <div className="_1WliW">
                        <img
                          src={whatsapp}
                          className="Qgzj8 gqwaM photo"
                          alt=""
                          style={{ display: "none" }}
                        />
                        <div className="_3ZW2E">
                          <div className="source-icon">
                            <img
                              src={
                                ticket.type === "WHATSAPP"
                                  ? WhatsappPng
                                  : TelegramPng
                              }
                              alt="source"
                            />
                          </div>
                          {ticket.client.profile_pic ? (
                        <img
                          src={ticket.client.profile_pic}
                          alt="perfil"
                          className="profilepic"
                        />
                      ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 212 212"
                            width="212"
                            height="212"
                          >
                            <path
                              fill="#DFE5E7"
                              d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"
                            />
                            <g fill="#FFF">
                              <path d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z" />
                            </g>
                          </svg>
                      )}
                        </div>
                      </div>
                    </div>
                    <div className="_3j7s9">
                      <div className="_2FBdJ">
                        <div className="_25Ooe">
                          <span
                            dir="auto"
                            title="Nome do Contato"
                            className="_1wjpf"
                          >
                            <VisibilityIcon
                              style={{ margin: "0px 5px" }}
                              onClick={() => {
                                setClientinfo(ticket);
                              }}
                            />
                            {ticket.client ? ticket.client.name : ticket.number}
                          </span>
                        </div>
                        <div className="_3Bxar">
                          <span className="_3T2VG">
                            {`${new Date(
                              ticket.timestamp
                            ).toLocaleDateString()} ${new Date(
                              ticket.timestamp
                            ).toLocaleString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}`}
                          </span>
                        </div>
                      </div>
                      <div className="_1AwDx">
                        <div className="_itDl">
                          <span className="_2_LEW last-message">
                            {/* <div className="_1VfKB">
                            <span data-icon="status-dblcheck" className="">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 18 18"
                                width="18"
                                height="18"
                              >
                                <path
                                  fill="#263238"
                                  fillOpacity=".4"
                                  d="M17.394 5.035l-.57-.444a.434.434 0 0 0-.609.076l-6.39 8.198a.38.38 0 0 1-.577.039l-.427-.388a.381.381 0 0 0-.578.038l-.451.576a.497.497 0 0 0 .043.645l1.575 1.51a.38.38 0 0 0 .577-.039l7.483-9.602a.436.436 0 0 0-.076-.609zm-4.892 0l-.57-.444a.434.434 0 0 0-.609.076l-6.39 8.198a.38.38 0 0 1-.577.039l-2.614-2.556a.435.435 0 0 0-.614.007l-.505.516a.435.435 0 0 0 .007.614l3.887 3.8a.38.38 0 0 0 .577-.039l7.483-9.602a.435.435 0 0 0-.075-.609z"
                                />
                              </svg>
                            </span>
                          </div> */}
                            <span dir="ltr" className="_1wjpf _3NFp9">
                              {ticket.message}
                            </span>
                            <div role="button" className="buttonaccept">
                              <button
                                type="button"
                                onClick={() => {
                                  acceptTicket(index);
                                }}
                                disabled={disable}
                              >
                                Atender
                              </button>
                            </div>
                            <div className="_3Bxar">
                              <div className="_15G96">
                                <span
                                  className="OUeyt messages-count-new"
                                  style={{ display: "none" }}
                                >
                                  1
                                </span>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="_210SC">
                <div className="pr5ny">Conversas Compartilhadas</div>
              </div> */}
              {/* <Group /> */}
              <div className="_210SC">
                <div className="pr5ny">
                  {t("HOME.pl.init.chats.conversations")}
                  <div
                    title="Nova Conversa"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setRender("3");
                    }}
                  >
                    <ChatIcon />
                  </div>
                </div>
              </div>
              <div className="change_chat_type">
                <div
                  className={`type ${chattype === 1 ? "active" : ""}`}
                  onClick={() => {
                    setChatype(1);
                  }}
                >
                  Em Atendimento
                </div>
                <div
                  className={`type ${chattype === 2 ? "active" : ""}`}
                  onClick={() => {
                    setChatype(2);
                  }}
                >
                  Histórico
                </div>
              </div>
              {chattype === 1 ? <Active /> : <History />}
            </div>
          </div>
        </div>
      </Page>
    </>
  );
}
