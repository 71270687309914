import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SyncIcon from "@material-ui/icons/Sync";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useDispatch } from "react-redux";
import DeleteCnpj from "./Dialogs/DeleteCNPJ";
import DeleteClient from "./Dialogs/DeleteClient";
import EditCnpj from "./Dialogs/EditCNPJ";
import EditClient from "./Dialogs/EditClient";
import api from "../../services/axios";
import NewUser from "./Dialogs/NewUser";
import Can from "../../components/Can";
import { useTranslation } from "react-i18next";
import { Page } from "./style";
import SyncConfirmation from "./Dialogs/SyncConfirmation";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default function () {
  const [open, setOpen] = React.useState(false);
  const [openuser, setOpenuser] = React.useState(false);
  const [opendeletecnpj, setOpendeletecnpj] = React.useState(false);
  const [openedituser, setOpenedituser] = React.useState(false);
  const [openeditcnpj, setOpeneditcnpj] = React.useState(false);
  const [openconfirm, setOpenconfirm] = React.useState(false);
  const [clients, setClients] = React.useState([]);
  const [deleteclient, setDeleteclient] = React.useState("");
  const [deletecnpj, setDeletecnpj] = React.useState("");
  const [editclient, setEditclient] = React.useState("");
  const [editcnpj, setEditcnpj] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = React.useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const identifyCpfOrCnpj = (data) => {
    if (!data) {
      return false;
    }
    data.replace(/([^\d])+/gim, "");
    if (data.length > 11) {
      return "CNPJ";
    }
    return "CPF";
  };
  const formatData = (data) => {
    if (!data) {
      return "Não Cadastrado";
    }
    if (data.length > 11) {
      return data.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    }
    return data.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };
  const synchronization = async (message, date) => {
    setOpenconfirm(false);

    api
      .post(
        "tasks",
        {
          description: message,
          timestamp: date,
          method: "clientSync",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "success",
            message: "Tarefa adicionada!",
            idclient: 0,
            read: false,
          },
        });
        updateData();
      })
      .catch((e) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: e.response.data.error,
            idclient: 0,
            read: false,
          },
        });
      });
  };
  const fetchData = () => {
    api
      .get(`clients`, {
        params: {
          key: debouncedSearchValue,
          size: rowsPerPage,
          page,
        },
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        console.log(res.data.count);
        setClients(res.data.rows);
        setCount(res.data.count);
      })
      .catch((e) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: e.response.data.error,
            idclient: 0,
            read: false,
          },
        });
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchValue(search);
    }, 500); // Tempo de espera em milissegundos (aqui definimos 500ms)

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  useEffect(() => {
    if(page === 0){
      fetchData()
    }else{
      setPage(0);
    }
  }, [debouncedSearchValue]);

  const updateData = async () => {
    await api
      .get("clients", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setClients([...res.data.rows]);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const changePage = (page, order) => {
    setPage(page);
  };
  const changeRowsPerPage = (rows, order) => {
    setRowsPerPage(rows);
  };
  const handleSearch = (key) => {
    setSearch(key);
  };
  const onSearchClose = () => {
    setSearch("");
    setPage(0);
  };
  const deleteClientUpdateData = (idclient) => {
    const oldData = clients;
    const index = oldData.findIndex((client) => client.idclient === idclient);
    oldData.splice(index, 1);
    setClients(oldData);
    setOpen(false);
  };
  const deleteClientCNPJUpdateData = (idclient, idcliente_cnpj) => {
    const oldData = clients;
    const index = oldData.findIndex((client) => client.idclient === idclient);
    const indexCnpj = oldData[index].cliente_cnpjs.findIndex(
      (cnpj) => cnpj.idcliente_cnpj === idcliente_cnpj
    );
    oldData[index].cliente_cnpjs.splice(indexCnpj, 1);
    setClients(oldData);
    setOpendeletecnpj(false);
  };
  const clientCNPJUpdateData = (idclient, cnpj) => {
    const oldData = clients;
    const index = oldData.findIndex((client) => client.idclient === idclient);
    const indexCnpj = oldData[index].cliente_cnpjs.findIndex(
      (value) => value.idcliente_cnpj === cnpj.idcliente_cnpj
    );
    oldData[index].cliente_cnpjs[indexCnpj] = cnpj;
    setClients(oldData);
    setOpeneditcnpj(false);
  };
  const clientUpdateData = (client) => {
    const oldData = clients;
    const index = oldData.findIndex(
      (value) => value.idclient === client.idclient
    );
    oldData[index] = client;
    setClients(oldData);
    setOpenedituser(false);
  };
  const newClientUpdateData = (client) => {
    const oldData = clients;
    oldData.push(client);
    setClients(oldData);
    setOpenuser(false);
  };
  const options = {
    page: page,
    rowsPerPage: rowsPerPage,
    count: count,
    onChangePage: (newPage) => setPage(newPage),
    onChangeRowsPerPage: (value) => {
      setRowsPerPage(value);
    },
    onSearchChange: (value) => setSearch(value),
    filterType: false,
    print: false,
    // sort: false,
    fixedHeader: true,
    viewColumns: false,
    serverSideFilterList: false,
    serverSide: true,
    download: false,
    filter: false,
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Desculpe, nenhum dado encontrado"
        ),
        toolTip: "Sort",

        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        rowsPerPage: "Linhas por Página",
      },
    },
    expandableRows: true,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const client = clients[rowMeta.dataIndex];
      return (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <MUIDataTable
              title=""
              data={
                client.cliente_cnpjs.length
                  ? client.cliente_cnpjs.map((clientitem, index) => [
                      clientitem.idcliente_cnpj,
                      clientitem.cnpj,
                      clientitem.rzsocial
                        ? clientitem.rzsocial
                        : clientitem.nmfantasia,
                      clientitem.cd_cliente_dislub,
                      <Can
                        role={JSON.parse(localStorage.user).role.idroles}
                        type="clients"
                        action="update"
                        yes={() => (
                          <EditIcon
                            style={{ fill: "#34b7f1" }}
                            onClick={() => {
                              setEditcnpj({
                                client,
                                establishments: clientitem,
                              });
                              setOpeneditcnpj(true);
                            }}
                          />
                        )}
                      />,
                      <Can
                        role={JSON.parse(localStorage.user).role.idroles}
                        type="clients"
                        action="delete"
                        yes={() => (
                          <HighlightOffIcon
                            style={{ fill: "#34b7f1" }}
                            onClick={() => {
                              setDeletecnpj({
                                client,
                                establishments: clientitem,
                              });
                              setOpendeletecnpj(true);
                            }}
                          />
                        )}
                      />,
                    ])
                  : []
              }
              columns={columnsexpand}
              options={optionstwo}
            />
          </TableCell>
        </TableRow>
      );
    },
    onTableChange: (action, tableState) => {
      // console.log(action, tableState);
      // switch (action) {
      //   case "changePage":
      //     changePage(tableState.page, tableState.sortOrder);
      //     break;
      //   case "changeRowsPerPage":
      //     changeRowsPerPage(tableState.rowsPerPage, tableState.sortOrder);
      //     break;
      //   case "onSearchOpen":
      //     //this.sort(tableState.page, tableState.sortOrder);
      //     break;
      //   case "onSearchClose":
      //     onSearchClose();
      //     break;
      //   case "search":
      //     handleSearch(tableState.searchText);
      //     break;
      //   default:
      //     console.log("action not handled.");
      // }
    },
  };
  const optionstwo = {
    filterType: false,
    print: false,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    filter: false,
    selectableRows: "none",
    search: false,
    pagination: false,
    customToolbar: null,
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum dado encontrado",
      },
    },
  };
  const columns = [
    "ID",
    t("CT.table.name"),
    "CPF / CPNJ",
    "Whatsapp",
    t("CT.table.wallet"),
    t("CT.table.edit"),
    t("CT.table.delete"),
    t("CT.table.cod"),
  ];
  const columnsexpand = [
    "ID",
    "CNPJ",
    t("CT.tableexpand.rz"),
    t("CT.tableexpand.cdclient"),
    t("CT.tableexpand.edit"),
    t("CT.tableexpand.delete"),
  ];
  return (
    <Page>
      {open && (
        <DeleteClient
          open={open}
          data={deleteclient}
          func={setOpen}
          update={deleteClientUpdateData}
        />
      )}
      {openuser && (
        <NewUser
          open={openuser}
          func={setOpenuser}
          update={newClientUpdateData}
        />
      )}
      {openedituser && (
        <EditClient
          open={openedituser}
          data={editclient}
          func={setOpenedituser}
          update={clientUpdateData}
        />
      )}
      {openeditcnpj && (
        <EditCnpj
          open={openeditcnpj}
          establishments={editcnpj}
          func={setOpeneditcnpj}
          update={clientCNPJUpdateData}
        />
      )}
      {opendeletecnpj && (
        <DeleteCnpj
          open={opendeletecnpj}
          establishments={deletecnpj}
          func={setOpendeletecnpj}
          update={deleteClientCNPJUpdateData}
        />
      )}
      {openconfirm && (
        <SyncConfirmation
          cancel={() => {
            setOpenconfirm(false);
          }}
          confirm={synchronization}
        />
      )}
      <MUIDataTable
        title={
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="clients"
            action="create"
            yes={() => (
              <div>
                <Button
                  onClick={() => {
                    setOpenuser(true);
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  startIcon={<AddCircleIcon />}
                >
                  {t("CT.bt1")}
                </Button>
                <Button
                  onClick={() => {
                    setOpenconfirm(true);
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                >
                  <SyncIcon />
                </Button>
              </div>
            )}
          />
        }
        data={clients.map((client, index) => [
          client.idclient,
          client.name,
          formatData(client.cpf),
          client.number,
          client.wallet ? client.wallet.name : null,
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="clients"
            action="update"
            yes={() => (
              <EditIcon
                style={{ fill: "#34b7f1" }}
                onClick={() => {
                  setOpenedituser(true);
                  setEditclient(clients[index]);
                }}
              />
            )}
          />,
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="clients"
            action="delete"
            yes={() => (
              <HighlightOffIcon
                style={{ fill: "#34b7f1" }}
                onClick={() => {
                  setOpen(true);
                  setDeleteclient(clients[index]);
                }}
              />
            )}
          />,
          client.cliente_cnpjs.map((x) => x.cd_cliente_dislub).join(", "),
        ])}
        columns={columns}
        options={options}
      />
    </Page>
  );
}
