import styled from "styled-components";

export const Page = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  .rowone {
    position: relative;
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: center;
    padding: 20px;
    flex-wrap: wrap;
  }
  .rowtwo {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-wrap: wrap;
  }
`;

export const TicketsSector = styled.div`
  .mask {
    display: flex;
    position: absolute;
    z-index: 2;
    background: white;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  position: relative;
  width: 50%;
  min-width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .titleperiod {
    color: #777777;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin: 2px;
      padding: 0px;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        cursor: pointer;
        margin: 0px 5px;
        font-weight: 600;
      }
    }
  }
`;
export const TicketsSectorAttendant = styled.div`
  .mask {
    display: flex;
    position: absolute;
    z-index: 2;
    background: white;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  position: relative;
  width: 100%;
  height: 600px;
  min-width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .titleperiod {
    color: #777777;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin: 2px;
      padding: 0px;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        cursor: pointer;
        margin: 0px 5px;
        font-weight: 600;
      }
    }
  }
  .chart {
    width: 100%;
    height: 500px;
  }
`;
export const WalletsPieGraphic = styled(TicketsSector)`
  position: relative;
  min-width: 200px;
`;
export const AttendantTicketGraphic = styled(TicketsSector)`
  width: 100%;
`;

export const UsersActive = styled.div`
  width: 50%;
  .title {
    color: #777777;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-btn{
      cursor: pointer;
      text-decoration: underline;
      font-weight: 600;
    }
    h3 {
      margin: 2px;
      padding: 0px;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        cursor: pointer;
        margin: 0px 5px;
        font-weight: 600;
      }
    }
  }
  .users {
    width: 50%;
    max-height: 300px;
    overflow-y: scroll;
  }
  .profile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #777777;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: white;
      }
    }
    .circle {
      width: 12px;
      height: 12px;
      background-color: #2ed8b6;
      margin-left: 8px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
`;
