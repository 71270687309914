import React, { useEffect, useState } from "react";
import socket from "../../../../../services/socket";

export default function ({ message }) {
  const user = JSON.parse(localStorage.user);
  const [status, setStatus] = useState(
    message.pending ? "PENDING" : message.status
  );
  useEffect(() => {
    socket.on(
      `${user.company.prefix}_updatemessage${message.idmessages}`,
      ({ status }) => {
        if (status) {
          setStatus(status);
        }
      }
    );
  }, []);

  return (
    <div className="message">
      <div
        className={`font-style _3DFk6 message-${
          message.client_message > 0 ? "in" : "out"
        } tail`}
        style={{
          backgroundColor: message.color && message.color,
        }}
      >
        <span className="tail-container" />
        <span className="tail-container highlight" />
        <div className="Tkt2p">
          <div className="_3zb-j ZhF0n">
            <span
              dir="ltr"
              className="selectable-text invisible-space message-text"
            >
              {message.content}
            </span>
          </div>
          <div className="_2f-RV">
            <div className="_1DZAH">
              <span className="msg-time">
                {" "}
                {new Date(message.timestamp).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
              {status === "PENDING" && (
                <div className="_2nWgr">
                  <span
                    data-testid="msg-time"
                    aria-label=" Pendente "
                    data-icon="msg-time"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 15"
                      width="16"
                      height="15"
                    >
                      <path
                        fill="currentColor"
                        d="M9.75 7.713H8.244V5.359a.5.5 0 0 0-.5-.5H7.65a.5.5 0 0 0-.5.5v2.947a.5.5 0 0 0 .5.5h.094l.003-.001.003.002h2a.5.5 0 0 0 .5-.5v-.094a.5.5 0 0 0-.5-.5zm0-5.263h-3.5c-1.82 0-3.3 1.48-3.3 3.3v3.5c0 1.82 1.48 3.3 3.3 3.3h3.5c1.82 0 3.3-1.48 3.3-3.3v-3.5c0-1.82-1.48-3.3-3.3-3.3zm2 6.8a2 2 0 0 1-2 2h-3.5a2 2 0 0 1-2-2v-3.5a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v3.5z"
                      />
                    </svg>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
