import React from "react";
import DialogShowMedia from "../Dialog/DialogShowMedia";
import socket from "../../../../../services/socket";
export default function ({ message }) {
  const [showmedia, setShowmedia] = React.useState(false);
  const user = JSON.parse(localStorage.user);
  const [status, setStatus] = React.useState(
    message.pending ? "PENDING" : message.status
  );
  React.useEffect(() => {
    socket.on(
      `${user.company.prefix}_updatemessage${message.idmessages}`,
      ({ status }) => {
        if (status) {
          setStatus(status);
        }
      }
    );
  }, []);
  return (
    <>
      {showmedia && (
        <DialogShowMedia
          close={() => {
            setShowmedia(false);
          }}
          mediasrc={`${message.pending ? "" : process.env.REACT_APP_API_URL}${
            message.media
          }`}
        />
      )}

      <div className="message">
        <div
          className={`_3_7SH _3qMSo message-${
            message.client_message > 0 ? "in" : "out"
          }`}
        >
          <div className="KYpDv">
            <div>
              <div
                className="_3v3PK"
                style={{ width: "330px", height: "330px" }}
              >
                {/* <div className="_34Olu">
                <div className="_2BzIU">
                  <div className="_2X3l6">
                    <svg
                      className="_1UDDE"
                      width="50"
                      height="50"
                      viewBox="0 0 43 43"
                    >
                      <circle
                        className="_3GbTq _2wGBy"
                        cx="21.5"
                        cy="21.5"
                        r="20"
                        fill="none"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                  <div className="_1l3ap">
                    <span data-icon="media-disabled" className="">
                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 44 44"
                        width="44"
                        height="44"
                      >
                        <path
                          fill="#FFF"
                          fillOpacity=".4"
                          d="M29.377 16.099l-1.475-1.475L22 20.525l-5.901-5.901-1.476 1.475L20.525 22l-5.901 5.901 1.476 1.475 5.9-5.901 5.901 5.901 1.475-1.475L23.475 22l5.902-5.901z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div> */}
                <img
                  src={`${
                    message.pending ? "" : process.env.REACT_APP_API_URL
                  }${message.media}`}
                  className="_1JVSX message-photo"
                  style={{ width: "100%" }}
                  loading="lazy"
                  alt=""
                  onClick={() => {
                    setShowmedia(true);
                  }}
                />
                <div className="_1i3Za" />
              </div>
              <div className="message-container-legend">
                <div className="_3zb-j ZhF0n">
                  <span
                    dir="ltr"
                    className="selectable-text invisible-space copyable-text message-text"
                  >
                    {message.content}
                  </span>
                </div>
              </div>
              <div className="_2TvOE">
                <div className="_1DZAH text-white" role="button">
                  <span className="message-time">
                    {" "}
                    {new Date(message.timestamp).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                  {status === "PENDING" && (
                    <div className="_2nWgr">
                      <span
                        data-testid="msg-time"
                        aria-label=" Pendente "
                        data-icon="msg-time"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 15"
                          width="16"
                          height="15"
                        >
                          <path
                            fill="currentColor"
                            d="M9.75 7.713H8.244V5.359a.5.5 0 0 0-.5-.5H7.65a.5.5 0 0 0-.5.5v2.947a.5.5 0 0 0 .5.5h.094l.003-.001.003.002h2a.5.5 0 0 0 .5-.5v-.094a.5.5 0 0 0-.5-.5zm0-5.263h-3.5c-1.82 0-3.3 1.48-3.3 3.3v3.5c0 1.82 1.48 3.3 3.3 3.3h3.5c1.82 0 3.3-1.48 3.3-3.3v-3.5c0-1.82-1.48-3.3-3.3-3.3zm2 6.8a2 2 0 0 1-2 2h-3.5a2 2 0 0 1-2-2v-3.5a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v3.5z"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="_3S8Q-" role="button">
          <span data-icon="forward-chat">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25 25"
              width="25"
              height="25"
            >
              <path
                fill="#FFF"
                d="M14.2 9.5V6.1l5.9 5.9-5.9 6v-3.5c-4.2 0-7.2 1.4-9.3 4.3.8-4.2 3.4-8.4 9.3-9.3z"
              />
            </svg>
          </span>
        </div> */}
        </div>
      </div>
    </>
  );
}
