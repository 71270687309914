import React, { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import api from "../../services/axios";
import socket from "../../services/socket";
import { Container, GlobalStyle } from "./style";
import { Info } from "./Components/Info";
import { GetContacts } from "./Components/GetContacts";
import { ImportArea } from "./Components/ImportArea";

export default () => {
  const user = JSON.parse(localStorage.user);
  const [status, setStatus] = useState("");
  const [contacts, setContacts] = useState([]);

  const getStatus = () => {
    api
      .get("/whatsapp/status", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        if (!response.data) {
          return setStatus("");
        }
        setStatus(response.data);
      })
      .catch((e) => console.log(e));
  };

  const getStatusName = () => {
    switch (status.status) {
      case 0:
        return (
          <div
            style={{
              background: "#34b7f1",
              borderRadius: "5px",
              marginLeft: "5px",
              padding: "5px",
            }}
          >
            <p
              style={{
                color: "white",
                margin: 0,
                padding: 0,
              }}
            >
              Inativo
            </p>
          </div>
        );
      case 1:
        return (
          <div
            style={{
              background: "#34b7f1",
              borderRadius: "5px",
              marginLeft: "5px",
              padding: "5px",
            }}
          >
            <p
              style={{
                color: "white",
                margin: 0,
                padding: 0,
              }}
            >
              Conectado
            </p>
          </div>
        );
      default:
        return (
          <div
            style={{
              background: "#f50057",
              borderRadius: "5px",
              marginLeft: "5px",
              padding: "5px",
            }}
          >
            <p
              style={{
                color: "white",
                margin: 0,
                padding: 0,
              }}
            >
              Desconectado
            </p>
          </div>
        );
    }
  };

  React.useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    socket.on(`${user.company.prefix}_whatsappconencted`, () => {
      getStatus();
    });
    socket.on(`${user.company.prefix}_whatsappdisconnected`, () => {
      getStatus();
    });
  }, []);

  return (
    <>
      <Container>
        {!status || status.status === 0 ? (
          <Info status={getStatusName()} />
        ) : contacts.length ? (
          <ImportArea contacts={contacts} setContacts={setContacts} />
        ) : (
          <GetContacts setContacts={setContacts} />
        )}
      </Container>
      <GlobalStyle />
    </>
  );
};
