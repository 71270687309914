import React from "react";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Page, ContactList } from "./style";
import whatsapp from "./img/whatsapp.png";
import api from "../../../../services/axios";

const Profile = (props) => {
  const [page, setPage] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const [findstrg, setFindstrg] = React.useState("");
  const [clients, setClients] = React.useState([]);
  const [clientsfilter, setClientsfilter] = React.useState([]);
  const [animation, setAnimation] = React.useState({
    ani: "translateX(-450px)",
    reset: false,
  });
  const dispatch = useDispatch();
  const getClient = async () => {
    await api
      .get("clients", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: { representative: ["0", "1"] },
      })
      .then((res) => {
        setPage(page + 1);
        setClients([...res.data.rows]);
        const srcData = res.data.rows.slice(page * 30, (page + 1) * 30);
        setClientsfilter(srcData);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  function moreClients() {
    if (clientsfilter.length >= clients.length) {
      return setHasMore(false);
    }
    setPage(page + 1);
    const srcData = clients.slice(page * 30, (page + 1) * 30);
    setClientsfilter([...clientsfilter, ...srcData]);
  }

  const backInitialComponent = () => {
    setAnimation({ reset: true, ani: "translateX(-450px)" });
  };
  function openChat(idclient, number) {
    dispatch({ type: "SET_USER_ACTIVE_CHAT", user: { idclient, number } });
    dispatch({ type: "SET_RENDER", display: "ACTIVECHAT" });
  }
  let timer = null;
  const onChange = (ev) => {
    const input = ev.currentTarget.value;
    setFindstrg(input);
    clearTimeout(timer);
    if (!input) {
      setPage(1);
      const srcData = clients.slice(0 * 30, (0 + 1) * 30);
      setClientsfilter(srcData);
    } else {
      timer = setTimeout(() => {
        const result = clients.filter((x) => {
          const obj = JSON.parse(JSON.stringify(x));
          obj.cliente_cnpjs = JSON.stringify(obj.cliente_cnpjs);
          return Object.values(obj)
            .join(" ")
            .toLowerCase()
            .includes(input.toLowerCase());
        });
        setClientsfilter(result);
      }, 500);
    }
  };
  React.useEffect(() => {
    setTimeout(() => {
      setAnimation({ ...animation, ani: "translateX(0px)" });
    }, 100);
    getClient();
  }, []);
  React.useEffect(() => {
    if (animation.reset) {
      setTimeout(() => {
        props.setRender("1");
      }, 300);
    }
  }, [animation]);

  return (
    <Page translate={animation.ani}>
      <div id="scrollableDiv" className="_2fq0t copyable-area">
        <header className="_1FroB">
          <div className="Ghmsz" data-animate-drawer-title="true">
            <div className="SFEHG">
              <button
                type="button"
                onClick={backInitialComponent}
                className="_1aTxu btn-close"
                id="btn-close-panel-add-contact"
              >
                <span data-icon="back-light">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      fill="#FFF"
                      d="M20 11H7.8l5.6-5.6L12 4l-8 8 8 8 1.4-1.4L7.8 13H20v-2z"
                    />
                  </svg>
                </span>
              </button>
            </div>
            <div className="_1xGbt">Nova conversa</div>
          </div>
        </header>
        <div tabIndex="-1">
          <div className="gQzdc">
            <form className="form-add" id="form-panel-add-contact">
              <label className="field">
                <input
                  type="email"
                  className="jN-F5 copyable-text"
                  placeholder="Nome ou Número de Telefone."
                  name="email"
                  value={findstrg}
                  onChange={onChange}
                  style={{ color: "#919191" }}
                />
                <span data-icon="search">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      fill="#263238"
                      fillOpacity=".3"
                      d="M15.009 13.805h-.636l-.22-.219a5.184 5.184 0 0 0 1.256-3.386 5.207 5.207 0 1 0-5.207 5.208 5.183 5.183 0 0 0 3.385-1.255l.221.22v.635l4.004 3.999 1.194-1.195-3.997-4.007zm-4.808 0a3.605 3.605 0 1 1 0-7.21 3.605 3.605 0 0 1 0 7.21z"
                    />
                  </svg>
                </span>
              </label>
            </form>
          </div>
        </div>
        <div className="_210SC">
          <div className="pr5ny">Contatos</div>
        </div>
        <InfiniteScroll
          dataLength={clientsfilter.length}
          next={moreClients}
          hasMore={hasMore}
          loader={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          }
          scrollableTarget="scrollableDiv"
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Estes são todos os dados</b>
            </p>
          }
        >
          {clientsfilter.map((client, index) => (
            <ContactList
              key={index}
              id="contacts-messages-list"
              className="contact-list"
              onClick={() => {
                openChat(client.idclient, client.number);
              }}
            >
              <div className="contact-item">
                <div className="dIyEr">
                  <div className="_1WliW">
                    <img
                      src={whatsapp}
                      className="Qgzj8 gqwaM photo"
                      alt=""
                      style={{ display: "none" }}
                    />
                    <div className="_3ZW2E">
                      {client.has_whatsapp ? (
                        <div className="source-icon">
                          <img src={whatsapp} alt="source" />
                        </div>
                      ) : (
                        ""
                      )}

                      {client.profile_pic ? (
                        <img
                          src={client.profile_pic}
                          alt="perfil"
                          className="profilepic"
                        />
                      ) : (
                        <span data-icon="default-user">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 212 212"
                            width="212"
                            height="212"
                          >
                            <path
                              fill="#DFE5E7"
                              d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"
                            />
                            <g fill="#FFF">
                              <path d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z" />
                            </g>
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="_3j7s9">
                  <div className="_2FBdJ">
                    <div className="_25Ooe">
                      <span
                        dir="auto"
                        title={client.name}
                        className="_1wjpf"
                        style={{
                          color:
                            client.representative === 1 && "rgb(52, 183, 241)",
                        }}
                      >
                        {client.name}
                      </span>
                    </div>
                    <div className="_3Bxar">
                      <span className="_3T2VG">
                        {new Date(client.timestamp).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                  <div className="_1AwDx">
                    <div className="_itDl">
                      <span className="_2_LEW last-message">
                        {/* <div className="_1VfKB">
                                     <span data-icon="status-dblcheck" className="">
                                       <svg
                                         xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 18 18"
                                         width="18"
                                         height="18"
                                       >
                                         <path
                                           fill="#263238"
                                           fillOpacity=".4"
                                           d="M17.394 5.035l-.57-.444a.434.434 0 0 0-.609.076l-6.39 8.198a.38.38 0 0 1-.577.039l-.427-.388a.381.381 0 0 0-.578.038l-.451.576a.497.497 0 0 0 .043.645l1.575 1.51a.38.38 0 0 0 .577-.039l7.483-9.602a.436.436 0 0 0-.076-.609zm-4.892 0l-.57-.444a.434.434 0 0 0-.609.076l-6.39 8.198a.38.38 0 0 1-.577.039l-2.614-2.556a.435.435 0 0 0-.614.007l-.505.516a.435.435 0 0 0 .007.614l3.887 3.8a.38.38 0 0 0 .577-.039l7.483-9.602a.435.435 0 0 0-.075-.609z"
                                         />
                                       </svg>
                                     </span>
                                   </div> */}
                        <span dir="ltr" className="_1wjpf _3NFp9">
                          {client.number}
                        </span>
                      </span>
                      <span className="_2_LEW last-message">
                        <span dir="ltr" className="_1wjpf _3NFp9">
                          CD.Interno:{" "}
                          {client.cliente_cnpjs
                            .map((x) => x.cd_cliente_dislub)
                            .join(", ")}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ContactList>
          ))}
        </InfiniteScroll>
      </div>
    </Page>
  );
};
export default Profile;
