import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const DialogWidth = styled.div`

`;