import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import fileicon from "../Images/file.png";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "rgb(0, 191, 165)",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function FullScreenDialog({ close, file, sendFile }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [mediasrc, setMediasrc] = React.useState("");
  const handleClose = () => {
    close();
  };
  const sendFileToApi = async () => await sendFile();
  React.useEffect(() => {
    const link = window.URL.createObjectURL(file);
    setMediasrc(link);
  }, [file]);
  return (
    <div>
      <Dialog
        fullScreen
        open
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("HOME.pr.active.dialogs.sendfile.bt2")}
            </Typography>
            <Button autoFocus color="inherit" onClick={sendFileToApi}>
              {t("HOME.pr.active.dialogs.sendfile.bt1")}
            </Button>
          </Toolbar>
        </AppBar>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "30px",
            }}
            src={fileicon}
            alt=""
          />
          <div className="namefile" style={{ color: "#4a4a4a" }}>
            {file.name}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
