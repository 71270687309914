import crypto from "crypto";
import store from "../store/index";

function decrypt(text) {
  const iv = Buffer.from(text["aXY="], "hex");
  const encryptedText = Buffer.from(text["ZW5jcnlwdGVkRGF0YQ=="], "hex");
  const decipher = crypto.createDecipheriv(
    "aes-256-cbc",
    Buffer.from(process.env.REACT_APP_KEY),
    iv
  );
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}
let { rules } = [];
if (localStorage["cnVsZXM="]) {
  rules = JSON.parse(decrypt(JSON.parse(localStorage["cnVsZXM="])));
}

store.subscribe(() => {
  rules = store.getState().rules;
});
export function getRules() {
  return rules;
}
export default rules;
