import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Page } from "./style";
import Header from "./Header";
import Footer from "./Footer";
import Content from "./Content";
// import Locked from "./Footer/locked";
import api from "../../../../services/axios";
import socket from "../../../../services/socket";

export default function Initial() {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.panelRight.userchat);
  const [messages, setMessages] = React.useState([]);
  const [messagestoread, setMessagestoread] = React.useState([]);
  const [clientcompare, setClientcompare] = React.useState("");
  const [infoclient, setInfoclient] = React.useState(client);

  const user = JSON.parse(localStorage.user);
  function getClientInfo() {
    api
      .get("/clients/clientinfo", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: client,
      })
      .then((response) => {
        setInfoclient(response.data);
      })
      .catch((e) => {
        setInfoclient(client);
        console.log(e);
      });
  }
  function getSingleConversations() {
    api
      .get("/ticket/groupmessages", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: { idticket: client.idticket },
      })
      .then((response) => {
        if (response.data.length) {
          setMessages([
            ...response.data[0].messages.sort(
              (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
            ),
          ]);
          // setMessagestoread([
          //   ...response.data[0].messages.filter((x) => !x.read),
          // ]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function readMessages() {
    const data = {
      messages: messagestoread,
    };
    api
      .post("/ticket/readmessages", data, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        setMessagestoread([]);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  React.useEffect(() => {
    if (!infoclient.timestamp) {
      getClientInfo();
      getSingleConversations();
    } else if (client.idclient !== infoclient.idclient) {
      getClientInfo();
      getSingleConversations();
    }
  }, [client]);
  React.useEffect(() => {
    dispatch({
      type: "SET_CLIENT_INFO",
      clientinfo: infoclient,
    });
  }, [infoclient]);
  React.useEffect(() => {
    if (clientcompare) {
      if (
        clientcompare.idclient === client.idclient ||
        clientcompare.number === client.number
      ) {
        getSingleConversations();
        getClientInfo();
      }
    }
  }, [clientcompare]);
  React.useEffect(() => {
    socket.on(
      `${user.company.prefix}_newmessage${
        JSON.parse(localStorage.user).idusers
      }`,
      (clientemit) => {
        setClientcompare(clientemit);
      }
    );
    socket.on(`${user.company.prefix}_updateclientinfo`, (clientemit) => {
      setClientcompare(clientemit);
    });
  }, []);
  React.useEffect(() => {
    if (messagestoread.length) {
      readMessages();
    }
  }, [messagestoread]);

  return (
    <>
      <Page>
        <div className="YUoyu" data-asset-chat-background="true" />
        <Header
          client={infoclient}
          setMessages={setMessages}
          messages={messages}
        />
        <Content messages={messages} />
        <Footer
          client={infoclient}
          messages={messages}
          setMessages={setMessages}
        />
      </Page>
    </>
  );
}
