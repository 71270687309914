import React from "react";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import { Handle } from "react-flow-renderer";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import { Button, Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ElementsFunctions from "./Functions";
import { Node, RightMessage } from "./style";
// eslint-disable-next-line import/no-anonymous-default-export
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
// eslint-disable-next-line import/no-anonymous-default-export
export default (data) => {
  const classes = useStyles();
  const {
    setElementupdate,
    id,
    valueDefault,
    tagsDefault,
    nodeFunctions,
    removenode,
    fileData,
    originalname,
  } = data.data;
  const [value, setValue] = React.useState(valueDefault || "");
  const [inputvalue, setInputvalue] = React.useState("");
  const [tags, setTags] = React.useState(tagsDefault || []);
  const [background, setBackground] = React.useState("lightgray");
  const [functions, setFunctions] = React.useState(nodeFunctions);
  const [showModalMessage, setShowModalMessage] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [file, setFile] = React.useState(fileData);
  const [imagePreview, setImagePreview] = React.useState("");
  function removeTag(index) {
    const oldTags = tags;
    oldTags.splice(index, 1);
    setTags([...oldTags]);
  }
  function allowDrop(ev) {
    setBackground("lightgreen");
    ev.preventDefault();
  }

  function drop(ev) {
    ev.preventDefault();
    const data = JSON.parse(ev.dataTransfer.getData("data"));
    const index = functions.findIndex((x) => x.code === data.code);
    if (index !== -1) {
      setBackground("lightred");
      return ev.preventDefault();
    }
    setBackground("lightgray");
    setFunctions([...functions, data]);
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickFunction = (index) => {
    const functionsClone = functions;
    functionsClone[index].open = !functionsClone[index].open;
    setFunctions([...functionsClone]);
  };
  const updateFunction = (func, index) => {
    const oldFunctions = functions;
    oldFunctions[index] = func;
    setFunctions([...oldFunctions]);
  };
  const removeFunction = (ev, index) => {
    ev.preventDefault();
    ev.stopPropagation();
    const oldFunctions = functions;
    oldFunctions.splice(index, 1);
    setFunctions([...oldFunctions]);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenShowMessage = () => {
    setShowModalMessage(true);
  };
  const handleCloseShowMessage = () => {
    setShowModalMessage(false);
  };
  const handleFile = (event) => {
    setFile(event.currentTarget.files[0]);
  };
  async function createFile(url) {
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], originalname, {
      type: data.type,
    });
    return file;
  }
  async function handleLoadFile() {
    createFile(process.env.REACT_APP_API_URL + fileData).then((file) => {
      setFile(file);
    });
  }
  React.useEffect(() => {
    if (
      tags.length ||
      value ||
      functions.length ||
      (file && typeof file !== "string")
    ) {
      setElementupdate({
        id,
        tagsDefault: tags,
        nodeFunctions: functions,
        valueDefault: value,
        fileData: file,
        originalname: file
          ? file.name
            ? file.name
            : originalname
          : originalname,
      });
    }
  }, [tags, value, functions, file]);
  React.useEffect(() => {
    if (file && typeof file !== "string") {
      if (file.type && file.type.split("/").shift() === "image") {
        setImagePreview(URL.createObjectURL(file));
      } else {
        setImagePreview(false);
      }
    }
  }, [file]);
  React.useEffect(() => {
    if (fileData && typeof fileData === "string") {
      handleLoadFile();
    }
  }, []);
  return (
    <>
      <Handle
        position="top"
        type="target"
        id="a"
        style={{
          background: "#555",
          width: "20px",
          height: "15px",
          bottom: "-7px",
          borderRadius: "5px",
        }}
      />
      <Handle
        position="bottom"
        type="source"
        id="b"
        style={{
          background: "#555",
          width: "20px",
          height: "15px",
          bottom: "-7px",
          borderRadius: "5px",
        }}
      />

      <Node functionAreaBg={background}>
        {showModalMessage && (
          <Drawer anchor="right" open onClose={handleCloseShowMessage}>
            <RightMessage>
              <h4>AÇÃO</h4>
              <Divider />
              <br />
              <br />
              <TextField
                id="outlined-multiline-flexible"
                multiline
                value={value}
                rowsMax={8}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                }}
                onChange={(ev) => {
                  setValue(ev.currentTarget.value);
                }}
                variant="outlined"
                fullWidth
                style={{
                  height: "200px",
                }}
              />
              <div className="actions">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleCloseShowMessage}
                >
                  Fechar
                </Button>
              </div>
            </RightMessage>
          </Drawer>
        )}
        <div className="header-node">
          <FlashOnIcon style={{ color: "yellow" }} />
          <p>AÇÃO</p>
          <MoreHorizIcon
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ cursor: "pointer" }}
          />

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                removenode({ id });
              }}
            >
              Excluir
            </MenuItem>
          </Menu>
        </div>
        <span>Palavras Chaves</span>
        <div className="tag-container">
          {tags.length
            ? tags.map((tag, index) => (
                <div key={index} className="tag">
                  <span>{tag}</span>
                  <ClearIcon
                    onClick={() => {
                      removeTag(index);
                    }}
                  />
                </div>
              ))
            : ""}

          <input
            value={inputvalue}
            onChange={(ev) => {
              setInputvalue(ev.currentTarget.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                const oldTags = tags;
                e.currentTarget.value.split(",").forEach((tag) => {
                  if (tag) oldTags.push(tag);
                });
                setTags([...oldTags]);
                setInputvalue("");
              }
            }}
          />
        </div>
        <div className="title">
          <h4>Mensagem</h4>
          <EditIcon
            style={{
              fill: "#34b7f1",
              color: "#34b7f1",
              cursor: "pointer",
            }}
            onClick={handleOpenShowMessage}
          />
        </div>
        <Divider />
        {value ? (
          <TextField
            id="outlined-multiline-flexible"
            multiline
            value={value}
            rowsMax={4}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
            onChange={(ev) => {
              setValue(ev.currentTarget.value);
            }}
            variant="outlined"
            disabled
          />
        ) : (
          <div className="btn-message">
            <Button
              variant="outlined"
              style={{ color: "#34b7f1", border: "solid 2px #34b7f1" }}
              onClick={handleOpenShowMessage}
            >
              Adicionar Mensagem
            </Button>
          </div>
        )}
        <div className="title">
          <h4>Arquivo</h4>
          <label htmlFor={`file-upload-${id}`}>
            {" "}
            <EditIcon
              style={{
                fill: "#34b7f1",
                color: "#34b7f1",
                cursor: "pointer",
              }}
            />
          </label>

          <input
            type="file"
            id={`file-upload-${id}`}
            style={{ display: "none" }}
            onChange={handleFile}
          />
        </div>
        <Divider />
        {file ? (
          <div className="file-preview">
            {imagePreview ? (
              <img src={imagePreview} alt="preview" />
            ) : (
              <InsertDriveFileIcon style={{ fill: "#cecece" }} />
            )}
            <p>{file.name}</p>
          </div>
        ) : (
          <div className="btn-message">
            <Button
              variant="outlined"
              style={{
                color: "#34b7f1",
                border: "solid 2px #34b7f1",
                padding: "0px",
              }}
            >
              <label htmlFor={`file-upload-${id}`}>Adicionar Arquivo</label>
            </Button>
            <input
              type="file"
              id={`file-upload-${id}`}
              style={{ display: "none" }}
              onChange={handleFile}
            />
          </div>
        )}
        <h3>Funções</h3>
        <div
          className="function-area"
          onDrop={drop}
          onDragOver={allowDrop}
          onDragLeave={() => {
            setBackground("lightgray");
          }}
        >
          <List component="nav" aria-labelledby="nested-list-subheader">
            {functions.length
              ? functions.map((value, index) => {
                  const ReactElement = ElementsFunctions[value.code];
                  return (
                    <>
                      <ListItem
                        button
                        onClick={() => {
                          handleClickFunction(index);
                        }}
                      >
                        <ListItemIcon
                          onClick={(ev) => {
                            removeFunction(ev, index);
                          }}
                        >
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText primary={value.name} />
                        {ReactElement &&
                          (value.open ? <ExpandLess /> : <ExpandMore />)}
                      </ListItem>
                      <Collapse in={value.open} timeout="auto" unmountOnExit>
                        {ReactElement && (
                          <ReactElement
                            func={{ ...value, index }}
                            updateFunction={updateFunction}
                          />
                        )}
                      </Collapse>
                      <Divider />
                    </>
                  );
                })
              : ""}
          </List>
        </div>
      </Node>
    </>
  );
};
