import React from "react";
import { useTranslation } from "react-i18next";
import { Page } from "./style";

export default function Search({ data, set }) {
  const { t } = useTranslation();
  const [show, setShow] = React.useState("");
  let timer = null;

  const searchingFor = (term) => (x) => {
    x = x.conversation ? x.conversation : x;
    return x.client.name.toLowerCase().includes(term.toLowerCase()) || !term;
  };
  // const onChange = (ev) => {
  //   const input = ev.currentTarget.value;
  //   setShow(input);
  //   clearTimeout(timer);
  //   timer = setTimeout(function () {
  //     console.log(input);
  //     const array = data;
  //     set(array.filter(searchingFor(input)));
  //   }, 500);
  // };
  const onChange = (ev) => {
    const input = ev.currentTarget.value;
    setShow(input);
    clearTimeout(timer);
    timer = setTimeout(() => {
      const array = data;
      const result = array.filter((x) => {
        const values = [];
        x = x.conversation ? x.conversation : x;
        const objKeys = Object.keys(x.client);

        objKeys.forEach((key) => {
          const value = x.client[key];
          values.push(value);
        });
        const obj = JSON.parse(JSON.stringify(x));
        obj.values = JSON.stringify(values);
        return Object.values(obj)
          .join(" ")
          .toLowerCase()
          .includes(input.toLowerCase());
      });
      set(result);
    }, 500);
  };
  return (
    <Page>
      <div className="gQzdc">
        <button className="C28xL" type="button">
          <div className="_1M3wR _1BC4w">
            <span data-icon="back-blue">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="#4FC3F7"
                  d="M20 11H7.8l5.6-5.6L12 4l-8 8 8 8 1.4-1.4L7.8 13H20v-2z"
                />
              </svg>
            </span>
          </div>
          <div className="_1M3wR _3M2St">
            <span data-icon="search">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="#263238"
                  fillOpacity=".3"
                  d="M15.009 13.805h-.636l-.22-.219a5.184 5.184 0 0 0 1.256-3.386 5.207 5.207 0 1 0-5.207 5.208 5.183 5.183 0 0 0 3.385-1.255l.221.22v.635l4.004 3.999 1.194-1.195-3.997-4.007zm-4.808 0a3.605 3.605 0 1 1 0-7.21 3.605 3.605 0 0 1 0 7.21z"
                />
              </svg>
            </span>
          </div>
        </button>

        <div className="_2cLHw">{!show && t("HOME.pl.init.search.ph")}</div>
        <label className="_2MSJr">
          <input
            type="text"
            className="jN-F5 copyable-text selectable-text"
            data-tab="2"
            dir="auto"
            title={t("HOME.pl.init.search.ph")}
            onChange={onChange}
          />
        </label>
      </div>
    </Page>
  );
}
