import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: Roboto,sans-serif;
    background-color: #f7f9fa;
  }
    &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
      }
      &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.08);
      }
`;

export const Page = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  h1 {
    font-family: "Poppins", sans-serif;
    font-size: 2.6rem;
    color: #4adf83;
  }
  h3 {
    font-family: "Poppins", sans-serif;
    font-size: 1.7rem;
    color: #4adf83;
  }
  .left {
    position: relative;
    width: 60%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .rigth {
    position: relative;
    width: 40%;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 230px;
    }
    form {
      position: relative;
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .label-input {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        width: 100%;
        input {
          border: none;
          background: transparent;
          outline: none;
          width: 100%;
        }
        border-bottom: 2px solid #e6e6e6;
      }
      button {
        outline: none;
        width: 150px;
        height: 35px;
        border-radius: 5px;
        background: #4adf83;
        font-family: "Poppins", sans-serif;
        font-size: 1.1rem;
        color: white;
        border: none;
        transition: 1s;
        cursor: pointer;
        &:hover {
          border: 2px solid #4adf83;
          background: transparent;
          color: #4adf83;
        }
      }
    }
  }
`;
