import React from "react";
import { Page } from "./style";
import TicketsSnippet from "./ticketsnippet";
import ServiceSnippet from "./servicesnippet";
import ClientSnippet from "./clientsnippet";
import TimeSnippet from "./timesnippet";
import TimeAttendantSnippet from "./timeAttendantSnippet";

export default function (props) {
  const { history } = props;
  return (
    <Page>
      <TicketsSnippet history={history} />
      <ServiceSnippet history={history} />
      <ClientSnippet history={history} />
      <TimeSnippet history={history} />
      <TimeAttendantSnippet history={history} />
    </Page>
  );
}
