import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import api from "../../../services/axios";
import WalletSelect from "./WalletSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));
export default function AlertDialog(props) {
  console.log(props);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [cpf, setCPF] = React.useState("");
  const [inputs, setInputs] = React.useState([]);
  const [wallet, setWallet] = React.useState("");
  const [selectwallet, setSelectwallet] = React.useState(false);
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    props.func(false);
  };
  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  React.useEffect(() => {
    setName(props.data?.name);
    setNumber(props.data?.number);
    setCPF(props.data?.cpf);
    setWallet(props.data?.wallet);
  }, [props.data]);
  const moreInputs = () => {
    setInputs([
      ...inputs,
      <>
        <InputMask mask="99.999.999/9999-99">
          {() => (
            <TextField
              id={`outlined-basic cnpjindex${inputs.length}`}
              label="CNPJ"
              variant="outlined"
              required
            />
          )}
        </InputMask>
        <TextField
          id={`outlined-basic codindex${inputs.length}`}
          label={t("CT.editclient.phcodclient")}
          variant="outlined"
          required
        />
        <TextField
          id={`outlined-basic rzsocialindex${inputs.length}`}
          label={t("CT.editclient.phrz")}
          variant="outlined"
          required
        />
      </>,
    ]);
  };

  const confirm = async () => {
    if (!name.length || !number.length) {
      return window.alert(t("CT.editclient.alert1"));
    }
    if (number.replace(/([^\d])+/gim, "").length < 12) {
      return window.alert("Numero com formato incorreto!");
    }
    const data = {
      client: {
        name,
        number: number.replace(/([^\d])+/gim, ""),
        cpf: cpf ? cpf.replace(/([^\d])+/gim, "") : undefined,
        idclient: props.data.idclient,
        idwallet: wallet ? wallet.idwallets : undefined,
      },
      establishments: [],
    };
    for (let i = 0; i <= inputs.length - 1; i++) {
      // if (
      //   !document.getElementById(`outlined-basic cnpjindex${i}`).value ||
      //   !document.getElementById(`outlined-basic codindex${i}`).value ||
      //   !document.getElementById(`outlined-basic rzsocialindex${i}`).value
      // ) {
      //   return window.alert(t("CT.editclient.alert1"));
      // }

      data.establishments.push({
        cnpj: document.getElementById(`outlined-basic cnpjindex${i}`).value,
        cod: document.getElementById(`outlined-basic codindex${i}`).value,
        rzsocial: document.getElementById(`outlined-basic rzsocialindex${i}`)
          .value,
      });
    }
    api
      .put("clients", data, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then(async (res) => {
        api
          .get("/clients/clientinfo", {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
            params: { number: data.client.number },
          })
          .then((response) => {
            props.update(response.data);
          })
          .catch((e) => {
            console.log(e);
            props.update({ ...props.data, ...data.client });
          });
      })
      .catch((err) => window.alert(err.response.data.error));
  };
  return (
    <div>
      {selectwallet && (
        <WalletSelect
          close={() => {
            setSelectwallet(false);
          }}
          setWallet={setWallet}
          wallet={wallet}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("CT.editclient.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("CT.editclient.description")}
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-basic"
                label={t("CT.editclient.phname")}
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
              />
              <TextField
                id="outlined-basic"
                label={t("CT.editclient.phnumber")}
                variant="outlined"
                value={number}
                onChange={(e) => {
                  setNumber(e.currentTarget.value);
                }}
              />
              <InputMask
                mask="999.999.999-99"
                value={cpf}
                onChange={(e) => {
                  setCPF(e.currentTarget.value);
                }}
              >
                {() => (
                  <TextField
                    id="outlined-basic"
                    label={t("CT.editclient.phcpf")}
                    variant="outlined"
                  />
                )}
              </InputMask>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectwallet(true);
                }}
              >
                {t("CT.newclient.bt3")}
              </Button>
              {wallet && wallet.name}
              <h3>CNPJ :</h3>
              {inputs.map((input) => input)}
              <AddCircleIcon
                style={{ height: "3ch", width: "35.5ch", margin: 0 }}
                onClick={moreInputs}
              />
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Button variant="contained" color="secondary">
              {t("CT.editclient.bt2")}
            </Button>
          </Button>
          <Button color="primary" onClick={confirm} autoFocus>
            <Button variant="contained" color="primary">
              {t("CT.editclient.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
