import React from "react";
import { useSelector } from "react-redux";
import PanelLeft from "./PanelLeft/index";
import Info from "./PanelRight/ActiveChat/Info/index";
import { GlobalStyle, Page } from "./style";
import PanelRight from "./PanelRight/index";

export default function Home(props) {
  const displayclientinfo = useSelector(
    (state) => state.panelRight.displayclientinfo
  );

  return (
    <Page displayclientinfo={displayclientinfo}>
      <div className="panel-left">
        <PanelLeft {...props} />
      </div>
      <div className="panel-right">
        <PanelRight {...props} />
      </div>
      {displayclientinfo && (
        <div className="panel-tree">
          <Info {...props} />
        </div>
      )}

      <GlobalStyle />
    </Page>
  );
}
