import styled from "styled-components";
import b59 from "./img/41d5efb8acd3487afc5ddea1501f0b08.webp";
import b73 from "./img/e62a5283b1ce6074795c0187d2b1f558.webp";
import b90 from "./img/57808f1baef5613ae7430addef0ae850.webp";
import b61 from "./img/83a619e0b15471e086d43e690dacae77.webp";
import b60 from "./img/53127fbfa400f71fc1dd0c0b99894922.webp";
import b74 from "./img/d7e2cc8550cba88f118186e51c38d72c.webp";
import b81 from "./img/14a4e42043b696112919bfd1b6309b26.webp";
import b71 from "./img/59e6ec989ff0b2041745837cbc159867.webp";
import b87 from "./img/122e775c91b96318a63daf7865786fdc.webp";
import b45 from "./img/843eccaa1a42b8fbe80e2c2e145fa0bf.webp";
import b44 from "./img/b5ad4d36b724bf3aa48e5f6bbec634ad.webp";
import b48 from "./img/ce550ac4afce4d2fd49d862db443cabc.webp";
import b17 from "./img/667e3712186e4bac44066e086521b46a.webp";
import b64 from "./img/d8ea2cc09853bb7d2f07deb57862587f.webp";
import b30 from "./img/960dc1c1fb4887fb84d869425ea2b881.webp";
import b72 from "./img/21153985726f011c4743bddde2cec195.webp";
import b29 from "./img/81e827fdf825193402a93ff78c0c13e8.webp";

export const Page = styled.div`
  button {
    border: 0;
    margin: 0;
    background: 0 0;
    outline: 0;
    cursor: pointer;
    font-family: inherit;
  }
  a,
  abbr,
  acronym,
  address,
  applet,
  article,
  aside,
  audio,
  b,
  big,
  blockquote,
  body,
  canvas,
  caption,
  center,
  cite,
  code,
  dd,
  del,
  details,
  dfn,
  div,
  dl,
  dt,
  em,
  embed,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  hgroup,
  html,
  i,
  iframe,
  img,
  ins,
  kbd,
  label,
  legend,
  li,
  mark,
  menu,
  nav,
  object,
  ol,
  output,
  p,
  pre,
  q,
  ruby,
  s,
  samp,
  section,
  small,
  span,
  strike,
  strong,
  sub,
  summary,
  sup,
  table,
  tbody,
  td,
  tfoot,
  th,
  thead,
  time,
  tr,
  tt,
  u,
  ul,
  var,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    outline: 0;
  }
  bottom: 0;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  left: 0;
  border-left: 1px solid #e4e4e4;
  transform: ${(props) =>
    props.transform ? "translateY(0px)" : "translateY(350px)"};
  transition: 0.3s ease-in-out;
  display: block;
  ._3ZkhL {
    height: 320px;
    overflow: hidden;
    transform: translateZ(0);
    transform-origin: bottom;
    .Sbkt2 {
      position: relative;
      background-color: #f0f0f0;
      ._2VzPl {
        opacity: 1;
        overflow-y: hidden;
        width: 100%;
        box-sizing: border-box;
        overflow-x: hidden;
        padding-bottom: 12px;
        &:last-child {
          padding-bottom: 10px;
        }
        ._1qUCC {
          margin-right: 12px;
          margin-left: 12px;
          ._1-N6t {
            color: #959595;
            margin: 0 12px;
            padding: 22px 0 8px;
            font-size: 14px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          .emojik {
            display: inline-block;
            vertical-align: top;
            zoom: 1;
            border: 0;
            width: 32px;
            height: 32px;
            margin: 6px;
            cursor: pointer;
            border-radius: 3px;
            &:focus {
              box-shadow: 0 0 0 2px rgba(0, 175, 255, 0.4);
            }
          }
          .emojik.wa {
            background-size: 172px 172px;
          }
          .emojik.wa.b96 {
            background-size: 32px 32px;
          }
          .emojik.wa.b59 {
            background-image: url(${b59});
          }
          .emojik.wa.b73 {
            background-image: url(${b73});
          }
          .emojik.wa.b90 {
            background-image: url(${b90});
          }
          .emojik.wa.b61 {
            background-image: url(${b61});
          }
          .emojik.wa.b60 {
            background-image: url(${b60});
          }
          .emojik.wa.b74 {
            background-image: url(${b74});
          }
          .emojik.wa.b81 {
            background-image: url(${b81});
          }
          .emojik.wa.b71 {
            background-image: url(${b71});
          }
          .emojik.wa.b87 {
            background-image: url(${b87});
          }
          .emojik.wa.b45 {
            background-image: url(${b45});
          }
          .emojik.wa.b44 {
            background-image: url(${b44});
          }
          .emojik.wa.b48 {
            background-image: url(${b48});
          }
          .emojik.wa.b17 {
            background-image: url(${b17});
          }
          .emojik.wa.b30 {
            background-image: url(${b30});
          }
          .emojik.wa.b64 {
            background-image: url(${b64});
          }
          .emojik.wa.b30 {
            background-image: url(${b30});
          }
          .emojik.wa.b72 {
            background-image: url(${b72});
          }
          .emojik.wa.b29 {
            background-image: url(${b29});
          }
        }
      }
    }
  }
`;
