import React from "react";
import { Page } from "./style";

export default class EmojisPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transform: false,
    };
  }

  sendEmoji = (ev) => {
    this.props.sendEmoji(ev.currentTarget.dataset.unicode);
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ transform: true });
    }, 100);
  }

  render() {
    return (
      <Page
        className="_1fkhx panel-down"
        id="panel-emojis"
        transform={this.state.transform}
      >
        <div>
          <div className="panel-emojis-container">
            <div tabIndex="-1">
              <div className="_3ZkhL">
                <div className="Sbkt2">
                  <div className="_2VzPl">
                    <div className="_1qUCC">
                      <div className="_1-N6t">Emojis &amp; Pessoas</div>
                      <div tabIndex="-1">
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😀"
                          data-emoji-index="0"
                          style={{ backgroundPosition: "0px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😃"
                          data-emoji-index="1"
                          style={{ backgroundPosition: "-105px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😄"
                          data-emoji-index="2"
                          style={{ backgroundPosition: "-140px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😁"
                          data-emoji-index="3"
                          style={{ backgroundPosition: "-35px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😆"
                          data-emoji-index="4"
                          style={{ backgroundPosition: " -35px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😅"
                          data-emoji-index="5"
                          style={{ backgroundPosition: " 0px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😂"
                          data-emoji-index="6"
                          style={{ backgroundPosition: " -70px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b73 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤣"
                          data-emoji-index="7"
                          style={{ backgroundPosition: " -70px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b90 emojik wa"
                          tabIndex="-1"
                          data-unicode="☺"
                          data-emoji-index="8"
                          style={{ backgroundPosition: " -140px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😊"
                          data-emoji-index="9"
                          style={{ backgroundPosition: " 0px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😇"
                          data-emoji-index="10"
                          style={{ backgroundPosition: " -70px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="🙂"
                          data-emoji-index="11"
                          style={{ backgroundPosition: " 0px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="🙃"
                          data-emoji-index="12"
                          style={{ backgroundPosition: " -35px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😉"
                          data-emoji-index="13"
                          style={{ backgroundPosition: " -140px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😌"
                          data-emoji-index="14"
                          style={{ backgroundPosition: " -70px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😍"
                          data-emoji-index="15"
                          style={{ backgroundPosition: " -105px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😘"
                          data-emoji-index="16"
                          style={{ backgroundPosition: " -140px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😗"
                          data-emoji-index="17"
                          style={{ backgroundPosition: " -105px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😙"
                          data-emoji-index="18"
                          style={{ backgroundPosition: " 0px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😚"
                          data-emoji-index="19"
                          style={{ backgroundPosition: " -35px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😋"
                          data-emoji-index="20"
                          style={{ backgroundPosition: " -35px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😛"
                          data-emoji-index="21"
                          style={{ backgroundPosition: " -70px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😝"
                          data-emoji-index="22"
                          style={{ backgroundPosition: " -140px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😜"
                          data-emoji-index="23"
                          style={{ backgroundPosition: " -105px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b74 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤪"
                          data-emoji-index="24"
                          style={{ backgroundPosition: " 0px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b73 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤨"
                          data-emoji-index="25"
                          style={{ backgroundPosition: " -105px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b81 emojik wa"
                          tabIndex="-1"
                          data-unicode="🧐"
                          data-emoji-index="26"
                          style={{ backgroundPosition: " 0px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b71 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤓"
                          data-emoji-index="27"
                          style={{ backgroundPosition: " -70px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😎"
                          data-emoji-index="28"
                          style={{ backgroundPosition: " -140px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b73 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤩"
                          data-emoji-index="29"
                          style={{ backgroundPosition: " -140px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😏"
                          data-emoji-index="30"
                          style={{ backgroundPosition: " 0px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😒"
                          data-emoji-index="31"
                          style={{ backgroundPosition: " -105px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😞"
                          data-emoji-index="32"
                          style={{ backgroundPosition: " 0px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😔"
                          data-emoji-index="33"
                          style={{ backgroundPosition: " 0px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😟"
                          data-emoji-index="34"
                          style={{ backgroundPosition: " -35px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😕"
                          data-emoji-index="35"
                          style={{ backgroundPosition: " -35px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="🙁"
                          data-emoji-index="36"
                          style={{ backgroundPosition: " -140px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b90 emojik wa"
                          tabIndex="-1"
                          data-unicode="☹"
                          data-emoji-index="37"
                          style={{ backgroundPosition: " -105px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😣"
                          data-emoji-index="38"
                          style={{ backgroundPosition: " 0px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😖"
                          data-emoji-index="39"
                          style={{ backgroundPosition: " -70px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😫"
                          data-emoji-index="40"
                          style={{ backgroundPosition: " -70px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b87 emojik wa"
                          tabIndex="-1"
                          data-unicode="😩"
                          data-emoji-index="41"
                          style={{ backgroundPosition: " -35px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😢"
                          data-emoji-index="42"
                          style={{ backgroundPosition: " -140px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😭"
                          data-emoji-index="43"
                          style={{ backgroundPosition: " -140px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😤"
                          data-emoji-index="44"
                          style={{ backgroundPosition: " -35px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😠"
                          data-emoji-index="45"
                          style={{ backgroundPosition: " -70px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😡"
                          data-emoji-index="46"
                          style={{ backgroundPosition: " -105px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b74 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤬"
                          data-emoji-index="47"
                          style={{ backgroundPosition: " -70px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b74 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤯"
                          data-emoji-index="48"
                          style={{ backgroundPosition: " 0px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😳"
                          data-emoji-index="49"
                          style={{ backgroundPosition: " 0px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😱"
                          data-emoji-index="50"
                          style={{ backgroundPosition: " -105px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😨"
                          data-emoji-index="51"
                          style={{ backgroundPosition: " 0px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😰"
                          data-emoji-index="52"
                          style={{ backgroundPosition: " -70px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😥"
                          data-emoji-index="53"
                          style={{ backgroundPosition: " -70px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😓"
                          data-emoji-index="54"
                          style={{ backgroundPosition: " -140px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b71 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤗"
                          data-emoji-index="55"
                          style={{ backgroundPosition: " -35px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b71 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤔"
                          data-emoji-index="56"
                          style={{ backgroundPosition: " -105px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b74 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤭"
                          data-emoji-index="57"
                          style={{ backgroundPosition: " -105px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b74 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤫"
                          data-emoji-index="58"
                          style={{ backgroundPosition: " -35px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b73 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤥"
                          data-emoji-index="59"
                          style={{ backgroundPosition: " -140px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😶"
                          data-emoji-index="60"
                          style={{ backgroundPosition: " -105px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😐"
                          data-emoji-index="61"
                          style={{ backgroundPosition: " -35px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😑"
                          data-emoji-index="62"
                          style={{ backgroundPosition: " -70px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😬"
                          data-emoji-index="63"
                          style={{ backgroundPosition: " -105px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="🙄"
                          data-emoji-index="64"
                          style={{ backgroundPosition: " -70px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😯"
                          data-emoji-index="65"
                          style={{ backgroundPosition: " -35px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😦"
                          data-emoji-index="66"
                          style={{ backgroundPosition: " -105px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😧"
                          data-emoji-index="67"
                          style={{ backgroundPosition: " -140px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😮"
                          data-emoji-index="68"
                          style={{ backgroundPosition: " 0px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😲"
                          data-emoji-index="69"
                          style={{ backgroundPosition: " -140px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😴"
                          data-emoji-index="70"
                          style={{ backgroundPosition: " -35px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b73 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤤"
                          data-emoji-index="71"
                          style={{ backgroundPosition: " -105px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b60 emojik wa"
                          tabIndex="-1"
                          data-unicode="😪"
                          data-emoji-index="72"
                          style={{ backgroundPosition: " -35px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😵"
                          data-emoji-index="73"
                          style={{ backgroundPosition: " -70px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b71 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤐"
                          data-emoji-index="74"
                          style={{ backgroundPosition: " 0px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b73 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤢"
                          data-emoji-index="75"
                          style={{ backgroundPosition: " -35px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b74 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤮"
                          data-emoji-index="76"
                          style={{ backgroundPosition: " -140px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b73 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤧"
                          data-emoji-index="77"
                          style={{ backgroundPosition: " -70px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😷"
                          data-emoji-index="78"
                          style={{ backgroundPosition: " -140px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b87 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤒"
                          data-emoji-index="79"
                          style={{ backgroundPosition: " 0px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b71 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤕"
                          data-emoji-index="80"
                          style={{ backgroundPosition: " -140px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b71 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤑"
                          data-emoji-index="81"
                          style={{ backgroundPosition: " -35px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b73 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤠"
                          data-emoji-index="82"
                          style={{ backgroundPosition: " -140px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b59 emojik wa"
                          tabIndex="-1"
                          data-unicode="😈"
                          data-emoji-index="83"
                          style={{ backgroundPosition: " -105px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b45 emojik wa"
                          tabIndex="-1"
                          data-unicode="👿"
                          data-emoji-index="84"
                          style={{ backgroundPosition: " 0px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b44 emojik wa"
                          tabIndex="-1"
                          data-unicode="👹"
                          data-emoji-index="85"
                          style={{ backgroundPosition: " -140px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b44 emojik wa"
                          tabIndex="-1"
                          data-unicode="👺"
                          data-emoji-index="86"
                          style={{ backgroundPosition: " 0px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b73 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤡"
                          data-emoji-index="87"
                          style={{ backgroundPosition: " 0px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b48 emojik wa"
                          tabIndex="-1"
                          data-unicode="💩"
                          data-emoji-index="88"
                          style={{ backgroundPosition: " -70px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b44 emojik wa"
                          tabIndex="-1"
                          data-unicode="👻"
                          data-emoji-index="89"
                          style={{ backgroundPosition: " -35px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b45 emojik wa"
                          tabIndex="-1"
                          data-unicode="💀"
                          data-emoji-index="90"
                          style={{ backgroundPosition: " -35px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b90 emojik wa"
                          tabIndex="-1"
                          data-unicode="☠"
                          data-emoji-index="91"
                          style={{ backgroundPosition: " 0px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b45 emojik wa"
                          tabIndex="-1"
                          data-unicode="👽"
                          data-emoji-index="92"
                          style={{ backgroundPosition: " -105px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b45 emojik wa"
                          tabIndex="-1"
                          data-unicode="👾"
                          data-emoji-index="93"
                          style={{ backgroundPosition: " -140px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b71 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤖"
                          data-emoji-index="94"
                          style={{ backgroundPosition: " 0px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b17 emojik wa"
                          tabIndex="-1"
                          data-unicode="🎃"
                          data-emoji-index="95"
                          style={{ backgroundPosition: " -105px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😺"
                          data-emoji-index="96"
                          style={{ backgroundPosition: " -70px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😸"
                          data-emoji-index="97"
                          style={{ backgroundPosition: " 0px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😹"
                          data-emoji-index="98"
                          style={{ backgroundPosition: " -35px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😻"
                          data-emoji-index="99"
                          style={{ backgroundPosition: " -105px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😼"
                          data-emoji-index="100"
                          style={{ backgroundPosition: " -140px -70px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😽"
                          data-emoji-index="101"
                          style={{ backgroundPosition: " 0px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="🙀"
                          data-emoji-index="102"
                          style={{ backgroundPosition: " -105px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😿"
                          data-emoji-index="103"
                          style={{ backgroundPosition: " -70px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b61 emojik wa"
                          tabIndex="-1"
                          data-unicode="😾"
                          data-emoji-index="104"
                          style={{ backgroundPosition: " -35px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b74 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤲"
                          data-emoji-index="105"
                          style={{ backgroundPosition: " -105px -140px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b30 emojik wa"
                          tabIndex="-1"
                          data-unicode="👐"
                          data-emoji-index="106"
                          style={{ backgroundPosition: " -35px -35px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b64 emojik wa"
                          tabIndex="-1"
                          data-unicode="🙌"
                          data-emoji-index="107"
                          style={{ backgroundPosition: " -140px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b30 emojik wa"
                          tabIndex="-1"
                          data-unicode="👏"
                          data-emoji-index="108"
                          style={{ backgroundPosition: " 0px 0px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b72 emojik wa"
                          tabIndex="-1"
                          data-unicode="🤝"
                          data-emoji-index="109"
                          style={{ backgroundPosition: " -35px -105px" }}
                        />
                        <span
                          onClick={this.sendEmoji}
                          className="b29 emojik wa"
                          tabIndex="-1"
                          data-unicode="👍"
                          data-emoji-index="110"
                          style={{ backgroundPosition: " -105px -70px" }}
                        />
                        <span
                          className="b29 emojik wa"
                          tabIndex="-1"
                          data-unicode="👎"
                          data-emoji-index="111"
                          style={{ backgroundPosition: " -140px -105px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
