import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    display: "flex",
    justifyContent: "right",
    backgroundColor: "rgb(0, 191, 165)",
  },
  toolBar: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    marginRight: theme.spacing(2),
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function FullScreenDialog({ close, mediasrc }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = () => {
    close();
  };

  return (
    <div>
      <Dialog
        fullScreen
        open
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("FC.chats.pr.active.dialogs.showmedia.bt1")}
            </Typography>
          </Toolbar>
        </AppBar>
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <img
            style={{
              position: "relative",
              height: "80%",
            }}
            src={mediasrc}
            alt=""
          />
        </div>
      </Dialog>
    </div>
  );
}
