import React from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import { CircularProgress } from "@material-ui/core";
import SelectPeriod from "../Dialogs/SelectPeriod";
import api from "../../../services/axios";
import { AddNotify } from "../../../components/Alert";
import { useTranslation } from "react-i18next";
import socket from "../../../services/socket";
Date.prototype.toDateInputValue = function () {
  const local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};
export default function ({ history }) {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.user);
  const [tickets, setTickets] = React.useState([]);
  const [period, setPeriod] = React.useState("");
  const [showloading, setShowloading] = React.useState(false);
  const [timeaverage, setTimeaverage] = React.useState("");
  const [showShowSelectPeriod, setShowShowSelectPeriod] = React.useState(false);
  const getTickets = async (date) => {
    setShowloading(true);
    const datatime = {
      initial: new Date(`${date.initial} 00:00:00`),
      final: new Date(`${date.final} 23:59:59`),
    };

    await api
      .get("dashboard/tickets", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: datatime,
      })
      .then((res) => {
        setTickets(res.data);
        setShowloading(false);
      })
      .catch((e) => {
        console.log(e);
        setShowloading(false);
        AddNotify("error", e.response.data.error);
      });
  };
  function addZero(value) {
    if (value >= 0 && value < 10) {
      return `0${value}`;
    }
    return value;
  }
  function someHours(value, some) {
    if (!value) {
      return some;
    }
    const starthour = value.split(":");
    const addhour = some.split(":");
    let totalhour = parseInt(starthour[0], 10) + parseInt(addhour[0], 10);
    let totalminutes = parseInt(starthour[1], 10) + parseInt(addhour[1], 10);
    let totalseconds = parseInt(starthour[2], 10) + parseInt(addhour[2], 10);
    if (totalseconds >= 60) {
      totalseconds -= 60;
      totalminutes += 1;
    }
    if (totalminutes >= 60) {
      totalminutes -= 60;
      totalhour += 1;
    }
    return `${addZero(totalhour)}:${addZero(totalminutes)}:${addZero(
      totalseconds
    )}`;
  }
  function intervalHours(initial, final) {
    const starthour = new Date(initial);
    const finalhour = new Date(final);
    return Math.abs(finalhour - starthour);
  }
  function msToTime(ms) {
    let seconds = ms / 1000;
    let minutes = parseInt(seconds / 60, 10);
    seconds %= 60;
    const hours = parseInt(minutes / 60, 10);
    minutes %= 60;

    return `${addZero(hours.toFixed())}:${addZero(minutes.toFixed())}:${addZero(
      seconds.toFixed()
    )}`;
  }
  function timeToMs(time) {
    return (
      Number(time.split(":")[0]) * 60 * 60 * 1000 +
      Number(time.split(":")[1]) * 60 * 1000 +
      Number(time.split(":")[2]) * 1000
    );
  }

  React.useEffect(() => {
    if (tickets.length) {
      const arrayclosed = tickets.filter((x) => x.status === "FECHADO");
      if (arrayclosed.length) {
        const totaltimeclosed = [];
        let timeaveragecalc = "";
        arrayclosed.map((ticketclosed) => {
          const initial = ticketclosed.ticket_timelines.find(
            (time) => time.status === "ACEITO"
          ).timestamp;
          const final = ticketclosed.ticket_timelines.find(
            (time) => time.status === "FECHADO"
          ).timestamp;
          const interval = intervalHours(initial, final);
          totaltimeclosed.push(msToTime(interval));
        });
        totaltimeclosed.map((timecalc) => {
          timeaveragecalc = someHours(timeaveragecalc, timecalc);
        });
        timeaveragecalc = timeToMs(timeaveragecalc);
        setTimeaverage(msToTime(timeaveragecalc / totaltimeclosed.length));
      }
    }
  }, [tickets]);

  React.useEffect(() => {
    getTickets({
      initial: new Date().toDateInputValue(),
      final: new Date().toDateInputValue(),
    });
    // socket.on(`${user.company.prefix}_updateticket`, () => {
    //   if (period) {
    //     getTickets(period);
    //   } else {
    //     getTickets({
    //       initial: new Date().toDateInputValue(),
    //       final: new Date().toDateInputValue(),
    //     });
    //   }
    // });
  }, []);
  function dateFilter(date) {
    setPeriod(date);
    getTickets(date);
  }
  return (
    <>
      {showShowSelectPeriod && (
        <SelectPeriod
          close={() => {
            setShowShowSelectPeriod(false);
          }}
          func={dateFilter}
        />
      )}
      <div className="col-md-4 col-xl-3">
        <div className="card bg-c-yellow order-card">
          <div className="card-block">
            {showloading && (
              <div className="mask">
                <CircularProgress />
              </div>
            )}
            <h6 className="header-card">
              {period ? `${period.initial} - ${period.final}` : "Hoje"} -
              {t("DB.snippets.time.description")}{" "}
              <DateRangeIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowShowSelectPeriod(true);
                }}
              />
            </h6>
            <h6 className="m-b-20">{t("DB.snippets.time.title")}</h6>
            <h2 className="text-right">
              <TimelapseIcon fontSize="large" />
              <span>
                &nbsp;&nbsp;
                {timeaverage || "..."}
              </span>
            </h2>
            {/* <p className="m-b-0">
              Atendentes Disponíveis
              <span className="f-right">351</span>
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
}
