import React from "react";
import messagesound from "./message.mp3";
import api from "../services/axios";
import socket from "../services/socket";
export default function NewMessageNotification() {
  const user = JSON.parse(localStorage.user);
  const [conversations, setConversations] = React.useState([]);
  function getConversations() {
    api
      .get("ticket/userconversations", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          size: 20,
        },
      })
      .then((response) => {
        setConversations(
          response.data.rows.sort(
            (a, b) =>
              new Date(b.messages[0].timestamp) -
              new Date(a.messages[0].timestamp)
          )
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }
  React.useEffect(() => {
    socket.on(`${user.company.prefix}_newmessage${user.idusers}`, (data) => {
      getConversations();
    });
  }, []);
  React.useEffect(() => {
    if (!!conversations.length) {
      const index = conversations.findIndex((x) => {
        return x.messages[0].read === 0;
      });

      if (index !== -1) {
        document.getElementById("messagetone").play();
      }
    }
  }, [conversations]);
  return (
    <audio id="messagetone" style={{ display: "none" }}>
      <source src={messagesound} type="audio/mpeg"></source>
    </audio>
  );
}
