import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import api from "../../../services/axios";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "60ch",
      justifyContent: "center",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    marginLeft: theme.spacing(4),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default function AlertDialog({ close, wallet, update }) {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState([]);
  const clients = wallet.clients;
  const [clientsfilter, setClientsfilter] = React.useState([...clients]);
  const classes = useStyles();
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  function handleCheckAll() {
    const newData = clients.map((x) => x.idclient);
    setChecked([...newData]);
  }
  function handleUnCheckAll() {
    setChecked([]);
  }

  const toggleExpand = (index) => {
    const arrayclients = clientsfilter;
    arrayclients[index].show = !arrayclients[index].show;
    setClientsfilter([...arrayclients]);
  };
  const searchingFor = (term) => (x) => {
    term = term.toLowerCase();
    let index;
    if (x.cliente_cnpjs.length) {
      const { length } = x.cliente_cnpjs;
      for (let i = 0; i < length; i++) {
        const paramter = x.cliente_cnpjs[i].rzsocial
          ? x.cliente_cnpjs[i].rzsocial
          : x.cliente_cnpjs[i].nmfantasia;
        const condition = paramter.toLowerCase().indexOf(term);
        if (condition !== -1) {
          return (index = true);
        }
      }
    }
    return index || x.name.toLowerCase().indexOf(term) !== -1 || !term;
  };

  const onChange = (value) => {
    const array = clients;
    setClientsfilter(array.filter(searchingFor(value)));
  };
  const confirm = () => {
    if (checked.length) {
      clients.forEach((client) => {
        const index = checked.indexOf(client.idclient);
        if (index !== -1) {
          client.checked = true;
        } else {
          delete client.checked;
        }
      });
      update(wallet);
      close();
    } else {
      clients.forEach((client) => {
        delete client.checked;
      });
      update(wallet);
      close();
    }
  };

  React.useEffect(() => {
    const checkedClients = clients.filter((client) => client.checked);
    setChecked(checkedClients.map((x) => x.idclient));
  }, []);
  return (
    <div>
      <Dialog
        open
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("WT.dialogs.client.title")}
        </DialogTitle>
        <DialogContent>
          <FormControl className={classes.margin} fullWidth>
            <Input
              id="input-with-icon-adornment"
              fullWidth
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              onChange={(ev) => {
                onChange(ev.currentTarget.value);
              }}
            />
          </FormControl>
          <br />
          <Divider />
          <br />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <a
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={handleCheckAll}
            >
              {t("DMT.dialogs.clients.a1")}
            </a>
            <a
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={handleUnCheckAll}
            >
              {t("DMT.dialogs.clients.a2")}
            </a>
            <div>
              {" "}
              {t("DMT.dialogs.clients.info")} : {checked.length}
            </div>
          </div>
          <br />
          <Divider />
          <List className={classes.root}>
            {!!clientsfilter.length &&
              clientsfilter.map((value, index) => {
                const labelId = `checkbox-list-label-${index}`;

                return (
                  <>
                    <ListItem
                      key={value.idclient}
                      role={undefined}
                      dense
                      button
                      onClick={handleToggle(value.idclient)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(value.idclient) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={`${value.name}`} />
                      <div
                        onClick={(ev) => {
                          ev.preventDefault();
                          ev.stopPropagation();
                          toggleExpand(index);
                        }}
                      >
                        {value.show ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    </ListItem>
                    <Collapse in={value.show} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {value.cliente_cnpjs.length &&
                          value.cliente_cnpjs.map((cnpj) => (
                            <ListItem button className={classes.nested}>
                              <ListItemText
                                primary={`${
                                  cnpj.rzsocial
                                    ? cnpj.rzsocial
                                    : cnpj.nmfantasia
                                } / ${cnpj.cnpj.replace(
                                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                                  "$1.$2.$3/$4-$5"
                                )}`}
                              />
                            </ListItem>
                          ))}
                      </List>
                    </Collapse>
                  </>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={close} autoFocus>
            <Button variant="contained" color="secondary">
              {t("WT.dialogs.client.bt2")}
            </Button>
          </Button>
          <Button color="primary" onClick={confirm} autoFocus>
            <Button variant="contained" className={classes.button}>
              {t("WT.dialogs.client.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
