import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import jwtDecode from "jsonwebtoken";
import Login from "../pages/Login/index";
import socket from "../services/socket";
import { useDispatch } from "react-redux";
const IsLogged = (props) => {
  const dispatch = useDispatch();
  let isAuth = false;
  if (localStorage.getItem("token")) {
    if (
      jwtDecode.decode(localStorage.getItem("token")).exp <
      Date.now() / 1000
    ) {
      localStorage.clear();
      socket.emit("client_disconnected", {
        id: socket.id,
      });
      dispatch({
        type: "RESET_CHAT",
      });
      dispatch({
        type: "RESET_CHAT_HISTORY",
      });
    } else {
      isAuth = true;
    }
  }
  return isAuth ? <Redirect to="/home" /> : <Route component={Login} />;
};

export default withRouter(IsLogged);
