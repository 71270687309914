import React from "react";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import { useDispatch } from "react-redux";
import crypto from "crypto";
import api from "../../services/axios";
import PipeGoLogo from "./img/pipego.png";
import { Page, GlobalStyle } from "./style";
import image from "./img/undraw_interview_rmcf.svg";
import socket from "../../services/socket";

export default function Login(props) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [alert, setAlert] = React.useState({ message: "", color: "red" });
  const dispatch = useDispatch();
  function decrypt(text) {
    const iv = Buffer.from(text["aXY="], "hex");
    const encryptedText = Buffer.from(text["ZW5jcnlwdGVkRGF0YQ=="], "hex");
    const decipher = crypto.createDecipheriv(
      "aes-256-cbc",
      Buffer.from(process.env.REACT_APP_KEY),
      iv
    );
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  }
  const onSubmit = async (ev) => {
    ev.preventDefault();
    await api
      .post("users/authenticate", {
        email,
        password,
      })
      .then(async (response) => {
        const { user } = response.data;
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(user));
        await api
          .get("users/rolerules", {
            headers: {
              authorization: `Bearer ${response.data.token}`,
            },
          })
          .then((responserules) => {
            localStorage.setItem(
              "cnVsZXM=",
              JSON.stringify(responserules.data)
            );
            dispatch({
              type: "SET_RULES",
              rules: JSON.parse(decrypt(responserules.data)),
            });
          });
        socket.emit("client_connected", {
          company: user.company.prefix,
          user: {
            idusers: user.idusers,
            username: user.username,
            email: user.email,
          },
        });

        props.history.push("/home/");
      })
      .catch((e) => {
        setTimeout(() => {
          setAlert({ message: "", color: "red" });
        }, 1500);
        setAlert({ message: e.response.data.error, color: "red" });
        console.log(e);
      });
  };

  return (
    <Page>
      <div className="left">
        <img src={image} alt="" />
      </div>
      <div className="rigth">
        <img src={PipeGoLogo} alt="logo" />
        <h3>Login</h3>
        <form onSubmit={onSubmit}>
          <label className="label-input" htmlFor="mail">
            <EmailIcon style={{ fill: "#c5c5c5" }} />
            <input
              type="text"
              id="mail"
              placeholder="Email"
              onChange={(ev) => {
                setEmail(ev.currentTarget.value);
              }}
              name="email"
              value={email}
            />
          </label>
          <label className="label-input" htmlFor="password">
            <LockIcon style={{ fill: "#c5c5c5" }} />
            <input
              type="password"
              id="password"
              placeholder="Senha"
              onChange={(ev) => {
                setPassword(ev.currentTarget.value);
              }}
              name="password"
              value={password}
            />
          </label>
          <span style={{ color: alert.color, fontWeight: "bold" }}>
            {alert.message}
          </span>
          <button type="submit">Entrar</button>
        </form>
      </div>
      <GlobalStyle />
    </Page>
  );
}
