import React from "react";
import { Page } from "./style";
import Header from "./header";
import Profile from "./profile";
import Description from "./description";
import Establishment from "./establishment";

export default function Info() {
  return (
    <Page>
      <span className="_1V8rX">
        <div
          className="_2fpYo"
          tabIndex={-1}
          style={{ height: "100%", transform: "translateX(0%)" }}
        >
          <span className="_2ueSF">
            <div className="_3gJlY _33fVQ _1LkpH copyable-area">
              <Header />
              <div className="_1qDvT _2wPpw">
                <div className="_1TM40">
                  <Profile />
                  <Description />
                  <Establishment />
                </div>
                <div className="_2NIgT" />
              </div>
            </div>
          </span>
        </div>
      </span>
    </Page>
  );
}
