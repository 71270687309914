import React from "react";
import { useSelector } from "react-redux";
import { Page } from "./style";
import ActiveChat from "./ActiveChat";
import InitialPage from "./Initial/index";

export default function PanelRight() {
  const paneldisplay = useSelector((state) => state.panelRight.displayfilter);
  React.useEffect(() => {
    console.log(paneldisplay);
  }, [paneldisplay]);
  const setRender = () => {
    switch (paneldisplay) {
      case "INITIAL":
        return <InitialPage />;
      case "ACTIVECHAT":
        return <ActiveChat />;
      case "REQUESTTABLE":
      default:
        break;
    }
  };
  return <Page>{setRender()}</Page>;
}
