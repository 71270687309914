import React from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "../services/axios";

export default function ClientsAwaitVerification() {
  const notifications = useSelector((state) => state.notify);
  const dispatch = useDispatch();
  const [conversations, setConversations] = React.useState([]);
  function getConversations(params) {
    api
      .get("ticket/userconversations", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
        params,
      })
      .then((response) => {
        setConversations(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  const getUsers = async () =>
    await api
      .get("users", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          sector: undefined,
        },
      })
      .then((res) => res.data.map((user) => user.idusers));
  React.useEffect(() => {
    if (localStorage.user) {
      if (conversations.length) {
        conversations.map((conversation) => {
          if (
            !!conversation.messages.length &&
            conversation.messages[0].client_message > 0
          ) {
            const now = new Date();
            const ms = Math.abs(
              now - new Date(conversation.messages[0].timestamp)
            );
            const seconds = ms / 1000;
            const minutes = parseInt(seconds / 60, 10);
            if (minutes > 5) {
              const index = notifications.findIndex(
                (notify) => notify.idclient === conversation.idclient
              );
              // if (index === -1) {
              //   dispatch({
              //     type: "ADD_NOTIFY",
              //     notify: {
              //       type: "info",
              //       message: `O Cliente ${conversation.idclient} está aguardando resposta do usuario ${conversation.iduser} á 5 minutos.`,
              //       idclient: conversation.idclient,
              //       read: false,
              //     },
              //   });
              // }
            }
          }
        });
      }
    }
  }, [conversations]);
  React.useEffect(() => {
    setInterval(async () => {
      if (localStorage.user) {
        //const users = await getUsers();
        //getConversations({ iduser: users });
      }
    }, 60000);
  }, []);

  return <></>;
}
