import React from "react";
import { useDispatch } from "react-redux";
import { Page } from "./style";
import api from "../../../../../../services/axios";
import DialogSendMedia from "../../Dialog/DialogSendMedia";
import DialogSendFile from "../../Dialog/DialogSendFile";

export default function MenuDropdown({
  client,
  close,
  setMessages,
  messages,
  updateMessage,
}) {
  const user = JSON.parse(localStorage.user);
  const [transform, setTransform] = React.useState(
    "translateY(-300px) scaleX(0) scaleY(0)"
  );
  const [showsendmedia, setShowsendmedia] = React.useState(false);
  const [showsendfile, setShowsendfile] = React.useState(false);
  const [media, setMedia] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const dispatch = useDispatch();
  const sendMedia = async (fileToSend) =>
    new Promise(async (resolve, reject) => {
      if (fileToSend) {
        setShowsendfile(false);
      } else {
        setShowsendmedia(false);
      }
      close();
      const archives = media ? [media] : fileToSend;
      // const reader = new FileReader();
      // reader.readAsDataURL(archive);
      // const base64 = await new Promise((resolve) => {
      //   reader.onloadend = () => {
      //     resolve(reader.result);
      //   };
      // });
      const filesArray = Array.from(archives);
      const ids = [];
      setMessages([
        ...filesArray.map((archive) => {
          const id = Math.random();
          ids.push(id);
          return {
            idmessages: id,
            content: archive.name,
            type: media ? "image" : "document",
            timestamp: new Date(),
            client_message: 0,
            pending: true,
            media: URL.createObjectURL(archive),
          };
        }),
        ...messages,
      ]);
      // eslint-disable-next-line no-restricted-syntax
      for (const [index, archive] of filesArray.entries()) {
        const message = {
          idclient: client.idclient,
          idtelegram: client.idtelegram,
          number: client.number,
          iduser: user.idusers,
          company: JSON.stringify(user.company),
          userfile: archive,
          type: media ? "image" : "document",
          message: archive.name,
        };
        const dataForm = new FormData();
        // eslint-disable-next-line no-restricted-syntax
        for (const key in message) {
          dataForm.append(key, message[key]);
        }
        try {
          const response = await api.post("ticket/sendmessagemedia", dataForm, {
            headers: {
              authorization: `Bearer ${localStorage.token}`,
            },
          });
          updateMessage(ids[index], response.data.idmessages);
          resolve();
        } catch (e) {
          window.alert("Ocorreu um erro.");
          console.log(e);
          resolve();
        }
      }
    });
  const verifyFile = async (files) => {
    if (!files || !files.length) {
      return;
    }
    const filesArray = Array.from(files);
    const filesToSend = [];
    filesArray.forEach((file) => {
      if (file.size > 104857600) {
        return dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: "Arquivo maior que o limite de 100MB.",
            idclient: 0,
            read: false,
          },
        });
      }
      if (!file.type) {
        return dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: "Tipo de arquivo desconhecido.",
            idclient: 0,
            read: false,
          },
        });
      }
      filesToSend.push(file);
    });
    return await sendMedia(filesToSend);
  };
  React.useEffect(() => {
    setTimeout(() => {
      setTransform("none");
    }, 100);
  }, []);
  React.useEffect(() => {
    if (!files.length) {
      return;
    }
    setShowsendfile(true);
  }, [files]);
  React.useEffect(() => {
    if (!media) {
      return;
    }
    setShowsendmedia(true);
  }, [media]);
  return (
    <Page transform={transform}>
      {showsendmedia && (
        <DialogSendMedia
          close={() => {
            setShowsendmedia(false);
          }}
          media={media}
          sendMedia={sendMedia}
        />
      )}
      {showsendfile && (
        <DialogSendFile
          close={() => {
            setShowsendfile(false);
          }}
          files={files}
          sendFile={verifyFile}
        />
      )}
      <div className="menu-dropdown" id="menu-attach">
        <div
          tabIndex="-1"
          className="_1txFK"
          style={{ transformOrigin: "left top 0px" }}
        >
          <ul className="_3s1D4">
            <li
              tabIndex="-1"
              className="_10anr vidHz _3asN5"
              data-animate-dropdown-item="true"
            >
              <label type="button" className="btn-attach" id="btn-attach-photo">
                <span data-icon="image">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 53 53"
                    width="53"
                    height="53"
                  >
                    <defs>
                      <circle
                        id="image-SVGID_1_"
                        cx="26.5"
                        cy="26.5"
                        r="25.5"
                      />
                    </defs>
                    <clipPath id="image-SVGID_2_">
                      <use href="#image-SVGID_1_" overflow="visible" />
                    </clipPath>
                    <g clipPath="url(#image-SVGID_2_)">
                      <path
                        fill="#AC44CF"
                        d="M26.5-1.1C11.9-1.1-1.1 5.6-1.1 27.6h55.2c-.1-19-13-28.7-27.6-28.7z"
                      />
                      <path
                        fill="#BF59CF"
                        d="M53 26.5H-1.1c0 14.6 13 27.6 27.6 27.6s27.6-13 27.6-27.6H53z"
                      />
                      <path fill="#AC44CF" d="M17 24.5h18v9H17z" />
                    </g>
                    <g fill="#F5F5F5">
                      <path
                        id="svg-image"
                        d="M18.318 18.25h16.364c.863 0 1.727.827 1.811 1.696l.007.137v12.834c0 .871-.82 1.741-1.682 1.826l-.136.007H18.318a1.83 1.83 0 0 1-1.812-1.684l-.006-.149V20.083c0-.87.82-1.741 1.682-1.826l.136-.007h16.364zm5.081 8.22l-3.781 5.044c-.269.355-.052.736.39.736h12.955c.442-.011.701-.402.421-.758l-2.682-3.449a.54.54 0 0 0-.841-.011l-2.262 2.727-3.339-4.3a.54.54 0 0 0-.861.011zm8.351-5.22a1.75 1.75 0 1 0 .001 3.501 1.75 1.75 0 0 0-.001-3.501z"
                      />
                    </g>
                  </svg>
                </span>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  style={{ display: "none" }}
                  id="input-photo"
                  onChange={(e) => {
                    setMedia(e.target.files[0]);
                  }}
                />
              </label>
            </li>
            <li
              tabIndex="-1"
              className="_10anr vidHz _3asN5"
              data-animate-dropdown-item="true"
            >
              <label type="button" className="btn-attach" id="btn-attach-photo">
                <span data-icon="document">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 53 53"
                    width="53"
                    height="53"
                  >
                    <defs>
                      <circle
                        id="document-SVGID_1_"
                        cx="26.5"
                        cy="26.5"
                        r="25.5"
                      />
                    </defs>
                    <clipPath id="document-SVGID_2_">
                      <use href="#document-SVGID_1_" overflow="visible" />
                    </clipPath>
                    <g clipPath="url(#document-SVGID_2_)">
                      <path
                        fill="#5157AE"
                        d="M26.5-1.1C11.9-1.1-1.1 5.6-1.1 27.6h55.2c-.1-19-13-28.7-27.6-28.7z"
                      />
                      <path
                        fill="#5F66CD"
                        d="M53 26.5H-1.1c0 14.6 13 27.6 27.6 27.6s27.6-13 27.6-27.6H53z"
                      />
                    </g>
                    <g fill="#F5F5F5">
                      <path
                        id="svg-document"
                        d="M29.09 17.09c-.38-.38-.89-.59-1.42-.59H20.5c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H32.5c1.1 0 2-.9 2-2V23.33c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM27.5 22.5V18l5.5 5.5h-4.5c-.55 0-1-.45-1-1z"
                      />
                    </g>
                  </svg>
                </span>
                <input
                  type="file"
                  accept="*"
                  multiple
                  style={{ display: "none" }}
                  id="input-photo"
                  onChange={(e) => {
                    setFiles(e.target.files);
                  }}
                />
              </label>
            </li>
          </ul>
        </div>
      </div>
    </Page>
  );
}
