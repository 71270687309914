import React from "react";
import requestsound from "./request.mp3";
import api from "../services/axios";
import socket from "../services/socket";
export default function NewMessageNotification() {
  const [requests, setRequests] = React.useState([]);
  const user = JSON.parse(localStorage.user);
  const updateData = async () => {
    await api
      .get("clients/clientrequest", {
        params: {
          company: user.company,
        },
      })
      .then((res) => {
        setRequests([...res.data]);
      });
  };
  React.useEffect(() => {
    socket.on(`${user.company.prefix}_newrequest`, () => {
      updateData();
    });
  }, []);
  React.useEffect(() => {
    if (!!requests.length) {
      document.getElementById("requesttone").play();
    }
  }, [requests]);
  return (
    <audio id="requesttone" style={{ display: "none" }}>
      <source src={requestsound} type="audio/mpeg"></source>
    </audio>
  );
}
