import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { useDispatch } from "react-redux";
import DeleteMessage from "./Dialogs/DeleteMessage";
import EditMessage from "./Dialogs/EditMessage";
import api from "../../services/axios";
import NewShortcut from "./Dialogs/NewShortcut";
import { Page } from "./style";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default () => {
  const { t } = useTranslation();
  const [messages, setMessages] = React.useState([]);
  const [messageselected, setMessageselected] = React.useState("");
  const [opennewmessage, setOpennewmessage] = React.useState(false);
  const [openeditmessage, setOpeneditmessage] = React.useState(false);
  const [opendeletemessage, setOpendeletemessage] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const updateData = async () => {
    api
      .get("shortcut", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        setMessages(response.data);
      })
      .catch((e) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: e.response.data.error,
            idclient: 0,
            read: false,
          },
        });
      });
  };
  const options = {
    filterType: false,
    print: false,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    filter: false,
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: t("DMT.table.noMatch"),
        toolTip: "Sort",

        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        rowsPerPage: "Linhas por Página",
      },
    },
  };

  const columns = [
    "ID",
    "Atalho",
    "Mensagem",
    t("DMT.table.edit"),
    t("DMT.table.delete"),
  ];

  useEffect(() => {
    updateData();
  }, []);
  return (
    <Page>
      {opendeletemessage && (
        <DeleteMessage
          data={messageselected}
          func={setOpendeletemessage}
          update={updateData}
        />
      )}

      {opennewmessage && (
        <NewShortcut func={setOpennewmessage} update={updateData} />
      )}

      {openeditmessage && (
        <EditMessage
          data={messageselected}
          func={setOpeneditmessage}
          update={updateData}
        />
      )}

      <MUIDataTable
        title={
          <Button
            onClick={() => {
              setOpennewmessage(true);
            }}
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<AddCircleIcon />}
          >
            Novo Atalho
          </Button>
        }
        data={messages.map((message, index) => [
          message.idmessages_shortcut,
          `/${message.command}`,
          message.message,
          <EditIcon
            style={{ fill: "#34b7f1" }}
            onClick={() => {
              setMessageselected(messages[index]);
              setOpeneditmessage(true);
            }}
          />,
          <RemoveCircleOutlineIcon
            style={{ fill: "#34b7f1" }}
            onClick={() => {
              setMessageselected(messages[index]);
              setOpendeletemessage(true);
            }}
          />,
          <Button
            onClick={(ev) => {
              ev.stopPropagation();
              setMessageselected(messages[index]);
            }}
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<AccountBoxIcon />}
          >
            {t("DMT.table.bt1")}
          </Button>,
        ])}
        columns={columns}
        options={options}
      />
    </Page>
  );
};
