import React from "react";
import { SideBar } from "./style";
import Divider from "@material-ui/core/Divider";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import ListIcon from "@material-ui/icons/List";
export default () => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };
  function drag(ev, data) {
    ev.dataTransfer.setData("data", JSON.stringify(data));
  }
  return (
    <SideBar>
      <div className="description">
        Você pode arrastar esses itens para à esquerda.
      </div>
      <div
        className="dndnode"
        onDragStart={(event) => onDragStart(event, "choice")}
        draggable
      >
        <FormatListNumberedIcon style={{ color: "#3a4bc5" }} />
        <div>MENU</div>
      </div>
      <div
        className="dndnode"
        onDragStart={(event) => onDragStart(event, "action")}
        draggable
      >
        <FlashOnIcon style={{ color: "yellow" }} />
        <div>AÇÃO</div>
      </div>
      <br />
      <Divider />
      <br />
      <div className="description">Funções</div>
      <div className="functions-area">
        <div
          className="function-node"
          onDragStart={(ev) => {
            drag(ev, {
              name: "Transferir",
              code: "transfer",
              open: true,
              type: "endpoint",
            });
          }}
          draggable
        >
          <ReplyAllIcon />
          TRANSFERIR
        </div>
        <div
          className="function-node"
          onDragStart={(ev) => {
            drag(ev, {
              name: "Voltar ao Menu",
              code: "backtomenu",
              type: "endpoint",
            });
          }}
          draggable
        >
          <ListIcon />
          VOLTAR AO MENU
        </div>
      </div>
    </SideBar>
  );
};
