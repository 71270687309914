import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { Time } from "./style";
import api from "../../../services/axios";
import { useTranslation } from "react-i18next";

Date.prototype.toDateInputValue = function () {
  const local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};
export default function SelectPeriod({ close, func }) {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = React.useState({
    initial: new Date().toDateInputValue(),
    final: new Date().toDateInputValue(),
  });
  const [message, setMessage] = React.useState("");
  const handleDateChange = (date) => {
    setSelectedDate({ ...selectedDate, ...date });
  };
  const handleClose = () => {
    close();
  };
  React.useEffect(() => {}, [selectedDate]);
  const confirm = () => {
    if (
      new Date(`${selectedDate.initial} 00:00:00`).getTime() >
      new Date(`${selectedDate.final} 00:00:00`).getTime()
    ) {
      return setMessage("Data Inicial maior que a Data Final.");
    }
    func(selectedDate);
    handleClose();
  };
  React.useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  }, [message]);
  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("DB.dialogs.selectperiod.title")}
        </DialogTitle>
        <DialogContent>
          <br />
          <Divider />
          <br />
          <DialogContentText id="alert-dialog-description">
            <div>
              {t("DB.dialogs.selectperiod.1")} :{" "}
              <Time
                type="date"
                value={selectedDate.initial}
                onChange={(e) => {
                  handleDateChange({ initial: e.currentTarget.value });
                }}
              />{" "}
              {t("DB.dialogs.selectperiod.2")} :{" "}
              <Time
                type="date"
                value={selectedDate.final}
                onChange={(e) => {
                  handleDateChange({ final: e.currentTarget.value });
                }}
              />
            </div>
            {message && message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Button variant="contained" color="secondary">
              Cancelar
            </Button>
          </Button>
          <Button color="primary" onClick={confirm} autoFocus>
            <Button variant="contained" color="primary">
              Confirmar
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
