import styled from "styled-components";

export const Page = styled.div`
  bottom: 0;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  left: 0;
  border-left: 1px solid #e4e4e4;
  transform: ${(props) =>
    props.transform ? "translateY(0px)" : "translateY(350px)"};
  transition: 0.3s ease-in-out;
  display: block;
  background-color: #f5f1ee;
`;
export const Item = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  padding: 13px !important;
  color: #a4a4a4;
  cursor: pointer;
  &:hover {
    background-color: #a4a4a442;
  }
`;
