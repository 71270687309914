import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ProfilePage } from "./style";
import api from "../../../../../services/axios";
import { useTranslation } from "react-i18next";

export default function Profile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const client = useSelector((state) => state.panelRight.clientinfo);
  const [showedit, setShowedit] = React.useState(false);
  const [name, setName] = React.useState(false);
  const editName = () => {
    if (showedit) {
      if (!name) {
        setShowedit(!showedit);
        return;
      }
      if (name !== client.name) {
        const newdata = {
          idclient: client.idclient,
          name,
          number: client.number,
        };
        api
          .put(
            "clients/",
            { client: newdata },
            {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            }
          )
          .then((response) => {
            dispatch({
              type: "SET_USER_ACTIVE_CHAT",
              user: { idclient: client.idclient, number: client.number },
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }
      setShowedit(!showedit);
    } else {
      setShowedit(!showedit);
    }
  };
  return (
    <ProfilePage>
      <div className="_2Ixxw">
        <div className="_3X-61">
          <div className="_3_Hvt" dir="ltr">
            <div className="Ao0On">
              <div className="H36t4">
                <div
                  style={{
                    width: "200px",
                    height: "200px",
                    top: "0px",
                    left: "0px",
                    position: "absolute",
                  }}
                >
                  {client.profile_pic ? (
                    <img
                      src={client.profile_pic}
                      alt="perfil"
                      className="profilepic"
                    />
                  ) : (
                    <span
                      data-testid="default-user"
                      data-icon="default-user"
                      className=""
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 212 212"
                        width="212"
                        height="212"
                      >
                        <path
                          fill="#DFE5E7"
                          className="background"
                          d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"
                        />
                        <path
                          fill="#FFF"
                          className="primary"
                          d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z"
                        />
                      </svg>
                    </span>
                  )}
                </div>
              </div>
            </div>
            <span />
          </div>
          <input
            type="file"
            accept="image/gif,image/jpeg,image/jpg,image/png"
            style={{ display: "none" }}
          />
        </div>
      </div>
      <div tabIndex={-1} className="_2m-1n _1pY1V cPu3l _15dwt">
        <div
          role="button"
          className="_1Rerq _1xryB"
          style={{
            borderBottom: showedit
              ? "2px solid #00bfa5"
              : "2px solid transparent",
          }}
        >
          <div tabIndex={-1} className="_2FVVk _230FM">
            <div className="_2FbwG" style={{ visibility: "hidden" }} />
            <div
              className="_3FRCZ copyable-text selectable-text"
              contentEditable={!!showedit}
              dir="ltr"
              onInput={(ev) => {
                console.log(ev.currentTarget.textContent, "edit");
                setName(ev.currentTarget.textContent);
              }}
              onBlur={(ev) => {
                console.log(ev.currentTarget.textContent, "edit");
                setName(ev.currentTarget.textContent);
              }}
            >
              {client.name}
            </div>
          </div>
          <span className="_3CkXi" />
          <span className="_1CUl1">
            <div
              className="_3-8bl"
              title={showedit ? "Salvar" : "Editar"}
              onClick={editName}
            >
              {showedit ? (
                <span
                  data-testid="checkmark"
                  data-icon="checkmark"
                  className=""
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      fill="currentColor"
                      d="M9 17.2l-4-4-1.4 1.3L9 19.9 20.4 8.5 19 7.1 9 17.2z"
                    />
                  </svg>
                </span>
              ) : (
                <span data-testid="pencil" data-icon="pencil" className>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                  >
                    <path
                      fill="currentColor"
                      d="M3.95 16.7v3.4h3.4l9.8-9.9-3.4-3.4-9.8 9.9zm15.8-9.1c.4-.4.4-.9 0-1.3l-2.1-2.1c-.4-.4-.9-.4-1.3 0l-1.6 1.6 3.4 3.4 1.6-1.6z"
                    />
                  </svg>
                </span>
              )}
            </div>
          </span>
        </div>
        <div className="_3V3__" />
      </div>
      <div className="_1005i">
        <span className="_2-1_O">
          {t("HOME.pr.active.info.profile.info")}{" "}
          {new Date(client.timestamp).toLocaleDateString()} à(s){" "}
          {new Date(client.timestamp).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
      </div>
    </ProfilePage>
  );
}
