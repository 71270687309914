import React from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import api from "../../../services/axios";
import WalletSelect from "./WalletSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));
export default function AlertDialog(props) {
  const user = JSON.parse(localStorage.user);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [verifyLoadButton, setVerifyLoadButton] = React.useState(false);
  const [name, setName] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [cpf, setCPF] = React.useState("");
  const [wallet, setWallet] = React.useState("");
  const [selectwallet, setSelectwallet] = React.useState(false);
  const [inputs, setInputs] = React.useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
    props.func(false);
  };
  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  const moreInputs = () => {
    setInputs([
      ...inputs,
      <>
        <InputMask mask="99.999.999/9999-99">
          {() => (
            <TextField
              id={`outlined-basic cnpjindex${inputs.length}`}
              label="CNPJ"
              variant="outlined"
              required
            />
          )}
        </InputMask>

        <TextField
          id={`outlined-basic codindex${inputs.length}`}
          label={t("CT.newclient.phcodclient")}
          variant="outlined"
          required
        />
        <TextField
          id={`outlined-basic nmfantasiaindex${inputs.length}`}
          label={t("CT.newclient.phnm")}
          variant="outlined"
          required
        />
        <TextField
          id={`outlined-basic rzsocialindex${inputs.length}`}
          label={t("CT.newclient.phrz")}
          variant="outlined"
          required
        />
      </>,
    ]);
  };
  const handleVerifyNumber = async () => {
    setVerifyLoadButton(true);
    try {
      const contact = await api.post(
        "/whatsapp/verify",
        {
          number: number.replace(/([^\d])+/gim, ""),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      );
      if (contact.data.whatsapp) {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "success",
            message: "Número possui whatsapp.",
            idclient: 0,
            read: false,
          },
        });
      } else {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: "Número não possui whatsapp.",
            idclient: 0,
            read: false,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "ADD_NOTIFY",
        notify: {
          type: "error",
          message: error?.response?.data?.error,
          idclient: 0,
          read: false,
        },
      });
    } finally {
      setVerifyLoadButton(false);
    }
  };
  const confirm = async () => {
    setDisable(true);
    if (!name.length || !number.length) {
      setDisable(false);
      return window.alert("Preencha todos os campos");
    }
    if (number.replace(/([^\d])+/gim, "").length < 12) {
      setDisable(false);
      return window.alert("Numero com formato incorreto!");
    }

    const data = {
      client: {
        name,
        number: number.replace(/([^\d])+/gim, ""),
        cpf: cpf.replace(/([^\d])+/gim, ""),
        idwallet: wallet ? wallet.idwallets : undefined,
        completed: 1,
      },
      company: user.company,
      establishments: [],
    };
    for (let i = 0; i <= inputs.length - 1; i++) {
      // if (
      //   !document.getElementById(`outlined-basic cnpjindex${i}`).value ||
      //   !document.getElementById(`outlined-basic codindex${i}`).value ||
      //   !document.getElementById(`outlined-basic nmfantasiaindex${i}`).value ||
      //   !document.getElementById(`outlined-basic rzsocialindex${i}`).value
      // ) {
      //   return window.alert("Preencha todos os campos");
      // }
      data.establishments.push({
        cnpj: document
          .getElementById(`outlined-basic cnpjindex${i}`)
          .value.replace(/([^\d])+/gim, ""),
        cod: document.getElementById(`outlined-basic codindex${i}`).value,
        nmfantasia: document.getElementById(
          `outlined-basic nmfantasiaindex${i}`
        ).value,
        rzsocial: document.getElementById(`outlined-basic rzsocialindex${i}`)
          .value,
      });
    }
    api
      .post("clients", data)
      .then(async (res) => {
        api
          .get("/clients/clientinfo", {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
            params: { number: data.client.number },
          })
          .then((response) => {
            props.update(response.data);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => handleClose());
      })
      .catch((err) => window.alert(err.response.data.error))
      .finally(() => {
        setDisable(false);
      });
  };
  return (
    <div>
      {selectwallet && (
        <WalletSelect
          close={() => {
            setSelectwallet(false);
          }}
          setWallet={setWallet}
          wallet={wallet}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("CT.newclient.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("CT.newclient.description")}
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-basic"
                label={t("CT.newclient.phname")}
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
              />
              <InputMask
                mask="99 (99) 9 9999-9999"
                value={number}
                onChange={(e) => {
                  setNumber(e.currentTarget.value);
                }}
              >
                {() => (
                  <TextField
                    id="outlined-basic"
                    label={t("CT.newclient.phnumber")}
                    variant="outlined"
                  />
                )}
              </InputMask>
              <InputMask
                mask="999.999.999-99"
                value={cpf}
                onChange={(e) => {
                  setCPF(e.currentTarget.value);
                }}
              >
                {() => (
                  <TextField
                    id="outlined-basic"
                    label={t("CT.newclient.phcpf")}
                    variant="outlined"
                  />
                )}
              </InputMask>
              <Button
                variant="contained"
                color="primary"
                onClick={handleVerifyNumber}
                style={{ width: "28.3ch" }}
                disabled={verifyLoadButton}
              >
                {verifyLoadButton ? (
                  <CircularProgress color="white" size={25} />
                ) : (
                  "Verificar Whatsapp"
                )}
              </Button>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectwallet(true);
                }}
              >
                {t("CT.newclient.bt3")}
              </Button>
              {wallet && wallet.name}
              <h3>CNPJ :</h3>
              {inputs.map((input) => input)}
              <AddCircleIcon
                style={{ height: "3ch", width: "35.5ch", margin: 0 }}
                onClick={moreInputs}
              />
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            {t("CT.newclient.bt2")}
          </Button>

          <Button
            onClick={confirm}
            variant="contained"
            color="primary"
            disabled={disable}
          >
            {t("CT.newclient.bt1")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
