import styled, { keyframes } from "styled-components";
const _1fqU3 = keyframes`
50%{
      opacity:0
  }
`;

export const Page = styled.div`
  button {
    border: 0;
    margin: 0;
    background: 0 0;
    outline: 0;
    cursor: pointer;
    font-family: inherit;
  }
  a,
  abbr,
  acronym,
  address,
  applet,
  article,
  aside,
  audio,
  b,
  big,
  blockquote,
  body,
  canvas,
  caption,
  center,
  cite,
  code,
  dd,
  del,
  details,
  dfn,
  div,
  dl,
  dt,
  em,
  embed,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  hgroup,
  html,
  i,
  iframe,
  img,
  ins,
  kbd,
  label,
  legend,
  li,
  mark,
  menu,
  nav,
  object,
  ol,
  output,
  p,
  pre,
  q,
  ruby,
  s,
  samp,
  section,
  small,
  span,
  strike,
  strong,
  sub,
  summary,
  sup,
  table,
  tbody,
  td,
  tfoot,
  th,
  thead,
  time,
  tr,
  tt,
  u,
  ul,
  var,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    outline: 0;
  }
  order: 3;
  flex: none;
  z-index: 1;
  min-height: 62px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.6);
  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.08);
  }
  .footer-content {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
  }
  ._3oju3 {
    -webkit-align-items: flex-end;
    align-items: flex-end;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    max-width: 100%;
    position: relative;
    z-index: 2;
    min-height: 62px;
    box-sizing: border-box;
    background-color: #f5f1ee;
    padding: 10px 19px;
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    ._2uQuX {
      ._2Fofa {
        flex: 0 0 auto;
        margin-bottom: 8px;
        margin-right: 20px;
      }
      &:focus ._2Fofa {
        box-shadow: 0 0 0 2px rgba(0, 175, 255, 0.4);
      }
    }
    ._2bXVy {
      background-color: #fff;
      border-color: #fff;
      border-radius: 5px;
      border-style: solid;
      border-width: 1px;
      padding: 10px 16px 10px 12px;
      box-sizing: border-box;
      line-height: 20px;
      min-height: 20px;
      outline: 0;
      flex: 1 1 auto;
      font-size: 15px;
      font-weight: 400;
      min-width: 0;
      width: inherit;
      ._3F6QL {
        position: relative;
        flex: 1;
        display: flex;
        overflow: hidden;
        padding-right: 5px;
        ._39LWd {
          pointer-events: none;
          color: #a4a4a4;
          font-size: 15px;
          line-height: 20px;
          position: absolute;
          top: 6px;
          transition: opacity 80ms linear;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          z-index: 100;
          z-index: 2;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          left: 2px;
          right: 2px;
          top: 0;
        }
        ._2S1VP {
          position: relative;
          white-space: pre-wrap;
          word-wrap: break-word;
          z-index: 1;
          outline: 0;
          min-height: 20px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          min-height: 20px;
          width: 100%;
          -webkit-user-select: text;
          -moz-user-select: text;
          -ms-user-select: text;
          user-select: text;
          padding: 0;
          font-size: 15px;
          max-height: 100px;
          overflow-x: hidden;
          overflow-y: auto;
          padding-right: 2px;
          border-left: 0.1px solid transparent;
        }
      }
    }
    #record-microphone {
      /* display: none; */
      padding: 3px 10px;
    }
    .record-microphone #record-microphone {
      display: block;
    }
    .record-microphone #btn-send-microphone {
      display: none;
    }
    ._1-hDo {
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      width: 200px;
      .cOcmP {
        width: 35px;
        height: 35px;
        position: relative;
        &:hover {
          transition: -webkit-transform 0.1s ease-in-out;
          transition: transform 0.1s ease-in-out;
          transition: transform 0.1s ease-in-out,
            -webkit-transform 0.1s ease-in-out;
          -webkit-transform: scale(1.12) !important;
          transform: scale(1.12) !important;
          ._1aVJp {
            opacity: 0;
          }
          .ZxQVp {
            opacity: 1;
          }
        }
        .ZBZ4i {
          top: 0;
          right: 0;
          position: absolute;
        }
        .ZxQVp {
          opacity: 0;
        }
      }
      ._30ie- {
        font-weight: 300;
        font-size: 20px;
        color: #606060;
      }
      ._30ie-:before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #e53935;
        animation: ${_1fqU3} 1.6s ease-in infinite;
        margin-right: 7px;
      }
    }
    ._2lkdt {
      -webkit-flex: 0 0 auto;
      flex: 0 0 auto;
      opacity: 0.9;
      margin-left: 26px;
      margin-bottom: 8px;
      &:disabled {
        opacity: 0.4;
      }
    }
  }
`;
export const PageLocked = styled.footer`
  position: relative;
  z-index: 1;
  flex: none;
  order: 3;
  box-sizing: border-box;
  min-height: 62px;
  background-color: #f0f0f0;
  ._3ge3i {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-height: 62px;
    color: var(--secondary);
    font-size: 14px;
    line-height: 20px;
    padding: 14px 19px;
    text-align: center;
    background-color: #ededed;
  }
`;
