import React from "react";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import Divider from "@material-ui/core/Divider";
import { Handle } from "react-flow-renderer";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditIcon from "@material-ui/icons/Edit";
import { Button, Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Node, RightMessage } from "./style";
// eslint-disable-next-line import/no-anonymous-default-export
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default (data) => {
  const classes = useStyles();
  const {
    setElementupdate,
    id,
    valueDefault,
    valueDefaultcallback,
    tagsDefault,
    removenode,
    index,
  } = data.data;
  const [value, setValue] = React.useState(valueDefault || "");
  const [valuecallback, setValuecallback] = React.useState(
    valueDefaultcallback || ""
  );
  const [inputvalue, setInputvalue] = React.useState("");
  const [tags, setTags] = React.useState(tagsDefault || []);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showModalMessage, setShowModalMessage] = React.useState(false);
  const [showModalMessageError, setShowModalMessageError] =
    React.useState(false);

  function removeTag(index) {
    const oldTags = tags;
    oldTags.splice(index, 1);
    setTags([...oldTags]);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenShowMessage = () => {
    setShowModalMessage(true);
  };
  const handleCloseShowMessage = () => {
    setShowModalMessage(false);
  };
  const handleOpenShowMessageError = () => {
    setShowModalMessageError(true);
  };
  const handleCloseShowMessageError = () => {
    setShowModalMessageError(false);
  };
  React.useEffect(() => {
    if (tags.length || valuecallback || value) {
      setElementupdate({
        id,
        tagsDefault: tags,
        valueDefault: value,
        valueDefaultcallback: valuecallback,
      });
    }
  }, [valuecallback, value, tags]);
  return (
    <>
      <Handle
        position="top"
        type="target"
        id="a"
        style={{
          background: "#555",
          width: "20px",
          height: "15px",
          bottom: "-7px",
          borderRadius: "5px",
        }}
      />
      <Handle
        position="bottom"
        type="source"
        id="b"
        style={{
          background: "#555",
          width: "20px",
          height: "15px",
          bottom: "-7px",
          borderRadius: "5px",
        }}
      />

      <Node>
        {showModalMessage && (
          <Drawer anchor="right" open onClose={handleCloseShowMessage}>
            <RightMessage>
              <h4>MENU</h4>
              <Divider />
              <br />
              <br />
              <TextField
                id="outlined-multiline-flexible"
                multiline
                value={value}
                rowsMax={8}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                }}
                onChange={(ev) => {
                  setValue(ev.currentTarget.value);
                }}
                variant="outlined"
                fullWidth
                style={{
                  height: "200px",
                }}
              />
              <div className="actions">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleCloseShowMessage}
                >
                  Fechar
                </Button>
              </div>
            </RightMessage>
          </Drawer>
        )}
        {showModalMessageError && (
          <Drawer anchor="right" open onClose={handleCloseShowMessageError}>
            <RightMessage>
              <h4>MENU</h4>
              <Divider />
              <br />
              <br />
              <TextField
                id="outlined-multiline-flexible"
                multiline
                value={valuecallback}
                rowsMax={8}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                }}
                onChange={(ev) => {
                  setValuecallback(ev.currentTarget.value);
                }}
                variant="outlined"
                fullWidth
                style={{
                  height: "200px",
                }}
              />
              <div className="actions">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleCloseShowMessageError}
                >
                  Fechar
                </Button>
              </div>
            </RightMessage>
          </Drawer>
        )}
        <div className="header-node">
          <FormatListNumberedIcon
            style={{ color: "#3a4bc5", cursor: "pointer" }}
          />
          <p>MENU</p>
          <MoreHorizIcon
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ cursor: "pointer" }}
          />

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                removenode({ id });
              }}
            >
              Excluir
            </MenuItem>
          </Menu>
        </div>
        {index !== 0 && (
          <>
            {" "}
            <span>Palavras Chaves</span>
            <div className="tag-container">
              {tags.length
                ? tags.map((tag, index) => (
                    <div key={index} className="tag">
                      <span>{tag}</span>
                      <ClearIcon
                        onClick={() => {
                          removeTag(index);
                        }}
                      />
                    </div>
                  ))
                : ""}

              <input
                value={inputvalue}
                onChange={(ev) => {
                  setInputvalue(ev.currentTarget.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    const oldTags = tags;
                    e.currentTarget.value.split(",").forEach((tag) => {
                      if (tag) oldTags.push(tag);
                    });
                    setTags([...oldTags]);
                    setInputvalue("");
                  }
                }}
              />
            </div>
          </>
        )}

        <div className="title">
          <h4>Mensagem</h4>
          <EditIcon
            style={{
              fill: "#34b7f1",
              color: "#34b7f1",
              cursor: "pointer",
            }}
            onClick={handleOpenShowMessage}
          />
        </div>
        <Divider />
        {value ? (
          <TextField
            id="outlined-multiline-flexible"
            multiline
            value={value}
            rowsMax={4}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
            onChange={(ev) => {
              setValue(ev.currentTarget.value);
            }}
            variant="outlined"
            disabled
          />
        ) : (
          <div className="btn-message">
            <Button
              variant="outlined"
              style={{ color: "#34b7f1", border: "solid 2px #34b7f1" }}
              onClick={handleOpenShowMessage}
            >
              Adicionar Mensagem
            </Button>
          </div>
        )}

        <br />
        <br />
        <Divider />
        <br />
        <div className="title">
          <h4>Mensagem Erro</h4>
          <EditIcon
            style={{
              fill: "#34b7f1",
              color: "#34b7f1",
              cursor: "pointer",
            }}
            onClick={handleOpenShowMessageError}
          />
        </div>
        <Divider />
        {valuecallback ? (
          <TextField
            id="outlined-multiline-flexible"
            multiline
            value={valuecallback}
            rowsMax={4}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
            onChange={(ev) => {
              setValuecallback(ev.currentTarget.value);
            }}
            variant="outlined"
            disabled
          />
        ) : (
          <div className="btn-message">
            <Button
              variant="outlined"
              style={{ color: "#34b7f1", border: "solid 2px #34b7f1" }}
              onClick={handleOpenShowMessageError}
            >
              Adicionar Mensagem de Erro
            </Button>
          </div>
        )}
      </Node>
    </>
  );
};
