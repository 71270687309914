import React from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { CircularProgress } from "@material-ui/core";
import TicketsPending from "../Dialogs/TicketsPending";
import ClosedTickets from "../Dialogs/ClosedTickets";
import SelectPeriod from "../Dialogs/SelectPeriod";
import api from "../../../services/axios";
import { AddNotify } from "../../../components/Alert";
import { useTranslation } from "react-i18next";
import socket from "../../../services/socket";

Date.prototype.toDateInputValue = function () {
  const local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};
export default function ({ history }) {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.user);
  const [tickets, setTickets] = React.useState([]);
  const [pending, setPending] = React.useState([]);
  const [period, setPeriod] = React.useState("");
  const [closed, setClosed] = React.useState([]);
  const [showloading, setShowloading] = React.useState(false);
  const [showticketspending, setShowticketspending] = React.useState(false);
  const [showClosedTickets, setShowClosedTickets] = React.useState(false);
  const [showShowSelectPeriod, setShowShowSelectPeriod] = React.useState(false);
  const getTickets = async (date) => {
    setShowloading(true);
    const datatime = {
      initial: new Date(`${date.initial} 00:00:00`),
      final: new Date(`${date.final} 23:59:59`),
    };

    await api
      .get("dashboard/tickets", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: datatime,
      })
      .then((res) => {
        setTickets(res.data);
        setShowloading(false);
      })
      .catch((e) => {
        setShowloading(false);
        AddNotify("error", e.response.data.error);
      });
  };
  React.useEffect(() => {
    if (tickets.length) {
      setPending(tickets.filter((x) => x.status === "PENDENTE"));
      setClosed(tickets.filter((x) => x.status === "FECHADO"));
    }
  }, [tickets]);
  React.useEffect(() => {
    getTickets({
      initial: new Date().toDateInputValue(),
      final: new Date().toDateInputValue(),
    });
    socket.on(`${user.company.prefix}_newticket`, () => {
      if (period) {
        getTickets(period);
      } else {
        getTickets({
          initial: new Date().toDateInputValue(),
          final: new Date().toDateInputValue(),
        });
      }
    });
    // socket.on(`${user.company.prefix}_updateticket`, () => {
    //   if (period) {
    //     getTickets(period);
    //   } else {
    //     getTickets({
    //       initial: new Date().toDateInputValue(),
    //       final: new Date().toDateInputValue(),
    //     });
    //   }
    // });
  }, []);
  function dateFilter(date) {
    setPeriod(date);
    getTickets(date);
  }
  return (
    <>
      {showShowSelectPeriod && (
        <SelectPeriod
          close={() => {
            setShowShowSelectPeriod(false);
          }}
          func={dateFilter}
        />
      )}
      {showticketspending && (
        <TicketsPending
          close={() => {
            setShowticketspending(false);
          }}
          data={pending}
          history={history}
        />
      )}
      {showClosedTickets && (
        <ClosedTickets
          close={() => {
            setShowClosedTickets(false);
          }}
          data={closed}
          history={history}
        />
      )}
      <div className="col-md-4 col-xl-3">
        <div className="card bg-c-blue order-card">
          <div className="card-block">
            {showloading && (
              <div className="mask">
                <CircularProgress />
              </div>
            )}
            <h6 className="header-card">
              {period ? `${period.initial} - ${period.final}` : "Hoje"} -
              {t("DB.snippets.ticket.description")}{" "}
              <DateRangeIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowShowSelectPeriod(true);
                }}
              />
            </h6>
            <h6 className="m-b-20"> {t("DB.snippets.ticket.title")}</h6>
            <h2 className="text-right">
              <AvTimerIcon fontSize="large" />
              <span>
                <VisibilityIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowticketspending(true);
                  }}
                />
                &nbsp;&nbsp;
                {pending.length || "..."}
              </span>
            </h2>
            <p className="m-b-0">
              {t("DB.snippets.ticket.subtitle")}
              <span className="f-right">
                {" "}
                <VisibilityIcon
                  fontSize="small"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowClosedTickets(true);
                  }}
                />
                &nbsp;&nbsp;
                {closed.length || "..."}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
