import styled from "styled-components";

export const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  .MUIDataTableBodyCell-root-78 {
    max-width: 170px;
    overflow-x: scroll;
  }
`;
export const Time = styled.input`
  outline: none;
  /* Removes the clear button from date inputs */
  &::-webkit-clear-button {
    display: none;
  }

  /* Removes the spin button */
  &::-webkit-inner-spin-button {
    display: none;
  }

  /* Always display the drop down caret */
  &::-webkit-calendar-picker-indicator {
    color: #2c3e50;
  }

  /* A few custom styles for date inputs */
  appearance: none;
  -webkit-appearance: none;
  color: #95a5a6;
  font-family: "Helvetica", arial, sans-serif;
  font-size: 18px;
  border: 1px solid #ecf0f1;
  background: #ecf0f1;
  padding: 5px;
  display: inline-block !important;
  visibility: visible !important;

  &:focus {
    color: #95a5a6;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
