import styled from "styled-components";

export const Page = styled.div`
  position: "relative";
  width: "100%";
  height: "100%";
  overflow: "auto";
  td {
    max-width: 250px;
    word-break: break-word;
  }
`;
export const FileStyled = styled.div`
  display: flex;
  justify-content: space-between;
  .filedescription {
    display: flex;
    align-items: center;
    p {
      margin: 0px;
      margin-left: 5px;
      padding: 0px;
      max-width: 400px;
      overflow: auto;
    }
    .place {
      width: 50px;
      height: 50px;
      border-radius: 5px;
      background-image: url(${(props) => props.url});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;
