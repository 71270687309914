import React from "react";
import { Page } from "./style";
import Filter from "./Filter";
import Chats from "./Chats";

export default function FilterChats() {
  const [data, setData] = React.useState("");
  return (
    <Page>
      {data ? (
        <Chats data={data} setData={setData} />
      ) : (
        <Filter setData={setData} />
      )}
    </Page>
  );
}
