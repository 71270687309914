import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import api from "../../../services/axios";
import { useTranslation } from "react-i18next";

export default function AlertDialog(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    props.func(false);
  };
  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  const confirm = async () => {
    api
      .post(
        "representatives/delete",
        {
          ...props.data,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then(async (res) => {
        await props.update();
        handleClose();
      })
      .catch((err) => window.alert(err.response.data.error));
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("RET.deleterepresentative.title")} {props.data?.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("RET.deleterepresentative.description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Button variant="contained" color="secondary">
              {t("RET.deleterepresentative.bt2")}
            </Button>
          </Button>
          <Button onClick={confirm} color="primary" autoFocus>
            <Button variant="contained" color="primary">
              {t("RET.deleterepresentative.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
