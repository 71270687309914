import React, { useState, useEffect } from "react";
import { Page, Item } from "./style";

export default function Mentions({ message, setMessage, shortcuts }) {
  const [transform, setTransform] = useState(0);
  const [mentions, setMentions] = useState(shortcuts);
  const [mentionsFilter, setMentionsFilter] = useState([...mentions]);
  function handleReplaceMention(mention) {
    const regex = new RegExp(/([/][\w_-]+)/g);
    const matchs = Array.from(message.matchAll(regex), (x) => x[1]);
    const lastMatch = matchs[matchs.length - 1];
    if (lastMatch) {
      const newMessage = message.replace(lastMatch, mention.message);
      setMessage(newMessage);
    } else {
      let newMessage = message.slice(1, message.length - 1) + mention.message;
      console.log(newMessage);

      setMessage(newMessage);
    }
  }
  const onChange = (input) => {
    const array = mentions;
    const result = array.filter((x) => {
      let obj = JSON.parse(JSON.stringify(x));
      return Object.values(obj)
        .join(" ")
        .toLowerCase()
        .includes(input.toLowerCase());
    });
    setMentionsFilter(result);
  };
  useEffect(() => {
    const regex = new RegExp(/([/][\w_-]+)/g);
    const matchs = Array.from(message.matchAll(regex), (x) => x[1]);
    const lastMatch = matchs[matchs.length - 1];
    if (lastMatch) onChange(matchs[matchs.length - 1]);
  }, [message]);

  useEffect(() => {
    setTimeout(() => {
      setTransform(1);
    }, 100);
  }, []);
  return (
    <Page transform={transform}>
      {mentionsFilter.map((mention) => (
        <Item
          key={mention.idmessages_shortcut}
          onClick={() => {
            handleReplaceMention(mention);
          }}
        >{`${mention.command} - ${mention.message}`}</Item>
      ))}
    </Page>
  );
}
