import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import api from "../../../services/axios";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));
export default function AlertDialog(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [cnpj, setCnpj] = React.useState("");
  const [cod, setCod] = React.useState("");
  const [rzsocial, setRzsocial] = React.useState("");
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    props.func(false);
    setCnpj("");
    setCod("");
    setRzsocial("");
  };
  React.useEffect(() => {
    setOpen(props.open);
    setCnpj(props.establishments.establishments.cnpj);
    setCod(props.establishments.establishments.cd_cliente_dislub);
    setRzsocial(props.establishments.establishments.rzsocial);
  }, [props.cliente_cnpj, props.open]);

  const confirm = async () => {
    if (cnpj.replace(/([^\d])+/gim, "").length < 14) {
      return window.alert("CNPJ inválido");
    }
    api
      .put(
        "representatives/establishment",
        {
          cnpj: cnpj.replace(/([^\d])+/gim, ""),
          cd_cliente_dislub: cod,
          rzsocial,
          idcliente_cnpj: props.establishments.establishments.idcliente_cnpj,
          number: props.establishments.representative.number,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then(async (res) => {
        await props.update();
        handleClose();
      })
      .catch((err) => window.alert(err.response.data.error));
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("RET.editcnpj.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("RET.editcnpj.description")}
            <form className={classes.root} noValidate autoComplete="off">
              <InputMask
                mask="99.999.999/9999-99"
                value={cnpj}
                onChange={(e) => {
                  setCnpj(e.currentTarget.value);
                }}
              >
                {() => (
                  <TextField
                    id="outlined-basic"
                    label="CNPJ"
                    variant="outlined"
                    required
                  />
                )}
              </InputMask>

              <TextField
                id="outlined-basic"
                label={t("RET.editcnpj.phcodclient")}
                variant="outlined"
                value={cod}
                onChange={(e) => {
                  setCod(e.currentTarget.value);
                }}
                required
              />
              <TextField
                id="outlined-basic"
                label={t("RET.editcnpj.phrz")}
                variant="outlined"
                value={rzsocial}
                onChange={(e) => {
                  setRzsocial(e.currentTarget.value);
                }}
                required
              />
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Button variant="contained" color="secondary">
              {t("RET.editcnpj.bt2")}
            </Button>
          </Button>
          <Button color="primary" onClick={confirm} autoFocus>
            <Button variant="contained" color="primary">
              {t("RET.editcnpj.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
