import React, { useEffect, useState } from "react";
import api from "../../services/axios";
import { Page, GlobalStyle } from "./style";
import InputMask from "react-input-mask";

import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import LogoPng from "./img/pipego.png";
function validarCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj == "") return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}
export default function Register(props) {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [plan, setPlan] = useState("1");
  const [fantasyName, setFantasyName] = useState("");
  const [corporateName, setCorporateName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [uf, setUf] = useState("");
  const [message, setMessage] = useState("");
  const [disable, setDisable] = useState(false);
  const [btnMessage, setBtnMessage] = useState("Iniciar Teste Grátis");
  const [termCheck, setTermCheck] = useState(false);
  const [showTerm, setShowTerm] = useState(false);
  function validateFormFields() {
    if (
      !name ||
      !email ||
      !password ||
      !plan ||
      !fantasyName ||
      !corporateName ||
      !cnpj ||
      !uf ||
      !number
    ) {
      setMessage("Preencha todos os dados!");
      return false;
    }
    if (password !== passwordConfirmation) {
      setMessage("As senhas são diferentes!");
      return false;
    }
    if (!validarCNPJ(cnpj)) {
      setMessage("CNPJ inválido!");
      return false;
    }
    if (!termCheck) {
      setMessage("É necessário aceitar os termos de serviço!");
      return false;
    }
    return true;
  }
  function handleFormSubmit(ev) {
    ev.preventDefault();
    setBtnMessage("Enviando....");
    setDisable(true);

    if (!validateFormFields()) {
      setBtnMessage("Iniciar Teste Grátis");
      return setDisable(false);
    }
    const dataToSend = {
      username: name,
      email: email,
      password: password,
      name: fantasyName,
      cnpj: cnpj,
      rz_social: corporateName,
      uf: uf,
      plan: plan,
      number: number,
    };
    api
      .post("/companie/register", dataToSend)
      .then(() => {
        return window.location.replace("http://187.45.177.170:5000");
      })
      .catch((err) => {
        if (err.response) {
          setMessage(err.response.data.error);
          setBtnMessage("Iniciar Teste Grátis");
          return setDisable(false);
        } else {
          setMessage("Ocorreu um erro.");
          setBtnMessage("Iniciar Teste Grátis");
          return setDisable(false);
        }
      });
  }
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  }, [message]);

  return (
    <>
      <GlobalStyle />
      <Page>
        <div className="wrapper w3-animate-top" id="wrapper">
          <div className="card card-4">
            <div className="card-body">
              <div className="logo-head">
                <img src={LogoPng} />
              </div>
              <h2 className="title">Formulário de Cadastro</h2>
              <form name="myForm" id="form" onSubmit={handleFormSubmit}>
                <div className="row row-space">
                  <div className="col-2">
                    <div className="input-group">
                      <label className="label">nome</label>
                      <input
                        className="input--style-4"
                        type="text"
                        name="username"
                        value={name}
                        onChange={(ev) => setName(ev.currentTarget.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="input-group">
                      <label className="label">Contato</label>
                      <InputMask
                        mask="+55 (99) 9 9999-9999"
                        className="input--style-4"
                        type="text"
                        name="number"
                        value={number}
                        onChange={(ev) => setNumber(ev.currentTarget.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-2 full-width">
                    <div className="input-group">
                      <label className="label">Email</label>

                      <input
                        className="input--style-4"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(ev) => setEmail(ev.currentTarget.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="input-group">
                      <label className="label">senha</label>
                      <input
                        className="input--style-4"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(ev) => setPassword(ev.currentTarget.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="input-group">
                      <label className="label">confirmar senha</label>

                      <input
                        className="input--style-4"
                        type="password"
                        name="password_confirmation"
                        value={passwordConfirmation}
                        onChange={(ev) =>
                          setPasswordConfirmation(ev.currentTarget.value)
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-space">
                  <div className="col-2 full-width">
                    <div className="input-group">
                      <label className="label">Plano</label>
                      <div className="p-t-10">
                        <label className="radio-container m-r-45">
                          Básico
                          <input
                            type="radio"
                            value="1"
                            onChange={(ev) => setPlan(ev.currentTarget.value)}
                            name="plan"
                            checked={plan === "1"}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="radio-container">
                          Intermediário
                          <input
                            type="radio"
                            value="2"
                            onChange={(ev) => setPlan(ev.currentTarget.value)}
                            name="plan"
                            checked={plan === "2"}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <h2 className="title">Informações Básicas da Empresa</h2>
                <div className="row row-space">
                  <div className="col-2">
                    <div className="input-group">
                      <label className="label">Nome Fantasia</label>
                      <input
                        className="input--style-4"
                        type="text"
                        name="fantasy_name"
                        value={fantasyName}
                        onChange={(ev) =>
                          setFantasyName(ev.currentTarget.value)
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="input-group">
                      <label className="label">Razão Social</label>

                      <input
                        className="input--style-4"
                        type="text"
                        name="corporate_name"
                        value={corporateName}
                        onChange={(ev) =>
                          setCorporateName(ev.currentTarget.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="row row-space">
                  <div className="col-2">
                    <div className="input-group">
                      <label className="label"> CNPJ</label>
                      <InputMask
                        mask="99.999.999/9999-99"
                        className="input--style-4"
                        type="text"
                        id="cnpj"
                        name="cnpj"
                        value={cnpj}
                        onChange={(ev) => setCnpj(ev.currentTarget.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="input-group">
                      <label className="label"> UF</label>
                      <input
                        className="input--style-4"
                        type="text"
                        name="uf"
                        value={uf}
                        onChange={(ev) => setUf(ev.currentTarget.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                {message && (
                  <div className="error-message" id="error-message">
                    {message}
                  </div>
                )}
                <div className="term-area">
                  <input
                    type="checkbox"
                    onChange={() => setTermCheck(!termCheck)}
                    className="term-check"
                    checked={termCheck}
                  />
                  Declaro que Li e concordo com o{" "}
                  <div
                    className="term-title"
                    onClick={() => {
                      setShowTerm(!showTerm);
                    }}
                  >
                    Termo de serviço
                  </div>
                </div>
                {showTerm && (
                  <div className="term-text">
                    <div id="page_1">
                      <p className="p0 ft1">
                        Termos de uso da Plataforma{" "}
                        <nobr>
                          <span className="ft0">Pipe-Go</span>
                        </nobr>
                      </p>
                      <p className="p1 ft1">
                        Última modificação: 21 de julho de 2021
                      </p>
                      <p className="p2 ft3">
                        <span className="ft1">1.</span>
                        <span className="ft2">ACEITAÇÃO DOS TERMOS</span>
                      </p>
                      <p className="p3 ft1">
                        Ao fazer o cadastro e clicar no botão de aceitação (“Li
                        e Concordo”) ou respondendo por <nobr>e-mail,</nobr> o
                        USUÁRIO
                      </p>
                      <p className="p4 ft1">
                        declara que está de acordo com estes Termos e com o uso
                        dos serviços da <nobr>Pipe-Go.</nobr>
                      </p>
                      <p className="p5 ft1">
                        Caso o USUÁRIO esteja fazendo o cadastro e a contratação
                        para uma pessoa jurídica, este declara que tem
                      </p>
                      <p className="p6 ft1">
                        autoridade para <nobr>fazê-lo</nobr> em nome dela (é seu
                        representante legal).
                      </p>
                      <p className="p7 ft1">
                        O USUÁRIO também declara, neste ato, possuir os
                        necessários poderes para assumir as declarações
                        previstas
                      </p>
                      <p className="p8 ft1">nestes Termos.</p>
                      <p className="p9 ft1">
                        Caso o USUÁRIO não concorde, ou deixe de concordar, com
                        estes Termos, deverá cessar imediatamente a
                      </p>
                      <p className="p10 ft1">
                        utilização dos serviços oferecidos pela{" "}
                        <nobr>Pipe-Go</nobr>
                      </p>
                      <p className="p2 ft3">
                        <span className="ft1">2.</span>
                        <span className="ft2">
                          REQUISITOS MÍNIMOS e ESCLARECIMENTOS INICIAIS
                        </span>
                      </p>
                      <p className="p11 ft4">
                        Nosso sistema depende do funcionamento mínimo de
                        internet do USUÁRIO. A instabilidade da rede local
                        poderá comprometer a qualidade e a estabilidade. Assim
                        sugerimos sempre uma internet dedicada com pelo menos
                        5MB a cada usuário no ambiente.
                      </p>
                      <p className="p3 ft1">
                        Instabilidades com a rede elétrica também comprometem a
                        conexão com a Plataforma <nobr>Pipe-Go,</nobr> que é
                        WEB.
                      </p>
                      <p className="p12 ft1">
                        Assim recomendamos <nobr>no-break</nobr> nas máquinas
                        que usam e nos modems de distribuição de internet.
                      </p>
                      <p className="p13 ft1">
                        Os arquivos enviados através da Plataforma{" "}
                        <nobr>Pipe-Go,</nobr> por parte do USUÁRIO, são links e
                        podem ser observados
                      </p>
                      <p className="p14 ft1">
                        normalmente, após clicados por parte do terceiro
                        envolvido no recebimento, exatamente como desejo do
                      </p>
                      <p className="p15 ft1">USUÁRIO.</p>
                      <p className="p16 ft4">
                        Após a efetivação do cadastro a <nobr>Pipe-Go</nobr> tem
                        até 72 (setenta e duas) horas para ativar o serviço. A
                        notificação de ativação é feita por <nobr>e-mail</nobr>{" "}
                        e os manuais de atendimento e configurações estão
                        disponíveis dentro da plataforma e no site da{" "}
                        <nobr>
                          <span className="ft5">Pipe-Go</span>.
                        </nobr>
                      </p>
                      <p className="p17 ft4">
                        Para o perfeito funcionamento da Plataforma{" "}
                        <nobr>Pipe-Go</nobr> o USUÁRIO precisa dispor de
                        aparelho celular com, no mínimo, 3GB de memória RAM,
                        32GB de memória de armazenamento (ROM) e sistema
                        operacional Android.
                      </p>
                      <p className="p18 ft1">
                        O USUÁRIO precisa disponibilizar para acesso os
                        navegadores GOOGLE CHROME, MOZILLA FIREFOX ou
                      </p>
                      <p className="p19 ft1">
                        SAFARI, ambos em suas últimas versões.
                      </p>
                      <p className="p20 ft1">
                        A fim de manter a Plataforma <nobr>Pipe-Go</nobr> sempre
                        online e estável, é de responsabilidade do USUÁRIO, caso
                        esteja
                      </p>
                      <p className="p21 ft1">na posse do aparelho celular:</p>
                      <p className="p22 ft1">
                        Manter a bateria do equipamento SEMPRE em 100%, sendo
                        recomendado que o aparelho SEMPRE esteja
                      </p>
                      <p className="p23 ft1">
                        conectado no carregador e na fonte de energia;
                      </p>
                      <p className="p24 ft1">
                        Manter o equipamento SEMPRE conectado à rede segura de
                        internet, bem como com o aplicativo WhatsApp
                      </p>
                      <p className="p25 ft1">SEMPRE aberto na tela;</p>
                      <p className="p26 ft1">
                        Garantir que o programa <nobr>Pipe-Go</nobr> Desktop
                        esteja rodando, em um único computador;
                      </p>
                      <p className="p27 ft1">
                        Não é permitido, a fim de manter a Plataforma{" "}
                        <nobr>Pipe-Go</nobr> funcionando, logar em uma outra
                        plataforma
                      </p>
                      <p className="p28 ft1">
                        concorrente ou similar simultaneamente (o próprio
                        WhatsApp Web irá derrubar a plataforma). As desconexões
                      </p>
                      <p className="p29 ft4">
                        feitas intencionalmente pelo USUÁRIO, inclusive para se
                        conectar ao WhatsApp Web, poderão deixar a plataforma
                        fora do ar por até 48h, as quais serão arcadas
                        inteiramente pelo cliente em virtude da não observância
                        das recomendações aqui postas.
                      </p>
                    </div>
                    <div id="page_2">
                      <p className="p30 ft6">
                        Durante o período de uso da plataforma, é extremamente
                        proibido o uso do celular por parte do USUÁRIO, caso o
                        tenha em posse, para envios de mensagens via aplicativo
                        WhatsApp. Esse fim, que é a razão de contratação da
                        Plataforma <nobr>Pipe-Go,</nobr> irá atrapalhar toda
                        lógica de distribuição e gerará transtornos, por vezes
                        irreparáveis.
                      </p>
                      <p className="p31 ft1">
                        A compreensão dos itens a serem usados é dever do
                        USUÁRIO. Assim, a má compreensão destes termos não
                      </p>
                      <p className="p32 ft1">
                        pode ser alegada como motivo de descumprimento
                        contratual com a{" "}
                        <nobr>
                          <span className="ft0">Pipe-Go</span>.
                        </nobr>
                      </p>
                      <p className="p33 ft1">
                        As mensagens recebidas via WhatsApp antes da contratação
                        e ativação da plataforma{" "}
                        <nobr>
                          <span className="ft0">Pipe-Go</span>,
                        </nobr>{" "}
                        bem como os
                      </p>
                      <p className="p34 ft1">
                        contatos salvos no aparelho celular que servirá como
                        base para o serviço, não são
                      </p>
                      <p className="p35 ft1">
                        automaticamente migrados para a plataforma. Com relação
                        às mensagens recebidas anteriormente, há a
                      </p>
                      <p className="p36 ft1">
                        possibilidade de sua recuperação parcial pela
                        plataforma.
                      </p>
                      <p className="p37 ft1">
                        Em virtude do modelo de negócio contratado e do serviço
                        adquirido, a{" "}
                        <nobr>
                          <span className="ft0">Pipe-Go</span>
                        </nobr>
                        <span className="ft0"> </span>apenas oferece assistência
                        e
                      </p>
                      <p className="p38 ft1">
                        suporte remotos, os quais devem ser tratados via{" "}
                        <nobr>e-mail</nobr>{" "}
                        <nobr>
                          (<span className="ft7">contato@it-br.com</span>),
                        </nobr>{" "}
                        ou via WhatsApp no link
                      </p>
                      <p className="p39 ft8">
                        <a href="http://wa.me/558197991202">
                          http://wa.me/55 81{" "}
                        </a>
                        <nobr>
                          <a href="http://wa.me/558197991202">9799-1202</a>
                        </nobr>
                      </p>
                      <p className="p13 ft1">
                        O cliente deve assistir aos vídeos de uso da Plataforma{" "}
                        <nobr>
                          <span className="ft0">Pipe-Go</span>
                        </nobr>
                        <span className="ft0"> </span>antes da efetiva
                        utilização do sistema, a fim de
                      </p>
                      <p className="p40 ft1">
                        que o mesmo possa ser operado com a máxima eficiência.
                      </p>
                      <p className="p41 ft4">
                        Caso o USUÁRIO identifique uma falha ou um problema com
                        a utilização da plataforma, o grupo{" "}
                        <nobr>
                          <span className="ft5">Pipe-Go</span>
                        </nobr>
                        <span className="ft5"> </span>terá até 24 horas para
                        encontrar uma solução e <nobr>informá-la,</nobr> o que,
                        não necessariamente, será entregar a própria solução em
                        si, a julgar pela dificuldade técnica da situação.
                      </p>
                      <p className="p18 ft1">
                        O horário de atendimento e suporte do grupo é de{" "}
                        <nobr>segunda-feira</nobr> à <nobr>sexta-feira,</nobr>{" "}
                        entre 08:00 e 18:00 e sábados
                      </p>
                      <p className="p42 ft1">das 8:00 as 13:00.</p>
                      <p className="p43 ft4">
                        Todas as políticas de funcionamento do WhatsApp precisam
                        ser respeitadas pelo USUÁRIO, uma vez que a Plataforma{" "}
                        <nobr>Pipe-Go</nobr> não exerce nenhum algoritmo de
                        invasibilidade. A plataforma funciona como provedor de
                        soluções receptivas do WhatsApp.
                      </p>
                      <p className="p44 ft6">
                        As atualizações do WhatsApp não são de responsabilidade
                        e domínio da <nobr>Pipe-Go</nobr> e, por questões
                        lógicas e sistêmicas, podem gerar instabilidades e até
                        desconexões. Dessa forma, <nobr>informa-se</nobr> que
                        esse tipo de problema
                      </p>
                      <p className="p45 ft4">
                        <span className="ft1">é</span>
                        <span className="ft9">
                          de gênero não controlável e sem a menor
                          responsabilidade por parte do grupo{" "}
                        </span>
                        <nobr>Pipe-Go.</nobr> Ainda se entende que, em caso de
                        finalização das atividades do WhatsApp o sistema
                        contratado para esse fim ficará indisponível e ambas
                        partes envolvidas não terão mais responsabilidades
                        envolvidas.
                      </p>
                      <p className="p46 ft1">
                        Não é permitido, a fim de evitar transtornos como
                        bloqueios, que sejam usados números{" "}
                        <nobr>recém-criados</nobr> na
                      </p>
                      <p className="p47 ft1">
                        plataforma WhatsApp, nem aparelhos de celular que já
                        tenham passado por algum tipo de bloqueio.
                      </p>
                      <p className="p48 ft1">
                        <span className="ft1">É</span>
                        <span className="ft10">
                          de suma importância é vital para o funcionamento que
                          se o USUÁRIO tiver, outrora, usado algum tipo de
                        </span>
                      </p>
                      <p className="p49 ft1">plataforma similar ao</p>
                      <p className="p50 ft1">
                        princípio de funcionamento da <nobr>Pipe-Go</nobr> que,
                        durante a negociação, isso seja informado.
                      </p>
                      <p className="p20 ft1">
                        Por fim, imperioso ressaltar, que a Plataforma{" "}
                        <nobr>Pipe-Go</nobr> não se presta a substituir o
                        aplicativo WhatsApp, ou sua
                      </p>
                      <p className="p51 ft4">
                        extensão WhatsApp Web, mas sim a melhorar a experiência
                        do usuário e a agregar funcionalidades, principalmente
                        no que diz respeito a atendimento massivo receptivo,
                        distribuição e direcionamento deste atendimento com
                        triagens automáticas, e metrificação da performance do
                        atendimento e dos agentes responsáveis.
                      </p>
                      <p className="p52 ft3">
                        <span className="ft1">3.</span>
                        <span className="ft2">OBRIGAÇÕES DO CONTRATANTE</span>
                      </p>
                      <p className="p53 ft1">
                        Pagar pontualmente o preço devido pela utilização dos
                        serviços ora contratados, incluindo os eventuais custos
                      </p>
                      <p className="p54 ft1">por utilização excedente.</p>
                    </div>
                    <div id="page_3">
                      <p className="p55 ft4">
                        Informar à <nobr>Pipe-Go</nobr> qualquer alteração dos
                        dados cadastrais, procedendo a devida alteração,
                        incluindo troca de <nobr>e-mail,</nobr> sob pena de em
                        não o fazendo <nobr>considerarem-se</nobr> válidos todos
                        os avisos e notificações enviados para os endereços
                        inicialmente informados e constantes do cadastro.
                      </p>
                      <p className="p56 ft1">
                        Responder com exclusividade pela suficiência das
                        interações contratadas, bem como pelo bom funcionamento
                      </p>
                      <p className="p57 ft1">
                        de seu aparelho celular (estar livre de vírus, estar com
                        programas de proteção devidamente instalados e
                      </p>
                      <p className="p58 ft1">
                        atualizados, estar devidamente carregado – bateria
                        100%), e das suas redes sociais <nobr>(mantê-las</nobr>{" "}
                        sempre ativas,
                      </p>
                      <p className="p59 ft1">principalmente).</p>
                      <p className="p60 ft1">
                        Configurar o programa/plataforma e suas funcionalidades
                        da forma como melhor atenda às suas necessidades,
                      </p>
                      <p className="p61 ft1">
                        optando por aquilo que corresponda a seus anseios;
                      </p>
                      <p className="p13 ft1">
                        Atender aos requisitos mínimos exigidos para o
                        funcionamento do sistema a nível de equipamentos
                        necessários,
                      </p>
                      <p className="p62 ft1">
                        de banda de internet, de sistema operacional, de
                        capacidade de memória (RAM e HD), entre outros
                      </p>
                      <p className="p63 ft1">mencionados nestes Termos.</p>
                      <p className="p64 ft1">
                        Assistir ao(s) vídeo(s) explicativos de utilização do{" "}
                        <nobr>Pipe-Go.</nobr>
                      </p>
                      <p className="p65 ft1">
                        Respeitar e cumprir fielmente os Termos de Uso e a
                        Política de Privacidade da Plataforma{" "}
                        <nobr>Pipe-Go.</nobr>
                      </p>
                      <p className="p27 ft1">
                        Sem prejuízo das obrigações acima elencadas, comuns a
                        todos os tipos de contratação, responder pelas
                      </p>
                      <p className="p66 ft1">
                        demais obrigações específicas constantes destes Termos.
                      </p>
                      <p className="p52 ft3">
                        <span className="ft1">4.</span>
                        <span className="ft2">OBRIGAÇÕES DA CONTRATADA</span>
                      </p>
                      <p className="p67 ft4">
                        Prestar o serviço objeto do presente, na forma e
                        condições previstas no preâmbulo, zelando pela
                        eficiência e regular funcionamento do software
                        (Plataforma <nobr>Pipe-Go)</nobr> adotando junto a cada
                        um dos USUÁRIOS todas as medidas necessárias para evitar
                        prejuízos ao funcionamento do mesmo.
                      </p>
                      <p className="p68 ft1">
                        Manter o sigilo sobre o conteúdo das informações
                        armazenadas.
                      </p>
                      <p className="p69 ft6">
                        <span className="ft1">É</span>
                        <span className="ft11">
                          de extrema importância destacar que o{" "}
                        </span>
                        <nobr>Pipe-Go</nobr> <nobr>compromete-se</nobr> a manter
                        na plataforma as informações trocadas (mensagens e
                        mídias) por até seis meses. Desta forma destacamos a
                        necessidade do cliente realizar backup (salvar as
                        informações) no seu próprio aparelho celular, na sua
                        conta do WhatsApp, para não perder as
                      </p>
                      <p className="p70 ft1">informações.</p>
                      <p className="p71 ft4">
                        Instalar nos servidores utilizados para o armazenamento
                        de informações as atualizações dos programas de proteção
                        contra a invasão por terceiros (hackers), não sendo, no
                        entanto, responsável em caso de ataques inevitáveis pela
                        superação da tecnologia disponível no mercado.
                      </p>
                      <p className="p72 ft4">
                        Garantir uma taxa de entrega de 50% (cinquenta por
                        cento) das mensagens enviadas, sendo que a mensagem
                        inicialmente perdida, mas depois recuperada, é entendida
                        como efetivamente entregue. Cabe aqui esclarecer que
                        como o domínio das mensagens não é da{" "}
                        <nobr>Pipe-Go,</nobr> sendo assim não nos
                        responsabilizamos por qualquer prejuízo causado ao
                        CONTRATANTE, por perda ou não entrega de mensagem. Como
                        já mencionado, o detentor das mensagens é o WhatsApp, e
                        caso haja importância no que for transacionado pela
                        plataforma, que seja sempre verificado no WhatsApp.
                      </p>
                      <p className="p73 ft3">
                        <span className="ft1">5.</span>
                        <span className="ft2">
                          DA UTILIZAÇÃO DA SENHA DE ADMINISTRAÇÃO DE ACESSO AO
                          SOFTWARE (PROGRAMA) E DA DECLARAÇÃO DE
                          RESPONSABILIDADE
                        </span>
                      </p>
                      <p className="p37 ft1">
                        A senha que possibilita o acesso para a Plataforma{" "}
                        <nobr>
                          <span className="ft0">Pipe-Go</span>
                        </nobr>
                        <span className="ft0"> </span>será escolhida e
                        cadastrada pelo USUÁRIO por
                      </p>
                      <p className="p74 ft1">
                        ocasião da contratação, sem qualquer participação da{" "}
                        <nobr>Pipe-Go,</nobr> sendo de exclusiva
                        responsabilidade do
                      </p>
                      <p className="p75 ft1">
                        USUÁRIO a definição da política de privacidade na
                        utilização da mesma.
                      </p>
                      <p className="p22 ft1">
                        Caso após a contratação o USUÁRIO, solicitar da{" "}
                        <nobr>Pipe-Go</nobr> a informação da senha de
                        administração por ele
                      </p>
                      <p className="p76 ft1">
                        anteriormente cadastrada, deverá o USUÁRIO efetuar tal
                        pedido via <nobr>e-mail.</nobr> Neste caso, a senha de
                      </p>
                      <p className="p77 ft1">
                        administração será enviada ao <nobr>e-mail</nobr>{" "}
                        principal constante do cadastro do USUÁRIO.
                      </p>
                    </div>
                    <div id="page_4">
                      <p className="p78 ft1">
                        Apenas o endereço eletrônico de <nobr>e-mail</nobr>{" "}
                        cadastrado pelo USUÁRIO receberá a senha de
                        administração e suas
                      </p>
                      <p className="p79 ft1">
                        eventuais substituições e alterações.
                      </p>
                      <p className="p80 ft4">
                        A posse da senha dará a quem a detiver não só amplos
                        poderes de acessar e configurar a Plataforma{" "}
                        <nobr>Pipe-Go,</nobr> mas também amplos poderes de
                        alterar eletronicamente a própria senha e, também, o
                        cadastro do USUÁRIO em qualquer de seus dados.
                      </p>
                      <p className="p81 ft4">
                        A responsabilidade por permitir o acesso à senha a quem
                        quer que seja, corre por conta única e exclusiva do
                        USUÁRIO uma vez que a <nobr>Pipe-Go</nobr> não possui
                        qualquer ingerência sobre a disponibilização da
                        utilização da senha inicialmente fornecida.
                      </p>
                      <p className="p82 ft12">
                        O USUÁRIO se declara plenamente responsável,
                        judicialmente e extrajudicialmente, tanto perante leis
                        internas, quanto externas, pelo conteúdo de todas as
                        interações, informações, dados e arquivos que forem
                        armazenados nos servidores da <nobr>Pipe-Go,</nobr>{" "}
                        principalmente no que se refere a violação de direitos
                        autorais de terceiros.
                      </p>
                      <p className="p83 ft14">
                        <nobr>
                          <span className="ft13">Pipe-Go</span>,
                        </nobr>{" "}
                        devido ao sigilo e confidencialidade que promete manter
                        dos dados do USUÁRIO, não exerce qualquer controle sobre
                        o conteúdo das interações, informações, dados e arquivos
                        armazenados nos seus servidores em virtude das
                        atividades do USUÁRIO.
                      </p>
                      <p className="p84 ft4">
                        A{" "}
                        <nobr>
                          <span className="ft5">Pipe-Go</span>
                        </nobr>
                        <span className="ft5"> </span>
                        <nobr>reserva-se</nobr> ao direito, independentemente de
                        outras situações descritas nestes Termos, e quando
                        devidamente notificada de violação a direitos autorais,
                        de desativar ou excluir informações, dados ou arquivos
                        com conteúdo supostamente infrator, bem como de
                        rescindir estes Termos com USUÁRIOS que sejam
                        reincidentes nas acusações de violação a direitos
                        autorais.
                      </p>
                      <p className="p85 ft4">
                        O USUÁRIO é única e exclusivamente responsável pelos
                        riscos perante a forma como usa, aborda ou é abordado
                        pelos clientes ou terceiros. Se isso culminar em danos
                        morais, danos com as próprias políticas do sistema em
                        uso, a{" "}
                        <nobr>
                          <span className="ft5">Pipe-Go</span>
                        </nobr>
                        <span className="ft5"> </span>se resguarda de não ter
                        essa obrigação legal, uma vez que apenas oferece meios
                        para contatos existirem, sem influenciar em como, o que
                        e por que são realizados. Vela lembrar que o uso
                        deturpado e demasiado dos sistemas podem causar o
                        banimento da conta, com o qual o usuário precisa se
                        certificar se está adequadamente realizando as
                        abordagens.
                      </p>
                      <p className="p86 ft3">
                        <span className="ft1">6.</span>
                        <span className="ft2">SIGILO E CONFIDENCIALIDADE</span>
                      </p>
                      <p className="p87 ft1">
                        As informações que forem armazenadas pela{" "}
                        <nobr>
                          <span className="ft0">Pipe-Go</span>
                        </nobr>
                        <span className="ft0"> </span>em virtude das interações
                        do USUÁRIO estão cobertas
                      </p>
                      <p className="p88 ft4">
                        pela cláusula de sigilo e confidencialidade, não podendo
                        a{" "}
                        <nobr>
                          <span className="ft5">Pipe-Go</span>,
                        </nobr>{" "}
                        ressalvados os casos de ordem e/ou pedido e/ou
                        determinação judicial de qualquer espécie e/ou de ordem
                        e/ou pedido e/ou determinação de autoridades públicas a
                        fim de esclarecer fatos e/ou circunstâncias e/ou
                        instruir investigação, inquérito e/ou denúncia em curso,
                        revelar as informações a terceiros.
                      </p>
                      <p className="p7 ft1">
                        A <nobr>Pipe-Go</nobr> não será responsável por
                        violações dos dados e informações acima referidas
                        resultantes de atos de
                      </p>
                      <p className="p89 ft1">
                        funcionários, prepostos ou de pessoas autorizadas pelo
                        USUÁRIO e nem daquelas resultantes da ação
                      </p>
                      <p className="p90 ft1">
                        criminosa ou irregular de terceiros (hackers) fora dos
                        limites da previsibilidade técnica do momento em que a
                      </p>
                      <p className="p91 ft1">mesma vier a ocorrer.</p>
                      <p className="p92 ft3">
                        <span className="ft1">7.</span>
                        <span className="ft2">DO TESTE GRATUITO</span>
                      </p>
                      <p className="p93 ft1">
                        O USUÁRIO poderá receber um período grátis de 07 (SETE)
                        dias de testes (ou o tempo acordado), a partir da
                      </p>
                      <p className="p94 ft1">data em realizar o cadastro.</p>
                      <p className="p13 ft1">
                        A{" "}
                        <nobr>
                          <span className="ft0">Pipe-Go</span>
                        </nobr>
                        <span className="ft0"> </span>se reserva o direito de
                        rescindir o período de avaliação a qualquer momento a
                        seu exclusivo critério,
                      </p>
                      <p className="p95 ft1">
                        principalmente caso observado quaisquer abusos por parte
                        do USUÁRIO.
                      </p>
                      <p className="p96 ft4">
                        Durante o período de teste gratuito, o USUÁRIO não
                        estará sujeito a nenhuma taxa de assinatura mensal ou
                        qualquer outra taxa. Entretanto o USUÁRIO deve realizar
                        o seu cadastro de forma completa, fornecendo informações
                        válidas.
                      </p>
                    </div>
                    <div id="page_5">
                      <p className="p97 ft4">
                        Caso o USUÁRIO não cancele o serviço dentro do período
                        de avaliação, será entendido que o USUÁRIO deseja manter
                        a utilização da Plataforma{" "}
                        <nobr>
                          <span className="ft5">Pipe-Go</span>,
                        </nobr>{" "}
                        sendo que a partir de então deverá realizar o pagamento
                        através do cadastramento da assinatura do serviço por
                        cartão de crédito ou conforme combinado por escrito,
                        caso contrário haverá bloqueio ao acesso da sua conta.
                      </p>
                      <p className="p98 ft3">
                        <span className="ft1">8.</span>
                        <span className="ft2">ASSINATURA ELETRÔNICA</span>
                      </p>
                      <p className="p99 ft4">
                        As partes envolvidas nos presentes Termos afirmam e
                        declaram que os presentes Termos poderão ser aceitos e
                        assinados por meio eletrônico, sendo consideradas
                        válidas os referidos aceites e assinaturas. As partes
                        também declaram reconhecerem como válidas os aceites e
                        as assinaturas eletrônicas feitas através da Plataforma{" "}
                        <nobr>
                          <span className="ft5">Pipe-Go</span>,
                        </nobr>{" "}
                        nos termos do art. 10, Parágrafo 2º, da{" "}
                        <nobr>MP2200-2/2001.</nobr>
                      </p>
                      <p className="p100 ft1">
                        <nobr>
                          <span className="ft0">Pipe-Go</span>
                        </nobr>
                        <span className="ft0"> </span>é um produto da empresa
                        D&amp;M SUPORTE E SOLUÇÕES EM TI LTDA, com sede na Rua
                        Cuiabá,
                      </p>
                      <p className="p101 ft1">
                        2090 Candeias Jaboatão dos Guararapes PE | CEP{" "}
                        <nobr>54440-130,</nobr> inscrita no CNPJ/MF sob o número
                      </p>
                      <p className="p102 ft1">
                        <nobr>21.931.629/0001-25</nobr>
                      </p>
                      <p className="p103 ft1">
                        <span className="ft1">9.</span>
                        <span className="ft15">FORMA DE PAGAMENTO</span>
                      </p>
                      <p className="p104 ft4">
                        Para facilitar a contratação e a manutenção do serviço,
                        o <nobr>Pipe-Go</nobr> utiliza o sistema de pagamento de
                        ASSINATURA, onde será enviado boletos via email{" "}
                        <nobr>[contato@it-br.com]</nobr> Não obstante, o usuário
                        poderá cancelar a assinatura a qualquer momento entrando
                        contato em contato via email:{" "}
                        <nobr>
                          <a href="mailto:contato@it-br.com">
                            <span className="ft16">contato@it-br.com</span>
                          </a>
                          <span className="ft17">,</span>
                        </nobr>
                        <span className="ft17"> </span>com o assunto
                        CANCELAMENTO: Após as assinaturas eletrônicas feitas e
                        aceite dos pagamentos no formato boleto de acordo com o
                        plano escolhido através da Plataforma{" "}
                        <nobr>Pipe-Go,</nobr> nos termos do art. 10, Parágrafo
                        2º, da <nobr>MP2200-2/2001.</nobr>
                      </p>
                      <p className="p105 ft1">
                        <span className="ft3">10.</span>
                        <span className="ft15">CANCELAMENTO DA ASSINATURA</span>
                      </p>
                      <p className="p106 ft1">
                        Após as assinaturas eletrônicas feitas e aceite dos
                        pagamentos no formato boleto de acordo com o plano
                      </p>
                      <p className="p107 ft1">
                        escolhido através da Plataforma <nobr>Pipe-Go,</nobr>{" "}
                        nos termos do art. 10, Parágrafo 2º, da{" "}
                        <nobr>MP2200-2/2001.</nobr>
                      </p>
                      <p className="p108 ft4">
                        Como consta no Art. 49. O consumidor pode desistir do
                        contrato, no prazo de 7 dias a contar de sua assinatura
                        ou do ato de recebimento do produto ou serviço, sempre
                        que a contratação de fornecimento de produtos e serviços
                        ocorrer fora do estabelecimento comercial, especialmente
                        por telefone ou a domicílio.
                      </p>
                      <p className="p109 ft4">
                        Parágrafo único. Se o consumidor exercitar o direito de
                        arrependimento previsto neste artigo, os valores
                        eventualmente pagos, a qualquer título, durante o prazo
                        de reflexão, serão devolvidos, de imediato,
                        monetariamente atualizados.
                      </p>
                      <p className="p110 ft4">
                        Deverá o USUÁRIO efetuar tal pedido via{" "}
                        <nobr>e-mail.</nobr> ( <nobr>contato@it-br.com),</nobr>{" "}
                        ou via WhatsApp no link{" "}
                        <a href="http://wa.me/558197991202">
                          <span className="ft16">http://wa.me/55 81 </span>
                        </a>
                        <nobr>
                          <a href="http://wa.me/558197991202">
                            <span className="ft16">9799-1202</span>
                          </a>
                        </nobr>{" "}
                        Não obstante, o usuário poderá cancelar a assinatura a
                        qualquer momento entrando contato em contato via email:{" "}
                        <nobr>
                          <span className="ft17">contato@it-br.com</span>,
                        </nobr>{" "}
                        com o assunto <span className="ft18">CANCELAMENTO</span>
                        .
                      </p>
                    </div>
                  </div>
                )}
                <div className="p-t-15">
                  <button
                    className="btn btn--radius-2 btn--green"
                    id="btn-send-form"
                    type="submit"
                    disabled={disable}
                  >
                    {btnMessage}
                  </button>
                </div>
              </form>
              <div className="footer">
                Desenvolvido por : IntelligenceIt
                <div className="social">
                  <a href="https://wa.me/558197991202">
                    <WhatsAppIcon />
                  </a>
                  <a href="https://www.instagram.com/intelligenceit/">
                    <InstagramIcon />
                  </a>
                  <a href="https://www.linkedin.com/company/intelligenceit/">
                    <LinkedInIcon />
                  </a>
                  <a href="https://youtu.be/vh4xoR2uwvc">
                    <YouTubeIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  );
}
