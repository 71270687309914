import styled from "styled-components";

export const Page = styled.div``;
export const Content = styled.div`
  .infoclient {
    margin-bottom: 20px;
    div {
      display: flex;
      align-items: center;
      h4 {
        margin: 2px;
      }
    }
  }
  .clientcnpjs {
    width: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    padding: 2px;
    height: 250px;
    overflow-y: scroll;
    ._210SC {
      width: 100%;
      margin: 10px 0px;
      border-bottom: 1px solid #e6e6e6;
      .eJ0yJ {
        position: relative;
        display: flex;
        flex-direction: row;
        padding-left: 14px;
        pointer-events: all;
        background-color: #fff;
        /* cursor: pointer; */
        ._325lp {
          display: flex;
          flex: none;
          align-items: center;
          margin-top: -1px;
          padding: 0 15px 0 13px;
          ._1BjNO {
            position: relative;
            overflow: hidden;
            background-color: #e6e6e6;
            border-radius: 50%;
            ._2goTk {
              position: relative;
              z-index: 100;
              display: block;
              width: 100%;
              height: 100%;
              overflow: hidden;
              transition: opacity 0.15s ease-out;
              border-radius: 50%;
              visibility: visible;
            }
            ._1V82l {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              svg {
                width: 60%;
                height: 60%;
              }
            }
          }
        }
        ._2kHpK {
          display: flex;
          flex-basis: auto;
          flex-direction: column;
          flex-grow: 1;
          justify-content: center;
          min-width: 0;
          padding-right: 15px;
          border-top: 1px solid #f2f2f2;
          ._3dtfX {
            display: flex;
            align-items: center;
            line-height: normal;
            text-align: left;
            ._3CneP {
              text-align: left;
              display: flex;
              flex-grow: 1;
              overflow: hidden;
              color: #000;
              font-weight: 400;
              font-size: 17px;
              line-height: 21px;
              line-height: normal;
              ._3ko75 {
                display: inline-block;
                position: relative;
                flex-grow: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                visibility: visible;
              }
            }
          }
          ._1582E {
            display: flex;
            align-items: center;
            min-height: 20px;
            color: var(--secondary);
            font-size: 13px;
            line-height: 20px;
            margin-top: 2px;
            ._3tBW6 {
              text-align: left;
              flex-grow: 1;
              overflow: hidden;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              white-space: nowrap;
              text-overflow: ellipsis;
              background-color: initial;
            }
            .m61XR {
              flex: none;
              max-width: 100%;
              overflow: hidden;
              color: rgba(0, 0, 0, 0.45);
              font-size: 12px;
              line-height: 20px;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: -1px;
              padding-right: 1px;
              margin-left: 6px;
              margin-top: 0;
            }
          }
        }
      }
    }
  }
`;
