import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import { Time } from "../style";
import { useDispatch } from "react-redux";
Date.prototype.toDateInputValue = function () {
  const local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};
export default function SyncConfirmation({ confirm, cancel }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState("");
  const [date, setDate] = React.useState(new Date().toDateInputValue());
  const handleDateChange = (date) => {
    setDate(date);
  };
  const verify = () => {
    if (!message) {
      return dispatch({
        type: "ADD_NOTIFY",
        notify: {
          type: "info",
          message: "Descrição obrigatória!",
          idclient: 0,
          read: false,
        },
      });
    }
    const now = new Date(`${new Date().toDateInputValue()} 00:00:00`).getTime();
    const newdate = new Date(`${date} 00:00:00`).getTime();
    if (newdate < now) {
      return dispatch({
        type: "ADD_NOTIFY",
        notify: {
          type: "info",
          message: "Data inválida.",
          idclient: 0,
          read: false,
        },
      });
    }
    confirm(message, date);
  };
  return (
    <div>
      <Dialog
        open
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("RET.sync.title")}</DialogTitle>
        <DialogContent>
          {t("RET.sync.description")}
          <br />
          <br />
          <TextField
            fullWidth
            id="outlined-multiline-static"
            label={"Descrição"}
            multiline
            variant="outlined"
            rows={4}
            value={message}
            onChange={(e) => {
              setMessage(e.currentTarget.value);
            }}
            required
          />
          <br />
          <br />
          Data de execução:
          <Time
            type="date"
            value={date}
            onChange={(e) => {
              handleDateChange(e.currentTarget.value);
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              cancel();
            }}
            color="primary"
          >
            <Button variant="contained" color="secondary">
              {t("RET.sync.bt2")}
            </Button>
          </Button>
          <Button onClick={verify} color="primary" autoFocus>
            <Button variant="contained" color="primary">
              {t("RET.sync.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
