import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { makeStyles } from "@material-ui/core/styles";

import api from "../../../services/axios";

import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      minWidth: "65ch",
      justifyContent: "center",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    background: "#34b7f1",
    color: "#f2f2f2",
    marginLeft: "5px",
    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
  colorPrimary: {
    backgroundColor: "rgb(0, 191, 165)",
  },
  barColorPrimary: {
    backgroundColor: "#B2DFDB",
  },
}));
export default function ImportsLogs({ data, cancel }) {
  const user = JSON.parse(localStorage.user);
  const [progress, setProgress] = React.useState(0);
  const [logs, setLogs] = React.useState([]);
  const [erros, setErros] = React.useState([]);
  const [success, setSuccess] = React.useState([]);
  const [expandErros, setExpandErros] = React.useState(false);
  const [expandSuccsess, setExpandSuccsess] = React.useState(false);
  const classes = useStyles();

  const handleClose = () => {
    cancel();
  };
  function updateLog() {
    setLogs([...data]);
  }
  function toggleExpandSuccsess() {
    setExpandSuccsess(!expandSuccsess);
  }
  function toggleExpandErros() {
    setExpandErros(!expandErros);
  }
  async function createClients() {
    const clients = [];
    for (const [, client] of data.entries()) {
      try {
        client.idclient = Math.random();
        const dataObj = {
          client: {
            name: client.name,
            number: client.jid,
            completed: 1,
          },
          company: user.company,
          establishments: [],
        };
        client.loading = true;
        clients.push(client);
        await api.post("clients", dataObj, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        });
        client.loading = false;
        updateLog();
      } catch (error) {
        client.loading = false;
        client.falied = true;
        updateLog();
      } finally {
        client.completed = true;
      }
    }
    setLogs([...clients]);
  }
  useEffect(() => {
    createClients();
  }, []);
  useEffect(() => {
    setProgress(logs.filter((client) => client.completed).length);
    setErros(logs.filter((client) => client.falied));
    setSuccess(logs.filter((client) => !client.falied));
  }, [logs]);
  return (
    <div>
      <Dialog
        open
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Importando contatos: {progress} de {data.length}...
        </DialogTitle>
        <DialogContent>
          {progress === data.length ? (
            <>
              <List className={classes.root}>
                <ListItem
                  role={undefined}
                  dense
                  button
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    toggleExpandSuccsess();
                  }}
                >
                  <ListItemIcon>
                    <CheckCircleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Importados com sucesso:  ${success.length} `}
                  />

                  {expandSuccsess ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={expandSuccsess} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {success.length &&
                      success.map((client) => (
                        <ListItem button className={classes.nested}>
                          <ListItemText
                            primary={`${client.name} - ${client.jid}`}
                          />
                        </ListItem>
                      ))}
                  </List>
                </Collapse>
                <ListItem
                  role={undefined}
                  dense
                  button
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    toggleExpandErros();
                  }}
                >
                  <ListItemIcon>
                    <ErrorIcon />
                  </ListItemIcon>
                  <ListItemText primary={`Com falha:  ${erros.length} `} />
                  {expandErros ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={expandErros} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {erros.length &&
                      erros.map((client) => (
                        <ListItem button className={classes.nested}>
                          <ListItemText
                            primary={`${client.name} - ${client.jid}`}
                          />
                        </ListItem>
                      ))}
                  </List>
                </Collapse>
              </List>
            </>
          ) : (
            <div
              style={{
                width: "200px",
                height: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose} autoFocus>
            <Button variant="contained" className={classes.button}>
              Fechar
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
