import React, { useEffect, useState } from "react";
import { Page } from "./style";
import DefaultMessage from "./defaultMessage";
import FileMessage from "./fileMessage";
import ImageMessage from "./imageMessage";
import InfoMessage from "./infoMessage";
import AudioMessage from "./audioMessage";
import VideoMessage from "./videoMessage";

export default function Message({ afterMessage, message, lastMessage }) {
  const [showtimeinfo, setShowtimeinfo] = useState(false);

  useEffect(() => {
    if (afterMessage) {
      const time = new Date(message.timestamp).toLocaleDateString().split("/");

      const oldtime = new Date(afterMessage.timestamp)
        .toLocaleDateString()
        .split("/");

      if (Number(time[0]) !== Number(oldtime[0])) {
        setShowtimeinfo(true);
      }
    }
    if (lastMessage) {
      setShowtimeinfo(true);
    }
  }, []);
  switch (message.type) {
    case "default":
      return (
        <div>
          {showtimeinfo && (
            <InfoMessage
              message={{
                content: new Date(message.timestamp).toLocaleDateString(),
              }}
            />
          )}
          <DefaultMessage message={message} />
        </div>
      );
    case "document":
      return (
        <>
          {showtimeinfo && (
            <InfoMessage
              message={{
                content: new Date(message.timestamp).toLocaleDateString(),
              }}
            />
          )}
          <FileMessage message={message} />
        </>
      );
    case "image":
      return (
        <>
          {showtimeinfo && (
            <InfoMessage
              message={{
                content: new Date(message.timestamp).toLocaleDateString(),
              }}
            />
          )}
          <ImageMessage message={message} />
        </>
      );
    case "info":
      return (
        <>
          {showtimeinfo && (
            <InfoMessage
              message={{
                content: new Date(message.timestamp).toLocaleDateString(),
              }}
            />
          )}
          <InfoMessage message={message} />
        </>
      );
    case "audio":
      return (
        <>
          {showtimeinfo && (
            <InfoMessage
              message={{
                content: new Date(message.timestamp).toLocaleDateString(),
              }}
            />
          )}
          <AudioMessage message={message} />
        </>
      );
    case "ptt":
      return (
        <>
          {showtimeinfo && (
            <InfoMessage
              message={{
                content: new Date(message.timestamp).toLocaleDateString(),
              }}
            />
          )}
          <AudioMessage message={message} />
        </>
      );
    case "video":
      return (
        <>
          {showtimeinfo && (
            <InfoMessage
              message={{
                content: new Date(message.timestamp).toLocaleDateString(),
              }}
            />
          )}
          <VideoMessage message={message} />
        </>
      );
    default:
      return <div />;
  }
}
