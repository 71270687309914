import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DeleteCnpj from "./Dialogs/DeleteCNPJ";
import DeleteUser from "./Dialogs/DeleteUser";
import ActiveUser from "./Dialogs/ActiveUser";
import EditUser from "./Dialogs/EditUser";
import api from "../../services/axios";
import NewUser from "./Dialogs/NewUser";
import Can from "../../components/Can";
import WalletsDetails from "./Dialogs/WalletsDetails";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openactive, setOpenactive] = React.useState(false);
  const [openuser, setOpenuser] = React.useState(false);
  const [opendeletecnpj, setOpendeletecnpj] = React.useState(false);
  const [openedituser, setOpenedituser] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [deleteuser, setDeleteclient] = React.useState("");
  const [deletecnpj, setDeletecnpj] = React.useState("");
  const [editclient, setEditclient] = React.useState("");
  const [openwallets, setOpenwallets] = React.useState(false);
  const [selecteduser, setSelecteduser] = React.useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const updateData = async () => {
    await api
      .get("users", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setUsers([...res.data]);
      })
      .catch((e) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: e.response.data.error,
            idclient: 0,
            read: false,
          },
        });
      });
  };
  const options = {
    filterType: false,
    print: false,
    fixedHeader: true,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    filter: false,
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: t("US.table.noMatch"),
        toolTip: "Sort",

        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        rowsPerPage: "Linhas por Página",
      },
    },
    expandableRows: true,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const user = users[rowMeta.dataIndex];
      return (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <MUIDataTable
              title=""
              data={
                user.users_has_sectors.length
                  ? user.users_has_sectors.map((sector, index) => {
                      if (sector.sub_sector) {
                        return [
                          sector.idsubsector,
                          ` ${t("US.tableexpand.info1")} : ${
                            sector.sub_sector.name
                          }`,
                        ];
                      }
                      return [
                        sector.idsector,
                        ` ${t("US.tableexpand.info2")} : ${sector.sector.name}`,
                      ];
                    })
                  : []
              }
              columns={columnsexpand}
              options={optionstwo}
            />
          </TableCell>
        </TableRow>
      );
    },
  };

  const optionstwo = {
    filterType: false,
    print: false,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    filter: false,
    selectableRows: "none",
    search: false,
    pagination: false,
    customToolbar: null,
    textLabels: {
      body: {
        noMatch: t("US.table.noMatch"),
      },
    },
  };
  const columns = [
    "ID",
    t("US.table.name"),
    "Email",
    "CPF",
    "Status",
    t("US.table.access"),
    t("US.table.edit"),
    t("US.table.options"),
    "",
  ];
  const columnsexpand = ["ID", t("US.tableexpand.name")];
  useEffect(() => {
    updateData();
  }, []);
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "auto",
      }}
    >
      <DeleteUser
        open={open}
        data={deleteuser}
        func={setOpen}
        update={updateData}
      />
      <ActiveUser
        open={openactive}
        data={deleteuser}
        func={setOpenactive}
        update={updateData}
      />
      {openuser && (
        <NewUser open={openuser} func={setOpenuser} update={updateData} />
      )}
      {openedituser && (
        <EditUser
          data={editclient}
          func={setOpenedituser}
          update={updateData}
        />
      )}

      <DeleteCnpj
        open={opendeletecnpj}
        establishments={deletecnpj}
        func={setOpendeletecnpj}
        update={updateData}
      />
      {openwallets && (
        <WalletsDetails
          wallets={selecteduser.users_has_wallets}
          func={setOpenwallets}
        />
      )}
      <MUIDataTable
        title={
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="users"
            action="create"
            yes={() => (
              <Button
                onClick={() => {
                  setOpenuser(true);
                }}
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<AddCircleIcon />}
              >
                {t("US.bt1")}
              </Button>
            )}
          />
        }
        data={users.map((user, index) => [
          user.idusers,
          user.username,
          user.email,
          user.cpf,
          user.status,
          user.role.name,
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="users"
            action="update"
            yes={() => (
              <EditIcon
                style={{ fill: "#34b7f1" }}
                onClick={() => {
                  setOpenedituser(true);
                  setEditclient(users[index]);
                }}
              />
            )}
          />,
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="users"
            action="delete"
            yes={() =>
              user.status === "ATIVO" ? (
                <RemoveCircleOutlineIcon
                  style={{ fill: "#34b7f1" }}
                  onClick={() => {
                    setOpen(true);
                    setDeleteclient(users[index]);
                  }}
                />
              ) : (
                <CheckIcon
                  style={{ fill: "#34b7f1" }}
                  onClick={() => {
                    setOpenactive(true);
                    setDeleteclient(users[index]);
                  }}
                />
              )
            }
          />,
          user.users_has_wallets.length ? (
            <Button
              onClick={(ev) => {
                ev.stopPropagation();
                setSelecteduser(users[index]);
                setOpenwallets(true);
              }}
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<AccountBoxIcon />}
            >
              {t("US.table.bt1")}
            </Button>
          ) : null,
          user.establishments,
        ])}
        columns={columns}
        options={options}
      />
    </div>
  );
};
