import React from "react";
import { useTranslation } from "react-i18next";
import { Page } from "./style";

export default function Initial() {
  const { t } = useTranslation();
  return (
    <Page>
      <div className="_3BqNZ">
        <div data-asset-intro-image="true" className="_2Uo0Z " />
        <div className="_2MnNk">
          <h1 className="iHhHL">PipeGo! CRM.</h1>
          <div className="_1ClcF">
            {t("FC.chats.pr.initial.description")}&nbsp;
            <a
              target="_blank"
              href="http://it-br.com/"
              rel="noopener noreferrer"
            >
              Intelligence
            </a>
            .
          </div>
          <div className="_1Y6o1">
            <div className="_3zFlu" />
            <div className="_3cK8a">
              <span data-icon="laptop" className="">
                <svg
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 21 18"
                  width="21"
                  height="18"
                >
                  <path
                    fill="#9DA3A5"
                    d="M10.426 14.235a.767.767 0 0 1-.765-.765c0-.421.344-.765.765-.765s.765.344.765.765-.344.765-.765.765zM4.309 3.529h12.235v8.412H4.309V3.529zm12.235 9.942c.841 0 1.522-.688 1.522-1.529l.008-8.412c0-.842-.689-1.53-1.53-1.53H4.309c-.841 0-1.53.688-1.53 1.529v8.412c0 .841.688 1.529 1.529 1.529H1.25c0 .842.688 1.53 1.529 1.53h15.294c.841 0 1.529-.688 1.529-1.529h-3.058z"
                  />
                </svg>
              </span>
              <div className="_1lrYJ">{t("FC.chats.pr.initial.info")}</div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
