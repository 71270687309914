import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import api from "../../../services/axios";
import { useTranslation } from "react-i18next";

export default function AlertDialog(props) {
  const { t } = useTranslation();
  const handleClose = () => {
    props.func(false);
  };

  const confirm = () => {
    api
      .put(
        "wallet",
        {
          idwallets: props.data.idwallets,
          delete: "*",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((res) => {
        props.update();
        handleClose();
      })
      .catch((err) => window.alert(err.response.data.error));
  };
  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${t("WT.dialogs.delete.title")} ${props.data?.name}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("WT.dialogs.delete.description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Button variant="contained" color="secondary">
              {t("WT.dialogs.delete.bt2")}
            </Button>
          </Button>
          <Button onClick={confirm} color="primary" autoFocus>
            <Button
              variant="contained"
              style={{ background: "#34b7f1", color: "white" }}
            >
              {t("WT.dialogs.delete.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
