import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import api from "../../../services/axios";

export default function AlertDialog(props) {
  const handleClose = () => {
    props.func(false);
  };

  const confirm = () => {
    api
      .post(
        "users/deleterole",
        {
          idroles: props.data.idroles,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((res) => {
        props.update();
        handleClose();
      })
      .catch((err) => window.alert(err.response.data.error));
  };
  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Deseja realmente EXCLUIR ${props.data?.name}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Só será possível excluir o perfil caso não tenha usuários vinculados
            a ele.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Button variant="contained" color="secondary">
              Cancelar
            </Button>
          </Button>
          <Button onClick={confirm} color="primary" autoFocus>
            <Button variant="contained" color="primary">
              Confirmar
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
