import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import api from "../../../../../services/axios";
import { useTranslation } from "react-i18next";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "60ch",
      justifyContent: "center",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    marginLeft: theme.spacing(4),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default function AlertDialog({ close, idclient }) {
  const { t } = useTranslation();
  const [tickets, setTickets] = React.useState([]);
  const [err, setErr] = React.useState(false);
  const classes = useStyles();
  const getTickets = async () => {
    await api
      .get("ticket/clienttickets", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: { idclient },
      })
      .then((res) => {
        setTickets([...res.data]);
      })
      .catch((e) => {
        setErr(true);
      });
  };
  const toggleExpand = (index) => {
    const array = tickets;
    array[index].show = !array[index].show;
    setTickets([...array]);
  };

  React.useEffect(() => {
    getTickets();
  }, []);
  return (
    <div>
      <Dialog
        open
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("HOME.pr.active.dialogs.tickets.titledialog")}
        </DialogTitle>
        <DialogContent>
          {tickets.length ? (
            <List
              className={classes.root}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {tickets.map((ticket, index) => {
                return (
                  <>
                    <ListItem
                      key={index}
                      button
                      onClick={() => {
                        toggleExpand(index);
                      }}
                    >
                      <ListItemIcon>
                        <ConfirmationNumberIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${ticket.idticket} - ${
                          ticket.status
                        } - ${new Date(ticket.timestamp).toLocaleString()}`}
                      />
                      {ticket.show ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={ticket.show} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {ticket.ticket_timelines.length &&
                          ticket.ticket_timelines.map((timeline) => {
                            return (
                              <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                  <QueryBuilderIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={`${timeline.status} - ${new Date(
                                    timeline.timestamp
                                  ).toLocaleString()}`}
                                />
                              </ListItem>
                            );
                          })}
                      </List>
                    </Collapse>
                  </>
                );
              })}
            </List>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {err ? (
                <DialogContentText>Nenhum ticket encontrado.</DialogContentText>
              ) : (
                <CircularProgress />
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              close();
            }}
            autoFocus
          >
            <Button variant="contained" className={classes.button}>
              {t("HOME.pr.active.dialogs.tickets.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
