import React from "react";
import { Page } from "./style";
import Chats from "./Chats/index";
//import Header from "./Header/index";
import Search from "./Search/index";

export default function Initial({ setRender, history }) {
  return (
    <Page>
      {/* <Header setRender={setRender} history={history} /> */}
      {/* <Search /> */}
      <Chats setRender={setRender} />
    </Page>
  );
}
