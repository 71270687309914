import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import Divider from "@material-ui/core/Divider";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { Button, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import * as dateFns from "date-fns";
import { TicketsSectorAttendant } from "./style";
import api from "../../../services/axios";
import SelectPeriod from "../Dialogs/SelectPeriod";
import socket from "../../../services/socket";
import UsersSelect from "../Dialogs/SelectAttendent";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));

export default function () {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.user);
  const [selected, setSelected] = React.useState(0);
  const [showloading, setShowloading] = React.useState(true);
  const [showUsers, setShowUsers] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [usersSelected, setUsersSelected] = React.useState([]);
  const [data, setData] = React.useState({});
  const [showShowSelectPeriod, setShowShowSelectPeriod] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  function getColorsByStatus(label) {
    switch (label) {
      case "ACEITO":
        return {
          backgroundColor: "rgba(46, 216, 182, 0.2)",
          borderColor: "rgba(46, 216, 182, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(46, 216, 182, 0.4)",
          hoverBorderColor: "rgba(46, 216, 182, 1)",
        };
      case "FECHADO":
        return {
          backgroundColor: "rgba(64, 153, 255, 0.2)",
          borderColor: "rgba(64, 153, 255, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(64, 153, 255, 0.4)",
          hoverBorderColor: "rgba(64, 153, 255, 1)",
        };
      case "PENDENTE":
        return {
          backgroundColor: "rgba(255, 182, 77, 0.2)",
          borderColor: "rgba(255, 182, 77, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255, 182, 77, 0.4)",
          hoverBorderColor: "rgba(255, 182, 77, 1)",
        };
      case "CANCELADO":
        return {
          backgroundColor: "rgba(255, 83, 112, 0.2)",
          borderColor: "rgba(255, 83, 112, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255, 83, 112, 0.4)",
          hoverBorderColor: "rgba(255, 83, 112, 1)",
        };
      default:
        return {
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
        };
    }
  }
  function ticketsOrganize(arraytickets) {
    const filter = [];
    // arraytickets = arraytickets.filter((x) => usersSelected.includes(x.iduser));
    arraytickets = arraytickets.filter((x) => x.status !== "CANCELADO");

    arraytickets.map((ticket) => {
      const index = filter.findIndex((x) => ticket.status === x.label);
      if (index === -1) {
        filter.push({
          label: ticket.status,
          data: [ticket],
        });
      } else {
        filter[index].data.push(ticket);
      }
    });
    const dataSet = {
      labels: users
        .filter((z) => usersSelected.includes(z.idusers))
        .map(
          (z) =>
            `${z.username.split(" ")[0]} ${
              z.username.split(" ")[1]
                ? z.username.split(" ")[1][0].toUpperCase()
                : ""
            }`
        ),
      datasets: [],
    };
    filter.map((x) => {
      const colors = getColorsByStatus(x.label);
      const ticketinfo = {
        label: x.label,
        backgroundColor: colors.backgroundColor,
        borderColor: colors.borderColor,
        borderWidth: 1,
        hoverBackgroundColor: colors.hoverBackgroundColor,
        hoverBorderColor: colors.hoverBorderColor,
        data: Array.from("0".repeat(usersSelected.length)),
      };
      x.data.map((z) => {
        const index = users.findIndex((y) => y.idusers === z.iduser);
        ticketinfo.data[index] = Number(ticketinfo.data[index]) + 1;
      });
      dataSet.datasets.push(ticketinfo);
    });
    setData(dataSet);
  }
  const getTickets = async (date) => {
    setShowloading(true);
    const datatime = {
      initial: new Date(`${date.initial} 00:00:00`),
      final: new Date(`${date.final} 23:59:59`),
    };

    await api
      .get("dashboard/tickets", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: datatime,
      })
      .then((res) => {
        ticketsOrganize(res.data, datatime);
      })
      .catch((e) => {
        console.log(e);
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: e.response.data.error,
            idclient: 0,
            read: false,
          },
        });
      })
      .finally(() => {
        setShowloading(false);
      });
  };
  const getUsers = async () => {
    setShowloading(true);
    await api
      .get("users", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setUsers([...res.data]);
        setUsersSelected(res.data.map((x) => x.idusers));
      })
      .catch((e) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: e.response.data.error,
            idclient: 0,
            read: false,
          },
        });
      })
      .finally(() => {
        setShowloading(false);
      });
  };
  function dateFilter(date) {
    getTickets(date);
  }
  function handleToggleOpenUsers() {
    setShowUsers(!showUsers);
  }
  function handleSelection() {
    const now = new Date();
    switch (selected) {
      case 0: {
        const firstdayweek = dateFns.startOfWeek(now, {
          weekStartsOn: 0,
        });
        getTickets({
          initial: firstdayweek.toDateInputValue(),
          final: now.toDateInputValue(),
        });
        break;
      }
      case 1:
        getTickets({
          initial: dateFns
            .startOfMonth(now, {
              weekStartsOn: 0,
            })
            .toDateInputValue(),
          final: now.toDateInputValue(),
        });
        break;
      case 2:
        getTickets({
          initial: dateFns
            .startOfYear(now, {
              weekStartsOn: 0,
            })
            .toDateInputValue(),
          final: now.toDateInputValue(),
        });
        break;
      default:
        break;
    }
  }
  React.useEffect(() => {
    handleSelection();
  }, [selected, usersSelected]);

  useEffect(() => {
    getUsers();
    socket.on(`${user.company.prefix}_newticket`, () => {
      handleSelection();
    });
    // socket.on(`${user.company.prefix}_updateticket`, () => {
    //   handleSelection();
    // });
  }, []);
  return (
    <>
      {showUsers && (
        <UsersSelect
          close={handleToggleOpenUsers}
          data={users}
          setData={setUsersSelected}
          usersSelected={usersSelected}
        />
      )}
      {showShowSelectPeriod && (
        <SelectPeriod
          close={() => {
            setShowShowSelectPeriod(false);
          }}
          func={dateFilter}
        />
      )}
      <TicketsSectorAttendant>
        <div className="titleperiod">
          {" "}
          <h3>Filtro de Tickets por Atendente</h3>
          <div>
            <Button
              onClick={handleToggleOpenUsers}
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
            >
              Selecionar atendentes
            </Button>
            {/* <span
              style={{ textDecoration: selected === 2 ? "none" : "underline" }}
              onClick={() => {
                setSelected(2);
              }}
            >
              {t("DB.charts.period.year")}
            </span> */}
            <span
              style={{ textDecoration: selected === 1 ? "none" : "underline" }}
              onClick={() => {
                setSelected(1);
              }}
            >
              {t("DB.charts.period.month")}
            </span>
            <span
              style={{ textDecoration: selected === 0 ? "none" : "underline" }}
              onClick={() => {
                setSelected(0);
              }}
            >
              {t("DB.charts.period.week")}
            </span>
            <DateRangeIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelected(3);
                setShowShowSelectPeriod(true);
              }}
            />
          </div>
        </div>
        <Divider />
        <div className="chart">
          {showloading && (
            <div className="mask">
              <CircularProgress />
            </div>
          )}
          <Bar data={data} options={{ maintainAspectRatio: false }} />
        </div>
      </TicketsSectorAttendant>
    </>
  );
}
