import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
export default function CancelSyncConfirmation({ confirm, cancel }) {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog
        open
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Cancelar sicronização"}
        </DialogTitle>
        <DialogContent>
          Tem certeza que deseja cancelar esta sincronização?
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              cancel();
            }}
            color="primary"
          >
            <Button variant="contained" color="secondary">
              {t("CT.sync.bt2")}
            </Button>
          </Button>
          <Button onClick={confirm} color="primary" autoFocus>
            <Button variant="contained" color="primary">
              {t("CT.sync.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
