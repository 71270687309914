import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomizedSnackbars() {
  const notifications = useSelector((state) => state.notify);
  const dispatch = useDispatch();

  const handleClose = (index) => {
    const array = notifications;
    array[index].read = true;
    dispatch({
      type: "REPLACE_ALL_NOTIFICATIONS",
      notifications: array,
    });
  };

  return (
    <>
      {!!notifications.length &&
        notifications.map((notify, index) => (
          <>
            {" "}
            {!notify.read && (
              <Snackbar key={index} open autoHideDuration={null}>
                <Alert
                  onClose={() => {
                    handleClose(index);
                  }}
                  severity={notify.type}
                >
                  {notify.message}
                </Alert>
              </Snackbar>
            )}
          </>
        ))}
    </>
  );
}
export function AddNotify(type, message) {
  const dispatch = useDispatch();
  dispatch({
    type: "ADD_NOTIFY",
    notify: {
      type,
      message,
      idclient: 0,
      read: false,
    },
  });
}
