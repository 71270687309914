import React from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import { CircularProgress } from "@material-ui/core";
import SelectPeriod from "../Dialogs/SelectPeriod";
import api from "../../../services/axios";
import { AddNotify } from "../../../components/Alert";
import { useTranslation } from "react-i18next";
import ClientsAttended from "../Dialogs/ClientsAttended";
import socket from "../../../services/socket";

Date.prototype.toDateInputValue = function () {
  const local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};
export default function ({ history }) {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.user);
  const [tickets, setTickets] = React.useState([]);
  const [period, setPeriod] = React.useState("");
  const [showloading, setShowloading] = React.useState(false);
  const [showclientattended, setShowclientattended] = React.useState(false);
  const [clientsattended, setClientsattended] = React.useState("");
  const [showShowSelectPeriod, setShowShowSelectPeriod] = React.useState(false);
  const getTickets = async (date) => {
    setShowloading(true);
    const datatime = {
      initial: new Date(`${date.initial} 00:00:00`),
      final: new Date(`${date.final} 23:59:59`),
    };

    await api
      .get("dashboard/tickets", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: datatime,
      })
      .then((res) => {
        setTickets(res.data);
        setShowloading(false);
      })
      .catch((e) => {
        setShowloading(false);
        AddNotify("error", e.response.data.error);
      });
  };
  React.useEffect(() => {
    if (tickets.length) {
      const clients = [];
      const arrayclosed = tickets.filter((x) => x.status === "FECHADO");
      if (arrayclosed.length) {
        arrayclosed.map((ticketclosed) => {
          const index = clients.findIndex(
            (client) => client.idclient === ticketclosed.client.idclient
          );
          if (index === -1) {
            clients.push(ticketclosed.client);
          }
        });
        setClientsattended(clients);
      }
    }
  }, [tickets]);
  React.useEffect(() => {
    getTickets({
      initial: new Date().toDateInputValue(),
      final: new Date().toDateInputValue(),
    });
    // socket.on(`${user.company.prefix}_updateticket`, () => {
    //   if (period) {
    //     getTickets(period);
    //   } else {
    //     getTickets({
    //       initial: new Date().toDateInputValue(),
    //       final: new Date().toDateInputValue(),
    //     });
    //   }
    // });
  }, []);
  function dateFilter(date) {
    setPeriod(date);
    getTickets(date);
  }
  return (
    <>
      {showShowSelectPeriod && (
        <SelectPeriod
          close={() => {
            setShowShowSelectPeriod(false);
          }}
          func={dateFilter}
        />
      )}
      {showclientattended && (
        <ClientsAttended
          close={() => {
            setShowclientattended(false);
          }}
          clients={clientsattended}
        />
      )}
      <div className="col-md-4 col-xl-3">
        <div className="card bg-c-red order-card">
          <div className="card-block">
            {showloading && (
              <div className="mask">
                <CircularProgress />
              </div>
            )}
            <h6 className="header-card">
              {period ? `${period.initial} - ${period.final}` : "Hoje"} -
              {t("DB.snippets.clients.description")}{" "}
              <DateRangeIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowShowSelectPeriod(true);
                }}
              />
            </h6>
            <h6 className="m-b-20">{t("DB.snippets.clients.title")}</h6>
            <h2 className="text-right">
              <InsertEmoticonIcon fontSize="large" />
              <span>
                <VisibilityIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowclientattended(true);
                  }}
                />
                &nbsp;&nbsp;
                {clientsattended.length || "..."}
              </span>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
