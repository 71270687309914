import React from "react";
import { useSelector } from "react-redux";
import { Page } from "./style";
import DefaultMessage from "./defaultMessage";
import FileMessage from "./fileMessage";
import ImageMessage from "./imageMessage";
import InfoMessage from "./infoMessage";
import AudioMessage from "./audioMessage";

export default function Initial() {
  const messages = useSelector((state) => {
    console.log(state.panelRight);
    return state.panelRight.messagesfilter;
  });
  React.useEffect(() => {
    setTimeout(() => {
      const section = document.getElementById("panel-messages-container");
      if (section) {
        section.scrollTop = section.scrollHeight;
      }
    }, 500);
  }, [messages]);
  return (
    <Page>
      <div className="copyable-area">
        <div className="panel-down panel-main" id="panel-document-preview">
          <span className="_3rsak">
            <div tabIndex="-1">
              <div className="_2fq0t _1drQ- copyable-area">
                <div className="_3TSht" style={{ display: "none" }} />
                <header className="wDFKR">
                  <div className="Ghmsz" data-animate-drawer-title="true">
                    <div className="SFEHG">
                      <button
                        type="button"
                        className="_1aTxu"
                        id="btn-close-panel-document-preview"
                      >
                        <span data-icon="x-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path
                              fill="#FFF"
                              d="M19.058 17.236l-5.293-5.293 5.293-5.293-1.764-1.764L12 10.178 6.707 4.885 4.942 6.649l5.293 5.293-5.293 5.293L6.707 19 12 13.707 17.293 19l1.765-1.764z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <div className="_1xGbt">Pré-visualizar</div>
                  </div>
                </header>
                <div className="_2sNbV _3ySAH" tabIndex="-1">
                  <div className="NuJ4j">
                    <span>
                      <div className="_3Mxes">
                        <div
                          id="file-panel-document-preview"
                          className="_3y0EN"
                          data-animate-attach-media="true"
                          style={{
                            opacity: "1",
                            transform: "scale(1)",
                            display: "none",
                          }}
                        >
                          <div className="_3EjgV">
                            <div className="_6Pnex">
                              <div
                                className="jcxhw icon-doc-generic"
                                id="icon-panel-document-preview"
                              />
                              <div
                                className="QA8Vz"
                                id="filename-panel-document-preview"
                              >
                                file.zip
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="image-panel-document-preview"
                          className="r4crx"
                          data-js-attach-preview="true"
                          style={{ display: "none" }}
                        >
                          <div className="_2-_Pl">
                            <div className="IuYNx _3E6Ha">
                              <div
                                style={{
                                  width: "641.263px",
                                  height: "516px",
                                }}
                              >
                                <img
                                  src="#"
                                  className="_3m_5X"
                                  id="img-panel-document-preview"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="_2GgBs"
                            id="info-panel-document-preview"
                          >
                            0 páginas
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                  <span>
                    <div
                      className="_3nfoJ"
                      tabIndex="-1"
                      style={{ transform: "scale(1)", opacity: "1" }}
                    >
                      <div
                        role="button"
                        className="_3hV1n yavlE"
                        id="btn-send-document"
                      >
                        <span data-icon="send-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 28 24"
                            width="28"
                            height="24"
                          >
                            <path
                              fill="#FFF"
                              d="M5.101 21.757L27.8 12.028 5.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </span>
                  <input
                    type="file"
                    accept="*"
                    style={{ display: "none" }}
                    id="input-document"
                  />
                </div>
              </div>
            </div>
          </span>
        </div>
        <div className="panel-down panel-main" id="panel-camera">
          <span className="_3rsak">
            <div className="_2fq0t r3Nu1 copyable-area">
              <header className="wDFKR">
                <div className="Ghmsz" data-animate-drawer-title="true">
                  <div className="SFEHG">
                    <button
                      className="_1aTxu"
                      id="btn-close-panel-camera"
                      type="button"
                    >
                      <span data-icon="x-light" className="btn-close">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            fill="#FFF"
                            d="M19.058 17.236l-5.293-5.293 5.293-5.293-1.764-1.764L12 10.178 6.707 4.885 4.942 6.649l5.293 5.293-5.293 5.293L6.707 19 12 13.707 17.293 19l1.765-1.764z"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className="_1xGbt">Tirar foto</div>
                  <div
                    className="btn-reshoot"
                    id="btn-reshoot-panel-camera"
                    style={{ display: "none" }}
                  >
                    <div className="_1VQOF">
                      <div className="_3KiSR">
                        <span data-icon="refresh-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path
                              fill="#FFF"
                              d="M17.6 6.4C16.2 4.9 14.2 4 12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8c3.7 0 6.8-2.6 7.7-6h-2.1c-.8 2.3-3 4-5.6 4-3.3 0-6-2.7-6-6s2.7-6 6-6c1.7 0 3.1.7 4.2 1.8L13 11h7V4l-2.4 2.4z"
                            />
                          </svg>
                        </span>
                      </div>
                      Tirar novamente
                    </div>
                  </div>
                </div>
              </header>
              <div className="_2sNbV _3ySAH">
                <div className="_3fJXs">
                  <div className="_1tfR8">
                    <span className="_2pEAB">
                      <div style={{ opacity: "1" }}>
                        <div className="IuYNx" style={{ textAlign: "center" }}>
                          <div className="video-container">
                            <div
                              className="_2oKVP"
                              data-animage-capture-webcam="true"
                            >
                              <track id="video-camera" className="_1Y0rl" />
                              <img
                                id="picture-camera"
                                className="_3ygW4 photo"
                                style={{ display: "none" }}
                                data-animate-capture-snapshot="true"
                                src="#"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="_3UUn3" id="container-take-picture">
                  <span className="_26WTk" />
                  <span>
                    <div
                      className="w0LJp"
                      style={{ transform: "scale(1)", opacity: "1" }}
                    >
                      <div
                        role="button"
                        className="_3hV1n yavlE"
                        id="btn-take-picture"
                      >
                        <span data-icon="camera-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path
                              fill="#FFF"
                              d="M21.317 4.381H10.971L9.078 2.45c-.246-.251-.736-.457-1.089-.457H4.905c-.352 0-.837.211-1.078.468L1.201 5.272C.96 5.529.763 6.028.763 6.38v1.878l-.002.01v11.189a1.92 1.92 0 0 0 1.921 1.921h18.634a1.92 1.92 0 0 0 1.921-1.921V6.302a1.92 1.92 0 0 0-1.92-1.921zM12.076 18.51a5.577 5.577 0 1 1 0-11.154 5.577 5.577 0 0 1 0 11.154zm0-9.506a3.929 3.929 0 1 0 0 7.858 3.929 3.929 0 0 0 0-7.858z"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
                <div
                  className="_3UUn3"
                  id="container-send-picture"
                  style={{ display: "none" }}
                >
                  <span className="_26WTk">
                    <div
                      tabIndex="-1"
                      className="TI3qN _2Mg6D"
                      style={{ opacity: "1", transform: "translateY(0px)" }}
                    >
                      <div className="_2ssn3" />
                      <div className="_17XE4">
                        <div className="_1xHCd">
                          <span className="TSSFW" />
                        </div>
                      </div>
                    </div>
                  </span>
                  <span>
                    <div
                      className="w0LJp _2-dbC"
                      style={{ transform: "scale(1)", opacity: "1" }}
                    >
                      <div
                        role="button"
                        className="_3hV1n yavlE"
                        id="btn-send-picture"
                      >
                        <span data-icon="send-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 28 24"
                            width="28"
                            height="24"
                          >
                            <path
                              fill="#FFF"
                              d="M5.101 21.757L27.8 12.028 5.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div className="messages-container" id="panel-messages-container">
          <div className="btn-reload" style={{ display: "none" }}>
            <div className="_3dGYA" title="Carregar mensagens recentes">
              <div className="LQEjW">
                <span data-icon="refresh">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      fill="#263238"
                      fillOpacity=".55"
                      d="M17.6 6.4C16.2 4.9 14.2 4 12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8c3.7 0 6.8-2.6 7.7-6h-2.1c-.8 2.3-3 4-5.6 4-3.3 0-6-2.7-6-6s2.7-6 6-6c1.7 0 3.1.7 4.2 1.8L13 11h7V4l-2.4 2.4z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="_2S4JS" />
          <div className="_9tCEa" id="messages">
            {!!messages.length &&
              messages.map((message, index) => {
                let showtimeinfo = false;
                if (index !== 0) {
                  const time = new Date(message.timestamp)
                    .toLocaleDateString()
                    .split("/");

                  const oldtime = new Date(messages[index - 1].timestamp)
                    .toLocaleDateString()
                    .split("/");
                  if (Number(time[0]) !== Number(oldtime[0])) {
                    console.log(time, oldtime, Number(time[0]));
                    showtimeinfo = true;
                  }
                }
                switch (message.type) {
                  case "default":
                    return (
                      <>
                        {showtimeinfo && (
                          <InfoMessage
                            message={{
                              content: new Date(
                                message.timestamp
                              ).toLocaleDateString(),
                            }}
                          />
                        )}
                        <DefaultMessage message={message} />
                      </>
                    );
                  case "document":
                    return (
                      <>
                        {showtimeinfo && (
                          <InfoMessage
                            message={{
                              content: new Date(
                                message.timestamp
                              ).toLocaleDateString(),
                            }}
                          />
                        )}
                        <FileMessage message={message} />
                      </>
                    );
                  case "image":
                    return (
                      <>
                        {showtimeinfo && (
                          <InfoMessage
                            message={{
                              content: new Date(
                                message.timestamp
                              ).toLocaleDateString(),
                            }}
                          />
                        )}
                        <ImageMessage message={message} />
                      </>
                    );
                  case "info":
                    return (
                      <>
                        {showtimeinfo && (
                          <InfoMessage
                            message={{
                              content: new Date(
                                message.timestamp
                              ).toLocaleDateString(),
                            }}
                          />
                        )}
                        <InfoMessage message={message} />
                      </>
                    );
                  case "audio":
                    return (
                      <>
                        {showtimeinfo && (
                          <InfoMessage
                            message={{
                              content: new Date(
                                message.timestamp
                              ).toLocaleDateString(),
                            }}
                          />
                        )}
                        <AudioMessage message={message} />
                      </>
                    );
                  case "ptt":
                    return (
                      <>
                        {showtimeinfo && (
                          <InfoMessage
                            message={{
                              content: new Date(
                                message.timestamp
                              ).toLocaleDateString(),
                            }}
                          />
                        )}
                        <AudioMessage message={message} />
                      </>
                    );
                  default:
                    return <div />;
                }
              })}
            {/* <div className="message">
                <div className="_3_7SH kNKwo message-in tail">
                  <span className="tail-container" />
                  <span className="tail-container highlight" />
                  <div className="_1YNgi copyable-text">
                    <div className="_3DZ69" role="button">
                      <div className="_20hTB">
                        <div
                          className="_1WliW"
                          style={{ height: "49px", width: "49px" }}
                        >
                          <img
                            src="#"
                            className="Qgzj8 gqwaM photo-contact-sended"
                            style={{ display: "none" }}
                            alt=""
                          />
                          <div className="_3ZW2E">
                            <span data-icon="default-user">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 212 212"
                                width="212"
                                height="212"
                              >
                                <path
                                  fill="#DFE5E7"
                                  d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"
                                />
                                <g fill="#FFF">
                                  <path d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z" />
                                </g>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="_1lC8v">
                        <div
                          dir="ltr"
                          className="_3gkvk selectable-text invisible-space copyable-text"
                        >
                          Nome do Contato Anexado
                        </div>
                      </div>
                      <div className="_3a5-b">
                        <div className="_1DZAH" role="button">
                          <span className="message-time">17:01</span>
                          <div className="message-status">
                            <span data-icon="msg-dblcheck">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 15"
                                width="16"
                                height="15"
                              >
                                <path
                                  fill="#92A58C"
                                  d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="_6qEXM">
                      <div className="btn-message-send" role="button">
                        Enviar mensagem
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

            {/* <div className="message">
                <div className="font-style _3DFk6 message-out tail">
                  <span className="tail-container" />
                  <span className="tail-container highlight" />
                  <div className="Tkt2p">
                    <div className="_3zb-j ZhF0n">
                      <span
                        dir="ltr"
                        className="selectable-text invisible-space message-text"
                      >
                        Oi, tudo bem?
                      </span>
                    </div>
                    <div className="_2f-RV">
                      <div className="_1DZAH" role="button">
                        <span className="msg-time">11:52</span>
                        <div className="message-status">
                          <span
                            data-icon="msg-dblcheck-ack"
                            style={{ display: "none" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 15"
                              width="16"
                              height="15"
                            >
                              <path
                                fill="#4FC3F7"
                                d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                              />
                            </svg>
                          </span>
                          <span data-icon="msg-dblcheck">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 15"
                              width="16"
                              height="15"
                            >
                              <path
                                fill="#92A58C"
                                d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                              />
                            </svg>
                          </span>
                          <span
                            data-icon="msg-check"
                            style={{ display: "none" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 15"
                              width="16"
                              height="15"
                            >
                              <path
                                fill="#92A58C"
                                d="M10.91 3.316l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                              />
                            </svg>
                          </span>
                          <span data-icon="msg-time">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 15"
                              width="16"
                              height="15"
                            >
                              <path
                                fill="#859479"
                                d="M9.75 7.713H8.244V5.359a.5.5 0 0 0-.5-.5H7.65a.5.5 0 0 0-.5.5v2.947a.5.5 0 0 0 .5.5h.094l.003-.001.003.002h2a.5.5 0 0 0 .5-.5v-.094a.5.5 0 0 0-.5-.5zm0-5.263h-3.5c-1.82 0-3.3 1.48-3.3 3.3v3.5c0 1.82 1.48 3.3 3.3 3.3h3.5c1.82 0 3.3-1.48 3.3-3.3v-3.5c0-1.82-1.48-3.3-3.3-3.3zm2 6.8a2 2 0 0 1-2 2h-3.5a2 2 0 0 1-2-2v-3.5a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v3.5z"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </Page>
  );
}
