import React from "react";
import MUIDataTable from "mui-datatables";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import cloneDeep from "lodash/cloneDeep";
import { useDispatch } from "react-redux";
import api from "../../services/axios";
import { Page } from "./style";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [rules, setRules] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [rulesfilter, setRulesfilter] = React.useState([]);
  const [roleselected, setRoleselected] = React.useState("");
  const dispatch = useDispatch();
  const options = {
    filterType: false,
    print: false,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    search: false,
    filter: false,
    rowsPerPage: 100,
    fixedHeader: true,
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum dado encontrado",
        toolTip: "Sort",

        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        rowsPerPage: "Linhas por Página",
      },
    },
  };
  const columns = [
    t("PT.table.type"),
    t("PT.table.view"),
    t("PT.table.create"),
    t("PT.table.edit"),
    t("PT.table.delete"),
  ];
  const checkedRule = (index, type) => {
    const arrayrule = rulesfilter;
    arrayrule[index][Object.keys(arrayrule[index])[0]].find(
      (x) => x.action === type
    ).checked = !arrayrule[index][Object.keys(arrayrule[index])[0]].find(
      (x) => x.action === type
    ).checked;
    setRulesfilter([...arrayrule]);
  };
  React.useEffect(() => {
    const role = roles[roleselected];
    const arrayrules = cloneDeep(rules);
    if (role && role.roles_has_rules.length) {
      role.roles_has_rules.map((rolerule) => {
        arrayrules.map((rule) => {
          const item = rule[Object.keys(rule)[0]].find(
            (x) => x.idcompanies_rules === rolerule.idcompanies_rules
          );
          if (item) {
            rule[Object.keys(rule)[0]].find(
              (x) => x.idcompanies_rules === rolerule.idcompanies_rules
            ).checked = true;
          }
          return null;
        });
        return null;
      });
      setRulesfilter([...arrayrules]);
    } else {
      setRulesfilter([...arrayrules]);
    }
  }, [roleselected]);
  const getRoles = () => {
    api
      .get("users/roles", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        setRoles(response.data);
      })
      .catch((e) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: e.response.data.error,
            idclient: 0,
            read: false,
          },
        });
      });
  };
  React.useEffect(() => {
    api
      .get("users/rules", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        if (response.data.length) {
          const arrayrules = [];
          response.data.map((rule) => {
            const index = arrayrules.findIndex(
              (rulefind) => rulefind[rule.rule.type]
            );
            if (index === -1) {
              arrayrules.push({
                [rule.rule.type]: [
                  { ...rule.rule, idcompanies_rules: rule.idcompanies_rules },
                ],
              });
            } else {
              arrayrules[index][rule.rule.type].push({
                ...rule.rule,
                idcompanies_rules: rule.idcompanies_rules,
              });
            }
          });
          console.log(arrayrules);
          setRules(arrayrules);
        }
      });
    getRoles();
  }, []);

  const confirm = () => {
    const role = roles[roleselected];
    const arrayrules = [];
    rulesfilter.map((rule) => {
      rule[Object.keys(rule)[0]].map((x) => {
        if (x.checked === true) {
          arrayrules.push(x);
        }
      });
    });
    const data = {
      role: role.idroles,
      rules: arrayrules,
    };
    api
      .put("users/rolerules", data, {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        getRoles();
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "success",
            message: "Salvo!",
            idclient: 0,
            read: false,
          },
        });
      });
  };
  const getTranslater = (name) => {
    switch (name) {
      case "USERS":
        return t("PT.profile.users");
      case "SECTORS":
        return t("PT.profile.sectors");
      case "WALLETS":
        return t("PT.profile.wallets");
      case "REQUESTS":
        return t("PT.profile.requests");
      case "CLIENTS":
        return t("PT.profile.clients");
      case "DASHBOARD":
        return t("PT.profile.dashboard");
      case "FILTERS":
        return t("PT.profile.filter");
      case "PRIVILEGES":
        return t("PT.profile.privileges");
      case "SUBSECTORS":
        return t("PT.profile.subsectors");
      case "ROLES":
        return t("PT.profile.roles");
      case "SETTINGS":
        return t("PT.profile.settings");

      default:
        return name;
    }
  };
  return (
    <Page>
      <h2>{t("PT.title")}</h2>
      <TextField
        id="filled-select-currency"
        select
        label={t("PT.ph")}
        value={roleselected}
        onChange={(ev) => {
          setRoleselected(ev.target.value);
        }}
        SelectProps={{
          multiple: false,
        }}
        variant="filled"
        helperText={t("PT.label")}
        margin="normal"
      >
        {!!roles.length &&
          roles.map((role, index) => (
            <MenuItem key={index} value={index}>
              {role.name}
            </MenuItem>
          ))}
      </TextField>
      <Divider />
      <MUIDataTable
        data={rulesfilter.map((rule, index) =>
          // eslint-disable-next-line no-sparse-arrays
          [
            getTranslater(Object.keys(rule)[0]),
            rule[Object.keys(rule)[0]].find((x) => x.action === "READ") && (
              <>
                {" "}
                <label className="container">
                  <input
                    type="checkbox"
                    onChange={(ev) => {
                      checkedRule(index, "READ");
                    }}
                    checked={
                      !!rule[Object.keys(rule)[0]].find(
                        (x) => x.action === "READ"
                      ).checked
                    }
                  />
                  <span className="checkmark" />
                </label>
              </>
            ),
            rule[Object.keys(rule)[0]].find((x) => x.action === "CREATE") && (
              <>
                {" "}
                <label className="container">
                  <input
                    type="checkbox"
                    onChange={(ev) => {
                      checkedRule(index, "CREATE");
                    }}
                    checked={
                      !!rule[Object.keys(rule)[0]].find(
                        (x) => x.action === "CREATE"
                      ).checked
                    }
                  />
                  <span className="checkmark" />
                </label>
              </>
            ),
            rule[Object.keys(rule)[0]].find((x) => x.action === "UPDATE") && (
              <>
                {" "}
                <label className="container">
                  <input
                    type="checkbox"
                    onChange={(ev) => {
                      checkedRule(index, "UPDATE");
                    }}
                    checked={
                      !!rule[Object.keys(rule)[0]].find(
                        (x) => x.action === "UPDATE"
                      ).checked
                    }
                  />
                  <span className="checkmark" />
                </label>
              </>
            ),
            rule[Object.keys(rule)[0]].find((x) => x.action === "DELETE") && (
              <>
                {" "}
                <label className="container">
                  <input
                    type="checkbox"
                    onChange={(ev) => {
                      checkedRule(index, "DELETE");
                    }}
                    checked={
                      !!rule[Object.keys(rule)[0]].find(
                        (x) => x.action === "DELETE"
                      ).checked
                    }
                  />
                  <span className="checkmark" />
                </label>
              </>
            ),
            ,
          ]
        )}
        columns={columns}
        options={options}
      />
      <div className="btnconfirm">
        <Button
          onClick={confirm}
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
        >
          {t("PT.bt1")}
        </Button>
      </div>
    </Page>
  );
};
