import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import api from "../../../services/axios";
import { useTranslation } from "react-i18next";
import WalletSelect from "./WalletSelect";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));
export default function AlertDialog(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [wallet, setWallet] = React.useState("");
  const [selectwallet, setSelectwallet] = React.useState(false);
  const [inputs, setInputs] = React.useState([
    <>
      <InputMask mask="99.999.999/9999-99">
        {() => (
          <TextField
            id="outlined-basic cnpjindex0"
            label="CNPJ"
            variant="outlined"
            required
          />
        )}
      </InputMask>

      <TextField
        id="outlined-basic codindex0"
        label="Cód. Cliente"
        variant="outlined"
        required
      />
      <TextField
        id="outlined-basic nmfantasiaindex0"
        label="Nome Fantasia"
        variant="outlined"
        required
      />
      <TextField
        id="outlined-basic rzsocialindex0"
        label="Razão Social"
        variant="outlined"
        required
      />
    </>,
  ]);
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    props.func(false);
    setNumber("");
    setName("");
    setInputs([
      <>
        <InputMask mask="99.999.999/9999-99">
          {() => (
            <TextField
              id="outlined-basic cnpjindex0"
              label="CNPJ"
              variant="outlined"
              required
            />
          )}
        </InputMask>
        <TextField
          id="outlined-basic codindex0"
          label="Cód. Cliente"
          variant="outlined"
          required
        />
        <TextField
          id="outlined-basic nmfantasiaindex0"
          label="Nome Fantasia"
          variant="outlined"
          required
        />
        <TextField
          id="outlined-basic rzsocialindex0"
          label="Razão Social"
          variant="outlined"
          required
        />
      </>,
    ]);
  };
  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  const moreInputs = () => {
    setInputs([
      ...inputs,
      <>
        <InputMask mask="99.999.999/9999-99">
          {() => (
            <TextField
              id={`outlined-basic cnpjindex${inputs.length}`}
              label="CNPJ"
              variant="outlined"
              required
            />
          )}
        </InputMask>

        <TextField
          id={`outlined-basic codindex${inputs.length}`}
          label={t("RET.newrepresentative.phcodclient")}
          variant="outlined"
          required
        />
        <TextField
          id={`outlined-basic nmfantasiaindex${inputs.length}`}
          label={t("RET.newrepresentative.phnm")}
          variant="outlined"
          required
        />
        <TextField
          id={`outlined-basic rzsocialindex${inputs.length}`}
          label={t("RET.newrepresentative.phrz")}
          variant="outlined"
          required
        />
      </>,
    ]);
  };

  const confirm = async () => {
    if (!name.length || !number.length) {
      return window.alert("Preencha todos os campos");
    }
    if (number.replace(/([^\d])+/gim, "").length !== 12) {
      return window.alert("Numero com formato incorreto!");
    }
    const data = {
      representative: {
        representative: 1,
        completed: 1,
        name,
        number: number.replace(/([^\d])+/gim, ""),
        idwallet: wallet ? wallet.idwallets : undefined,
      },
      establishments: [],
    };
    for (let i = 0; i <= inputs.length - 1; i++) {
      if (
        !document.getElementById(`outlined-basic cnpjindex${i}`).value ||
        !document.getElementById(`outlined-basic codindex${i}`).value ||
        !document.getElementById(`outlined-basic nmfantasiaindex${i}`).value ||
        !document.getElementById(`outlined-basic rzsocialindex${i}`).value
      ) {
        return window.alert("Preencha todos os campos");
      }
      data.establishments.push({
        cnpj: document
          .getElementById(`outlined-basic cnpjindex${i}`)
          .value.replace(/([^\d])+/gim, ""),
        cod: document.getElementById(`outlined-basic codindex${i}`).value,
        nmfantasia: document.getElementById(
          `outlined-basic nmfantasiaindex${i}`
        ).value,
        rzsocial: document.getElementById(`outlined-basic rzsocialindex${i}`)
          .value,
      });
    }
    api
      .post("representatives", data, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then(async (res) => {
        await props.update();
        handleClose();
      })
      .catch((err) => window.alert(err.response.data.error));
  };
  return (
    <div>
      {selectwallet && (
        <WalletSelect
          close={() => {
            setSelectwallet(false);
          }}
          setWallet={setWallet}
          wallet={wallet}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("RET.newrepresentative.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("RET.newrepresentative.description")}
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-basic"
                label={t("RET.newrepresentative.phname")}
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
              />
              <InputMask
                mask="99 (99) 9999-9999"
                value={number}
                onChange={(e) => {
                  setNumber(e.currentTarget.value);
                }}
              >
                {() => (
                  <TextField
                    id="outlined-basic"
                    label={t("RET.newrepresentative.phnumber")}
                    variant="outlined"
                  />
                )}
              </InputMask>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectwallet(true);
                }}
              >
                {t("RET.newrepresentative.bt3")}
              </Button>
              {wallet && wallet.name}
              <h3>CNPJ :</h3>
              {inputs.map((input) => input)}
              <AddCircleIcon
                style={{ height: "3ch", width: "35.5ch", margin: 0 }}
                onClick={moreInputs}
              />
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Button variant="contained" color="secondary">
              {t("RET.newrepresentative.bt2")}
            </Button>
          </Button>
          <Button onClick={confirm} color="primary" autoFocus>
            <Button variant="contained" color="primary">
              {t("RET.newrepresentative.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
