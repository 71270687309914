import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  height: 59px;
  width: 100%;
  align-items: center;
  position: relative;
  background-color: #eee;
  padding: 10px 16px;
  border-left: 1px solid rgba(0, 0, 0, 0.08) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.08) !important;
  z-index: 1000;
  box-sizing: border-box;
  &:after {
    content: "";
    height: 1px;
    bottom: -1px;
    position: absolute;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.14);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    left: 0;
    right: 0;
  }
  a,
  abbr,
  acronym,
  address,
  applet,
  article,
  aside,
  audio,
  b,
  big,
  blockquote,
  body,
  canvas,
  caption,
  center,
  cite,
  code,
  dd,
  del,
  details,
  dfn,
  div,
  dl,
  dt,
  em,
  embed,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  hgroup,
  html,
  i,
  iframe,
  img,
  ins,
  kbd,
  label,
  legend,
  li,
  mark,
  menu,
  nav,
  object,
  ol,
  output,
  p,
  pre,
  q,
  ruby,
  s,
  samp,
  section,
  small,
  span,
  strike,
  strong,
  sub,
  summary,
  sup,
  table,
  tbody,
  td,
  tfoot,
  th,
  thead,
  time,
  tr,
  tt,
  u,
  ul,
  var,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    outline: 0;
    list-style: none;
  }

  ._18tv- {
    padding: 0 15px 0 0;
    margin-top: -1px;
    ._1WliW {
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      background-color: #dfe5e7;
      .Qgzj8 {
        display: block;
        opacity: 1;
        position: relative;
        transition: opacity 150ms ease-out;
        z-index: 100;
        overflow: hidden;
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
      ._3ZW2E {
        position: absolute;
        top: 0;
        z-index: 1;
        left: 0;
        right: 0;
        .profilepic {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          display: block;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  ._1WBXd {
    flex-grow: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ._2EbF- {
      display: flex;
      line-height: normal;
      align-items: flex-start;
      text-align: left;
      ._2zCDG {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        white-space: nowrap;
        color: #000;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        .buttonaccept {
          display: flex;
          margin-left: 10px;
          span {
            margin: 0px 5px;
            color: #34b7f1;
            font-weight: 600;
            border: 1px solid #34b7f1;
            border-radius: 5px;
            padding: 2px;
            font-size: 13px;
          }
        }
        ._1wjpf {
          flex-grow: 1;
          position: relative;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    ._3sgkv {
      display: flex;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      .O90ur {
        flex: 1 1 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .Gd51Q {
      color: rgba(0, 0, 0, 0.6);
      font-size: 13px;
      line-height: 20px;
      min-height: 20px;
    }
  }
  ._1i0-u {
    flex: none;
    margin-left: 20px;
    ._3Kxus {
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
      }
      .rAUz7 {
        position: relative;
        cursor: pointer;
        flex: none;
        background-color: rgba(var(--shadow-rgb), 0.1);
        transition: background-color 0.18s ease;
        border-radius: 50%;
        padding: 8px;
        margin-left: 10px;
        &:first-of-type {
          margin-left: 0;
        }
        ._2s_eZ {
          right: 4px;
          text-align: left;
          background-color: #fff;
          border-radius: 3px;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26),
            0 2px 10px 0 rgba(0, 0, 0, 0.16);
          padding: 9px 0;
          top: 44px;
          max-width: 340px;
          max-height: 200px;
          overflow: auto;
          position: absolute;
          z-index: 10000;
          ul {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
              position: relative;
              opacity: 1;
              div {
                padding-left: 24px;
                padding-right: 58px;
                cursor: pointer;
                padding-top: 13px;
                flex-grow: 1;
                overflow: hidden;
                position: relative;
                text-overflow: ellipsis;
                white-space: nowrap;
                box-sizing: border-box;
                color: #4a4a4a;
                display: block;
                font-size: 14.5px;
                height: 40px;
                line-height: 14.5px;
              }
            }
          }
          &::-webkit-scrollbar {
            width: 6px !important;
            height: 6px !important;
          }
          &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2);
          }
          &::-webkit-scrollbar-track {
            background: rgba(255, 255, 255, 0.08);
          }
        }
      }

      .rAUz7:active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
  @media screen and (max-width: 1024px) {
    ._3AwwN {
      border-left: 1px solid #e0e0e0;
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    ._3AwwN {
      border-left: 1px solid #e0e0e0;
      border-right: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
  @media screen and (min-width: 1301px) {
    ._3AwwN {
      border-right: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
  @media screen and (max-width: 720px) {
    .rAUz7 {
      padding: 6px;
      margin-left: 2px;
    }
  }
`;
