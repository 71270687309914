import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  margin: 20px 40px;
  flex-wrap: wrap;
  .col-md-4 {
    -webkit-tap-highlight-color: transparent;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    box-sizing: inherit;
    position: relative;
    width: 100%;
    min-width: 275px;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;

    max-width: 25%;
   
    .card {
      -webkit-tap-highlight-color: transparent;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      box-sizing: inherit;
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      color: #fff;
      border-radius: 5px;
      box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
      border: none;
      margin-bottom: 30px;
      transition: all 0.3s ease-in-out;

      .card-block {
        min-height: 158px;
        -webkit-tap-highlight-color: transparent;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          "Helvetica Neue", Arial, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #fff;
        box-sizing: inherit;
        padding: 25px;
        .mask{
      display: flex;
    position: absolute;
    z-index: 2;
    background: white;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    margin: -25px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    }
        .header-card {
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
          border-bottom: 1px solid white;
          margin-bottom: 5px;
          justify-content: flex-end;
        }
        .m-b-20 {
          -webkit-tap-highlight-color: transparent;
          box-sizing: inherit;
          margin-top: 0;
          margin-bottom: 0.5rem;
          font-family: inherit;
          font-weight: 500;
          line-height: 1.1;
          color: inherit;
          font-size: 1rem;
        }
        .text-right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 0;
          margin-bottom: 0.5rem;
          font-family: inherit;
          font-weight: 500;
          line-height: 1.1;
          color: inherit;
          font-size: 2rem;
          text-align: right !important;
          span {
            -webkit-tap-highlight-color: transparent;
            font-family: inherit;
            font-weight: 500;
            line-height: 1.1;
            color: inherit;
            font-size: 2rem;
            text-align: right !important;
            box-sizing: inherit;
          }
        }
        .m-b-0 {
          -webkit-tap-highlight-color: transparent;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            "Helvetica Neue", Arial, sans-serif;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #fff;
          box-sizing: inherit;
          margin-top: 0;
          margin-bottom: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .f-right {
            -webkit-tap-highlight-color: transparent;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
              "Helvetica Neue", Arial, sans-serif;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #fff;
            display: flex;
            align-items: flex-end;
            box-sizing: inherit;
          }
        }
      }
    }
    .bg-c-blue {
      background: linear-gradient(45deg, #4099ff, #73b4ff);
    }
    .bg-c-green {
      background: linear-gradient(45deg, #2ed8b6, #59e0c5);
    }
    .bg-c-yellow {
      background: linear-gradient(45deg, #ffb64d, #ffcb80);
    }
    .bg-c-red {
      background: linear-gradient(45deg, #ff5370, #ff869a);
    }
    .bg-c-pink {
      background: linear-gradient(45deg, #f74dff, #bf84f8);
    }
  }
`;
