import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Page } from "./style";
import Header from "./Header";
import Footer from "./Footer";
import Content from "./Content";
// import Locked from "./Footer/locked";
import api from "../../../../services/axios";
import socket from "../../../../services/socket";

export default function Initial() {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.panelRight.userchat);
  const [messages, setMessages] = React.useState([]);
  const [messagestoread, setMessagestoread] = React.useState([]);
  const [clientcompare, setClientcompare] = React.useState("");
  const [infoclient, setInfoclient] = React.useState(client);
  const [isFetching, setIsFetching] = React.useState(false);

  const [page, setPage] = React.useState(0);

  const user = JSON.parse(localStorage.user);
  function getClientInfo() {
    api
      .get("/clients/clientinfo", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: client,
      })
      .then((response) => {
        setInfoclient(response.data);
      })
      .catch((e) => {
        setInfoclient(client);
        console.log(e);
      });
  }
  function getSingleConversations(NumPage = page, reset) {
    if (isFetching) return;
    setIsFetching(true);
    api
      .get("/ticket/singleconversations", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: { ...client, page: NumPage, size: 20 },
      })
      .then((response) => {
        console.log(response);
        setPage((prev) => prev + 1);
        if (response.data) {
          if (reset) {
            setMessages((prev) => [...response.data?.messages]);
          } else {
            setMessages((prev) => [...prev, ...response.data?.messages]);
          }
          setMessagestoread([...response.data.messages.filter((x) => !x.read)]);
        } else {
          if (reset) {
            setMessages([]);
          }
        }
      })
      .catch((e) => {
        setMessages([]);
        console.log(e);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  function readMessages() {
    const data = {
      messages: messagestoread,
      number: infoclient.number,
    };
    api
      .post("/ticket/readmessages", data, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        setMessagestoread([]);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function updateMessage(oldId, newId) {
    setMessages((prevMessages) => {
      const updatedMessages = prevMessages.map((message) => {
        if (message.idmessages === oldId) {
          return { ...message, idmessages: newId };
        }
        return message;
      });
      return updatedMessages; // Retorna o novo estado atualizado
    });
  }
  function addMessage(message) {
    setMessages((prev) => {
      return [message, ...prev];
    });
    dispatch({
      type: "UPDATE_CHAT_MESSAGE",
      payload: { ...message, number: infoclient.number },
    });
  }
  React.useEffect(() => {
    if (!infoclient.timestamp) {
      getClientInfo();
      getSingleConversations();
    } else if (client.idclient !== infoclient.idclient) {
      getClientInfo();
      setPage(0);
      getSingleConversations(0, true);
    }
  }, [client]);
  React.useEffect(() => {
    dispatch({
      type: "SET_CLIENT_INFO",
      clientinfo: infoclient,
    });
  }, [infoclient]);
  React.useEffect(() => {
    if (clientcompare) {
      if (
        clientcompare.idclient === client.idclient ||
        clientcompare.number === client.number
      ) {
        getClientInfo();
        if (clientcompare.content && clientcompare.client_message) {
          if (clientcompare.media) {
            addMessage({
              content: clientcompare.content,
              type: clientcompare.type,
              timestamp: new Date(clientcompare.timestamp),
              client_message: clientcompare.client_message,
              pending: false,
              media: clientcompare.media,
            });
          } else {
            addMessage({
              content: clientcompare.content,
              type: "default",
              timestamp: new Date(clientcompare.timestamp),
              client_message: clientcompare.client_message,
              pending: false,
              idmessages: clientcompare.idmessages,
            });
          }
        }
      }
    }
  }, [clientcompare]);
  React.useEffect(() => {
    socket.on(
      `${user.company.prefix}_closeTicket${
        JSON.parse(localStorage.user).idusers
      }`,
      (data) => {
        if (
          client.idclient === data.idclient &&
          client.number === data.number
        ) {
          getClientInfo();
        }
      }
    );
    socket.on(`${user.company.prefix}_updateclientinfo`, (clientemit) => {
      setClientcompare(clientemit);
    });
    socket.on(`${user.company.prefix}_updateClientInfo`, (data) => {
      if (data.number === client.number) {
        setInfoclient((prev) => {
          return { ...prev, name: data.name, number: data.number };
        });
      }
    });
    socket.on(
      `${user.company.prefix}_newmessage${
        JSON.parse(localStorage.user).idusers
      }`,
      (clientemit) => {
        setClientcompare(clientemit);
      }
    );
    socket.on(
      `${user.company.prefix}_readMessages${
        JSON.parse(localStorage.user).idusers
      }`,
      (data) => {
        dispatch({
          type: "READ_MESSAGE_CHAT",
          payload: data,
        });
      }
    );
  }, []);

  React.useEffect(() => {
    if (messagestoread.length) {
      readMessages();
    }
  }, [messagestoread]);
  return (
    <>
      <Page>
        <div className="YUoyu" data-asset-chat-background="true" />
        <Header
          client={infoclient}
          setMessages={setMessages}
          messages={messages}
          updateMessage={updateMessage}
        />
        <Content
          messages={messages}
          fetchMessages={getSingleConversations}
          isFetching={isFetching}
        />
        <Footer
          client={infoclient}
          addMessage={addMessage}
          updateMessage={updateMessage}
        />
      </Page>
    </>
  );
}
