import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FaceIcon from "@material-ui/icons/Face";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ReplayIcon from "@material-ui/icons/Replay";
import { AddNotify } from "../../../components/Alert";
import api from "../../../services/axios";
import ActivityUsers from "../Dialogs/ActivityUsers";
import socket from "../../../services/socket";

Date.prototype.toDateInputValue = function () {
  const local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};
export default function () {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.user);
  const [users, setUsers] = React.useState([]);
  const [lastupdate, setLastupdate] = React.useState(
    new Date().toLocaleString()
  );
  const [usersworking, setUsersworking] = React.useState([]);
  const [showactivityusers, setShowactivityusers] = React.useState(false);
  const [usersfree, setUsersfree] = React.useState([]);
  const [viewusers, setViewusers] = React.useState([]);
  const [showloading, setShowloading] = React.useState(false);
  const getUsers = async (date) => {
    setShowloading(true);
    await api
      .get("dashboard/usersworking", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setUsers(res.data);
        setShowloading(false);
        setLastupdate(new Date().toLocaleString());
      })
      .catch((e) => {
        setShowloading(false);
        AddNotify("error", e.response.data.error);
      });
  };

  React.useEffect(() => {
    getUsers();
    // socket.on(`${user.company.prefix}_updateticket`, () => {
    //   getUsers();
    // });
  }, []);
  React.useEffect(() => {
    if (viewusers.length) {
      setShowactivityusers(true);
    }
  }, [viewusers]);
  React.useEffect(() => {
    if (users.length) {
      const usersactive = [];
      const usersinative = [];
      users.forEach((user) => {
        if (user.ticketsaccepted) {
          usersactive.push(user);
        } else {
          usersinative.push(user);
        }
      });
      setUsersfree(usersinative);
      setUsersworking(usersactive);
    }
  }, [users]);

  return (
    <>
      {showactivityusers && (
        <ActivityUsers
          close={() => {
            setShowactivityusers(false);
            setViewusers([]);
          }}
          users={viewusers}
        />
      )}
      <div className="col-md-4 col-xl-3">
        <div className="card bg-c-green order-card">
          <div className="card-block">
            {showloading && (
              <div className="mask">
                <CircularProgress />
              </div>
            )}
            <h6 className="header-card">
              Atualizado - {lastupdate}
              {"  "}
              <ReplayIcon style={{ cursor: "pointer" }} onClick={getUsers} />
            </h6>
            <h6 className="m-b-20"> {t("DB.snippets.service.title")}</h6>
            <h2 className="text-right">
              <FaceIcon fontSize="large" />
              <span>
                <VisibilityIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setViewusers(usersworking);
                  }}
                />
                &nbsp;&nbsp;
                {usersworking.length || "0"}
              </span>
            </h2>
            <p className="m-b-0">
              {t("DB.snippets.service.subtitle")}
              <span className="f-right">
                {" "}
                <VisibilityIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setViewusers(usersfree);
                  }}
                />
                &nbsp;&nbsp; {usersfree.length || "0"}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
