import styled from "styled-components";

export const Page = styled.div`
  color: #4a4a4a;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  user-select: none;
  pointer-events: none;
  font: inherit;
  font-size: 100%;
  vertical-align: initial;
  outline: none;
  margin: 0;
  padding: 0;
  border: 0;
  position: relative;
  z-index: 100;
  height: 100%;
  overflow: hidden;
  flex: 30%;
  transform: translateZ(0);
  ._1V8rX {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    ._2fpYo {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      ._2ueSF {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        ._3gJlY {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          overflow: hidden;
          pointer-events: auto;
          background-color: #ededed;
          ._2wPpw {
            position: relative;
            z-index: 100;
            display: flex;
            flex: 1;
            flex-direction: column;
            overflow-x: hidden;
            overflow-y: auto;
            opacity: 1;
            ._1TM40 {
              flex: none;
            }
          }
        }
      }
    }
  }
`;
export const HeaderPage = styled.header`
  display: flex;
  flex: none;
  align-items: center;
  box-sizing: border-box;
  height: 59px;
  color: #4a4a4a;
  padding-right: 20px;
  padding-left: 25px;
  background-color: #ededed;
  ._1D7Lo {
    display: flex;
    flex: none;
    align-items: center;
    width: 100%;
    color: #000;
    font-weight: 400;
    text-align: initial;
    ._3SrqU {
      width: 54px;
      flex: none;
      .t4a8o {
        flex: none;
        color: #fff;
        vertical-align: top;
        cursor: pointer;
        color: #919191;
      }
    }
    ._3vTfY {
      margin-top: -4px;
      font-size: 16px;
      line-height: normal;
      flex-grow: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
export const ProfilePage = styled.div`
  color: #4a4a4a;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  user-select: text;
  visibility: visible;
  pointer-events: auto;
  font: inherit;
  font-size: 100%;
  vertical-align: initial;
  outline: none;
  margin: 0;
  border: 0;
  position: relative;
  flex: none;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 28px 30px 19px;
  ._2Ixxw {
    margin-bottom: 20px;
    ._3_Hvt,
    ._3X-61 {
      position: relative;
      ._3_Hvt {
        position: relative;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        border-radius: 50%;
        transform: translateZ(0);
        cursor: pointer;
        margin-right: auto;
        margin-left: auto;
        width: 200px;
        height: 200px;
        overflow: hidden;
        .Ao0On {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          .H36t4 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
          }
        }
      }
    }
  }
  ._1pY1V {
    margin-bottom: 6px;
    position: relative;
    transition: margin 0.1s ease;
    ._1Rerq {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: flex-start;
      ._2FVVk {
        position: relative;
        display: flex;
        flex: 1;
        overflow: hidden;
        padding-right: 0;
        cursor: text;
        ._2FbwG {
          position: absolute;
          top: 6px;
          left: 2px;
          z-index: 2;
          color: #999;
          font-size: 15px;
          line-height: 20px;
          transition: opacity 0.08s linear;
          user-select: none;
          pointer-events: none;
        }
        ._3FRCZ {
          margin: 5px 0 6px;
          padding: 0;
          font-size: 19px;
          line-height: 26px;
          position: relative;
          width: 100%;
          min-height: 20px;
          color: #4a4a4a;
          white-space: pre-wrap;
          user-select: text;
        }
      }
      ._3CkXi {
        padding-top: 8px;
        display: flex;
        flex: none;
        color: #919191;
      }
      ._1CUl1 {
        position: relative;
        padding-top: 8px;
        flex: none;
        width: 24px;
        color: #919191;
        ._3-8bl {
          position: absolute;
          right: 0;
          top: 6px;
        }
      }
    }
  }
  ._1005i {
    margin-top: -4px;
    min-height: 20px;

    ._3V3__ {
      position: absolute;
      top: 0;
      width: 100%;
    }
    ._2-1_O {
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
      line-height: 20px;
    }
  }
`;
export const DescriptionPage = styled.div`
  color: #4a4a4a;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  user-select: text;
  visibility: visible;
  pointer-events: auto;
  font: inherit;
  font-size: 100%;
  vertical-align: initial;
  outline: none;
  margin: 0;
  border: 0;
  position: relative;
  flex: none;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 14px 30px 19px;
  ._1Gecv {
    margin-bottom: 4px;
    padding: 0;
    ._3mR1z {
      display: flex;
      align-items: center;
      ._1e77x {
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1 1 auto;
        ._2y8MV {
          color: #009688;
          font-size: 14px;
          line-height: normal;
        }
      }
    }
  }
  ._24nYt {
    white-space: pre-wrap;

    ._2m-1n {
      position: relative;
      min-height: 32px;
      transition: margin 0.1s ease;
      ._1xryB {
        border-bottom: 2px solid transparent;
        min-height: 32px;
        position: relative;
        z-index: 2;
        display: flex;
        align-items: flex-start;
        ._3sKYI {
          color: #4a4a4a;
          text-rendering: optimizeLegibility;
          font-feature-settings: "kern";
          user-select: text;
          visibility: visible;
          pointer-events: auto;
          white-space: pre-wrap;
          font: inherit;
          vertical-align: initial;
          outline: none;
          margin: 0;
          padding: 0;
          border: 0;
          flex: 1;
          overflow: hidden;
          font-size: 14px;
          line-height: 20px;
          padding-top: 6px;
          padding-bottom: 6px;
          padding-right: 5px;
        }
        ._3CkXi {
          display: flex;
          flex: none;
          color: #919191;
          padding-top: 6px;
        }
        ._1CUl1 {
          position: relative;
          padding-top: 6px;
          flex: none;
          width: 24px;
          color: #919191;
          ._3-8bl {
            position: absolute;
            right: 0;
            top: 4px;
          }
        }
      }
    }
  }
`;
export const EstablishmentPage = styled.div`
  color: #4a4a4a;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  user-select: text;
  visibility: visible;
  pointer-events: auto;
  font: inherit;
  font-size: 100%;
  vertical-align: initial;
  outline: none;
  margin: 0;
  padding: 0;
  border: 0;
  position: relative;
  flex: none;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  ._1Gecv {
    padding: 14px 30px;
    ._3mR1z {
      display: flex;
      align-items: center;
      ._1e77x {
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1 1 auto;
        ._2y8MV {
          color: #009688;
          font-size: 14px;
          line-height: normal;
        }
      }
      .sb_Co {
        flex: none;
        margin-left: 10px;
        ._3lS1C {
          color: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  .-GlrD {
    position: relative;
    overflow: hidden;
    margin-top: -1px;
    ._210SC {
      width: 100%;
      .eJ0yJ {
        position: relative;
        display: flex;
        flex-direction: row;
        height: 72px;
        padding-left: 14px;
        pointer-events: all;
        background-color: #fff;
        cursor: pointer;
        ._325lp {
          display: flex;
          flex: none;
          align-items: center;
          margin-top: -1px;
          padding: 0 15px 0 13px;
          ._1BjNO {
            position: relative;
            overflow: hidden;
            background-color: #e6e6e6;
            border-radius: 50%;
            ._2goTk {
              position: relative;
              z-index: 100;
              display: block;
              width: 100%;
              height: 100%;
              overflow: hidden;
              transition: opacity 0.15s ease-out;
              border-radius: 50%;
              visibility: visible;
            }
            ._1V82l {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              svg {
                width: 60%;
                height: 60%;
              }
            }
          }
        }
        ._2kHpK {
          display: flex;
          flex-basis: auto;
          flex-direction: column;
          flex-grow: 1;
          justify-content: center;
          min-width: 0;
          padding-right: 15px;
          border-top: 1px solid #f2f2f2;
          ._3dtfX {
            display: flex;
            align-items: center;
            line-height: normal;
            text-align: left;
            ._3CneP {
              text-align: left;
              display: flex;
              flex-grow: 1;
              overflow: hidden;
              color: #000;
              font-weight: 400;
              font-size: 17px;
              line-height: 21px;
              line-height: normal;
              ._3ko75 {
                display: inline-block;
                position: relative;
                flex-grow: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                visibility: visible;
              }
            }
          }
          ._1582E {
            display: flex;
            align-items: center;
            min-height: 20px;
            color: var(--secondary);
            font-size: 13px;
            line-height: 20px;
            margin-top: 2px;
            ._3tBW6 {
              text-align: left;
              flex-grow: 1;
              overflow: hidden;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              white-space: nowrap;
              text-overflow: ellipsis;
              background-color: initial;
            }
            .m61XR {
              flex: none;
              max-width: 100%;
              overflow: hidden;
              color: rgba(0, 0, 0, 0.45);
              font-size: 12px;
              line-height: 20px;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: -1px;
              padding-right: 1px;
              margin-left: 6px;
              margin-top: 0;
            }
          }
        }
      }
    }
  }
`;
