import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PeopleIcon from "@material-ui/icons/People";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ClientSelect from "./ClientSelect";
import UsersSelect from "./UsersSelect";
import api from "../../../services/axios";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
      justifyContent: "center",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    marginLeft: theme.spacing(4),
  },
  form: {
    display: "flex",
    justifyContent: "center",
  },
}));
export default function AlertDialog({ data, func, update }) {
  const { t } = useTranslation();
  const [wallet, setWallet] = React.useState(data);
  const [openclients, setOpenclients] = React.useState(false);
  const [openclientsinput, setOpenclientsinput] = React.useState(false);
  const [openusers, setOpenusers] = React.useState(false);
  const [openusersinput, setOpenusersinput] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [clientsfilter, setClientsfilter] = React.useState([]);
  const updateData = () => {
    api
      .get("/wallet/singlewallet", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: { idwallets: wallet.idwallets },
      })
      .then((response) => {
        setClients(response.data.clients);
        setClientsfilter(response.data.clients);
        setUsers(response.data.users_has_wallets);
        setWallet(response.data);
      });
  };
  const handleClickClients = () => {
    setOpenclients(!openclients);
  };
  const handleClickUsers = () => {
    setOpenusers(!openusers);
  };
  const toggleExpand = (index) => {
    const arrayclients = clientsfilter;
    arrayclients[index].show = !arrayclients[index].show;
    setClientsfilter([...arrayclients]);
  };
  const removeClient = (client) => {
    api
      .put(
        "/clients/",
        {
          client: {
            idclient: client.idclient,
            idwallet: null,
            number: client.number,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((response) => {
        updateData();
      });
  };
  const removeUser = (id) => {
    api
      .put(
        "/wallet/removeuser",
        { idusers_has_wallets: id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((response) => {
        updateData();
      });
  };
  const classes = useStyles();
  const searchingFor = (term) => (x) => {
    term = term.toLowerCase();
    let index;
    if (x.cliente_cnpjs.length) {
      const { length } = x.cliente_cnpjs;
      for (let i = 0; i < length; i++) {
        const paramter = x.cliente_cnpjs[i].rzsocial
          ? x.cliente_cnpjs[i].rzsocial
          : x.cliente_cnpjs[i].nmfantasia;
        const condition = paramter.toLowerCase().indexOf(term);
        if (condition !== -1) {
          return (index = true);
        }
      }
    }
    return index || x.name.toLowerCase().indexOf(term) !== -1 || !term;
  };
  const onChange = (value) => {
    const array = clients;
    setClientsfilter(array.filter(searchingFor(value)));
  };
  React.useEffect(() => {
    updateData();
  }, []);
  return (
    <div>
      <Dialog
        open
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {t("WT.dialogs.wallet.title")}
        </DialogTitle>
        <DialogContent>
          <List
            fullWidth
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.list}
          >
            <ListItem button onClick={handleClickClients}>
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Clientes" />
              {openclients ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openclients} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <FormControl
                  style={{ width: "100%" }}
                  className={classes.margin}
                >
                  <Input
                    id="input-with-icon-adornment"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    onChange={(ev) => {
                      onChange(ev.currentTarget.value);
                    }}
                  />
                </FormControl>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    className={classes.button}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      setOpenclientsinput(true);
                    }}
                    variant="contained"
                    style={{ background: "#34b7f1", color: "white" }}
                    size="small"
                    startIcon={<AddCircleIcon />}
                  >
                    {t("WT.dialogs.wallet.btadd")}
                  </Button>
                </div>
                {!!clientsfilter.length &&
                  clientsfilter.map((client, index) => (
                    <>
                      <ListItem
                        button
                        className={classes.nested}
                        key={client.idclient}
                      >
                        <div
                          onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            toggleExpand(index);
                          }}
                        >
                          {client.show ? <ExpandLess /> : <ExpandMore />}
                        </div>
                        <ListItemText primary={client.name} />
                        <ListItemIcon
                          onClick={() => {
                            removeClient(client);
                          }}
                        >
                          <DeleteIcon />
                        </ListItemIcon>
                      </ListItem>
                      <Collapse in={client.show} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {client.cliente_cnpjs.length &&
                            client.cliente_cnpjs.map((cnpj) => (
                              <ListItem button className={classes.nested}>
                                <ListItemText
                                  primary={`COD: ${cnpj.cd_cliente_dislub} - ${
                                    cnpj.rzsocial
                                      ? cnpj.rzsocial
                                      : cnpj.nmfantasia
                                  } / ${cnpj.cnpj.replace(
                                    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                                    "$1.$2.$3/$4-$5"
                                  )}`}
                                />
                              </ListItem>
                            ))}
                        </List>
                      </Collapse>
                    </>
                  ))}
              </List>
            </Collapse>
            <ListItem button onClick={handleClickUsers}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Usuarios" />
              {openusers ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openusers} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className={classes.button}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      setOpenusersinput(true);
                    }}
                    variant="contained"
                    style={{ background: "#34b7f1", color: "white" }}
                    size="small"
                    startIcon={<AddCircleIcon />}
                  >
                    {t("WT.dialogs.wallet.btadd")}
                  </Button>
                </div>
                {!!users.length &&
                  users.map((user) => (
                    <ListItem button className={classes.nested}>
                      <ListItemText primary={user.user.username} />
                      <ListItemIcon
                        onClick={() => {
                          removeUser(user.idusers_has_wallets);
                        }}
                      >
                        <DeleteIcon />
                      </ListItemIcon>
                    </ListItem>
                  ))}
              </List>
            </Collapse>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              update();
              func();
            }}
            autoFocus
          >
            <Button
              variant="contained"
              style={{ background: "#34b7f1", color: "white" }}
            >
              {t("WT.dialogs.wallet.bt2")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
      {openclientsinput && (
        <ClientSelect
          wallet={wallet}
          close={() => {
            setOpenclientsinput(false);
          }}
          update={updateData}
        />
      )}
      {openusersinput && (
        <UsersSelect
          wallet={wallet}
          close={() => {
            setOpenusersinput(false);
          }}
          update={updateData}
        />
      )}
    </div>
  );
}
