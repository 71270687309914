import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import MicIcon from "@material-ui/icons/Mic";
import api from "../../../../../services/axios";
import whatsapp from "./img/whatsapp.png";
import Search from "../Search";
import socket from "../../../../../services/socket";

import WhatsappPng from "./img/whatsapp.png";
import TelegramPng from "./img/telegram.png";

export default () => {
  const conversationsRedux = useSelector((state) => {
    return state.chats;
  });
  const [conversations, setConversations] = React.useState([]);
  const [conversationsfilter, setConversationsfilter] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.user);
  function getConversations() {
    api
      .get("ticket/useractiveconversations", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          size: 20,
          page,
        },
      })
      .then((response) => {
        setPage(page + 1);
        if (conversations.length >= response.data.count) {
          setHasMore(false);
        }
        if (page === 0) {
          dispatch({
            type: "SET_CHATS",
            payload: [
              ...response.data.rows.sort(
                (a, b) =>
                  new Date(b.conversation.messages[0].timestamp) -
                  new Date(a.conversation.messages[0].timestamp)
              ),
            ],
          });
        } else {
          dispatch({
            type: "SET_CHATS",
            payload: [
              ...conversations,
              ...response.data.rows.sort(
                (a, b) =>
                  new Date(b.conversation.messages[0].timestamp) -
                  new Date(a.conversation.messages[0].timestamp)
              ),
            ],
          });
        }
        // setConversations([
        //   ...conversations,
        //   ...response.data.rows.sort(
        //     (a, b) =>
        //       new Date(b.conversation.messages[0].timestamp) -
        //       new Date(a.conversation.messages[0].timestamp)
        //   ),
        // ]);
        // setConversationsfilter([
        //   ...conversationsfilter,
        //   ...response.data.rows.sort(
        //     (a, b) =>
        //       new Date(b.conversation.messages[0].timestamp) -
        //       new Date(a.conversation.messages[0].timestamp)
        //   ),
        // ]);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function openChat(idclient, number) {
    dispatch({ type: "SET_USER_ACTIVE_CHAT", user: { idclient, number } });
    dispatch({ type: "SET_RENDER", display: "ACTIVECHAT" });
  }
  function getIconMessage(type) {
    switch (type) {
      case "default":
        return null;
      case "image":
        return <PhotoCameraIcon fontSize="small" />;
      case "audio":
        return <MicIcon />;
      default:
        break;
    }
  }
  React.useEffect(() => {
    if (!conversationsRedux.length) {
      getConversations();
    }
    socket.on(`${user.company.prefix}_newmessage${user.idusers}`, (data) => {
      if (data) {
        dispatch({
          type: "UPDATE_CHAT_MESSAGE",
          payload: { ...data },
        });
      }
    });
    socket.on(`${user.company.prefix}_updateClientInfo`, (data) => {
      if (data) {
        dispatch({
          type: "UPDATE_CLIENT_INFO_CHAT",
          payload: { ...data },
        });
      }
    });
    socket.on(
      `${user.company.prefix}_ticketAccept${
        JSON.parse(localStorage.user).idusers
      }`,
      (data) => {
        dispatch({
          type: "ADD_NEW_CHAT",
          payload: data,
        });
      }
    );
    socket.on(
      `${user.company.prefix}_closeTicket${
        JSON.parse(localStorage.user).idusers
      }`,
      (data) => {
        dispatch({
          type: "REMOVE_CHAT",
          payload: { ...data },
        });
      }
    );
  }, []);

  React.useEffect(() => {
    if (conversationsRedux.length) {
      setConversations(conversationsRedux);
      setConversationsfilter(conversationsRedux);
    }
  }, [conversationsRedux]);
  return (
    <>
      <Search data={conversations} set={setConversationsfilter} />
      <InfiniteScroll
        dataLength={conversationsfilter.length}
        next={getConversations}
        hasMore={hasMore}
        loader={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        }
        scrollableTarget="scrollableDiv"
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Estes são todos os dados</b>
          </p>
        }
      >
        {conversationsfilter.map((conversation, index) => {
          conversation = conversation.conversation;
          return (
            <div
              key={index}
              id="contacts-messages-list"
              className="contact-list"
              onClick={() => {
                openChat(
                  conversation.client
                    ? conversation.client.idclient
                    : undefined,
                  conversation.number
                );
              }}
            >
              <div className="contact-item">
                <div className="dIyEr">
                  <div className="_1WliW">
                    <img
                      src={whatsapp}
                      className="Qgzj8 gqwaM photo"
                      alt=""
                      style={{ display: "none" }}
                    />
                    <div className="_3ZW2E">
                      {conversation.client.has_whatsapp ? (
                        <div className="source-icon">
                          <img src={WhatsappPng} alt="source" />
                        </div>
                      ) : (
                        ""
                      )}

                      {conversation.client.profile_pic ? (
                        <img
                          src={conversation.client.profile_pic}
                          alt="perfil"
                          className="profilepic"
                        />
                      ) : (
                        <span data-icon="default-user">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 212 212"
                            width="212"
                            height="212"
                          >
                            <path
                              fill="#DFE5E7"
                              d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"
                            />
                            <g fill="#FFF">
                              <path d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z" />
                            </g>
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="_3j7s9">
                  <div className="_2FBdJ">
                    <div className="_25Ooe">
                      <span
                        dir="auto"
                        title={
                          conversation.client
                            ? conversation.client.name
                            : conversation.number
                        }
                        className="_1wjpf"
                        style={{
                          fontWeight: `${
                            !conversation.messages[0].read ? "600" : "500"
                          }`,
                        }}
                      >
                        {conversation.client
                          ? conversation.client.name
                          : conversation.number}
                      </span>
                    </div>
                    <div className="_3Bxar">
                      <span className="_3T2VG">
                        {new Date(
                          conversation.messages[0].timestamp
                        ).toLocaleDateString() !==
                        new Date().toLocaleDateString()
                          ? new Date(
                              conversation.messages[0].timestamp
                            ).toLocaleDateString()
                          : new Date(
                              conversation.messages[0].timestamp
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                      </span>
                    </div>
                  </div>
                  <div className="_1AwDx">
                    <div className="_itDl">
                      <span className="_2_LEW last-message">
                        {/* <div className="_1VfKB">
                <span data-icon="status-dblcheck" className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    width="18"
                    height="18"
                  >
                    <path
                      fill="#263238"
                      fillOpacity=".4"
                      d="M17.394 5.035l-.57-.444a.434.434 0 0 0-.609.076l-6.39 8.198a.38.38 0 0 1-.577.039l-.427-.388a.381.381 0 0 0-.578.038l-.451.576a.497.497 0 0 0 .043.645l1.575 1.51a.38.38 0 0 0 .577-.039l7.483-9.602a.436.436 0 0 0-.076-.609zm-4.892 0l-.57-.444a.434.434 0 0 0-.609.076l-6.39 8.198a.38.38 0 0 1-.577.039l-2.614-2.556a.435.435 0 0 0-.614.007l-.505.516a.435.435 0 0 0 .007.614l3.887 3.8a.38.38 0 0 0 .577-.039l7.483-9.602a.435.435 0 0 0-.075-.609z"
                    />
                  </svg>
                </span>
              </div> */}
                        <span
                          dir="ltr"
                          className="_1wjpf _3NFp9"
                          style={{
                            color: `${
                              conversation.messages[0].read
                                ? "none"
                                : "rgba(0, 0, 0, 0.8)"
                            }`,
                          }}
                        >
                          {conversation.messages[0].client_message === 0 &&
                            "Você: "}
                          {getIconMessage(conversation.messages[0].type)}

                          {conversation.messages[0].content}
                        </span>
                        <div className="_3Bxar">
                          <div className="_15G96">
                            {!conversation.messages[0].read && (
                              <span className="OUeyt messages-count-new">
                                {t("HOME.pl.init.chats.info")}
                              </span>
                            )}
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
    </>
  );
};
