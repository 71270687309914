import React from "react";
import { Page } from "./style";
import Message from "./Message";
import { CircularProgress } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { useRef } from "react";
import { useState } from "react";

export default function Initial({ messages, fetchMessages, isFetching }) {
  const [showDown, setShowDown] = useState(false);
  const scrollableDivRef = useRef(null);
  const handleScroll = () => {
    const porcentagem =
      (scrollableDivRef.current.scrollTop /
        scrollableDivRef.current.scrollHeight) *
      100;
    if (porcentagem <= 20) {
      if (messages.length >= 20) {
        fetchMessages();
      }
    }
    if (
      Number(scrollableDivRef.current.scrollTop).toFixed() - 1 !==
      Number(
        scrollableDivRef.current.scrollHeight -
          scrollableDivRef.current.offsetHeight
      )
    ) {
      setShowDown(true);
    } else {
      setShowDown(false);
    }
  };
  function toDown() {
    const section = document.getElementById("scrollableDivMessages");
    if (section) {
      section.scrollTop = section.scrollHeight;
    }
  }
  React.useEffect(() => {
    setTimeout(() => {
      !isFetching && toDown();
    }, 500);
  }, [messages]);

  return (
    <Page>
      {showDown && (
        <div className="btn-down" onClick={toDown}>
          <ArrowDropDown />
        </div>
      )}

      <div className="copyable-area">
        <div
          className="messages-container"
          id="scrollableDivMessages"
          ref={scrollableDivRef}
          onScroll={handleScroll}
        >
          {isFetching && (
            <div
              className="_2S4JS_load"
              style={{
                display: "flex",
                width: "100 %",
                alignItems: "center",
                justifyContent: "center",
                margin: "5px",
              }}
            >
              <CircularProgress />
            </div>
          )}

          <div className="_2S4JS" />
          <div className="_9tCEa" id="messages">
            {messages.map((message, index) => (
              <Message
                key={message.idmessages}
                message={message}
                afterMessage={messages[index + 1]}
                lastMessage={index === messages.length - 1}
              />
            ))}
            {/* <CommentMessage /> */}
          </div>
        </div>
      </div>
    </Page>
  );
}
