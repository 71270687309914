import React from "react";
import FileType from "file-type/browser";

export default function ({ message }) {
  const [size, setSize] = React.useState(0);
  const [type, setType] = React.useState("FILE");

  function download() {
    fetch(
      `${message.pending ? "" : process.env.REACT_APP_API_URL}${message.media}`
    ).then((t) =>
      t.blob().then((b) => {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", message.content);
        a.click();
      })
    );
  }
  function formatSizeUnits(bytes) {
    if (bytes >= 1073741824) {
      bytes = `${(bytes / 1073741824).toFixed(2)} GB`;
    } else if (bytes >= 1048576) {
      bytes = `${(bytes / 1048576).toFixed(2)} MB`;
    } else if (bytes >= 1024) {
      bytes = `${(bytes / 1024).toFixed(2)} KB`;
    } else if (bytes > 1) {
      bytes += " bytes";
    } else if (bytes == 1) {
      bytes += " byte";
    } else {
      bytes = "0 bytes";
    }
    return bytes;
  }
  async function fetchFile() {
    const file = await fetch(
      `${message.pending ? "" : process.env.REACT_APP_API_URL}${message.media}`
    ).then((res) => res.blob());
    const response = await fetch(
      `${message.pending ? "" : process.env.REACT_APP_API_URL}${message.media}`
    );
    try {
      const fileType = await FileType.fromStream(response.body);
      setSize(formatSizeUnits(file.size));
      setType(fileType ? fileType.ext.toUpperCase() : "FILE");
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    fetchFile();
  }, []);
  return (
    <div className="message">
      <div
        className={`_3_7SH _1ZPgd message-${
          message.client_message > 0 ? "in" : "out"
        }`}
        style={{
          backgroundColor: message.color && message.color,
        }}
      >
        <div className="_1fnMt _2CORf" onClick={download}>
          <video
            width="100%"
            height="160"
            controls
            src={`${message.pending ? "" : process.env.REACT_APP_API_URL}${
              message.media
            }`}
          />
          <div className="_3cMIj">
            <span className="PyPig message-file-info" />
            <span className="PyPig message-file-type">{type}</span>
            <span className="PyPig message-file-size">{size}</span>
          </div>
          <img
            src={`${message.pending ? "" : process.env.REACT_APP_API_URL}${
              message.media
            }`}
            className="_1JVSX message-photo"
            style={{ width: "100%" }}
            alt=""
          />
          <div className="_3Lj_s">
            <div className="_1DZAH" role="button">
              <span className="message-time">
                {" "}
                {new Date(message.timestamp).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
              {message.pending ||
                (message.status === "PENDING" && (
                  <div className="_2nWgr">
                    <span
                      data-testid="msg-time"
                      aria-label=" Pendente "
                      data-icon="msg-time"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 15"
                        width="16"
                        height="15"
                      >
                        <path
                          fill="currentColor"
                          d="M9.75 7.713H8.244V5.359a.5.5 0 0 0-.5-.5H7.65a.5.5 0 0 0-.5.5v2.947a.5.5 0 0 0 .5.5h.094l.003-.001.003.002h2a.5.5 0 0 0 .5-.5v-.094a.5.5 0 0 0-.5-.5zm0-5.263h-3.5c-1.82 0-3.3 1.48-3.3 3.3v3.5c0 1.82 1.48 3.3 3.3 3.3h3.5c1.82 0 3.3-1.48 3.3-3.3v-3.5c0-1.82-1.48-3.3-3.3-3.3zm2 6.8a2 2 0 0 1-2 2h-3.5a2 2 0 0 1-2-2v-3.5a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v3.5z"
                        />
                      </svg>
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
