import React from "react";
import { Page } from "./style";
import InitialPage from "./Initial/index";
import Profile from "./Profile";
import NewConversation from "./NewConversation";

export default class PanelRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rendercontent: "1",
    };
  }

  setRender = (value) => this.setState({ rendercontent: value });

  getRender = () => {
    const { rendercontent } = this.state;
    switch (rendercontent) {
      case "1":
        return (
          <InitialPage
            setRender={this.setRender}
            history={this.props.history}
          />
        );
      case "2":
        return <Profile setRender={this.setRender} />;
      case "3":
        return <NewConversation setRender={this.setRender} />;
      default:
        return null;
    }
  };

  render() {
    return <Page>{this.getRender()}</Page>;
  }
}
