import React from "react";

export default ({
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
  connectionLineType,
  connectionLineStyle,
}) => {
  return (
    <>
      <defs>
        <marker
          class="react-flow__arrowhead"
          id="react-flow__arrowclosed"
          markerWidth="12.5"
          markerHeight="12.5"
          viewBox="-10 -10 20 20"
          orient="auto"
          refX="0"
          refY="0"
        >
          <polyline
            stroke="#b1b1b7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            fill="#b1b1b7"
            points="-5,-4 0,0 -5,4 -5,-4"
          ></polyline>
        </marker>
        <marker
          class="react-flow__arrowhead"
          id="react-flow__arrow"
          markerWidth="12.5"
          markerHeight="12.5"
          viewBox="-10 -10 20 20"
          orient="auto"
          refX="0"
          refY="0"
        >
          <polyline
            stroke="#b1b1b7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            fill="none"
            points="-5,-4 0,0 -5,4"
          ></polyline>
        </marker>
      </defs>
      <g>
        <path
          fill="none"
          stroke="rgb(136, 159, 172)"
          strokeWidth={"4px"}
          className="animated"
          marker-end="url(#react-flow__arrow)"
          d={`M${sourceX},${sourceY} C ${sourceX} ${targetY} ${sourceX} ${targetY} ${targetX},${targetY}`}
        />
        {/* <ellipse
          strokeWidth={1.5}
          strokeDasharray="5,5"
          ry="10"
          rx="10"
          id="svg_1"
          cx={targetX}
          cy={targetY}
          stroke="#000"
          fill="#fff"
        /> */}
      </g>
    </>
  );
};
