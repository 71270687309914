import styled from "styled-components";
import logo from "./img/undraw_work_from_anywhere_7sdx.svg";

export const Page = styled.div`
  position: relative;
  width: 100%;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  z-index: 1;
  display: flex;
  height: 100%;
  background-color: #f7f9fa;
  cursor: default;
  text-align: center;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 0;
    border-top: 6px solid #58e870;
    left: 0;
    right: 0;
  }
  a {
    color: #07bc4c;
  }
  ._3BqNZ {
    position: relative;
    max-width: 460px;
    width: 80%;
    text-align: center;
    margin-top: -20px;
    ._2Uo0Z {
      width: 356px;
      height: 250px;
      transition: height 0.3s ease;
      background-image: url(${logo});
      margin: 0 auto;
      transform: scale(1);
      transform-origin: center;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
    }
    ._2MnNk {
      opacity: 1;
      transform: translateY(0px);
      .iHhHL {
        color: #4b5961;
        font-size: 32px;
        font-weight: 300;
        margin-top: 38px;
      }
      ._1ClcF,
      ._1vgz_,
      ._3ZGVC,
      ._3cK8a {
        color: #929fa6;
      }
      ._1ClcF {
        font-size: 14px;
        line-height: 20px;
        margin-top: 24px;
      }
      ._1Y6o1 {
        margin-top: 34px;
        ._3zFlu {
          height: 1px;
          margin-bottom: 34px;
          background-color: #e1e9eb;
        }
        ._3cK8a {
          color: #929fa6;
          font-size: 14px;
          line-height: 20px;
          display: -webkit-inline-flex;
          display: inline-flex;
          -webkit-align-items: center;
          align-items: center;
          ._1lrYJ {
            margin-left: 5px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    ._2Uo0Z,
    ._3BqNZ {
      width: 80%;
    }
    ._2Uo0Z {
      height: 280px;
    }
  }
  @media screen and (max-height: 740px) {
    ._2Uo0Z {
      height: 250px;
    }
    .iHhHL {
      margin-top: 28px;
      font-size: 32px;
    }
    ._1ClcF {
      margin-top: 18px;
    }
  }
`;
