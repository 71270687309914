import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  z-index: 1;
  background-color: #fff;
  .btnfilter {
    justify-content: center;
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 15px;
    z-index: 2;
    .MuiButtonBase-root{
      width: 175px;
     
    }
    .MuiButton-label{
      
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }
  }
  .RLfQR {
    margin-top: -1px;
    margin-bottom: 70px;
    overflow: hidden;
    position: relative;
    ._210SC {
      z-index: 19;
      transition: none 0s ease 0s;
      height: 72px;
      transform: translateY(0px);
      .pr5ny {
        text-rendering: optimizeLegibility;
        -webkit-font-feature-settings: "kern";
        user-select: none;
        pointer-events: auto;
        font: inherit;
        vertical-align: initial;
        outline: none;
        margin: 0;
        border: 0;
        height: 72px;
        box-sizing: border-box;
        font-size: 16px;
        color: #009688;
        text-transform: uppercase;
        background-color: var(--background-default);
        padding: 30px 0 15px 32px;
      }
    }
    .contact-list {
      z-index: 326;
      height: 72px;
      transform: translate3d(0px, 0px, 0px);
      .contact-item {
        display: flex;
        flex-direction: row;
        height: 72px;
        position: relative;
        background-color: #fff;
        cursor: pointer;
        &:hover {
          background-color: #f4f5f5;
          ._3j7s9 {
            border-top-color: #f4f5f5;
          }
        }
        .dIyEr {
          margin-top: -1px;
          flex: none;
          display: flex;
          align-items: center;
          padding: 0 15px 0 13px;
          padding: 0 13px 0 15px;
          ._1WliW {
            height: 49px;
            width: 49px;
            cursor: pointer;
            background-color: #dfe5e7;
            border-radius: 50%;
            position: relative;
            overflow: hidden;
            img {
              display: none;
              width: 100%;
              height: 100%;
            }
            ._3ZW2E {
              position: absolute;
              top: 0;
              z-index: 1;
              left: 0;
              right: 0;
              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        ._3j7s9 {
          flex-grow: 1;
          min-width: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-top: 1px solid #f2f2f2;
          padding-right: 15px;
          ._2FBdJ {
            margin-top: -1px;
            align-items: center;
            line-height: normal;
            display: flex;
            text-align: left;
            ._25Ooe {
              font-weight: 400;
              color: #000;
              display: flex;
              flex-grow: 1;
              font-size: 16px;
              line-height: 21px;
              overflow: hidden;
              text-align: left;
              ._1wjpf {
                -webkit-flex-grow: 1;
                flex-grow: 1;
                position: relative;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            ._3Bxar {
              margin-top: 4px;
              margin-left: 6px;
              line-height: 14px;
              color: rgba(0, 0, 0, 0.4);
              flex: none;
              font-size: 12px;
              max-width: 100%;
              overflow: hidden;
              ._3T2VG {
                text-transform: capitalize;
              }
            }
          }
          ._1AwDx {
            margin-top: 2px;
            align-items: center;
            color: rgba(0, 0, 0, 0.6);
            display: flex;
            font-size: 13px;
            line-height: 19px;
            min-height: 20px;
            ._itDl {
              text-align: left;
              background-color: transparent;
              flex-grow: 1;
              font-size: 14px;
              line-height: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: 400;
              .vdXUe {
                font-weight: 400;
                color: #07bc4c;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              ._1wjpf {
                overflow: hidden;
                flex-grow: 1;
                position: relative;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              ._2_LEW {
                display: flex;
                align-items: center;
                .buttonaccept {
                  button {
                    outline: none;
                    background: #34b7f1;
                    border-radius: 5px;
                    color: white;
                    font-weight: 600;
                    height: 25px;
                  }
                }
                ._1VfKB {
                  margin-right: 2px;
                  vertical-align: top;
                  flex: none;
                  display: inline-block;
                }
                ._1wjpf {
                  overflow: hidden;
                  flex-grow: 1;
                  position: relative;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                ._3NFp9 {
                  display: inline-block;
                }
                ._3Bxar {
                  margin-top: 4px;
                  margin-left: 6px;
                  line-height: 14px;
                  color: rgba(0, 0, 0, 0.4);
                  flex: none;
                  font-size: 12px;
                  max-width: 100%;
                  overflow: hidden;
                  ._15G96 {
                    vertical-align: top;
                    display: inline-block;
                    margin-right: 5px;
                    margin-left: 5px;
                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .contact-item.active:after,
      .contact-item:hover:after {
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 0;
        content: "";
      }
      .contact-item:hover:after {
        border-top: 1px solid #f4f5f5;
        left: 0;
        right: 0;
      }
    }
  }
  @media screen and (min-width: 1441px) {
    ._1NrpZ {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
`;
