import styled from "styled-components";

export const Page = styled.div`
  margin: 20px;
  form {
    display: "flex";
  }
  .dateinput {
    display: flex;
    align-items: center;
    font-weight: bold;
    flex-wrap: wrap;
    .titlefilter {
      width: 20%;
    }
  }
  .btnconfirm {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
  }
`;
