import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import api from "../../../services/axios";
import { useTranslation } from "react-i18next";
import { FileStyled } from "../style";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "100%",
    },
  },
}));
export default function AlertDialog(props) {
  const { t } = useTranslation();
  const [message, setMessage] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [url, setUrl] = React.useState(null);
  const classes = useStyles();
  const handleClose = () => {
    props.func(false);
  };

  const confirm = () => {
    if (!message.length) {
      return window.alert("Preencha todos os campos marcados com *");
    }
    const data = {
      content: message,
      userfile: file,
    };
    const dataform = new FormData();
    for (const key in data) {
      dataform.append(key, data[key]);
    }

    api
      .post("defaultmessages", dataform, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        props.update();
        handleClose();
      })
      .catch((err) => window.alert(err.response.data.error));
  };
  const handleFile = (event) => {
    setFile(event.currentTarget.files[0]);
  };
  React.useEffect(() => {
    if (file) {
      if (!file.type || file.type.split("/").shift() !== "image") {
        setUrl("/document.png");
      } else {
        setUrl(URL.createObjectURL(file));
      }
    } else {
      setUrl(null);
    }
  }, [file]);
  return (
    <div>
      <Dialog
        open
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" "}
          {t("DMT.dialogs.new.title")}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Utilize # + palavrachave para facilitar o seu uso.
            <br />
            Ex: "Olá meu nome é #nome!" ficará "Olá meu nome é Nome do Usuário!"
            <br />
            Palavras Chaves:
            <br />▷ <b>#nome</b> = Nome do Usuário
          </DialogContentText> */}
          <br />
          <Divider />
          <br />
          <DialogContentText id="alert-dialog-description">
            <form className={classes.root} noValidate autoComplete="off">
              {file && (
                <FileStyled url={url}>
                  <div className="filedescription">
                    <div className="place"></div>
                    <p className="namefile">{file.name}</p>
                  </div>
                  <p
                    onClick={() => {
                      setFile(null);
                    }}
                    style={{ color: "#f50057", cursor: "pointer" }}
                  >
                    Remover
                  </p>
                </FileStyled>
              )}

              <TextField
                id="outlined-multiline-static"
                label={t("DMT.dialogs.new.ph")}
                multiline
                variant="outlined"
                rows={4}
                value={message}
                onChange={(e) => {
                  setMessage(e.currentTarget.value);
                }}
                required
              />
              {!file && (
                <>
                  {" "}
                  <Button color="primary">
                    <label for="file-upload">Anexa Arquivo</label>
                  </Button>
                  <input
                    type="file"
                    id="file-upload"
                    style={{ display: "none" }}
                    onChange={handleFile}
                  />
                </>
              )}
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Button variant="contained" color="secondary">
              {t("DMT.dialogs.new.bt2")}
            </Button>
          </Button>
          <Button color="primary" onClick={confirm} autoFocus>
            <Button variant="contained" color="primary">
              {t("DMT.dialogs.new.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
