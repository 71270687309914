import React from "react";
import { Page } from "./style";
import InitialPage from "./Initial/index";

export default function PanelRight({ data, setData }) {
  return (
    <Page>
      <InitialPage data={data} setData={setData} />
    </Page>
  );
}
