import styled from "styled-components";

export const Page = styled.div`
  flex: none;
  background-color: #fbfbfb;
  .gQzdc {
    flex: none;
    height: 49px;
    box-sizing: border-box;
    position: relative;
    transition: box-shadow 0.18s ease-out, background-color 0.18s ease-out;
    z-index: 100;
    &:after {
      content: "";
      bottom: 0;
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -1px;
      background-color: rgba(255, 255, 255, 0.14);
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      left: 0;
      right: 0;
    }
    .C28xL {
      pointer-events: none;
      /* opacity: 0; */
      border: 0;
      z-index: 100;
      position: absolute;
      width: 24px;
      height: 24px;
      top: 12px;
      left: 24px;
      right: 24px;
      background: 0 0 !important;
      ._1M3wR {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
      }
      ._1BC4w {
        pointer-events: none;
        opacity: 0;
        transform: scale(0.8) rotate(225deg);
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transform: scale(1) rotate(360deg);
      }
      ._3M2St {
        transform: rotate(0);
        transition: all 0.24s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
    ._2cLHw {
      left: 65px;
      margin-top: -7px;
      top: 50%;
      width: calc(100% - 82px);
      font-size: 13px;
      line-height: 16px;
      color: #a4a4a4;
      transition: opacity 0.08s linear;
      z-index: 100;
      position: absolute;
      overflow: hidden;
      pointer-events: none;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    ._2MSJr {
      display: flex;
      position: absolute;
      align-items: center;
      box-sizing: border-box;
      display: flex;
      height: 34px;
      transition: 0.12s cubic-bezier(0.1, 0.82, 0.25, 1);
      width: calc(100% - 28px);
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #f6f6f6;
      margin-top: 7px;
      margin-left: 12px;
      padding-left: 52px;
      padding-right: 54px;
      .jN-F5{
        z-index:1;
    -webkit-user-select:text;
    -moz-user-select:text;
    -ms-user-select:text;
    user-select:text;
    font-size:15px;
    width:100%;
    font-weight:400;
    line-height:20px;
    min-height:20px;
    outline:0;
    padding:0;
    border:none;
    text-align:left;

    }
  }
`;
