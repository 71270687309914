import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import ScheduleIcon from "@material-ui/icons/Schedule";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Can from "../../../components/Can";
import ForumIcon from "@material-ui/icons/Forum";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: 600,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function TicketsPending({ close, data, history }) {
  const { t } = useTranslation();

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    close();
  };
  function addZero(value) {
    if (value >= 0 && value < 10) {
      return `0${value}`;
    }
    return value;
  }
  function msToTime(ms) {
    let seconds = ms / 1000;
    let minutes = parseInt(seconds / 60, 10);
    seconds %= 60;
    const hours = parseInt(minutes / 60, 10);
    minutes %= 60;

    return `${addZero(hours.toFixed())}:${addZero(minutes.toFixed())}:${addZero(
      seconds.toFixed()
    )}`;
  }
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open
        fullWidth={true}
        maxWidth={"md"}
        className={classes.dialog}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("DB.dialogs.ticketpending.title")}
        </DialogTitle>
        <DialogContent dividers>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                style={{ marginLeft: "57px" }}
              >
                {t("DB.dialogs.ticketpending.description")}
              </ListSubheader>
            }
          >
            {!!data.length &&
              data.map((ticket, index) => {
                console.log(new Date(ticket.timestamp));
                const time = msToTime(new Date() - new Date(ticket.timestamp));
                return (
                  <ListItem key={index} button>
                    <ListItemIcon>
                      <ScheduleIcon />
                    </ListItemIcon>
                    <ListItemText>
                      {time} / ID : {ticket.idticket} -{" "}
                      {t("DB.dialogs.ticketpending.number")} : {ticket.number}
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions>
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="chats"
            action="read"
            yes={() => (
              <Button
                onClick={() => {
                  history.push("/home");
                }}
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<ForumIcon />}
              >
                {t("DB.dialogs.ticketpending.bt1")}
              </Button>
            )}
            no={() => (
              <Button
                onClick={handleClose}
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
              >
                {t("DB.dialogs.ticketpending.bt2")}
              </Button>
            )}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
