import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useDispatch } from "react-redux";
import SyncIcon from "@material-ui/icons/Sync";
import { useTranslation } from "react-i18next";
import DeleteCnpj from "./Dialogs/DeleteCNPJ";
import DeleteRepresentative from "./Dialogs/DeleteRepresentative";
import EditCnpj from "./Dialogs/EditCNPJ";
import EditRepresentative from "./Dialogs/EditRepresentative";
import api from "../../services/axios";
import NewRepresentative from "./Dialogs/NewRepresentative";
import Can from "../../components/Can";
import SyncConfirmation from "./Dialogs/SyncConfirmation";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default function () {
  const [open, setOpen] = React.useState(false);
  const [openuser, setOpenuser] = React.useState(false);
  const [opendeletecnpj, setOpendeletecnpj] = React.useState(false);
  const [openedituser, setOpenedituser] = React.useState(false);
  const [openeditcnpj, setOpeneditcnpj] = React.useState(false);
  const [representatives, setRepresentatives] = React.useState([]);
  const [deleterepresentative, setDeleterepresentative] = React.useState("");
  const [deletecnpj, setDeletecnpj] = React.useState("");
  const [editclient, setEditclient] = React.useState("");
  const [editcnpj, setEditcnpj] = React.useState("");
  const [openconfirm, setOpenconfirm] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const synchronization = async (message, date) => {
    setOpenconfirm(false);

    api
      .post(
        "tasks",
        {
          description: message,
          timestamp: date,
          method: "representativeSync",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "success",
            message: "Tarefa adicionada!",
            idclient: 0,
            read: false,
          },
        });
        updateData();
      })
      .catch((e) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: e.response.data.error,
            idclient: 0,
            read: false,
          },
        });
      });
  };
  useEffect(() => {
    api
      .get("representatives", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setRepresentatives(res.data.rows);
      })
      .catch((e) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: e.response.data.error,
            idclient: 0,
            read: false,
          },
        });
      });
  }, [dispatch]);
  const updateData = async () => {
    await api
      .get("representatives", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setRepresentatives([...res.data.rows]);
      });
  };
  const options = {
    filterType: false,
    print: false,
    fixedHeader: true,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    filter: false,
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum dado encontrado",
        toolTip: "Sort",

        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        rowsPerPage: "Linhas por Página",
      },
    },
    expandableRows: true,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const representative = representatives[rowMeta.dataIndex];
      return (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <MUIDataTable
              title=""
              data={
                representative.cliente_cnpjs.length
                  ? representative.cliente_cnpjs.map((cliente_cnpj, index) => [
                      cliente_cnpj.idcliente_cnpj,
                      cliente_cnpj.cnpj,
                      cliente_cnpj.rzsocial
                        ? cliente_cnpj.rzsocial
                        : cliente_cnpj.nmfantasia,
                      cliente_cnpj.cd_cliente_dislub,
                      <Can
                        role={JSON.parse(localStorage.user).role.idroles}
                        type="representatives"
                        action="update"
                        yes={() => (
                          <EditIcon
                            style={{ fill: "#34b7f1" }}
                            onClick={() => {
                              setEditcnpj({
                                representative,
                                establishments: cliente_cnpj,
                              });
                              setOpeneditcnpj(true);
                            }}
                          />
                        )}
                      />,
                      <Can
                        role={JSON.parse(localStorage.user).role.idroles}
                        type="representatives"
                        action="delete"
                        yes={() => (
                          <HighlightOffIcon
                            style={{ fill: "#34b7f1" }}
                            onClick={() => {
                              setDeletecnpj({
                                representative,
                                establishments: cliente_cnpj,
                              });
                              setOpendeletecnpj(true);
                            }}
                          />
                        )}
                      />,
                    ])
                  : []
              }
              columns={columnsexpand}
              options={optionstwo}
            />
          </TableCell>
        </TableRow>
      );
    },
  };
  const optionstwo = {
    filterType: false,
    print: false,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    filter: false,
    selectableRows: "none",
    search: false,
    pagination: false,
    customToolbar: null,
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum dado encontrado",
      },
    },
  };
  const columns = [
    "ID",
    t("RET.table.name"),
    "Whatsapp",
    t("RET.table.wallet"),
    t("RET.table.edit"),
    t("RET.table.delete"),
  ];
  const columnsexpand = [
    "ID",
    "CNPJ",
    t("RET.tableexpand.rz"),
    t("RET.tableexpand.cdclient"),
    t("RET.tableexpand.edit"),
    t("RET.tableexpand.delete"),
  ];
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "auto",
      }}
    >
      {openconfirm && (
        <SyncConfirmation
          cancel={() => {
            setOpenconfirm(false);
          }}
          confirm={synchronization}
        />
      )}
      {open && (
        <DeleteRepresentative
          open={open}
          data={deleterepresentative}
          func={setOpen}
          update={updateData}
        />
      )}
      {openuser && (
        <NewRepresentative
          open={openuser}
          func={setOpenuser}
          update={updateData}
        />
      )}
      {openedituser && (
        <EditRepresentative
          open={openedituser}
          data={editclient}
          func={setOpenedituser}
          update={updateData}
        />
      )}
      {openeditcnpj && (
        <EditCnpj
          open={openeditcnpj}
          establishments={editcnpj}
          func={setOpeneditcnpj}
          update={updateData}
        />
      )}
      {opendeletecnpj && (
        <DeleteCnpj
          open={opendeletecnpj}
          establishments={deletecnpj}
          func={setOpendeletecnpj}
          update={updateData}
        />
      )}
      <MUIDataTable
        title={
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="representatives"
            action="create"
            yes={() => (
              <div>
                <Button
                  onClick={() => {
                    setOpenuser(true);
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  startIcon={<AddCircleIcon />}
                >
                  {t("RET.bt1")}
                </Button>
                <Button
                  onClick={() => {
                    setOpenconfirm(true);
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                >
                  <SyncIcon />
                </Button>
              </div>
            )}
          />
        }
        data={representatives.map((representative, index) => [
          representative.idclient,
          representative.name,
          representative.number,
          representative.wallet ? representative.wallet.name : null,
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="representatives"
            action="update"
            yes={() => (
              <EditIcon
                style={{ fill: "#34b7f1" }}
                onClick={() => {
                  setOpenedituser(true);
                  setEditclient(representatives[index]);
                }}
              />
            )}
          />,
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="representatives"
            action="delete"
            yes={() => (
              <HighlightOffIcon
                style={{ fill: "#34b7f1" }}
                onClick={() => {
                  setOpen(true);
                  setDeleterepresentative(representatives[index]);
                }}
              />
            )}
          />,
          representative.establishments,
        ])}
        columns={columns}
        options={options}
      />
    </div>
  );
}
