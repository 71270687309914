import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import Divider from "@material-ui/core/Divider";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { CircularProgress } from "@material-ui/core";
import { AttendantTicketGraphic } from "./style";
import api from "../../../services/axios";
import SelectPeriod from "../Dialogs/SelectPeriod";
import { useTranslation } from "react-i18next";
import socket from "../../../services/socket";
export default function () {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.user);
  const [selected, setSelected] = React.useState(0);
  const [tickets, setTickets] = React.useState([]);
  const [data, setData] = React.useState({});
  const [period, setPeriod] = React.useState("");
  const [showloading, setShowloading] = React.useState(true);
  const [showShowSelectPeriod, setShowShowSelectPeriod] = React.useState(false);
  const dispatch = useDispatch();
  const [yLabels, setYLabels] = React.useState({
    0: "00:00:00",
    1: "01:00:00",
    2: "02:00:00",
    3: "03:00:00",
    4: "04:00:00",
    5: "05:00:00",
    6: "06:00:00",
    7: "07:00:00",
    8: "08:00:00",
    9: "09:00:00",
    10: "10:00:00",
  });

  const options = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label;
          let value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].x;
          return " " + label + ": " + value;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            stepSize: 1,
            callback: function (value, index, values) {
              return yLabels[value];
            },
          },
        },
      ],
    },
  };
  function getRandom(initial, final) {
    return Math.floor(Math.random() * final) + initial;
  }
  function getColorsByStatus() {
    const randomrgb = `${getRandom(1, 255)},${getRandom(1, 255)},${getRandom(
      1,
      255
    )}`;
    return {
      backgroundColor: `rgba(${randomrgb},0.4)`,
      borderColor: `rgba(${randomrgb},1)`,
      pointBorderColor: `rgba(${randomrgb},1)`,
      pointHoverBackgroundColor: `rgba(${randomrgb},1)`,
    };
  }
  function getAverage(array) {
    const totaltimeclosed = [];
    let timeaveragecalc = "";
    array.map((y) => {
      const initial = y.ticket_timelines.find(
        (time) => time.status === "PENDENTE"
      ).timestamp;
      const final = y.ticket_timelines.find(
        (time) => time.status === "ACEITO"
      ).timestamp;
      const interval = intervalHours(initial, final);
      totaltimeclosed.push(msToTime(interval));
    });
    totaltimeclosed.map((timecalc) => {
      timeaveragecalc = someHours(timeaveragecalc, timecalc);
    });
    timeaveragecalc = timeToMs(timeaveragecalc);

    const ms = timeaveragecalc / totaltimeclosed.length;
    const average = msToTime(timeaveragecalc / totaltimeclosed.length);

    return { average, ms };
  }
  function ticketsOrganize(arraytickets) {
    const filter = arraytickets;
    const labelsoptions = {};
    const labelsarray = [{ ms: 0, average: "00:00:00" }];
    switch (selected) {
      case 0:
        const finaldataweek = {
          labels: ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"],
          datasets: [],
        };
        filter.map((x) => {
          const ticektsmonth = [];
          x.tickets.map((z) => {
            const ticketdate = new Date(z.timestamp);
            const indexticket = ticektsmonth.findIndex((g) => {
              return g.day === ticketdate.getDay();
            });
            if (indexticket === -1) {
              ticektsmonth.push({
                day: ticketdate.getDay(),
                tickets: [z],
              });
            } else {
              ticektsmonth[indexticket].tickets.push(z);
            }
          });
          ticektsmonth.map((z) => {
            const timecalc = getAverage(z.tickets);
            labelsarray.push({ ms: timecalc.ms, average: timecalc.average });
            z.average = timecalc.average;
          });
          labelsarray
            .sort((a, b) => {
              return a.ms - b.ms;
            })
            .map((j, index) => {
              labelsoptions[index] = j.average;
            });
        });
        filter.map((x) => {
          const colors = getColorsByStatus();
          const userinfo = {
            label: x.wallet.name,
            fill: false,
            lineTension: 0.1,
            backgroundColor: colors.backgroundColor,
            borderColor: colors.borderColor,
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: colors.pointBorderColor,
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: colors.pointHoverBackgroundColor,
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
            ],
          };
          const ticektsday = [];
          x.tickets.map((z) => {
            const ticketdate = new Date(z.timestamp);
            const indexticket = ticektsday.findIndex((g) => {
              return g.day === ticketdate.getDay();
            });
            if (indexticket === -1) {
              ticektsday.push({
                day: ticketdate.getDay(),
                tickets: [z],
              });
            } else {
              ticektsday[indexticket].tickets.push(z);
            }
          });
          ticektsday.map((z) => {
            const timecalc = getAverage(z.tickets);
            const arraypr = Object.keys(labelsoptions);
            const value = arraypr.find((z) => {
              return labelsoptions[z] === timecalc.average;
            });
            userinfo.data[z.day].y = value;
            userinfo.data[z.day].x = timecalc.average;
          });
          finaldataweek.datasets.push(userinfo);
        });
        setYLabels(labelsoptions);
        setData(finaldataweek);
        break;
      case 1:
        const finaldatamonth = {
          labels: [
            "JAN",
            "FEV",
            "MAR",
            "ABR",
            "MAIO",
            "JUN",
            "JUL",
            "AGO",
            "SET",
            "OUT",
            "NOV",
            "DEZ",
          ],
          datasets: [],
        };
        filter.map((x) => {
          const ticektsmonth = [];
          x.tickets.map((z) => {
            const ticketdate = new Date(z.timestamp);
            const indexticket = ticektsmonth.findIndex((g) => {
              return g.month === ticketdate.getMonth();
            });
            if (indexticket === -1) {
              ticektsmonth.push({
                month: ticketdate.getMonth(),
                tickets: [z],
              });
            } else {
              ticektsmonth[indexticket].tickets.push(z);
            }
          });
          ticektsmonth.map((z) => {
            const timecalc = getAverage(z.tickets);
            labelsarray.push({ ms: timecalc.ms, average: timecalc.average });
            z.average = timecalc.average;
          });
          labelsarray
            .sort((a, b) => {
              return a.ms - b.ms;
            })
            .map((j, index) => {
              labelsoptions[index] = j.average;
            });
        });
        filter.map((x) => {
          const colors = getColorsByStatus();
          const userinfo = {
            label: x.wallet.name,
            fill: false,
            lineTension: 0.1,
            backgroundColor: colors.backgroundColor,
            borderColor: colors.borderColor,
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: colors.pointBorderColor,
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: colors.pointHoverBackgroundColor,
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
            ],
          };
          const ticektsmonth = [];
          x.tickets.map((z) => {
            const ticketdate = new Date(z.timestamp);
            const indexticket = ticektsmonth.findIndex((g) => {
              return g.month === ticketdate.getMonth();
            });
            if (indexticket === -1) {
              ticektsmonth.push({
                month: ticketdate.getMonth(),
                tickets: [z],
              });
            } else {
              ticektsmonth[indexticket].tickets.push(z);
            }
          });
          ticektsmonth.map((z) => {
            const timecalc = getAverage(z.tickets);
            const arraypr = Object.keys(labelsoptions);
            const value = arraypr.find((z) => {
              return labelsoptions[z] === timecalc.average;
            });
            userinfo.data[z.month].y = value;
            userinfo.data[z.month].x = timecalc.average;
          });
          finaldatamonth.datasets.push(userinfo);
        });
        setYLabels(labelsoptions);
        setData(finaldatamonth);
        break;
      case 2:
        const finaldatayear = {
          labels: [
            "2020",
            "2021",
            "2022",
            "2023",
            "2024",
            "2025",
            "2026",
            "2027",
            "2028",
            "2029",
            "2030",
          ],
          datasets: [],
        };
        filter.map((x) => {
          const ticektsyear = [];
          x.tickets.map((z) => {
            const ticketdate = new Date(z.timestamp);
            const indexticket = ticektsyear.findIndex((g) => {
              return g.year === ticketdate.getFullYear().toString();
            });
            if (indexticket === -1) {
              ticektsyear.push({
                year: ticketdate.getFullYear().toString(),
                tickets: [z],
              });
            } else {
              ticektsyear[indexticket].tickets.push(z);
            }
          });
          ticektsyear.map((z) => {
            const timecalc = getAverage(z.tickets);
            labelsarray.push({ ms: timecalc.ms, average: timecalc.average });
            z.average = timecalc.average;
          });
          labelsarray
            .sort((a, b) => {
              return a.ms - b.ms;
            })
            .map((j, index) => {
              labelsoptions[index] = j.average;
            });
        });
        filter.map((x) => {
          const colors = getColorsByStatus();
          const userinfo = {
            label: x.wallet.name,
            fill: false,
            lineTension: 0.1,
            backgroundColor: colors.backgroundColor,
            borderColor: colors.borderColor,
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: colors.pointBorderColor,
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: colors.pointHoverBackgroundColor,
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
              { x: "00:00:00", y: 0 },
            ],
          };
          const ticektsyear = [];
          x.tickets.map((z) => {
            const ticketdate = new Date(z.timestamp);
            const indexticket = ticektsyear.findIndex((g) => {
              return g.year === ticketdate.getFullYear().toString();
            });
            if (indexticket === -1) {
              ticektsyear.push({
                year: ticketdate.getFullYear().toString(),
                tickets: [z],
              });
            } else {
              ticektsyear[indexticket].tickets.push(z);
            }
          });
          ticektsyear.map((z) => {
            const timecalc = getAverage(z.tickets);
            const arraypr = Object.keys(labelsoptions);
            const value = arraypr.find((z) => {
              return labelsoptions[z] === timecalc.average;
            });
            const indexuserinfo = finaldatayear.labels.indexOf(z.year);
            userinfo.data[indexuserinfo].y = value;
            userinfo.data[indexuserinfo].x = timecalc.average;
          });
          finaldatayear.datasets.push(userinfo);
        });
        setYLabels(labelsoptions);
        setData(finaldatayear);
        break;
      case 3:
        const finaldatacustom = {
          labels: [],
          datasets: [],
        };
        for (
          let i = period.initial.getTime();
          i < period.final.getTime();
          i = period.initial.setDate(period.initial.getDate() + 1)
        ) {
          finaldatacustom.labels.push(period.initial.toLocaleDateString());
        }
        filter.map((x) => {
          const ticektscustom = [];
          x.tickets.map((z) => {
            const ticketdate = new Date(z.timestamp);
            const indexuserinfo = finaldatacustom.labels.indexOf(
              ticketdate.toLocaleDateString()
            );
            const indexticket = ticektscustom.findIndex((g) => {
              return g.custom === indexuserinfo;
            });
            if (indexticket === -1) {
              ticektscustom.push({
                custom: indexuserinfo,
                tickets: [z],
              });
            } else {
              ticektscustom[indexticket].tickets.push(z);
            }
          });
          ticektscustom.map((z) => {
            const timecalc = getAverage(z.tickets);
            labelsarray.push({ ms: timecalc.ms, average: timecalc.average });
            z.average = timecalc.average;
          });
          labelsarray
            .sort((a, b) => {
              return a.ms - b.ms;
            })
            .map((j, index) => {
              labelsoptions[index] = j.average;
            });
        });
        filter.map((x) => {
          const colors = getColorsByStatus();
          const userinfo = {
            label: x.wallet.name,
            fill: false,
            lineTension: 0.1,
            backgroundColor: colors.backgroundColor,
            borderColor: colors.borderColor,
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: colors.pointBorderColor,
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: colors.pointHoverBackgroundColor,
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [],
          };
          for (let i = 0; i <= finaldatacustom.labels.length; i++) {
            userinfo.data.push({ x: "00:00:00", y: 0 });
          }
          const ticektscustom = [];
          x.tickets.map((z) => {
            const ticketdate = new Date(z.timestamp);
            const indexuserinfo = finaldatacustom.labels.indexOf(
              ticketdate.toLocaleDateString()
            );
            const indexticket = ticektscustom.findIndex((g) => {
              return g.custom === indexuserinfo;
            });
            if (indexticket === -1) {
              ticektscustom.push({
                custom: indexuserinfo,
                tickets: [z],
              });
            } else {
              ticektscustom[indexticket].tickets.push(z);
            }
          });
          ticektscustom.map((z) => {
            const timecalc = getAverage(z.tickets);
            const arraypr = Object.keys(labelsoptions);
            const value = arraypr.find((z) => {
              return labelsoptions[z] === timecalc.average;
            });
            userinfo.data[z.custom].y = value;
            userinfo.data[z.custom].x = timecalc.average;
          });
          finaldatacustom.datasets.push(userinfo);
        });
        setYLabels(labelsoptions);
        setData(finaldatacustom);
        break;

      default:
        break;
    }
  }
  const getTickets = async (date) => {
    setShowloading(true);
    const datatime = {
      initial: new Date(`${date.initial} 00:00:00`),
      final: new Date(`${date.final} 23:59:59`),
    };
    setPeriod(datatime);
    await api
      .get("dashboard/tickets", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: datatime,
      })
      .then((res) => {
        setTickets(res.data);
        setShowloading(false);
      })
      .catch((e) => {
        setShowloading(false);

        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: e.response.data.error,
            idclient: 0,
            read: false,
          },
        });
      });
  };
  function dateFilter(date) {
    getTickets(date);
  }
  function addZero(value) {
    if (value >= 0 && value < 10) {
      return `0${value}`;
    }
    return value;
  }
  function someHours(value, some) {
    if (!value) {
      return some;
    }
    const starthour = value.split(":");
    const addhour = some.split(":");
    let totalhour = parseInt(starthour[0], 10) + parseInt(addhour[0], 10);
    let totalminutes = parseInt(starthour[1], 10) + parseInt(addhour[1], 10);
    let totalseconds = parseInt(starthour[2], 10) + parseInt(addhour[2], 10);
    if (totalseconds >= 60) {
      totalseconds -= 60;
      totalminutes += 1;
    }
    if (totalminutes >= 60) {
      totalminutes -= 60;
      totalhour += 1;
    }
    return `${addZero(totalhour)}:${addZero(totalminutes)}:${addZero(
      totalseconds
    )}`;
  }
  function intervalHours(initial, final) {
    const starthour = new Date(initial);
    const finalhour = new Date(final);
    return Math.abs(finalhour - starthour);
  }
  function msToTime(ms) {
    let seconds = ms / 1000;
    let minutes = parseInt(seconds / 60, 10);
    seconds %= 60;
    const hours = parseInt(minutes / 60, 10);
    minutes %= 60;

    return `${addZero(hours.toFixed())}:${addZero(minutes.toFixed())}:${addZero(
      seconds.toFixed()
    )}`;
  }
  function timeToMs(time) {
    return (
      Number(time.split(":")[0]) * 60 * 60 * 1000 +
      Number(time.split(":")[1]) * 60 * 1000 +
      Number(time.split(":")[2]) * 1000
    );
  }
  function handleSelection() {
    const now = new Date();
    switch (selected) {
      case 0:
        const firstdayweek = new Date(
          now.setDate(now.getDate() - now.getDay())
        );
        const lastdayweek = new Date(
          now.setDate(now.getDate() - now.getDay() + 6)
        );
        getTickets({
          initial: firstdayweek.toDateInputValue(),
          final: lastdayweek.toDateInputValue(),
        });
        break;
      case 1:
        getTickets({
          initial: new Date(new Date().getFullYear(), 0, 1).toDateInputValue(),
          final: new Date(new Date().getFullYear(), 11, 31).toDateInputValue(),
        });
        break;
      case 2:
        getTickets({
          initial: new Date(2020, 0, 1).toDateInputValue(),
          final: new Date(2030, 11, 31).toDateInputValue(),
        });
        break;
      default:
        break;
    }
  }
  React.useEffect(() => {
    handleSelection();
  }, [selected]);
  React.useEffect(() => {
    const arrayaccept = tickets.filter((x) => x.status === "FECHADO");
    const ticketswallets = [];

    arrayaccept.map((ticketaccept) => {
      if (!ticketaccept.client.wallet) {
        ticketaccept.client.wallet = {
          idwallets: 0,
          name: "Sem Carteira",
        };
      }
      const index = ticketswallets.findIndex((z) => {
        return z.wallet.idwallets === ticketaccept.client.wallet.idwallets;
      });
      if (index === -1) {
        ticketswallets.push({
          wallet: ticketaccept.client.wallet,
          tickets: [ticketaccept],
        });
      } else {
        ticketswallets[index].tickets.push(ticketaccept);
      }
    });
    ticketsOrganize(ticketswallets);
  }, [tickets]);
  // useEffect(() => {
  //   socket.on(`${user.company.prefix}_updateticket`, () => {
  //     handleSelection();
  //   });
  // }, []);
  return (
    <>
      {showShowSelectPeriod && (
        <SelectPeriod
          close={() => {
            setShowShowSelectPeriod(false);
          }}
          func={dateFilter}
        />
      )}
      <AttendantTicketGraphic>
        <div className="titleperiod">
          {" "}
          <h3>{t("DB.charts.walletstime.title")}</h3>
          <div>
            <span
              style={{ textDecoration: selected === 2 ? "none" : "underline" }}
              onClick={() => {
                setSelected(2);
              }}
            >
              {t("DB.charts.period.year")}
            </span>
            <span
              style={{ textDecoration: selected === 1 ? "none" : "underline" }}
              onClick={() => {
                setSelected(1);
              }}
            >
              {t("DB.charts.period.month")}
            </span>
            <span
              style={{ textDecoration: selected === 0 ? "none" : "underline" }}
              onClick={() => {
                setSelected(0);
              }}
            >
              {t("DB.charts.period.week")}
            </span>
            <DateRangeIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelected(3);
                setShowShowSelectPeriod(true);
              }}
            />
          </div>
        </div>
        <Divider />
        <div>
          {showloading && (
            <div className="mask">
              <CircularProgress />
            </div>
          )}
          <Line data={data} options={options} />
        </div>
      </AttendantTicketGraphic>
    </>
  );
}
