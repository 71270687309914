import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { makeStyles } from "@material-ui/core/styles";

import api from "../../../services/axios";

import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      minWidth: "65ch",
      justifyContent: "center",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    background: "#34b7f1",
    color: "#f2f2f2",
    marginLeft: "5px",
    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
  colorPrimary: {
    backgroundColor: "rgb(0, 191, 165)",
  },
  barColorPrimary: {
    backgroundColor: "#B2DFDB",
  },
}));
export default function MessagesLogs({ data, message, cancel }) {
  const { t } = useTranslation();
  const [logs, setLogs] = React.useState([]);
  const classes = useStyles();

  const handleClose = () => {
    cancel();
  };
  function updateLog() {
    setLogs([...data]);
  }
  async function sendMessages() {
    const clients = [];
    data.map((client) => {
      client.loading = true;
      clients.push(client);
      api
        .post(
          "defaultmessages/sendmessages",
          {
            client: client,
            message: message,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          client.loading = false;
          updateLog();
        })
        .catch((err) => {
          console.log(err);
          client.loading = false;
          client.falied = true;
          updateLog();
        });
    });
    setLogs([...clients]);
  }
  useEffect(() => {
    sendMessages();
  }, []);
  return (
    <div>
      <Dialog
        open
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Logs de envio de mensagens
        </DialogTitle>
        <DialogContent>
          <List className={classes.root}>
            {!!logs.length &&
              logs.map((value, index) => {
                return (
                  <ListItem key={value.idclient} role={undefined} dense button>
                    <ListItemIcon>
                      {value.loading ? (
                        <CircularProgress
                          style={{ width: "24px", height: "24px" }}
                        />
                      ) : value.falied ? (
                        <ErrorIcon />
                      ) : (
                        <CheckCircleIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      id={value.idclient}
                      primary={`Enviando mensagem para ${value.number}...`}
                    />
                  </ListItem>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose} autoFocus>
            <Button variant="contained" className={classes.button}>
              Fechar
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
