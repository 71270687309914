import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #f7f9fa;
  }
    &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
      }
      &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.08);
      }
`;

export const Page = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0 255 55 / 50%);
  font-family: Roboto, sans-serif;
  .logo-head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 200px !important;
    }
  }
  .footer {
    margin-top: 30px;
    font-size: 15;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .social {
      cursor: pointer;
      width: 30%;
      display: flex;
      justify-content: space-around;
      a {
        color: #1eae98;
      }
    }
  }
  .full-width {
    width: 100% !important;
  }
  .term-area {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .term-check {
    width: auto !important;
    margin-right: 10px;
  }
  .term-title {
    color: #1dad97;
    margin-left: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  .term-text {
    width: 100%;
    height: 150px;
    overflow: scroll;
    text-align: center;
    border: 1px solid gray;
  }
  .wrapper {
    margin: 10% auto;
    max-width: 680px;
  }
  .card {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #fff;
  }
  .card-4 {
    background: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    margin-top: -40px;
  }
  .card-4 img {
    width: 250px;
    margin-top: 20px;
    align-self: center;
  }

  @media (max-width: 767px) {
    .card-body {
      padding: 50px 40px;
    }
  }
  .card-body {
    padding: 57px 65px;
    padding-bottom: 65px;
  }
  .title {
    font-size: 24px;
    color: #525252;
    font-weight: 400;
    margin-bottom: 40px;
    text-align: center;
  }

  .col-2 {
    width: -webkit-calc((100% - 30px) / 2);
    width: -moz-calc((100% - 30px) / 2);
    width: calc((100% - 30px) / 2);
  }
  .p-t-10 {
    padding-top: 4px;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    .col-2 {
      width: 100%;
    }
  }
  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .row-space {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  input {
    outline: none;
    margin: 0;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
    font-size: 14px;
    font-family: inherit;
  }
  .input--style-4 {
    width: 92%;
    line-height: 50px;
    background: #fafafa;
    -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 0 20px;
    font-size: 16px;
    color: #666;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .input--style-4::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #666;
  }

  .input--style-4:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #666;
    opacity: 1;
  }

  .input--style-4::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #666;
    opacity: 1;
  }

  .input--style-4:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #666;
  }
  .input--style-4:-ms-input-placeholder {
    /* Microsoft Edge */
    color: #666;
  }

  .label {
    font-size: 16px;
    color: #555;
    text-transform: capitalize;
    display: block;
    margin-bottom: 5px;
  }

  .radio-container {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 16px;
    color: #666;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  }
  .m-r-45 {
    margin-bottom: 5px;
    margin-right: 45px;
  }

  .radio-container input:checked ~ .checkmark {
    background-color: #e5e5e5;
  }

  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }

  .radio-container .checkmark:after {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #1dad97;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #e5e5e5;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .input-group {
    position: relative;
    margin-bottom: 22px;
  }

  .input-group-icon {
    position: relative;
  }

  .input-icon {
    position: absolute;
    font-size: 18px;
    color: #999;
    right: 18px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
  }
  .p-t-15 {
    padding-top: 15px;
    display: flex;
    justify-content: center;
  }
  .btn {
    display: inline-block;
    line-height: 50px;
    padding: 0 50px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
  }

  .error-message {
    box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
    background-color: #fff6f6;
    color: #9f3a38;
    min-height: 1em;
    margin: 1em 0;
    background: #f8f8f9;
    padding: 1em 1.5em;
    transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease,
      box-shadow 0.1s ease;
    border-radius: 5px;
  }
  .btn--radius-2 {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  .btn--green {
    background: #1dad97;
  }

  .btn--green:hover {
    background: #4dae3c;
  }
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  .w3-animate-top {
    position: relative;
    animation: animatetop 0.4s;
  }
`;
