import React from "react";
import { useSelector } from "react-redux";
import PanelLeft from "./PanelLeft/index";
import Info from "./PanelRight/ActiveChat/Info/index";
import { GlobalStyle, Page } from "./style";
import PanelRight from "./PanelRight/index";

export default function Home({ data, setData }) {
  const displayclientinfo = useSelector(
    (state) => state.panelRight.displayclientinfofilter
  );

  return (
    <Page displayclientinfo={displayclientinfo}>
      <div className="panel-left">
        <PanelLeft data={data} setData={setData} />
      </div>
      <div className="panel-right">
        <PanelRight {...data} />
      </div>
      {displayclientinfo && (
        <div className="panel-tree">
          <Info {...data} />
        </div>
      )}

      <GlobalStyle />
    </Page>
  );
}
