import React from "react";
import { Page } from "./style";
import Chats from "./Chats/index";

export default function Initial({ data, setData }) {
  return (
    <Page>
      <Chats data={data} setData={setData} />
    </Page>
  );
}
