import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Page } from "./style";
import Header from "./Header";
import Content from "./Content";
import Locked from "./locked";

export default function Initial() {
  return (
    <>
      <Page>
        <div className="YUoyu" data-asset-chat-background="true" />
        <Header />
        <Content />
        <Locked />
      </Page>
    </>
  );
}
