import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  .head {
    margin-left: 5px;
  }
`;
