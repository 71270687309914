import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ForumIcon from "@material-ui/icons/Forum";
import TextsmsIcon from "@material-ui/icons/Textsms";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PeopleIcon from "@material-ui/icons/People";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { Route } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import RateReviewIcon from "@material-ui/icons/RateReview";
import SyncIcon from "@material-ui/icons/Sync";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import TranslateIcon from "@material-ui/icons/Translate";
import { useTranslation } from "react-i18next";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import Collapse from "@material-ui/core/Collapse";
import AndroidIcon from "@material-ui/icons/Android";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import ContactsIcon from "@material-ui/icons/Contacts";
import CalendarToday from "@material-ui/icons/CalendarToday";
import { Page } from "./style";
import Home from "../Home/index";
import ClientsTable from "../ClientsTable";
import UsersTable from "../UsersTable";
import RequestTable from "../RequestsTable/index";
import SectorsTable from "../SectorsTable/index";
import WalletsTable from "../WalletsTable";
import SettingsPage from "../SettingsPage";
import Dashboard from "../Dashboard";
import Filter from "../FilterChats";
import Can from "../../components/Can";
import PrivilegeTable from "../PrivilegeTable";
import RolesTable from "../RolesTable";
import DefaultMessagesTable from "../DefaultMessagesTable";
import MessagesShortcutTable from "../MessagesShortcutTable";
import NewMessageNotification from "../../components/NewMessageNotification";
import NewTicketNotification from "../../components/NewTicketNotification";
import NewRequestNotification from "../../components/NewRequestNotification";
import Language from "./dialogs/language";
import RepresentativesTable from "../RepresentativesTable";
import api from "../../services/axios";
import SyncPage from "../SyncPage";
import ChatBot from "../ChatBot";
import socket from "../../services/socket";
import Contacts from "../Contacts";
import { useDispatch } from "react-redux";
//import Agenda from "../Agenda";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "6px !important",
      height: "6px !important",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    "*::-webkit-scrollbar-track": {
      background: "rgba(255, 255, 255, 0.08)",
    },
  },
  root: {
    display: "flex",
    "MuiListItem-root": {
      "Mui-selected": {
        backgroundColor: "red",
      },
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    position: "relative",
    paddingTop: "64px",
  },
  sectionDesktop: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  grow: {
    flexGrow: 1,
  },
}));

export default function MiniDrawer(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const user = JSON.parse(localStorage.user);
  const theme = useTheme();
  const [status, setStatus] = React.useState("");
  const [selected, setSelected] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openeettings, setOpeneettings] = React.useState(false);
  const [showlanguage, setShowlanguage] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const logout = async () => {
    handleMenuClose();
    localStorage.clear();
    socket.emit("client_disconnected", {
      id: socket.id,
    });
    dispatch({
      type: "RESET_CHAT",
    });
    dispatch({
      type: "RESET_CHAT_HISTORY",
    });
    props.history.push("/");
  };
  const getStatusData = () => {
    api
      .get("/whatsapp/status", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        if (!response.data) {
          setStatus("");
        }
        setStatus(response.data);
      })
      .catch((e) => console.log(e));
  };
  const getStatus = () => {
    switch (status.status) {
      case 0:
        return { name: "INATIVO", color: "gray" };
      case 1:
        return { name: "CONECTADO", color: "green" };

      default:
        return { name: "DESCONECTADO", color: "red" };
    }
  };
  React.useEffect(() => {
    getStatusData();
  }, []);
  React.useEffect(() => {
    socket.on(`${user.company.prefix}_whatsappconencted`, () => {
      getStatusData();
    });
    socket.on(`${user.company.prefix}_whatsappdisconnected`, () => {
      getStatusData();
    });
  }, []);
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="primary-search-account-menu"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Perfil</MenuItem>
      <MenuItem onClick={logout}>Sair</MenuItem>
    </Menu>
  );
  return (
    <>
      {showlanguage && (
        <Language
          func={() => {
            setShowlanguage(false);
          }}
        />
      )}
      <NewMessageNotification />
      <NewTicketNotification />
      <NewRequestNotification />
      <Page className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          style={{ background: "#00bfa5" }}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              PipeGo
            </Typography>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Typography
                variant="h6"
                noWrap
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  style={{
                    marginRight: "10px",
                    backgroundColor: "#ffffff",
                    padding: "5px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "5px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelected(12);
                    props.history.push("/home/settings");
                  }}
                >
                  <WhatsAppIcon style={{ color: getStatus().color }} />
                  &nbsp;
                  <span style={{ color: "#34b7f1" }}>{getStatus().name}</span>
                </div>
                <div
                  style={{
                    marginRight: "10px",
                    backgroundColor: "#34b7f1",
                    padding: "5px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "5px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  <span>{JSON.parse(localStorage.user).role.name}</span>
                </div>{" "}
                {JSON.parse(localStorage.user).username}
              </Typography>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <Can
              role={JSON.parse(localStorage.user).role.idroles}
              type="dashboard"
              action="read"
              yes={() => (
                <Tooltip title={t("NV.dashboard")}>
                  <ListItem
                    button
                    key={t("NV.dashboard")}
                    selected={selected === 0}
                    onClick={() => {
                      setSelected(0);
                      props.history.push("/home/dashboard");
                    }}
                  >
                    <ListItemIcon>
                      <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("NV.dashboard")} />
                  </ListItem>
                </Tooltip>
              )}
            />
            <Can
              role={JSON.parse(localStorage.user).role.idroles}
              type="requests"
              action="read"
              yes={() => (
                <Tooltip title={t("NV.requests")}>
                  <ListItem
                    button
                    key={t("NV.requests")}
                    onClick={() => {
                      setSelected(1);
                      props.history.push("/home/requests");
                    }}
                    selected={selected === 1}
                  >
                    <ListItemIcon>
                      <NotificationsActiveIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("NV.requests")} />
                  </ListItem>
                </Tooltip>
              )}
            />
            {/* <Can
              role={JSON.parse(localStorage.user).role.idroles}
              type="defaultmessages"
              action="read"
              yes={() => (
                <Tooltip title={t("NV.defaultmessages")}>
                  <ListItem
                    button
                    key={t("NV.defaultmessages")}
                    onClick={() => {
                      setSelected(2);
                      props.history.push("/home/messagesdefault");
                    }}
                    selected={selected === 2}
                  >
                    <ListItemIcon>
                      <RateReviewIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("NV.defaultmessages")} />
                  </ListItem>
                </Tooltip>
              )}
            /> */}
            <Tooltip title="Atalho de Mensagens">
              <ListItem
                button
                key="Atalho de Mensagens"
                onClick={() => {
                  setSelected(3);
                  props.history.push("/home/shortcut");
                }}
                selected={selected === 3}
              >
                <ListItemIcon>
                  <ReplyAllIcon />
                </ListItemIcon>
                <ListItemText primary="Atalho de Mensagens" />
              </ListItem>
            </Tooltip>
            <Can
              role={JSON.parse(localStorage.user).role.idroles}
              type="users"
              action="read"
              yes={() => (
                <Tooltip title={t("NV.users")}>
                  <ListItem
                    button
                    key={t("NV.users")}
                    onClick={() => {
                      setSelected(4);
                      props.history.push("/home/users");
                    }}
                    selected={selected === 4}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("NV.users")} />
                  </ListItem>
                </Tooltip>
              )}
            />
            <Can
              role={JSON.parse(localStorage.user).role.idroles}
              type="sectors"
              action="read"
              yes={() => (
                <Tooltip title={t("NV.sectors")}>
                  <ListItem
                    button
                    key={t("NV.sectors")}
                    onClick={() => {
                      setSelected(5);
                      props.history.push("/home/sectors");
                    }}
                    selected={selected === 5}
                  >
                    <ListItemIcon>
                      <AccountTreeIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("NV.sectors")} />
                  </ListItem>
                </Tooltip>
              )}
            />
            <Can
              role={JSON.parse(localStorage.user).role.idroles}
              type="clients"
              action="read"
              yes={() => (
                <Tooltip title={t("NV.clients")}>
                  <ListItem
                    button
                    key={t("NV.clients")}
                    onClick={() => {
                      setSelected(6);
                      props.history.push("/home/clients");
                    }}
                    selected={selected === 6}
                  >
                    <ListItemIcon>
                      <GroupAddIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("NV.clients")} />
                  </ListItem>
                </Tooltip>
              )}
            />
            <Can
              role={JSON.parse(localStorage.user).role.idroles}
              type="chats"
              action="read"
              yes={() => (
                <Tooltip title={t("NV.chats")}>
                  <ListItem
                    button
                    key={t("NV.chats")}
                    onClick={() => {
                      setSelected(7);
                      props.history.push("/home");
                    }}
                    selected={selected === 7}
                  >
                    <ListItemIcon>
                      <ForumIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("NV.chats")} />
                  </ListItem>
                </Tooltip>
              )}
            />
            <Can
              role={JSON.parse(localStorage.user).role.idroles}
              type="wallets"
              action="read"
              yes={() => (
                <Tooltip title={t("NV.wallets")}>
                  <ListItem
                    button
                    key={t("NV.wallets")}
                    onClick={() => {
                      setSelected(8);
                      props.history.push("/home/wallets");
                    }}
                    selected={selected === 8}
                  >
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("NV.wallets")} />
                  </ListItem>
                </Tooltip>
              )}
            />
            <Can
              role={JSON.parse(localStorage.user).role.idroles}
              type="filters"
              action="read"
              yes={() => (
                <Tooltip title={t("NV.chatfilter")}>
                  <ListItem
                    button
                    key={t("NV.chatfilter")}
                    onClick={() => {
                      setSelected(9);
                      props.history.push("/home/filter");
                    }}
                    selected={selected === 9}
                  >
                    <ListItemIcon>
                      <TextsmsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("NV.chatfilter")} />
                  </ListItem>
                </Tooltip>
              )}
            />
            <Can
              role={JSON.parse(localStorage.user).role.idroles}
              type="representatives"
              action="read"
              yes={() => (
                <Tooltip title={t("NV.representatives")}>
                  <ListItem
                    button
                    key={t("NV.representatives")}
                    onClick={() => {
                      setSelected(10);
                      props.history.push("/home/representatives");
                    }}
                    selected={selected === 10}
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("NV.representatives")} />
                  </ListItem>
                </Tooltip>
              )}
            />
            <Can
              role={JSON.parse(localStorage.user).role.idroles}
              type="roles"
              action="read"
              yes={() => (
                <Tooltip title={t("NV.profiles")}>
                  <ListItem
                    button
                    key={t("NV.profiles")}
                    onClick={() => {
                      setSelected(11);
                      props.history.push("/home/roles");
                    }}
                    selected={selected === 11}
                  >
                    <ListItemIcon>
                      <AssignmentIndIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("NV.profiles")} />
                  </ListItem>
                </Tooltip>
              )}
            />
            <Can
              role={JSON.parse(localStorage.user).role.idroles}
              type="privileges"
              action="read"
              yes={() => (
                <Tooltip title={t("NV.privileges")}>
                  <ListItem
                    button
                    key={t("NV.privileges")}
                    onClick={() => {
                      setSelected(12);
                      props.history.push("/home/privileges");
                    }}
                    selected={selected === 12}
                  >
                    <ListItemIcon>
                      <VpnKeyIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("NV.privileges")} />
                  </ListItem>
                </Tooltip>
              )}
            />
            <Can
              role={JSON.parse(localStorage.user).role.idroles}
              type="settings"
              action="read"
              yes={() => (
                <>
                  <Tooltip title={t("NV.settings")}>
                    <ListItem
                      button
                      key={t("NV.settings")}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpeneettings(!openeettings);
                        setOpen(true);
                      }}
                      selected={selected === 13}
                    >
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("NV.settings")} />
                      {openeettings ? (
                        <ExpandLess
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpeneettings(!openeettings);
                          }}
                        />
                      ) : (
                        <ExpandMore
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpeneettings(!openeettings);
                          }}
                        />
                      )}
                    </ListItem>
                  </Tooltip>
                  <Collapse in={openeettings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() => {
                          setSelected(13);
                          props.history.push("/home/settings");
                        }}
                      >
                        <ListItemIcon>
                          <WhatsAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Conexão" />
                      </ListItem>{" "}
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() => {
                          setSelected(13);
                          props.history.push("/home/contacts");
                        }}
                      >
                        <ListItemIcon>
                          <ContactsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Importar Contatos" />
                      </ListItem>{" "}
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() => {
                          setSelected(13);
                          props.history.push("/home/sync");
                        }}
                      >
                        <ListItemIcon>
                          <SyncIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sincronização" />
                      </ListItem>{" "}
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() => {
                          setSelected(13);
                          props.history.push("/home/chatbot");
                        }}
                      >
                        <ListItemIcon>
                          <AndroidIcon />
                        </ListItemIcon>
                        <ListItemText primary="ChatBot" />
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )}
            />
            <Tooltip title={t("NV.language")}>
              <ListItem
                button
                key={t("NV.language")}
                onClick={() => {
                  setSelected(13);
                  setShowlanguage(true);
                }}
                selected={selected === 14}
              >
                <ListItemIcon>
                  <TranslateIcon />
                </ListItemIcon>
                <ListItemText primary={t("NV.language")} />
              </ListItem>
            </Tooltip>
            {/* <Tooltip title={t("Agenda")}>
              <ListItem
                button
                key={t("Agenda")}
                onClick={() => {
                  setSelected(14);
                  props.history.push("/home/agenda");
                }}
                selected={selected === 14}
              >
                <ListItemIcon>
                  <CalendarToday />
                </ListItemIcon>
                <ListItemText primary={t("Agenda")} />
              </ListItem>
            </Tooltip> */}
          </List>
          <Divider />
          <List>
            <Tooltip title={t("NV.exit")}>
              <ListItem
                button
                key={t("NV.exit")}
                onClick={logout}
                selected={selected === 15}
              >
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={t("NV.exit")} />
              </ListItem>
            </Tooltip>
          </List>
        </Drawer>
        <main className={classes.content}>
          <Route exact path="/home" component={Home} />
          <Route exact path="/home/number/:number" component={Home} />
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="clients"
            action="read"
            yes={() => (
              <Route exact path="/home/clients" component={ClientsTable} />
            )}
          />
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="representatives"
            action="read"
            yes={() => (
              <Route
                exact
                path="/home/representatives"
                component={RepresentativesTable}
              />
            )}
          />
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="requests"
            action="read"
            yes={() => (
              <Route exact path="/home/requests" component={RequestTable} />
            )}
          />
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="wallets"
            action="read"
            yes={() => (
              <Route exact path="/home/wallets" component={WalletsTable} />
            )}
          />
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="users"
            action="read"
            yes={() => (
              <Route exact path="/home/users" component={UsersTable} />
            )}
          />
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="dashboard"
            action="read"
            yes={() => (
              <Route exact path="/home/dashboard" component={Dashboard} />
            )}
          />
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="filters"
            action="read"
            yes={() => <Route exact path="/home/filter" component={Filter} />}
          />
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="sectors"
            action="read"
            yes={() => (
              <Route exact path="/home/sectors" component={SectorsTable} />
            )}
          />
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="privileges"
            action="read"
            yes={() => (
              <Route exact path="/home/privileges" component={PrivilegeTable} />
            )}
          />
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="roles"
            action="read"
            yes={() => (
              <Route exact path="/home/roles" component={RolesTable} />
            )}
          />

          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="settings"
            action="read"
            yes={() => (
              <>
                <Route exact path="/home/settings" component={SettingsPage} />
                <Route exact path="/home/contacts" component={Contacts} />
                <Route exact path="/home/sync" component={SyncPage} />
                <Route exact path="/home/chatbot" component={ChatBot} />
              </>
            )}
          />
          {/* <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="defaultmessages"
            action="read"
            yes={() => (
              <Route
                exact
                path="/home/messagesdefault"
                component={DefaultMessagesTable}
              />
            )}
          /> */}
          <Route
            exact
            path="/home/shortcut"
            component={MessagesShortcutTable}
          />
          {/* <Route exact path="/home/agenda" component={Agenda} /> */}
          {/* <Route exact path="/home/*" component={InitialPage} /> */}
        </main>
        {renderMenu}
      </Page>
    </>
  );
}
