import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from "react-i18next";
import Deletesector from "./Dialogs/DeleteSector";
import DeleteSubSector from "./Dialogs/DeleteSubSector";
import DeleteSubSectorDDD from "./Dialogs/DeleteSubSectorDDD";
import EditSector from "./Dialogs/EditSector";
import EditSubSector from "./Dialogs/EditSubSector";
import EditSubSectorDDD from "./Dialogs/EditSubSectorDDD";
import api from "../../services/axios";
import NewSector from "./Dialogs/NewSector";
import NewSubSector from "./Dialogs/NewSubSector";
import NewSubSectorDDD from "./Dialogs/NewSubSectorDDD";
import Can from "../../components/Can";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [opensector, setOpensector] = React.useState(false);
  const [opensubsector, setOpensubsector] = React.useState(false);
  const [opensubsectorddd, setOpensubsectorddd] = React.useState(false);
  const [openeditsector, setOpeneditsector] = React.useState(false);
  const [openeditsubsector, setOpeneditsubsector] = React.useState(false);
  const [openeditsubsectorddd, setOpeneditsubsectorddd] = React.useState(false);
  const [opendeletesubsector, setOpendeletesubsector] = React.useState(false);
  const [opendeletesubsectorddd, setOpendeletesubsectorddd] = React.useState(
    false
  );
  const [sectors, setSectors] = React.useState([]);
  const [deletesector, setDeletesector] = React.useState("");
  const [subsectorselected, setSubsectorselected] = React.useState("");
  const [subsectordddselected, setSubsectordddselected] = React.useState("");
  const [editsector, setEditsector] = React.useState("");
  const classes = useStyles();
  const updateData = async () => {
    await api
      .get("sectors", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setSectors([...res.data]);
      });
  };

  useEffect(() => {
    updateData();
  }, []);

  const options = {
    filterType: false,
    print: false,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    filter: false,
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: t("ST.table.noMatch"),
        toolTip: "Sort",

        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        rowsPerPage: "Linhas por Página",
      },
    },
    expandableRows: true,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const sector = sectors[rowMeta.dataIndex];
      return (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <MUIDataTable
              title=""
              data={
                sector.sub_sectors.length
                  ? sector.sub_sectors.map((subsector, index) => [
                      subsector.idsubsectors,
                      subsector.name,
                      <Can
                        role={JSON.parse(localStorage.user).role.idroles}
                        type="subsectors"
                        action="update"
                        yes={() => (
                          <EditIcon
                            style={{ fill: "#34b7f1" }}
                            onClick={() => {
                              setOpeneditsubsector(true);
                              setSubsectorselected(subsector);
                            }}
                          />
                        )}
                      />,
                      <Can
                        role={JSON.parse(localStorage.user).role.idroles}
                        type="subsectors"
                        action="delete"
                        yes={() => (
                          <RemoveCircleOutlineIcon
                            style={{ fill: "#34b7f1" }}
                            onClick={() => {
                              setOpendeletesubsector(true);
                              setSubsectorselected(subsector);
                            }}
                          />
                        )}
                      />,
                      <Can
                        role={JSON.parse(localStorage.user).role.idroles}
                        type="subsectors"
                        action="create"
                        yes={() => (
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.button}
                            startIcon={<AddCircleIcon />}
                            onClick={(ev) => {
                              ev.stopPropagation();
                              setSubsectorselected(subsector);
                              setOpensubsectorddd(true);
                            }}
                          >
                            {t("ST.bt3")}
                          </Button>
                        )}
                      />,
                    ])
                  : []
              }
              columns={columnsexpand}
              options={optionstwo}
            />
          </TableCell>
        </TableRow>
      );
    },
  };
  const optionstree = {
    filterType: false,
    print: false,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    filter: false,
    selectableRows: "none",
    search: false,
    pagination: false,
    customToolbar: null,
  };
  const optionstwo = {
    filterType: false,
    print: false,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    filter: false,
    selectableRows: "none",
    search: false,
    pagination: false,
    customToolbar: null,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      let sector;
      sectors.map((x) => {
        x.sub_sectors.map((y) => {
          if (y.idsubsectors === rowData[0]) {
            sector = y;
          }
          return y;
        });
        return x;
      });
      return (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <MUIDataTable
              title=""
              data={
                sector?.sub_sectors_ddds.length
                  ? sector.sub_sectors_ddds.map((subsectorddd, index) => [
                      subsectorddd.idsub_sectors_ddd,
                      subsectorddd.cod,
                      <Can
                        role={JSON.parse(localStorage.user).role.idroles}
                        type="subsectors"
                        action="update"
                        yes={() => (
                          <EditIcon
                            style={{ fill: "#34b7f1" }}
                            onClick={() => {
                              setSubsectordddselected(subsectorddd);
                              setOpeneditsubsectorddd(true);
                            }}
                          />
                        )}
                      />,
                      <Can
                        role={JSON.parse(localStorage.user).role.idroles}
                        type="subsectors"
                        action="delete"
                        yes={() => (
                          <RemoveCircleOutlineIcon
                            style={{ fill: "#34b7f1" }}
                            onClick={() => {
                              setSubsectordddselected(subsectorddd);
                              setOpendeletesubsectorddd(true);
                            }}
                          />
                        )}
                      />,
                    ])
                  : []
              }
              columns={columnsexpandsub}
              options={optionstree}
            />
          </TableCell>
        </TableRow>
      );
    },
    textLabels: {
      body: {
        noMatch: t("ST.table.noMatch"),
      },
    },
  };
  const columns = [
    "ID",
    t("ST.table.name"),
    t("ST.table.edit"),
    t("ST.table.delete"),
    "",
  ];
  const columnsexpand = [
    "ID",
    t("ST.tableexpand.name"),
    t("ST.tableexpand.edit"),
    t("ST.tableexpand.delete"),
    "",
  ];
  const columnsexpandsub = [
    "ID",
    t("ST.tableexpandsub.name"),
    t("ST.tableexpand.edit"),
    t("ST.tableexpand.delete"),
  ];
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "auto",
      }}
    >
      <Deletesector
        open={open}
        data={deletesector}
        func={setOpen}
        update={updateData}
      />
      <DeleteSubSector
        open={opendeletesubsector}
        data={subsectorselected}
        func={setOpendeletesubsector}
        update={updateData}
      />
      <DeleteSubSectorDDD
        open={opendeletesubsectorddd}
        data={subsectordddselected}
        func={setOpendeletesubsectorddd}
        update={updateData}
      />
      <NewSector open={opensector} func={setOpensector} update={updateData} />
      <NewSubSector
        open={opensubsector}
        func={setOpensubsector}
        update={updateData}
        data={deletesector}
      />
      <NewSubSectorDDD
        open={opensubsectorddd}
        func={setOpensubsectorddd}
        update={updateData}
        data={subsectorselected}
      />
      <EditSector
        open={openeditsector}
        data={editsector}
        func={setOpeneditsector}
        update={updateData}
      />
      <EditSubSector
        open={openeditsubsector}
        data={subsectorselected}
        func={setOpeneditsubsector}
        update={updateData}
      />
      <EditSubSectorDDD
        open={openeditsubsectorddd}
        data={subsectordddselected}
        func={setOpeneditsubsectorddd}
        update={updateData}
      />
      <MUIDataTable
        title={
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="sectors"
            action="create"
            yes={() => (
              <Button
                onClick={() => {
                  setOpensector(true);
                }}
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<AddCircleIcon />}
              >
                {t("ST.bt1")}
              </Button>
            )}
          />
        }
        data={sectors.map((sector, index) => [
          sector.idsectors,
          sector.name,
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="sectors"
            action="update"
            yes={() => (
              <EditIcon
                style={{ fill: "#34b7f1" }}
                onClick={() => {
                  setOpeneditsector(true);
                  setEditsector(sectors[index]);
                }}
              />
            )}
          />,
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="sectors"
            action="delete"
            yes={() => (
              <RemoveCircleOutlineIcon
                style={{ fill: "#34b7f1" }}
                onClick={() => {
                  setOpen(true);
                  setDeletesector(sectors[index]);
                }}
              />
            )}
          />,
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="subsectors"
            action="create"
            yes={() => (
              <Button
                onClick={(ev) => {
                  ev.stopPropagation();
                  setOpensubsector(true);
                  setDeletesector(sectors[index]);
                }}
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<AddCircleIcon />}
              >
                {t("ST.bt2")}
              </Button>
            )}
          />,
        ])}
        columns={columns}
        options={options}
      />
    </div>
  );
};
