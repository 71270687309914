import React, { useState } from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ImportContactsConfirmation from "../Dialogs/ImportContactsConfirmation";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",
    width: "250px",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
  root: {
    display: "flex",
    alignItems: "end",
    justifyContent: "flex-end",
    paddingRight: "8%",
  },
}));
export function CustomFooter(props) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <>
      <TableFooter>
        <TableRow>
          <TableCell>
            <Button
              onClick={() => {
                setOpenDialog(true);
              }}
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
            >
              {`Importar Contatos : ${props.count}`}
            </Button>
          </TableCell>

          <TableCell>
            <TablePagination
              className={classes.root}
              count={props.count}
              page={props.page}
              rowsPerPage={props.rowsPerPage}
              onChangePage={props.onChangePage}
              onChangeRowsPerPage={props.onChangeRowsPerPage}
            />
          </TableCell>
        </TableRow>
      </TableFooter>
      {openDialog && (
        <ImportContactsConfirmation
          cancel={() => {
            setOpenDialog(false);
          }}
          data={props.data}
        />
      )}
    </>
  );
}
