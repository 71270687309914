import React from "react";
import DialogShowMedia from "../Dialog/DialogShowMedia";

export default function ({ message }) {
  const [showmedia, setShowmedia] = React.useState(false);
  return (
    <>
      {showmedia && (
        <DialogShowMedia
          close={() => {
            setShowmedia(false);
          }}
          mediasrc={`${process.env.REACT_APP_API_URL}${message.media}`}
        />
      )}

      <div className="message">
        <div
          className={`_3_7SH _3qMSo message-${
            message.client_message > 0 ? "in" : "out"
          }`}
        >
          <div className="KYpDv">
            <div>
              <div
                className="_3v3PK"
                style={{ width: "330px", height: "330px" }}
              >
                {/* <div className="_34Olu">
                <div className="_2BzIU">
                  <div className="_2X3l6">
                    <svg
                      className="_1UDDE"
                      width="50"
                      height="50"
                      viewBox="0 0 43 43"
                    >
                      <circle
                        className="_3GbTq _2wGBy"
                        cx="21.5"
                        cy="21.5"
                        r="20"
                        fill="none"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                  <div className="_1l3ap">
                    <span data-icon="media-disabled" className="">
                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 44 44"
                        width="44"
                        height="44"
                      >
                        <path
                          fill="#FFF"
                          fillOpacity=".4"
                          d="M29.377 16.099l-1.475-1.475L22 20.525l-5.901-5.901-1.476 1.475L20.525 22l-5.901 5.901 1.476 1.475 5.9-5.901 5.901 5.901 1.475-1.475L23.475 22l5.902-5.901z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div> */}
                <img
                  src={`${process.env.REACT_APP_API_URL}${message.media}`}
                  className="_1JVSX message-photo"
                  style={{ width: "100%" }}
                  alt=""
                  onClick={() => {
                    setShowmedia(true);
                  }}
                />
                <div className="_1i3Za" />
              </div>
              <div className="message-container-legend">
                <div className="_3zb-j ZhF0n">
                  <span
                    dir="ltr"
                    className="selectable-text invisible-space copyable-text message-text"
                  >
                    {message.content}
                  </span>
                </div>
              </div>
              <div className="_2TvOE">
                <div className="_1DZAH text-white" role="button">
                  <span className="message-time">
                    {" "}
                    {new Date(message.timestamp).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                  <div className="message-status">
                    <span data-icon="msg-check-light">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 15"
                        width="16"
                        height="15"
                      >
                        <path
                          fill="#FFF"
                          d="M10.91 3.316l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="_3S8Q-" role="button">
          <span data-icon="forward-chat">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25 25"
              width="25"
              height="25"
            >
              <path
                fill="#FFF"
                d="M14.2 9.5V6.1l5.9 5.9-5.9 6v-3.5c-4.2 0-7.2 1.4-9.3 4.3.8-4.2 3.4-8.4 9.3-9.3z"
              />
            </svg>
          </span>
        </div> */}
        </div>
      </div>
    </>
  );
}
