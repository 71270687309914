import React from "react";
import { useSelector } from "react-redux";
import { Page } from "./style";
import Message from "./Message";
import socket from "../../../../../services/socket";

export default function Initial({ messages, setMessages }) {
  const user = JSON.parse(localStorage.user);
  const client = useSelector((state) => state.panelRight.userchat);
  React.useEffect(() => {
    setTimeout(() => {
      const section = document.getElementById("panel-messages-container");
      if (section) {
        section.scrollTop = section.scrollHeight;
      }
    }, 500);
  }, [messages]);
  return (
    <Page>
      <div className="copyable-area">
        <div className="panel-down panel-main" id="panel-document-preview">
          <span className="_3rsak">
            <div tabIndex="-1">
              <div className="_2fq0t _1drQ- copyable-area">
                <div className="_3TSht" style={{ display: "none" }} />
                <header className="wDFKR">
                  <div className="Ghmsz" data-animate-drawer-title="true">
                    <div className="SFEHG">
                      <button
                        type="button"
                        className="_1aTxu"
                        id="btn-close-panel-document-preview"
                      >
                        <span data-icon="x-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path
                              fill="#FFF"
                              d="M19.058 17.236l-5.293-5.293 5.293-5.293-1.764-1.764L12 10.178 6.707 4.885 4.942 6.649l5.293 5.293-5.293 5.293L6.707 19 12 13.707 17.293 19l1.765-1.764z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <div className="_1xGbt">Pré-visualizar</div>
                  </div>
                </header>
                <div className="_2sNbV _3ySAH" tabIndex="-1">
                  <div className="NuJ4j">
                    <span>
                      <div className="_3Mxes">
                        <div
                          id="file-panel-document-preview"
                          className="_3y0EN"
                          data-animate-attach-media="true"
                          style={{
                            opacity: "1",
                            transform: "scale(1)",
                            display: "none",
                          }}
                        >
                          <div className="_3EjgV">
                            <div className="_6Pnex">
                              <div
                                className="jcxhw icon-doc-generic"
                                id="icon-panel-document-preview"
                              />
                              <div
                                className="QA8Vz"
                                id="filename-panel-document-preview"
                              >
                                file.zip
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="image-panel-document-preview"
                          className="r4crx"
                          data-js-attach-preview="true"
                          style={{ display: "none" }}
                        >
                          <div className="_2-_Pl">
                            <div className="IuYNx _3E6Ha">
                              <div
                                style={{
                                  width: "641.263px",
                                  height: "516px",
                                }}
                              >
                                <img
                                  src="#"
                                  className="_3m_5X"
                                  id="img-panel-document-preview"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="_2GgBs"
                            id="info-panel-document-preview"
                          >
                            0 páginas
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                  <span>
                    <div
                      className="_3nfoJ"
                      tabIndex="-1"
                      style={{ transform: "scale(1)", opacity: "1" }}
                    >
                      <div
                        role="button"
                        className="_3hV1n yavlE"
                        id="btn-send-document"
                      >
                        <span data-icon="send-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 28 24"
                            width="28"
                            height="24"
                          >
                            <path
                              fill="#FFF"
                              d="M5.101 21.757L27.8 12.028 5.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </span>
                  <input
                    type="file"
                    accept="*"
                    style={{ display: "none" }}
                    id="input-document"
                  />
                </div>
              </div>
            </div>
          </span>
        </div>
        <div className="panel-down panel-main" id="panel-camera">
          <span className="_3rsak">
            <div className="_2fq0t r3Nu1 copyable-area">
              <header className="wDFKR">
                <div className="Ghmsz" data-animate-drawer-title="true">
                  <div className="SFEHG">
                    <button
                      className="_1aTxu"
                      id="btn-close-panel-camera"
                      type="button"
                    >
                      <span data-icon="x-light" className="btn-close">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            fill="#FFF"
                            d="M19.058 17.236l-5.293-5.293 5.293-5.293-1.764-1.764L12 10.178 6.707 4.885 4.942 6.649l5.293 5.293-5.293 5.293L6.707 19 12 13.707 17.293 19l1.765-1.764z"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className="_1xGbt">Tirar foto</div>
                  <div
                    className="btn-reshoot"
                    id="btn-reshoot-panel-camera"
                    style={{ display: "none" }}
                  >
                    <div className="_1VQOF">
                      <div className="_3KiSR">
                        <span data-icon="refresh-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path
                              fill="#FFF"
                              d="M17.6 6.4C16.2 4.9 14.2 4 12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8c3.7 0 6.8-2.6 7.7-6h-2.1c-.8 2.3-3 4-5.6 4-3.3 0-6-2.7-6-6s2.7-6 6-6c1.7 0 3.1.7 4.2 1.8L13 11h7V4l-2.4 2.4z"
                            />
                          </svg>
                        </span>
                      </div>
                      Tirar novamente
                    </div>
                  </div>
                </div>
              </header>
              <div className="_2sNbV _3ySAH">
                <div className="_3fJXs">
                  <div className="_1tfR8">
                    <span className="_2pEAB">
                      <div style={{ opacity: "1" }}>
                        <div className="IuYNx" style={{ textAlign: "center" }}>
                          <div className="video-container">
                            <div
                              className="_2oKVP"
                              data-animage-capture-webcam="true"
                            >
                              <track id="video-camera" className="_1Y0rl" />
                              <img
                                id="picture-camera"
                                className="_3ygW4 photo"
                                style={{ display: "none" }}
                                data-animate-capture-snapshot="true"
                                src="#"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="_3UUn3" id="container-take-picture">
                  <span className="_26WTk" />
                  <span>
                    <div
                      className="w0LJp"
                      style={{ transform: "scale(1)", opacity: "1" }}
                    >
                      <div
                        role="button"
                        className="_3hV1n yavlE"
                        id="btn-take-picture"
                      >
                        <span data-icon="camera-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path
                              fill="#FFF"
                              d="M21.317 4.381H10.971L9.078 2.45c-.246-.251-.736-.457-1.089-.457H4.905c-.352 0-.837.211-1.078.468L1.201 5.272C.96 5.529.763 6.028.763 6.38v1.878l-.002.01v11.189a1.92 1.92 0 0 0 1.921 1.921h18.634a1.92 1.92 0 0 0 1.921-1.921V6.302a1.92 1.92 0 0 0-1.92-1.921zM12.076 18.51a5.577 5.577 0 1 1 0-11.154 5.577 5.577 0 0 1 0 11.154zm0-9.506a3.929 3.929 0 1 0 0 7.858 3.929 3.929 0 0 0 0-7.858z"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
                <div
                  className="_3UUn3"
                  id="container-send-picture"
                  style={{ display: "none" }}
                >
                  <span className="_26WTk">
                    <div
                      tabIndex="-1"
                      className="TI3qN _2Mg6D"
                      style={{ opacity: "1", transform: "translateY(0px)" }}
                    >
                      <div className="_2ssn3" />
                      <div className="_17XE4">
                        <div className="_1xHCd">
                          <span className="TSSFW" />
                        </div>
                      </div>
                    </div>
                  </span>
                  <span>
                    <div
                      className="w0LJp _2-dbC"
                      style={{ transform: "scale(1)", opacity: "1" }}
                    >
                      <div
                        role="button"
                        className="_3hV1n yavlE"
                        id="btn-send-picture"
                      >
                        <span data-icon="send-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 28 24"
                            width="28"
                            height="24"
                          >
                            <path
                              fill="#FFF"
                              d="M5.101 21.757L27.8 12.028 5.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div className="messages-container" id="panel-messages-container">
          <div className="btn-reload" style={{ display: "none" }}>
            <div className="_3dGYA" title="Carregar mensagens recentes">
              <div className="LQEjW">
                <span data-icon="refresh">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      fill="#263238"
                      fillOpacity=".55"
                      d="M17.6 6.4C16.2 4.9 14.2 4 12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8c3.7 0 6.8-2.6 7.7-6h-2.1c-.8 2.3-3 4-5.6 4-3.3 0-6-2.7-6-6s2.7-6 6-6c1.7 0 3.1.7 4.2 1.8L13 11h7V4l-2.4 2.4z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="_2S4JS" />
          <div className="_9tCEa" id="messages">
            {messages.map((message, index) => (
              <Message
                key={message.idmessages}
                message={message}
                client={client}
                beforeMessage={index !== 0 && messages[index - 1]}
                lastMessage={index === messages.length - 1}
              />
            ))}

            {/* <CommentMessage /> */}
          </div>
        </div>
      </div>
    </Page>
  );
}
