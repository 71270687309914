import React from "react";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { Page } from "./style";
import api from "../../../services/axios";
import Can, { can } from "../../../components/Can";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 400,
    },
  },
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function ({ setData }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const useractive = JSON.parse(localStorage.user);
  const [sectors, setSectors] = React.useState([]);
  const [disable, setDisable] = React.useState(false);
  const [sectorselected, setSectorselected] = React.useState([]);
  const [subsectorselected, setSubsectorselected] = React.useState([]);
  const [sectorsfilter, setSectorsfilter] = React.useState([]);
  const [userselected, setUserselected] = React.useState([]);
  const [clientselected, setClientselected] = React.useState([]);
  const [ticket, setTicket] = React.useState("");
  const [dataselected, setDataselected] = React.useState({
    initial: "",
    final: "",
  });
  const [users, setUsers] = React.useState([]);
  const [usersfilter, setUsersfilter] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [company, setCompany] = React.useState([]);
  const [companyfilter, setCompanyfilter] = React.useState("");
  function dataAtualFormatada(month) {
    let data = new Date();
    if (month) {
      data = new Date(data.setMonth(data.getMonth() - month));
    }
    const dia = data.getDate().toString(),
      diaF = dia.length == 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? "0" + mes : mes,
      anoF = data.getFullYear();
    return anoF + "-" + mesF + "-" + diaF;
  }
  const getClients = async () => {
    await api
      .get("clients", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        const arraycompany = [];
        setClients([...res.data.rows]);
        res.data.rows.map((x) => {
          if (x.cliente_cnpjs.length) {
            x.cliente_cnpjs.map((y) => {
              const index = arraycompany.findIndex((z) => z === y.rzsocial);
              if (index === -1) {
                if (y.rzsocial) {
                  arraycompany.push(y.rzsocial);
                }
              }
            });
          }
        });
        setCompany(arraycompany);
      });
  };
  const getUsers = async () => {
    api
      .get("users", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setUsers(res.data);
        setUsersfilter(res.data);
      });
  };
  React.useEffect(() => {
    if (sectorselected.length) {
      if (sectorselected.length === 1) {
        let subsectors = [];
        sectorselected.map((sector) => {
          subsectors = [...subsectors, ...sector.sub_sectors];
        });
        setSectorsfilter(subsectors);
      }
      const usersarray = [];
      const usersstate = users;
      sectorselected.map((sector) => {
        usersstate.map((user) => {
          const index = usersarray.findIndex((x) => {
            return x.idusers === user.idusers;
          });
          if (index !== -1) {
            return;
          }
          const sectorexists = user.users_has_sectors.findIndex((x) => {
            return x.idsector === sector.idsectors;
          });
          if (sectorexists !== -1) {
            usersarray.push(user);
          }
        });
      });
      setUsersfilter([...usersarray]);
    } else {
      setUsersfilter([...users]);
      setSectorsfilter([]);
      setSubsectorselected([]);
    }
  }, [sectorselected]);

  React.useEffect(() => {
    api
      .get("sectors", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setSectors([...res.data]);
        // if (
        //   !can(JSON.parse(localStorage.user).role.idroles, "sectors", "read")
        // ) {
        //   setSectorselected([
        //     res.data.find(
        //       (sector) =>
        //         sector.idsectors ===
        //         useractive.users_has_sectors[0].sector.idsectors
        //     ),
        //   ]);
        // }
      });
    getUsers();
    getClients();
    if (!can(JSON.parse(localStorage.user).role.idroles, "users", "read")) {
      setUserselected([useractive]);
    }
  }, []);
  const filter = () => {
    setDisable(true);
    let user;
    let client;
    if (userselected.length) {
      user = {
        idusers: userselected.map((us) => us.idusers),
      };
    }
    if (clientselected.length) {
      client = {
        idclient: clientselected.map((cl) => cl.idclient),
      };
    }
    if (dataselected.initial || dataselected.final) {
      if (!dataselected.initial) {
        setDisable(false);
        return dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: "Selecione uma data inicial.",
            idclient: 0,
            read: false,
          },
        });
      }
      if (!dataselected.final) {
        dataselected.final = new Date().toDateInputValue();
      }
      if (
        new Date(`${dataselected.initial} 00:00:00`).getTime() >
        new Date(`${dataselected.final} 00:00:00`).getTime()
      ) {
        setDisable(false);
        return dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: "Data Inicial maior que a Data Final.",
            idclient: 0,
            read: false,
          },
        });
      }
      dataselected.initial = new Date(`${dataselected.initial} 00:00:00`);
      dataselected.final = new Date(`${dataselected.final} 23:59:59`);
    }
    const data = {
      ticket: ticket || undefined,
      user: user || undefined,
      client: client || undefined,
      date: dataselected || undefined,
    };
    console.log(data);
    api
      .get("/report", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: { data: JSON.stringify(data) },
      })
      .then((response) => {
        setData(response.data);
        setDisable(false);
      })
      .catch((e) => {
        const resetdate = {
          initial: dataselected.initial
            ? dataselected.initial.toDateInputValue()
            : "",
          final: dataselected.final
            ? dataselected.final.toDateInputValue()
            : "",
        };
        setDataselected(resetdate);
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: e.response.data.error,
            idclient: 0,
            read: false,
          },
        });
        setDisable(false);
      });
  };
  function clearClientsSelectedRZ(value) {
    const arrayclientsselect = clientselected;
    let clientstoremove = [];
    arrayclientsselect.map((x, indexx) => {
      const index = x.cliente_cnpjs.findIndex(
        (y) => y.rzsocial === companyfilter
      );
      if (index !== -1) {
        clientstoremove.push(indexx);
      }
    });
    if (clientstoremove.length) {
      clientstoremove = clientstoremove.sort((a, b) => b - a);
      clientstoremove.map((x) => {
        arrayclientsselect.splice(x, 1);
      });
    }
    filterClientsSelectedRZ(value, arrayclientsselect);
  }
  function filterClientsSelectedRZ(value, arrayclientsselect) {
    if (!value) {
      setClientselected([...arrayclientsselect]);
      return setCompanyfilter(value);
    }
    setCompanyfilter(value);
    if (clients.length) {
      const clientsfilter = [];
      clients.map((x) => {
        const index = x.cliente_cnpjs.findIndex((y) => y.rzsocial === value);
        if (index !== -1) {
          const indextwo = arrayclientsselect.findIndex(
            (z) => z.idclient === x.idclient
          );
          if (indextwo === -1) {
            clientsfilter.push(x);
          }
        }
      });
      setClientselected([...arrayclientsselect, ...clientsfilter]);
    }
  }

  return (
    <Page>
      <form className={classes.root} noValidate autoComplete="off">
        <div className="dateinput">
          <div className="titlefilter">
            {t("FC.filter.label1")}:&nbsp;&nbsp;
          </div>
          <TextField
            label="Ticket"
            type="number"
            onChange={(ev) => {
              setTicket(ev.currentTarget.value);
            }}
            id="outlined-size-normal"
            defaultValue="Normal"
            variant="outlined"
            InputProps={{ inputProps: { min: 0 } }}
          />
        </div>
        <Divider />
        <div className="dateinput">
          <div className="titlefilter">
            {t("FC.filter.label2")}:&nbsp;&nbsp;
          </div>
          <TextField
            id="date"
            InputProps={{
              inputProps: {
                min: dataAtualFormatada(3),
                max: dataAtualFormatada(),
              },
            }}
            label={t("FC.filter.phinitial")}
            onChange={(ev) => {
              setDataselected({
                ...dataselected,
                initial: ev.currentTarget.value,
              });
            }}
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="date"
            label={t("FC.filter.phfinal")}
            type="date"
            onChange={(ev) => {
              setDataselected({
                ...dataselected,
                final: ev.currentTarget.value,
              });
            }}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <Divider />
        <Can
          role={JSON.parse(localStorage.user).role.idroles}
          type="sectors"
          action="read"
          yes={() => (
            <div className="dateinput">
              <div className="titlefilter">
                {t("FC.filter.label6")}:&nbsp;&nbsp;
              </div>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={sectors}
                onChange={(event, values) => setSectorselected([...values])}
                getOptionLabel={(sector) => sector.name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t("FC.filter.lbsector")}
                    placeholder={t("FC.filter.phsector")}
                  />
                )}
              />
            </div>
          )}
        />
        <Can
          role={JSON.parse(localStorage.user).role.idroles}
          type="subsectors"
          action="read"
          yes={() =>
            !!sectorsfilter.length && (
              <div className="dateinput">
                <div className="titlefilter">
                  {t("FC.filter.label7")}:&nbsp;&nbsp;
                </div>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  onChange={(event, values) =>
                    setSubsectorselected([...values])
                  }
                  options={sectorsfilter}
                  getOptionLabel={(subsector) => subsector.name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t("FC.filter.lbssubector")}
                      placeholder={t("FC.filter.phsubsector")}
                    />
                  )}
                />
              </div>
            )
          }
        />
        <Divider />
        <Can
          role={JSON.parse(localStorage.user).role.idroles}
          type="users"
          action="read"
          yes={() => (
            <div className="dateinput">
              <div className="titlefilter">
                {t("FC.filter.label3")}:&nbsp;&nbsp;
              </div>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={usersfilter}
                onChange={(event, values) => setUserselected([...values])}
                getOptionLabel={(user) => user.username}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t("FC.filter.lbuser")}
                    placeholder={t("FC.filter.phuser")}
                  />
                )}
              />
            </div>
          )}
        />
        {!!company.length && (
          <>
            <div className="dateinput">
              <div className="titlefilter">
                {t("FC.filter.label4")}:&nbsp;&nbsp;
              </div>
              <Autocomplete
                id="tags-outlined"
                options={company}
                onChange={(event, values) => {
                  clearClientsSelectedRZ(values);
                }}
                getOptionLabel={(companydesc) => companydesc}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t("FC.filter.lbrz")}
                    placeholder={t("FC.filter.phrz")}
                  />
                )}
              />
            </div>
          </>
        )}
        <div className="dateinput">
          <div className="titlefilter">
            {t("FC.filter.label5")}:&nbsp;&nbsp;
          </div>
          <Autocomplete
            multiple
            value={clientselected}
            id="tags-outlined"
            options={clients}
            onChange={(event, values) => setClientselected([...values])}
            getOptionLabel={(client) =>
              `${client.idclient} - ${client.name} - ${client.number}`
            }
            filterSelectedOptions
            defaultValue={clientselected}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t("FC.filter.lbclient")}
                placeholder={t("FC.filter.phclient")}
              />
            )}
          />
        </div>
        <Divider />
        <div className="btnconfirm">
          <Button
            onClick={filter}
            variant="contained"
            color="primary"
            size="large"
            disabled={disable}
            className={classes.button}
            startIcon={<SearchIcon />}
          >
            {t("FC.filter.bt1")}
          </Button>
        </div>
      </form>
    </Page>
  );
}
