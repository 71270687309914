import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import api from "../../../services/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
export default function AlertDialog(props) {
  const [name, setName] = React.useState("");

  const classes = useStyles();
  const handleClose = () => {
    props.func(false);
  };

  const confirm = () => {
    if (!name.length) {
      return window.alert("Preencha todos os campos marcados com *");
    }

    api
      .post(
        "users/roles",
        { name: name.toUpperCase() },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((res) => {
        props.update();
        handleClose();
      })
      .catch((err) => window.alert(err.response.data.error));
  };
  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Novo Perfil</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
                required
              />
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Button variant="contained" color="secondary">
              Cancelar
            </Button>
          </Button>
          <Button color="primary" onClick={confirm} autoFocus>
            <Button variant="contained" color="primary">
              Confirmar
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
