import React, { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import { GetContactsContent } from "../style";
import InfoIcon from "@material-ui/icons/Info";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import api from "../../../services/axios";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",
    width: "250px",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export function GetContacts({ setContacts }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  function fetchData() {
    setLoading(true);
    api
      .get("whatsapp/contacts", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        setContacts(response.data);
      })
      .catch((e) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <GetContactsContent>
      <div>
        <h3 style={{ display: "flex", alignItems: "center" }}>
          Importar Contatos do Whatsapp
        </h3>
        <Divider />
      </div>
      <div className="content">
        {loading ? (
          <div className="loading">
            Buscando seus contatos do Whatsapp...
            <CircularProgress />
          </div>
        ) : error ? (
          <div className="action">
            Tivemos um problema ao buscar seus contatos.
            <Button
              onClick={fetchData}
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
            >
              Tentar novamente
            </Button>
          </div>
        ) : (
          <div className="action">
            Clique em "Carregar contatos" para que possamos buscar seus contatos
            do Whatsapp.
            <Button
              onClick={fetchData}
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
            >
              Carregar contatos
            </Button>
          </div>
        )}
      </div>
    </GetContactsContent>
  );
}
