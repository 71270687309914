import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import api from "../../../services/axios";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "60ch",
      justifyContent: "center",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    marginLeft: theme.spacing(4),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default function AlertDialog({
  close,
  setSectors,
  setSubsectors,
  sectors,
  subsectors,
  setData,
}) {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState([...sectors]);
  const [checkedsub, setCheckedsub] = React.useState([...subsectors]);
  const [sectorsdata, setSectorsdata] = React.useState([]);
  const handleToggle = (value, arraysub) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      if (arraysub.length) {
        const newarraysub = [...checkedsub];
        arraysub.map((sub) => {
          const indexsub = newarraysub.indexOf(sub.idsubsectors);
          if (indexsub !== -1) {
            newarraysub.splice(indexsub, 1);
            setCheckedsub(newarraysub);
          }
        });
      }
    }
    setChecked(newChecked);
  };
  const handleToggleSub = (value, idsectors) => () => {
    const indexSector = checked.indexOf(idsectors);
    if (indexSector === -1) {
      return;
    }
    const currentIndex = checkedsub.indexOf(value);
    const newChecked = [...checkedsub];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedsub(newChecked);
  };
  const classes = useStyles();
  const getSectors = async () => {
    await api
      .get("sectors", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setSectorsdata([...res.data]);
        setData([...res.data]);
      });
  };
  const toggleExpand = (index) => {
    const arraysectors = sectorsdata;
    arraysectors[index].show = !arraysectors[index].show;
    setSectorsdata([...arraysectors]);
  };

  const confirm = () => {
    setSectors(checked);
    setSubsectors(checkedsub);
    close();
  };

  React.useEffect(() => {
    getSectors();
  }, []);
  return (
    <div>
      <Dialog
        open
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("US.dialogs.newuser.titledialog")}
        </DialogTitle>
        <DialogContent>
          <List className={classes.root}>
            {!!sectorsdata.length &&
              sectorsdata.map((value, index) => {
                const labelId = `checkbox-list-label-${index}`;

                return (
                  <>
                    <ListItem
                      key={index}
                      role={undefined}
                      dense
                      button
                      onClick={handleToggle(value.idsectors, value.sub_sectors)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(value.idsectors) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={`${value.name}`} />
                      <div
                        onClick={(ev) => {
                          ev.preventDefault();
                          ev.stopPropagation();
                          toggleExpand(index);
                        }}
                      >
                        {value.sub_sectors.length ? (
                          value.show ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </ListItem>
                    <Collapse in={value.show} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {value.sub_sectors.length &&
                          value.sub_sectors.map((sub) => (
                            <ListItem
                              button
                              className={classes.nested}
                              onClick={handleToggleSub(
                                sub.idsubsectors,
                                value.idsectors
                              )}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={
                                    checkedsub.indexOf(sub.idsubsectors) !== -1
                                  }
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText primary={sub.name} />
                            </ListItem>
                          ))}
                      </List>
                    </Collapse>
                  </>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={close} autoFocus>
            <Button variant="contained" color="secondary">
              {t("WT.dialogs.client.bt2")}
            </Button>
          </Button>
          <Button color="primary" onClick={confirm} autoFocus>
            <Button variant="contained" className={classes.button}>
              {t("WT.dialogs.client.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
