import React, { useState } from "react";
import { ImportAreaContent } from "../style";
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { CustomFooter } from "./CustomFooter";

export function ImportArea({ contacts, setContacts }) {
  const columns = ["Nome", "Número"];

  const options = {
    filterType: "checkbox",
    print: false,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    filter: false,
    customToolbar: null,
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum dado encontrado",
      },
    },
    onRowsDelete: (rowsDeleted, data, newTableData) => {
      const oldData = contacts;
      const idsToRemove = [];
      rowsDeleted.data.map((x) => {
        idsToRemove.push(oldData[x.dataIndex].id);
      });
      idsToRemove.map((id) => {
        const index = oldData.findIndex((z) => z.id === id);
        oldData.splice(index, 1);
      });
      setContacts([...oldData]);
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <CustomFooter
          count={count}
          data={contacts}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={(event) => changeRowsPerPage(event.target.value)}
          onChangePage={(_, page) => changePage(page)}
        />
      );
    },
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // function fetchData() {
  //   setLoading(true);
  //   api
  //     .get("whatsapp/contacts", {
  //       headers: {
  //         authorization: `Bearer ${localStorage.token}`,
  //       },
  //     })
  //     .then((response) => {
  //       setContacts(response.data);
  //     })
  //     .catch((e) => {
  //       setError(true);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }
  return (
    <ImportAreaContent>
      <MUIDataTable
        title={`Contatos Encontrados`}
        data={contacts.map((contact) => [
          contact.name,
          contact.jid,
          contacts.id,
        ])}
        columns={columns}
        options={options}
      />
    </ImportAreaContent>
  );
}
