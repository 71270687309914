import React from "react";
import { Page } from "./style";

export default class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: "translateX(-450px)",
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ animation: "translateX(0px)" });
    }, 100);
  }

  backInitialComponent = () => {
    this.setState({ animation: "translateX(-450px)" }, () => {
      setTimeout(() => {
        this.props.setRender("1");
      }, 200);
    });
  };

  render() {
    const { animation } = this.state;
    return (
      <Page translate={animation}>
        <div className="_2fq0t copyable-area">
          <header className="_1FroB">
            <div className="Ghmsz" data-animate-drawer-title="true">
              <div className="SFEHG">
                <button
                  onClick={this.backInitialComponent}
                  type="button"
                  className="_1aTxu btn-close"
                  id="btn-close-panel-edit-profile"
                >
                  <span data-icon="back-light">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        fill="#FFF"
                        d="M20 11H7.8l5.6-5.6L12 4l-8 8 8 8 1.4-1.4L7.8 13H20v-2z"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <div className="_1xGbt">Perfil</div>
            </div>
          </header>
          <div className="_2sNbV">
            <div className="_12fSF">
              <div className="_1wCju">
                <div className="_2UkYn" dir="ltr">
                  <div className="_1WNtc">
                    <div className="IuYNx">
                      <div
                        id="photo-container-edit-profile"
                        style={{
                          width: "200px",
                          height: "200px",
                          top: "0px",
                          left: "0px",
                          position: "absolute",
                        }}
                      >
                        <img
                          src="#"
                          className="photo"
                          id="img-panel-edit-profile"
                          alt=""
                          style={{
                            height: "100%",
                            width: "100%",
                            display: "none",
                          }}
                        />
                        <div
                          className="_3ZW2E no-photo-edit"
                          id="img-default-panel-edit-profile"
                        >
                          <span data-icon="default-user">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 212 212"
                              width="212"
                              height="212"
                            >
                              <path
                                fill="#DFE5E7"
                                d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"
                              />
                              <g fill="#FFF">
                                <path d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z" />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="file"
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                  style={{ display: "none" }}
                  id="input-profile-photo"
                />
              </div>
            </div>
            <div className="_1CRb5 _34vig _2phEY">
              <div className="_2VQzd">
                <div>
                  <div className="LlZXr">
                    <div className="_3e7ko _1AUd-">
                      <span className="_1sYdX">Seu Nome</span>
                    </div>
                  </div>
                </div>
              </div>
              <div tabIndex="-1" className="ogWqZ  _2-h1L _31WRs">
                <span className="_2rXhY CrwPM" />
                <div className="_2OIDe" />
                <div className="_1DTd4">
                  <div tabIndex="-1" className="_3F6QL bsmJe">
                    <div className="_39LWd" style={{ visibility: "hidden" }} />
                    <div
                      id="input-name-panel-edit-profile"
                      className="_2S1VP copyable-text selectable-text input-name"
                      contentEditable="true"
                      dir="ltr"
                    >
                      Meu Nome de Contato
                    </div>
                  </div>
                  <div className="_2YmC2">
                    <span className="_3jFFV">
                      <div
                        className="_3cyFx btn-save"
                        id="btn-save-panel-edit-profile"
                        title="Clique para salvar"
                        style={{
                          transform: "scaleX(1) scaleY(1)",
                          opacity: "1",
                        }}
                      >
                        <span data-icon="checkmark">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path
                              opacity=".45"
                              fill="#263238"
                              d="M9 17.2l-4-4-1.4 1.3L9 19.9 20.4 8.5 19 7.1 9 17.2z"
                            />
                          </svg>
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="_idKB">
              <span className="Cpiae">
                Este nome será visível para seus contatos.
              </span>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
