import React from "react";
import { PageLocked } from "./style";
import { useTranslation } from "react-i18next";
export default function Locked() {
  const { t } = useTranslation();
  return (
    <PageLocked>
      <div className="_3ge3i">
        <span
          dir="ltr"
          className="_3Whw5 selectable-text invisible-space copyable-text"
        >
          {t("FC.chats.pr.active.locked.title")}
        </span>
      </div>
    </PageLocked>
  );
}
