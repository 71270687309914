import styled from "styled-components";

export const Page = styled.span`
  .menu-dropdown {
    position: absolute;
    top: 49px;
    height: 320px;
    width: 320px;
    overflow: hidden;
    pointer-events: none;
    cursor: default;
    right: -17px;
    /* display:none; */
    display: block;
    ._1txFK {
      position: absolute;
      top: 0;
      width: 51px;
      box-sizing: initial;
      z-index: 1000;
      pointer-events: all;
      padding: 0 10px 30px;
      cursor: default;
      right: 2px;
      ._3s1D4 {
        ._10anr {
          margin-top: 15px;
          position: relative;
          opacity: 1;
          .btn-attach {
            outline: none;
            border: 0;
            padding: 0;
            margin: 0;
            cursor: pointer;
            transform-origin: top center;
            border-radius: 50%;
            height: 53px;
            background: transparent;
            transform: translate3d(0, 0, 0);
            transform-origin: top center;
            transition: 0.2s ease-in-out;
            transform: ${(props) => props.transform};
          }
        }
        .vidHz {
          position: relative;
        }
        ._3asN5 {
          opacity: 1;
        }
      }
    }
  }
`;
