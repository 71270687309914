import React from "react";
import socket from "../../../../../services/socket";

export default function ({ message }) {
  const user = JSON.parse(localStorage.user);
  const [showbtnpause, setShowbtnpause] = React.useState(false);
  const [showbtnplay, setShowbtnplay] = React.useState(false);
  const [showbtnload, setShowbtnload] = React.useState(true);
  const [duration, setDuration] = React.useState("0:00");
  const [range, setRange] = React.useState("0");
  const [status, setStatus] = React.useState(
    message.pending ? "PENDING" : message.status
  );

  function formatTime(value) {
    const minutes = `0${Math.floor(value / 60)}`;
    const seconds = `0${Math.floor(value - minutes * 60)}`;
    return `${minutes.substr(-2)}:${seconds.substr(-2)}`;
  }
  React.useEffect(() => {
    socket.on(
      `${user.company.prefix}_updatemessage${message.idmessages}`,
      ({ status }) => {
        if (status) {
          setStatus(status);
        }
      }
    );
  }, []);
  return (
    <div className="message">
      <div
        className={`_3_7SH _17oKL message-${
          message.client_message > 0 ? "in" : "out"
        }`}
      >
        <div className="_2N_Df LKbsn">
          <div className="_2jfIu">
            <div className="_2cfqh">
              <div className="_1QMEq _1kZiz fS1bA">
                <div className="E5U9C">
                  {/* <svg
                    className="_1UDDE"
                    width="34"
                    height="34"
                    viewBox="0 0 43 43"
                  >
                    <circle
                      className="_3GbTq _37WZ9"
                      cx="21.5"
                      cy="21.5"
                      r="20"
                      fill="none"
                      strokeWidth="3"
                    />
                  </svg> */}
                  {showbtnplay && (
                    <button
                      type="button"
                      className="_2pQE3 btn-audio-play"
                      onClick={() => {
                        document
                          .getElementById(`audio-midia-${message.idmessages}`)
                          .play();
                      }}
                    >
                      <span data-icon="audio-play">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 34 34"
                          width="34"
                          height="34"
                        >
                          <path
                            fill="#263238"
                            fillOpacity=".5"
                            d="M8.5 8.7c0-1.7 1.2-2.4 2.6-1.5l14.4 8.3c1.4.8 1.4 2.2 0 3l-14.4 8.3c-1.4.8-2.6.2-2.6-1.5V8.7z"
                          />
                        </svg>
                      </span>
                    </button>
                  )}

                  {showbtnpause && (
                    <button
                      type="button"
                      className="_2pQE3 btn-audio-pause"
                      onClick={() => {
                        document
                          .getElementById(`audio-midia-${message.idmessages}`)
                          .pause();
                      }}
                    >
                      <span data-icon="audio-pause">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 34 34"
                          width="34"
                          height="34"
                        >
                          <path
                            fill="#263238"
                            fillOpacity=".5"
                            d="M9.2 25c0 .5.4 1 .9 1h3.6c.5 0 .9-.4.9-1V9c0-.5-.4-.9-.9-.9h-3.6c-.4-.1-.9.3-.9.9v16zm11-17c-.5 0-1 .4-1 .9V25c0 .5.4 1 1 1h3.6c.5 0 1-.4 1-1V9c0-.5-.4-.9-1-.9 0-.1-3.6-.1-3.6-.1z"
                          />
                        </svg>
                      </span>
                    </button>
                  )}
                </div>
                <div className="_1_Gu6">
                  <div className="message-audio-duration">{duration}</div>
                  <div className="_1sLSi">
                    <span className="nDKsM" style={{ width: "0%" }} />
                    <input
                      type="range"
                      min="0"
                      max="100"
                      className="_3geJ8"
                      value={range}
                      onChange={(ev) => {
                        setRange(ev.currentTarget.value);
                        document.getElementById(
                          `audio-midia-${message.idmessages}`
                        ).currentTime =
                          (ev.currentTarget.value *
                            document.getElementById(
                              `audio-midia-${message.idmessages}`
                            ).duration) /
                          100;
                      }}
                    />
                    <audio
                      src={`${process.env.REACT_APP_API_URL}${message.media}`}
                      preload="auto"
                      id={`audio-midia-${message.idmessages}`}
                      onLoadedData={() => {
                        setDuration(
                          formatTime(
                            document.getElementById(
                              `audio-midia-${message.idmessages}`
                            ).duration
                          )
                        );
                        setShowbtnload(false);
                        setShowbtnplay(true);
                      }}
                      onPlay={() => {
                        setShowbtnplay(false);
                        setShowbtnpause(true);
                      }}
                      onPause={() => {
                        setShowbtnplay(true);
                        setShowbtnpause(false);
                        setDuration(
                          formatTime(
                            document.getElementById(
                              `audio-midia-${message.idmessages}`
                            ).duration
                          )
                        );
                      }}
                      // onEnded={() => {
                      //   document.getElementById(
                      //     `audio-midia-${message.idmessages}`
                      //   ).currentTime = 0;
                      //   setShowbtnplay(false);
                      //   setShowbtnpause(false);
                      // }}
                      onTimeUpdate={() => {
                        setDuration(
                          formatTime(
                            document.getElementById(
                              `audio-midia-${message.idmessages}`
                            ).currentTime
                          )
                        );
                        setRange(
                          (document.getElementById(
                            `audio-midia-${message.idmessages}`
                          ).currentTime *
                            100) /
                            document.getElementById(
                              `audio-midia-${message.idmessages}`
                            ).duration
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="_1mbqw">
              <div className="QnDup">
                <span data-icon="ptt-out-blue">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 19 26"
                    width="19"
                    height="26"
                  >
                    <path
                      fill="#DDF6C9"
                      d="M9.217 24.401c-1.158 0-2.1-.941-2.1-2.1v-2.366c-2.646-.848-4.652-3.146-5.061-5.958l-.052-.357-.003-.081a2.023 2.023 0 0 1 .571-1.492c.39-.404.939-.637 1.507-.637h.3c.254 0 .498.044.724.125v-6.27A4.27 4.27 0 0 1 9.367 1a4.27 4.27 0 0 1 4.265 4.265v6.271c.226-.081.469-.125.723-.125h.3c.564 0 1.112.233 1.501.64s.597.963.571 1.526c0 .005.001.124-.08.6-.47 2.703-2.459 4.917-5.029 5.748v2.378c0 1.158-.942 2.1-2.1 2.1h-.301v-.002z"
                    />
                    <path
                      fill="#03A9F4"
                      d="M9.367 15.668a2.765 2.765 0 0 0 2.765-2.765V5.265a2.765 2.765 0 0 0-5.529 0v7.638a2.764 2.764 0 0 0 2.764 2.765zm5.288-2.758h-.3a.64.64 0 0 0-.631.598l-.059.285a4.397 4.397 0 0 1-4.298 3.505 4.397 4.397 0 0 1-4.304-3.531l-.055-.277a.628.628 0 0 0-.629-.579h-.3a.563.563 0 0 0-.579.573l.04.278a5.894 5.894 0 0 0 5.076 4.978v3.562c0 .33.27.6.6.6h.3c.33 0 .6-.27.6-.6V18.73c2.557-.33 4.613-2.286 5.051-4.809.057-.328.061-.411.061-.411a.57.57 0 0 0-.573-.6z"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className="_2fuJy">
              <div className="_1WliW" style={{ height: "55px", width: "55px" }}>
                <img
                  src="#"
                  className="Qgzj8 gqwaM message-photo"
                  style={{ display: "none" }}
                  alt=""
                />
                <div className="_3ZW2E">
                  <span data-icon="default-user">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 212 212"
                      width="212"
                      height="212"
                    >
                      <path
                        fill="#DFE5E7"
                        d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"
                      />
                      <g fill="#FFF">
                        <path d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z" />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="_27K_5">
            <div className="_1DZAH" role="button">
              <span className="message-time">
                {" "}
                {new Date(message.timestamp).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
              {status === "PENDING" && (
                <div className="_2nWgr">
                  <span
                    data-testid="msg-time"
                    aria-label=" Pendente "
                    data-icon="msg-time"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 15"
                      width="16"
                      height="15"
                    >
                      <path
                        fill="currentColor"
                        d="M9.75 7.713H8.244V5.359a.5.5 0 0 0-.5-.5H7.65a.5.5 0 0 0-.5.5v2.947a.5.5 0 0 0 .5.5h.094l.003-.001.003.002h2a.5.5 0 0 0 .5-.5v-.094a.5.5 0 0 0-.5-.5zm0-5.263h-3.5c-1.82 0-3.3 1.48-3.3 3.3v3.5c0 1.82 1.48 3.3 3.3 3.3h3.5c1.82 0 3.3-1.48 3.3-3.3v-3.5c0-1.82-1.48-3.3-3.3-3.3zm2 6.8a2 2 0 0 1-2 2h-3.5a2 2 0 0 1-2-2v-3.5a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v3.5z"
                      ></path>
                    </svg>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="_3S8Q-" role="button">
          <span data-icon="forward-chat">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25 25"
              width="25"
              height="25"
            >
              <path
                fill="#FFF"
                d="M14.2 9.5V6.1l5.9 5.9-5.9 6v-3.5c-4.2 0-7.2 1.4-9.3 4.3.8-4.2 3.4-8.4 9.3-9.3z"
              />
            </svg>
          </span>
        </div> */}
      </div>
    </div>
  );
}
