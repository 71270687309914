import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import api from "../../../services/axios";
import { useTranslation } from "react-i18next";
import { CircularProgress, Divider } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "60ch",
      justifyContent: "center",
    },
  },
  list: {
    margin: theme.spacing(1),
    width: "60ch",
    justifyContent: "center",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    marginLeft: theme.spacing(4),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
  margin: {
    margin: "10px",
    marginBottom: "20px",
  },
}));
export default function AlertDialog({ close, wallet, update }) {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [key, setKey] = React.useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = React.useState(key);
  const [clientsCount, setClientsCount] = React.useState(0);
  const [checked, setChecked] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [clientsfilter, setClientsfilter] = React.useState([]);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const classes = useStyles();
  const getClients = async () => {
    await api
      .get("clients/withoutwallet", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          size: 100,
          page,
          key,
        },
      })
      .then((res) => {
        setClientsCount(res.data.count);
        setPage((prev) => prev + 1);
        if (clients.length >= res.data.count) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setClients((prev) => [...prev, ...res.data.rows]);
      })
      .catch((e) => {
        setHasMore(false);
        setPage(0);
        if(page === 0){
          setClients([]);
        }
      });
  };
  const toggleExpand = (index) => {
    const arrayclients = clientsfilter;
    arrayclients[index].show = !arrayclients[index].show;
    setClientsfilter([...arrayclients]);
  };

  React.useEffect(() => {
    const timerId = setTimeout(() => {
      if (page !== 0) {
        setPage(0);
        setClients([]);
      }
      setDebouncedSearchTerm(key);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [key]);

  React.useEffect(() => {
    getClients();
  }, [debouncedSearchTerm]);

  const onChange = (value) => {
    setKey(value);
  };
  const confirm = () => {
    if (checked.length) {
      const arrayclients = [];
      checked.map((x) =>
        arrayclients.push(clients.find((y) => y.idclient === x))
      );
      const data = {
        idwallets: wallet.idwallets,
        clients: [...arrayclients],
      };
      api
        .post("/wallet/addclients", data, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          update();
          close();
        });
    }
    console.log(checked);
  };
  const handleCheckAll = () => {
    setChecked([...clients.map((client) => client.idclient)]);
  };
  const handleUncheckAll = () => {
    setChecked([]);
  };
  // React.useEffect(() => {
  //   getClients();
  // }, []);
  return (
    <div>
      <Dialog
        open
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("WT.dialogs.client.title")}
        </DialogTitle>
        <FormControl className={classes.margin}>
          <Input
            id="input-with-icon-adornment"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            onChange={(ev) => {
              onChange(ev.currentTarget.value);

              // setTimeout(() => {
              // }, 500);
            }}
          />
        </FormControl>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <a
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              handleCheckAll();
            }}
          >
            Marcar Todos
          </a>
          <a
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              handleUncheckAll();
            }}
          >
            Desmarcar Todos
          </a>
          <div>
            {" "}
            Selecionados : <b>{checked.length}</b>
          </div>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "10px",
          }}
        >
          Exibindo: <b>{clients.length}</b> de : <b>{clientsCount}</b>
        </div>
        <DialogContent id="scrollableDiv">
          <List className={classes.list}>
            <InfiniteScroll
              dataLength={clients.length}
              next={getClients}
              hasMore={hasMore}
              loader={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              }
              scrollableTarget="scrollableDiv"
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Estes são todos os dados</b>
                </p>
              }
              style={{ overflow: "hidden" }}
            >
              {clients.map((value, index) => {
                const labelId = `checkbox-list-label-${index}`;

                return (
                  <>
                    <ListItem
                      key={value.idclient}
                      role={undefined}
                      dense
                      button
                      onClick={handleToggle(value.idclient)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(value.idclient) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={`${value.name}`} />
                      <div
                        onClick={(ev) => {
                          ev.preventDefault();
                          ev.stopPropagation();
                          toggleExpand(index);
                        }}
                      >
                        {value.cliente_cnpjs.length ? (
                          value.show ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </ListItem>
                    <Collapse in={value.show} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {value.cliente_cnpjs.length
                          ? value.cliente_cnpjs.map((cnpj) => (
                              <ListItem button className={classes.nested}>
                                <ListItemText
                                  primary={`${
                                    cnpj.rzsocial
                                      ? cnpj.rzsocial
                                      : cnpj.nmfantasia
                                  } / ${cnpj.cnpj.replace(
                                    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                                    "$1.$2.$3/$4-$5"
                                  )}`}
                                />
                              </ListItem>
                            ))
                          : ""}
                      </List>
                    </Collapse>
                  </>
                );
              })}
            </InfiniteScroll>
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={close} autoFocus>
            <Button variant="contained" color="secondary">
              {t("WT.dialogs.client.bt2")}
            </Button>
          </Button>
          <Button color="primary" onClick={confirm} autoFocus>
            <Button variant="contained" className={classes.button}>
              {t("WT.dialogs.client.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
