import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { FileStyled } from "../style";
import api from "../../../services/axios";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "100%",
    },
  },
}));
export default function AlertDialog(props) {
  const { t } = useTranslation();
  const [message, setMessage] = React.useState("");
  const [shortcut, setShortcut] = React.useState("");
  const classes = useStyles();
  const handleClose = () => {
    props.func(false);
  };
  const getFile = async (url) => {
    return fetch(url).then((res) => res.blob());
  };
  React.useEffect(() => {
    setMessage(props.data.message);
    setShortcut(props.data.command);
  }, [props.data]);

  const confirm = () => {
    if (!message || !shortcut) {
      return window.alert("Preencha todos os campos marcados com *");
    }
    const data = {
      command: shortcut,
      message,
      idmessages_shortcut: props.data.idmessages_shortcut,
    };

    api
      .put("shortcut", data, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        props.update();
        handleClose();
      })
      .catch((err) => window.alert(err.response.data.error));
  };

  return (
    <div>
      <Dialog
        open
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Novo Atalho de Mensagem
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-multiline-static"
                label={"Atalho"}
                multiline
                variant="outlined"
                rows={1}
                value={shortcut}
                onChange={(e) => {
                  setShortcut(e.currentTarget.value);
                }}
                required
              />
              <br />
              <br />
              <TextField
                id="outlined-multiline-static"
                label={t("DMT.dialogs.new.ph")}
                multiline
                variant="outlined"
                rows={4}
                value={message}
                onChange={(e) => {
                  setMessage(e.currentTarget.value);
                }}
                required
              />
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Button variant="contained" color="secondary">
              {t("DMT.dialogs.edit.bt2")}
            </Button>
          </Button>
          <Button color="primary" onClick={confirm} autoFocus>
            <Button variant="contained" color="primary">
              {t("DMT.dialogs.edit.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
