import { getRules } from "../config/rbac-rules";

const check = (rules, role, type, action) => {
  type = type.toUpperCase();
  action = action.toUpperCase();
  if (!rules || !rules.length) {
    return false;
  }
  const rulesofrole = rules.filter((rule) => rule.idrole === role);
  if (!rulesofrole.length) {
    return false;
  }
  const rulefilter = rulesofrole.find(
    (rule) =>
      rule.companies_rule.rule.type === type &&
      rule.companies_rule.rule.action === action
  );
  if (rulefilter) {
    return true;
  }
  return false;
};
export const can = (role, type, action) =>
  check(getRules(), role, type, action);

const Can = (props) =>
  check(getRules(), props.role, props.type, props.action)
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
