import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DeleteRole from "./Dialogs/DeleteRole";
import EditRole from "./Dialogs/EditRole";
import api from "../../services/axios";
import NewRole from "./Dialogs/NewRole";
import Can from "../../components/Can";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default () => {
  const { t } = useTranslation();
  const [roles, setRoles] = React.useState([]);
  const [roleselected, setRoleselected] = React.useState("");
  const [opennewrole, setOpennewrole] = React.useState(false);
  const [openeditrole, setOpeneditrole] = React.useState(false);
  const [opendeleterole, setOpendeleterole] = React.useState(false);
  const classes = useStyles();

  const updateData = async () => {
    api
      .get("users/roles", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        setRoles(response.data);
      });
  };
  const options = {
    filterType: false,
    print: false,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    filter: false,
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: t("RT.table.noMatch"),
        toolTip: "Sort",

        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        rowsPerPage: "Linhas por Página",
      },
    },
  };

  const columns = [
    "ID",
    t("RT.table.name"),
    t("RT.table.edit"),
    t("RT.table.delete"),
  ];

  useEffect(() => {
    updateData();
  }, []);
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "auto",
      }}
    >
      {opendeleterole && (
        <DeleteRole
          data={roleselected}
          func={setOpendeleterole}
          update={updateData}
        />
      )}

      {opennewrole && <NewRole func={setOpennewrole} update={updateData} />}

      {openeditrole && (
        <EditRole
          data={roleselected}
          func={setOpeneditrole}
          update={updateData}
        />
      )}

      <MUIDataTable
        title={
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="ROLES"
            action="create"
            yes={() => (
              <Button
                onClick={() => {
                  setOpennewrole(true);
                }}
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<AddCircleIcon />}
              >
                {t("RT.bt1")}
              </Button>
            )}
          />
        }
        data={roles.map((role, index) => [
          role.idroles,
          role.name,
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="roles"
            action="update"
            yes={() => (
              <EditIcon
                style={{ fill: "#34b7f1" }}
                onClick={() => {
                  setRoleselected(roles[index]);
                  setOpeneditrole(true);
                }}
              />
            )}
          />,
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="roles"
            action="delete"
            yes={() => (
              <RemoveCircleOutlineIcon
                style={{ fill: "#34b7f1" }}
                onClick={() => {
                  setRoleselected(roles[index]);
                  setOpendeleterole(true);
                }}
              />
            )}
          />,
        ])}
        columns={columns}
        options={options}
      />
    </div>
  );
};
