import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../../services/axios";
import { compose } from "redux";

export default (props) => {
  const [sectors, setSectors] = React.useState([]);

  const [idsectors, setIdsectors] = React.useState(null);
  const getSectors = () => {
    api
      .get("sectors", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setSectors([...res.data]);
        if (!props.func.idsectors) {
          setIdsectors(res.data[0].idsectors);
        } else {
          setIdsectors(props.func.idsectors);
        }
      });
  };
  const handleChange = (event) => {
    setIdsectors(event.target.value);
  };

  React.useEffect(() => {
    getSectors();
  }, []);
  React.useEffect(() => {
    if (idsectors) {
      props.updateFunction({ ...props.func, idsectors }, props.func.index);
    }
  }, [idsectors]);
  return (
    <div>
      <FormControl variant="filled" fullWidth>
        <InputLabel id="demo-simple-select-filled-label">
          Selecione um Setor
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={idsectors}
          onChange={handleChange}
        >
          {sectors.length ? (
            sectors.map((sector) => {
              return (
                <MenuItem value={sector.idsectors}>{sector.name}</MenuItem>
              );
            })
          ) : (
            <LinearProgress />
          )}
        </Select>
      </FormControl>
    </div>
  );
};
