import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { ArrowLeft, ArrowRight, RemoveCircle } from "@material-ui/icons";
import fileicon from "../Images/file.png";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "rgb(0, 191, 165)",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function FullScreenDialog({ close, files, sendFile }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [filesView, setFilesView] = React.useState(files);
  const [position, setPosition] = React.useState(0);
  const handleClose = () => {
    close();
  };

  const sendFileToApi = async () => {
    sendFile(filesView);
  };

  function handlePrevious() {
    const newPosition = position;
    if (newPosition - 1 >= 0) {
      setPosition(newPosition - 1);
    }
  }
  function handleNext() {
    const newPosition = position;
    if (newPosition + 1 < filesView.length) {
      setPosition(newPosition + 1);
    }
  }
  function handleRemove() {
    const oldData = Array.from(filesView);
    oldData.splice(position, 1);
    setFilesView([...oldData]);
    const newPosition = position;
    if (newPosition + 1 < oldData.length) {
      setPosition(newPosition + 1);
    } else if (newPosition - 1 >= 0) {
      setPosition(newPosition - 1);
    } else {
      close();
    }
  }
  return (
    <div>
      <Dialog
        fullScreen
        open
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("HOME.pr.active.dialogs.sendfile.bt2")}
            </Typography>
            <Button autoFocus color="inherit" onClick={sendFileToApi}>
              {t("HOME.pr.active.dialogs.sendfile.bt1")}
            </Button>
          </Toolbar>
        </AppBar>
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "100vh",
            padding: "0px 20px",
          }}
        >
          <div
            style={{
              width: "34px",
              background: "#c1bdbd",
              height: "34px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={handlePrevious}
          >
            <ArrowLeft />
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "40px 0px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#34b7f1",
                color: "#f2f2f2",
                height: "34px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
                padding: "0px 5px",
              }}
              onClick={handleRemove}
            >
              Remover <RemoveCircle color="#f2f2f2" />
            </div>
            <div>
              <img
                style={{
                  position: "relative",
                  width: "30px",
                }}
                src={fileicon}
                alt=""
              />
              <div className="namefile" style={{ color: "#4a4a4a" }}>
                {filesView[position].name}
              </div>
            </div>
            <div style={{}}>
              {position + 1}/{filesView.length}
            </div>
          </div>
          <div
            style={{
              width: "34px",
              background: "#c1bdbd",
              height: "34px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={handleNext}
          >
            <ArrowRight />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
