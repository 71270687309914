import React, { useEffect, useState } from "react";
import { Divider, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import QRCode from "qrcode.react";
import { useDispatch } from "react-redux";
import Mentions from "rc-mentions";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Switch from "@material-ui/core/Switch";

import { Container, GlobalStyle } from "./style";
import socket from "../../services/socket";
import api from "../../services/axios";
import AuthQr from "./Dialogs/AuthQr";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
  textField: {
    maxWidth: "80px",
  },
}));
export default () => {
  const user = JSON.parse(localStorage.user);
  const dispatch = useDispatch();
  const { Option } = Mentions;
  const [settings, setSettings] = useState(null);
  const [messageattend, setMessageattend] = useState("");
  const [timer, setTimer] = useState(null);
  const [automaticClosing, setAutomaticClosing] = useState(false);
  const [messageclose, setMessageclose] = useState("");
  const [emergencymessagedisabled, setEmergencymessagedisabled] =
    useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [messageremergencyactive, setMessageremergencyactive] = useState(false);
  const [messageremergency, setMessageremergency] = useState("");
  const [messageredirect, setMessageredirect] = useState("");
  const [messageAutomaticClosing, setMessageAutomaticClosing] = useState("");
  const [status, setStatus] = useState("");
  const [qrcode, setQrcode] = useState("");
  const [connectbtn, setConnectbtn] = useState({
    message: "Conectar",
    disable: false,
  });
  const mentions = [
    "idcliente",
    "idusuario",
    "numerocliente",
    "nomeusuario",
    "emailusuario",
    "cpfcliente",
    "numeroticket",
  ];
  const mentionssector = [...mentions, "nomesetor"];
  const classes = useStyles();
  const getStatus = () => {
    api
      .get("/whatsapp/status", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        if (!response.data) {
          setQrcode("");
          setStatus("");
          return setConnectbtn({ message: "Conectar", disable: false });
        }
        setStatus(response.data);
      })
      .catch((e) => console.log(e));
  };
  const getSettings = () => {
    api
      .get("/companie/settings", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        setSettings(response.data);
      })
      .catch((e) => console.log(e));
  };
  const getStatusName = () => {
    switch (status.status) {
      case 0:
        return (
          <div
            style={{
              background: "#34b7f1",
              borderRadius: "5px",
              marginLeft: "5px",
              padding: "5px",
            }}
          >
            <p
              style={{
                color: "white",
                margin: 0,
                padding: 0,
              }}
            >
              Inativo
            </p>
          </div>
        );
      case 1:
        return (
          <div
            style={{
              background: "#34b7f1",
              borderRadius: "5px",
              marginLeft: "5px",
              padding: "5px",
            }}
          >
            <p
              style={{
                color: "white",
                margin: 0,
                padding: 0,
              }}
            >
              Conectado
            </p>
          </div>
        );
      default:
        return (
          <div
            style={{
              background: "#f50057",
              borderRadius: "5px",
              marginLeft: "5px",
              padding: "5px",
            }}
          >
            <p
              style={{
                color: "white",
                margin: 0,
                padding: 0,
              }}
            >
              Desconectado
            </p>
          </div>
        );
    }
  };
  const diconnect = () => {
    api.post(
      "whatsapp/disconnect",
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
  };
  const getQrCode = () => {
    setOpenAuth(true);
    return;
    setConnectbtn({ message: "Gerando QRCODE...", disable: true });
    api.post(
      "whatsapp/qr",
      {
        email: user.email,
        password: "123456",
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
  };
  const confirmEmergencyMessage = () => {
    const data = {
      idcompanies_settings:
        settings &&
        settings.companies_setting &&
        settings.companies_setting.idcompanies_settings,
      emergency_message: messageremergency,
    };
    api
      .post("/companie/settings", data, {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then(() => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "success",
            message: "Salvo!",
            idclient: 0,
            read: false,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: "Ocorreu um erro.",
            idclient: 0,
            read: false,
          },
        });
      });
  };
  const confirmMessage = () => {
    const data = {
      idcompanies_settings:
        settings &&
        settings.companies_setting &&
        settings.companies_setting.idcompanies_settings,
      attendance_ticket_message: messageattend,
      close_ticket_message: messageclose,
      redirect_ticket_message: messageredirect,
      ticket_expire_time: timer
        ? `${timer}:00`.split(":").reduce((acc, time) => 60 * acc + +time)
        : null,
      ticket_expire_message: messageAutomaticClosing,
    };
    api
      .post("/companie/settings", data, {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "success",
            message: "Salvo!",
            idclient: 0,
            read: false,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: "Ocorreu um erro.",
            idclient: 0,
            read: false,
          },
        });
      });
  };
  const changeStatusEmergencyMessage = () => {
    setMessageremergencyactive(!messageremergencyactive);
    setEmergencymessagedisabled(true);
    const data = {
      idcompanies_settings:
        settings &&
        settings.companies_setting &&
        settings.companies_setting.idcompanies_settings,
      emergency_message_status: !messageremergencyactive,
    };
    api
      .post("/companie/settings", data, {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "success",
            message: "Salvo!",
            idclient: 0,
            read: false,
          },
        });
        setEmergencymessagedisabled(false);
      })
      .catch((e) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: "Ocorreu um erro.",
            idclient: 0,
            read: false,
          },
        });
        setEmergencymessagedisabled(false);
      });
  };
  function toggleButtonAutomaticClosing() {
    if (automaticClosing) {
      setTimer(null);
    }
    setAutomaticClosing(!automaticClosing);
  }
  function handleTimeChange(value) {
    setTimer(value);
  }
  React.useEffect(() => {
    getStatus();
    getSettings();
  }, []);
  React.useEffect(() => {
    if (settings && settings.companies_setting) {
      setMessageattend(settings.companies_setting.attendance_ticket_message);
      setMessageclose(settings.companies_setting.close_ticket_message);
      setMessageredirect(settings.companies_setting.redirect_ticket_message);
      setMessageremergencyactive(
        settings.companies_setting.emergency_message_status
      );
      setMessageremergency(settings.companies_setting.emergency_message);
      setMessageAutomaticClosing(
        settings.companies_setting.ticket_expire_message
      );
      setTimer(
        new Date(settings.companies_setting.ticket_expire_time * 1000)
          .toISOString()
          .substr(11, 8)
      );
      setAutomaticClosing(
        settings.companies_setting.ticket_expire_time &&
          settings.companies_setting.ticket_expire_time !== "00:00:00"
      );
    }
  }, [settings]);
  React.useEffect(() => {
    console.log("status", status);
    if (status && status.status && status.status === 1) {
      setQrcode("");
    } else {
      setQrcode("");
      setConnectbtn({ message: "Conectar", disable: false });
    }
  }, [status]);
  useEffect(() => {
    socket.on(`${user.company.prefix}_qr`, ({ qr }) => {
      setQrcode(qr);
    });
    socket.on(`${user.company.prefix}_whatsappconencted`, () => {
      getStatus();
    });
    socket.on(`${user.company.prefix}_whatsappdisconnected`, () => {
      getStatus();
    });
  }, []);

  return (
    <>
      {openAuth && (
        <AuthQr
          close={() => {
            setOpenAuth(false);
          }}
        />
      )}
      <Container>
        <div className="head">
          <h3 style={{ display: "flex", alignItems: "center" }}>
            Status: {getStatusName()}
          </h3>
          <Divider />
        </div>
        <section className="connection">
          <div className="helparea">
            <div className="landing-title">Para usar o Bot do PipeGo :</div>
            <ol className="_1155t">
              <li className="_38sqg">Abra o WhatsApp no seu celular</li>
              <li className="_38sqg">
                <span dir="ltr" className="_1VzZY">
                  Toque em{" "}
                  <strong>
                    <span dir="ltr" className="_1VzZY">
                      Mais opções{" "}
                      <span className="_2ezGC">
                        <svg height="24px" viewBox="0 0 24 24" width="24px">
                          <rect fill="#f2f2f2" height="24" rx="3" width="24" />
                          <path
                            d="m12 15.5c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5-1.5-.675-1.5-1.5.675-1.5 1.5-1.5zm0-2c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5zm0-5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5z"
                            fill="#818b90"
                          />
                        </svg>
                      </span>
                    </span>
                  </strong>{" "}
                  ou{" "}
                  <strong>
                    <span dir="ltr" className="_1VzZY">
                      Ajustes{" "}
                      <span className="_2ezGC">
                        <svg width="24" height="24" viewBox="0 0 24 24">
                          <rect fill="#F2F2F2" width="24" height="24" rx="3" />
                          <path
                            d="M12 18.69c-1.08 0-2.1-.25-2.99-.71L11.43 14c.24.06.4.08.56.08.92 0 1.67-.59 1.99-1.59h4.62c-.26 3.49-3.05 6.2-6.6 6.2zm-1.04-6.67c0-.57.48-1.02 1.03-1.02.57 0 1.05.45 1.05 1.02 0 .57-.47 1.03-1.05 1.03-.54.01-1.03-.46-1.03-1.03zM5.4 12c0-2.29 1.08-4.28 2.78-5.49l2.39 4.08c-.42.42-.64.91-.64 1.44 0 .52.21 1 .65 1.44l-2.44 4C6.47 16.26 5.4 14.27 5.4 12zm8.57-.49c-.33-.97-1.08-1.54-1.99-1.54-.16 0-.32.02-.57.08L9.04 5.99c.89-.44 1.89-.69 2.96-.69 3.56 0 6.36 2.72 6.59 6.21h-4.62zM12 19.8c.22 0 .42-.02.65-.04l.44.84c.08.18.25.27.47.24.21-.03.33-.17.36-.38l.14-.93c.41-.11.82-.27 1.21-.44l.69.61c.15.15.33.17.54.07.17-.1.24-.27.2-.48l-.2-.92c.35-.24.69-.52.99-.82l.86.36c.2.08.37.05.53-.14.14-.15.15-.34.03-.52l-.5-.8c.25-.35.45-.73.63-1.12l.95.05c.21.01.37-.09.44-.29.07-.2.01-.38-.16-.51l-.73-.58c.1-.4.19-.83.22-1.27l.89-.28c.2-.07.31-.22.31-.43s-.11-.35-.31-.42l-.89-.28c-.03-.44-.12-.86-.22-1.27l.73-.59c.16-.12.22-.29.16-.5-.07-.2-.23-.31-.44-.29l-.95.04c-.18-.4-.39-.77-.63-1.12l.5-.8c.12-.17.1-.36-.03-.51-.16-.18-.33-.22-.53-.14l-.86.35c-.31-.3-.65-.58-.99-.82l.2-.91c.03-.22-.03-.4-.2-.49-.18-.1-.34-.09-.48.01l-.74.66c-.39-.18-.8-.32-1.21-.43l-.14-.93a.426.426 0 00-.36-.39c-.22-.03-.39.05-.47.22l-.44.84-.43-.02h-.22c-.22 0-.42.01-.65.03l-.44-.84c-.08-.17-.25-.25-.48-.22-.2.03-.33.17-.36.39l-.13.88c-.42.12-.83.26-1.22.44l-.69-.61c-.15-.15-.33-.17-.53-.06-.18.09-.24.26-.2.49l.2.91c-.36.24-.7.52-1 .82l-.86-.35c-.19-.09-.37-.05-.52.13-.14.15-.16.34-.04.51l.5.8c-.25.35-.45.72-.64 1.12l-.94-.04c-.21-.01-.37.1-.44.3-.07.2-.02.38.16.5l.73.59c-.1.41-.19.83-.22 1.27l-.89.29c-.21.07-.31.21-.31.42 0 .22.1.36.31.43l.89.28c.03.44.1.87.22 1.27l-.73.58c-.17.12-.22.31-.16.51.07.2.23.31.44.29l.94-.05c.18.39.39.77.63 1.12l-.5.8c-.12.18-.1.37.04.52.16.18.33.22.52.14l.86-.36c.3.31.64.58.99.82l-.2.92c-.04.22.03.39.2.49.2.1.38.08.54-.07l.69-.61c.39.17.8.33 1.21.44l.13.93c.03.21.16.35.37.39.22.03.39-.06.47-.24l.44-.84c.23.02.44.04.66.04z"
                            fill="#818b90"
                          />
                        </svg>
                      </span>
                    </span>
                  </strong>{" "}
                  e selecione <strong>WhatsApp Web</strong>
                </span>
              </li>
              <li className="_38sqg">
                Aponte seu celular para essa tela para capturar o código
              </li>
            </ol>
          </div>
          <div className="connection-btn">
            <div className="qr">
              {qrcode ? (
                <QRCode
                  id="CodeQr"
                  value={qrcode}
                  size={350}
                  level="L"
                  includeMargin
                />
              ) : (
                <WhatsAppIcon />
              )}
            </div>
            {!qrcode &&
              (!status || status.status === 0 ? (
                <Button
                  onClick={getQrCode}
                  variant="contained"
                  size="large"
                  className={classes.button}
                  startIcon={<CompareArrowsIcon />}
                  disabled={connectbtn.disable}
                >
                  {connectbtn.message}
                </Button>
              ) : (
                <Button
                  onClick={diconnect}
                  variant="contained"
                  color="secondary"
                  size="large"
                  startIcon={<CompareArrowsIcon />}
                >
                  Desconectar
                </Button>
              ))}
          </div>
        </section>
        <Divider />
        <br />
        <section className="messages-actions">
          <div>
            <h3>Mensagem de Atendimento de Ticket:</h3>
            <div>
              <Mentions
                autoFocus
                rows={3}
                value={messageattend}
                onChange={(text) => {
                  setMessageattend(text);
                }}
                notFoundContent={<div />}
              >
                {mentions.map((mention) => (
                  <Option value={mention}>{mention}</Option>
                ))}
              </Mentions>
            </div>
          </div>
          <div>
            <h3>Mensagem de Encerramento de Ticket:</h3>
            <div>
              <Mentions
                autoFocus
                rows={3}
                value={messageclose}
                notFoundContent={<div />}
                onChange={(text) => {
                  setMessageclose(text);
                }}
              >
                {mentions.map((mention) => (
                  <Option value={mention}>{mention}</Option>
                ))}
              </Mentions>
            </div>
          </div>
          <div>
            <h3>Mensagem de Redirecionamento de Ticket:</h3>
            <div>
              <Mentions
                autoFocus
                rows={3}
                notFoundContent={<div />}
                value={messageredirect}
                onChange={(text) => {
                  setMessageredirect(text);
                }}
              >
                {mentionssector.map((mention) => (
                  <Option value={mention}>{mention}</Option>
                ))}
              </Mentions>
            </div>
          </div>
          <div>
            <h3>
              <span className="beta-tag">BETA</span>
              Encerramento automático de Ticket:{" "}
              <Switch
                checked={automaticClosing}
                onChange={toggleButtonAutomaticClosing}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </h3>
            {automaticClosing ? (
              <>
                <h4>
                  Defina um tempo de encerramento automático (Hora:Minuto) :
                </h4>
                <div>
                  <TextField
                    id="time"
                    type="time"
                    defaultValue="00:00"
                    value={timer}
                    onChange={(value) => {
                      setTimer(value.currentTarget.value);
                    }}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <h4>Defina uma mensagem de encerramento automático:</h4>
                  <textarea
                    rows={3}
                    notFoundContent={<div />}
                    value={messageAutomaticClosing}
                    onChange={(value) => {
                      setMessageAutomaticClosing(value.currentTarget.value);
                    }}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div>
            <Button
              onClick={confirmMessage}
              variant="contained"
              size="large"
              className={classes.button}
            >
              Confirmar
            </Button>
          </div>
        </section>
        <br />
        <Divider />
        {/* <section className="messages-actions">
          <div>
            <h3>
              Mensagem de Emergência:{" "}
              <Switch
                checked={messageremergencyactive}
                onChange={changeStatusEmergencyMessage}
                name="checkedB"
                color={"primary"}
                inputProps={{ "aria-label": "primary checkbox" }}
                disabled={emergencymessagedisabled}
              />
              <Tooltip
                title={`Ativa e desativa mensagem de emergência.\nMensagem de Emergência: Texto que o cliente vai receber quando fala com o bot, e antes de receber a árvore de opções.`}
              >
                <HelpOutlineIcon style={{ color: "gray" }} />
              </Tooltip>
            </h3>
            <div>
              <textarea
                autoFocus
                rows={3}
                value={messageremergency}
                onChange={(ev) => {
                  setMessageremergency(ev.currentTarget.value);
                }}
              />
            </div>
          </div>
          <div>
            <Button
              onClick={confirmEmergencyMessage}
              variant="contained"
              size="large"
              className={classes.button}
            >
              Confirmar
            </Button>
          </div>
        </section> */}
        <br />
        <Divider />
      </Container>
      <GlobalStyle />
    </>
  );
};
