import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import jwtDecode from "jsonwebtoken";
const ProtectedRoute = (props) => {
  console.log(props);
  let isAuth = false;
  if (localStorage.getItem("token")) {
    if (
      !(jwtDecode.decode(localStorage.getItem("token")).exp < Date.now() / 1000)
    ) {
      isAuth = true;
    }
  }
  return isAuth ? <Route {...props} /> : <Redirect to="/" />;
};

export default withRouter(ProtectedRoute);
