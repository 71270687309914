import styled from "styled-components";

export const Page = styled.div`
  position: relative;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  -webkit-order: 2;
  order: 2;
  z-index: 1;

  #messages {
    padding-top: 10px;
  }
  a {
    text-decoration: none;
  }
  button {
    border: 0;
    margin: 0;
    background: 0 0;
    outline: 0;
    cursor: pointer;
    font-family: inherit;
  }
  .btn-down{
    position: absolute;
    right: 14px;
    bottom: 25px;
    background: white;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 999999;
  }
  .copyable-area {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    .panel-down {
      transform: translateY(350px);
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
      ._3rsak {
        overflow: hidden;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        ._2fq0t {
          top: 0;
          height: 100%;
          display: flex;
          -webkit-flex-direction: column;
          flex-direction: column;
          pointer-events: auto;
          background-color: #f7f7f7;
          width: 100%;
          overflow: hidden;
          position: absolute;
          right: 0;
          left: 0;
          -webkit-user-select: text;
          -moz-user-select: text;
          -ms-user-select: text;
          user-select: text;
          ._3TSht {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1002;
            left: 0;
            right: 0;
          }
          .wDFKR {
            display: flex;
            flex: none;
            height: 49px;
            -webkit-align-items: center;
            align-items: center;
            color: #fff;
            box-sizing: border-box;
            background-color: #00bfa5;
            padding: 0 20px 0 25px;
            .Ghmsz {
              align-items: center;
              display: flex;
              -webkit-flex: none;
              flex: none;
              width: 100%;
              text-align: initial;
              .SFEHG {
                flex: none;
                width: 48px;
                opacity: 0.7;
                ._1aTxu {
                  flex: none;
                  margin-top: -2px;
                  cursor: pointer;
                  vertical-align: top;
                }
              }
              ._1xGbt {
                white-space: nowrap;
                -webkit-flex-grow: 1;
                overflow: hidden;
                flex-grow: 1;
                font-weight: 500;
                font-size: 19px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                text-overflow: ellipsis;
                .btn-reshoot {
                  -webkit-align-items: center;
                  align-items: center;
                  display: flex;
                  -webkit-flex: none;
                  flex: none;
                  cursor: pointer;
                  ._1VQOF {
                    -webkit-align-items: center;
                    align-items: center;
                    display: flex;
                    ._3KiSR {
                      flex: none;
                      margin-right: 6px;
                    }
                  }
                }
              }
            }
          }
          ._2sNbV {
            display: flex;
            -webkit-flex-direction: column;
            flex-direction: column;
            -webkit-flex: 1;
            flex: 1;
            opacity: 1;
            overflow-y: auto;
            z-index: 2;
            overflow-x: hidden;
            position: relative;
            .NuJ4j {
              align-items: center;
              display: flex;
              -webkit-flex: 1;
              flex: 1;
              -webkit-justify-content: center;
              justify-content: center;
              width: 100%;
              -webkit-align-items: center;
              display: -webkit-flex;
              position: relative;
              ._3y0EN {
                -webkit-flex: 1;
                flex: 1;
                position: relative;
                width: 100%;
                ._3EjgV {
                  background-position: 50% 50%;
                  background-size: contain;
                  background-repeat: no-repeat;
                  height: calc(100% - 40px);
                  top: 20px;
                  display: -webkit-flex;
                  left: 20px;
                  position: absolute;
                  width: calc(100% - 40px);
                  display: flex;
                  -webkit-align-items: center;
                  align-items: center;
                  -webkit-justify-content: center;
                  justify-content: center;
                  ._6Pnex {
                    -webkit-flex: 0 0 auto;
                    flex: 0 0 auto;
                    -webkit-align-self: center;
                    align-self: center;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-align-items: center;
                    align-items: center;
                    -webkit-flex-direction: column;
                    flex-direction: column;
                    .jcxhw {
                      width: 26px;
                      height: 30px;
                      background-repeat: no-repeat;
                      background-size: contain;
                      margin-bottom: 15px;
                    }
                    .icon-doc-generic {
                      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAYAAADL94L/AAAByElEQVR4Ae3axdJTQRAFYFyegA3u8ALseCDcicsGhxt3x+G32BXc3X3NBnfXYTqp3sZlhuqpOlXZRL46He9ReJyJxGSTEreaPfEHZiX+1uSJvelVNu+Jvjd7Yk9zI8aSUe0eDpjCIYfNSuw5v/zF5In/6mU27478tXriLJvXjdSwPq1lCDTCmxjiCNav8GZYBVMwWKagX8kWjk9vCcMhYWhEFEw1+oV0wZjdPKY6Vn9EwmBDTYPwBoXCYPLGDQTJjkHQNQRJj0FQtmgs+C8wOHIIkh2DoDu5vD5Xfkz9hsTBWDyxhjDYUDqvLRYSY1JilSQGyyxXOt4QKJPX70NDQmI27gyxHcn9bH/5RFMNAUgoDI4afOAMHBiCdiDNj5woGAhgsCEYudSI1lBCRwoPL957slAoDDYEoPXb/ZVs3FE/y9072fDxsx4BMPVfGOpl1VY/y5++4EWM1Fm9LcCKpy8RpnchDGEIQxjCEIYwhCEMYQhDGMIQhjCEIQxhCEMYwhCGMIQhDGEIQxhYNlXiP+XHXLRDM5thQVpyzIfS2YtLceVEkRmzalsgMArPhp258bA6b/LEb8LqPM930VNdvY/fhMmCxw+Of+4BTcPInBo2AAAAAElFTkSuQmCC);
                    }
                    .QA8Vz {
                      font-weight: 300;
                    }
                  }
                }
              }
              .r4crx {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                ._2-_Pl {
                  position: relative;
                  top: 20px;
                  width: calc(100% - 40px);
                  height: calc(100% - 60px);
                  left: 20px;
                  .IuYNx {
                    position: absolute;
                    overflow: hidden;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    text-align: center;
                    right: 0;
                    ._3m_5X {
                      z-index: 2;
                      height: 100%;
                      margin: 0 auto;
                    }
                  }
                  ._3E6Ha {
                    overflow: visible;
                    display: flex;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -webkit-align-items: center;
                    align-items: center;
                  }
                }
                ._2GgBs {
                  height: 40px;
                  padding-top: 40px;
                  text-align: center;
                }
              }
            }
            ._3fJXs {
              -webkit-flex: 1;
              flex: 1;
              display: flex;
              position: relative;
              -webkit-align-items: center;
              align-items: center;
              -webkit-justify-content: center;
              justify-content: center;
              width: 100%;
              ._1tfR8 {
                position: absolute;
                top: 0;
                display: flex;
                -webkit-flex-direction: column;
                flex-direction: column;
                -webkit-align-items: center;
                align-items: center;
                left: 0;
                height: 100%;
                width: 100%;
                ._2pEAB {
                  z-index: 2;
                  height: 100%;
                  width: 100%;
                  .IuYNx {
                    position: absolute;
                    overflow: hidden;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    text-align: center;
                    right: 0;
                    ._2oKVP {
                      opacity: 1;
                      transition: opacity 0.3s cubic-bezier(0.1, 0.82, 0.25, 1);
                      -webkit-transform-origin: 50% 50%;
                      transform-origin: 50% 50%;
                      -webkit-transform: scaleX(-1);
                      transform: scaleX(-1);
                      height: 100%;
                      width: 100%;
                      ._1Y0rl {
                        height: 100%;
                        width: 100%;
                      }
                      ._3ygW4 {
                        -webkit-animation: _2XF1e 0.5s;
                        animation: _2XF1e 0.5s;
                        height: 100%;
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
            ._3UUn3 {
              align-items: center;
              display: flex;
              -webkit-justify-content: center;
              justify-content: center;
              min-height: 90px;
              position: relative;
              z-index: 2;
              background-color: #e0e0e0;
              padding: 0 20px 6px;
              -webkit-align-items: center;
              box-sizing: border-box;
              ._26WTk {
                flex: none;
                width: 75%;
                .TI3qN {
                  position: relative;
                  word-break: break-word;
                  ._2ssn3 {
                    position: relative;
                    height: 24px;
                  }
                  ._17XE4 {
                    position: absolute;
                    pointer-events: none;
                    width: 100%;
                    height: 182px;
                    bottom: 100%;
                    padding-left: 10px;
                    padding-right: 10px;
                    left: -10px;
                    overflow: hidden;
                    ._1xHCd {
                      position: absolute;
                      bottom: 0;
                      left: 10px;
                      right: 10px;
                      .TSSFW {
                        position: absolute;
                        top: 0;
                        width: 100%;
                        left: 0;
                      }
                    }
                  }
                }
              }
              .w0LJp {
                position: absolute;
                opacity: 0;
                top: -30px;
                left: 50%;
                margin-left: -30px;
                ._3hV1n {
                  text-transform: uppercase;
                  -webkit-align-items: center;
                  align-items: center;
                  color: #fff;
                  display: -webkit-flex;
                  display: flex;
                  font-size: 14px;
                  font-weight: 500;
                  height: 46px;
                  -webkit-justify-content: center;
                  justify-content: center;
                  transition: box-shadow 80ms cubic-bezier(0.4, 0, 0.2, 1);
                  width: 46px;
                  background-color: #00e676;
                  border-radius: 50%;
                  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
                  padding: 0;
                  &:active {
                    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26),
                      0 2px 10px 0 rgba(0, 0, 0, 0.16);
                  }
                }
                .yavlE {
                  width: 60px;
                  height: 60px;
                }
              }
              ._2-dbC {
                right: 40px;
                left: auto;
              }
            }
            ._3nfoJ {
              bottom: 92px;
              position: absolute;
              z-index: 2;
              margin: 0;
              padding: 0;
              right: 40px;
              left: 40px;
              text-align: center;
              opacity: 0;
              ._3hV1n {
                text-transform: uppercase;
                -webkit-align-items: center;
                align-items: center;
                color: #fff;
                display: -webkit-flex;
                display: flex;
                font-size: 14px;
                font-weight: 500;
                height: 46px;
                -webkit-justify-content: center;
                justify-content: center;
                transition: box-shadow 80ms cubic-bezier(0.4, 0, 0.2, 1);
                width: 46px;
                background-color: #00e676;
                border-radius: 50%;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
                padding: 0;
                &:active {
                  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26),
                    0 2px 10px 0 rgba(0, 0, 0, 0.16);
                }
              }
              .yavlE {
                width: 60px;
                height: 60px;
              }
            }
          }
          ._3ySAH {
            overflow: hidden;
          }
        }
      }
    }
    .panel-down.open {
      display: block;
      transform: translateY(0px);
    }
    .panel-main {
      display: none;
      height: 10%;
    }
    .messages-container {
      border-left: 1px solid rgba(0, 0, 0, 0.03);
      left: 0;
      background-color: transparent;
      box-sizing: border-box;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      overflow-y: scroll;
      position: absolute;
      top: 0;
      transition: background 0.3s ease-out 0.1s;
      width: 100%;
      height: 100%;
      z-index: 100;
      overflow-x: hidden;
      .btn-reload {
        display: flex;
        -webkit-flex: none;
        flex: none;
        -webkit-justify-content: center;
        justify-content: center;
        padding: 0 0 24px;
        ._3dGYA {
          align-items: center;
          display: -webkit-flex;
          display: flex;
          width: 44px;
          height: 44px;
          -webkit-justify-content: center;
          justify-content: center;
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06),
            0 2px 5px 0 rgba(0, 0, 0, 0.2);
          background-color: #fff;
          border-radius: 50%;
          margin: 0 auto;
          .LQEjW path {
            fill: #c9cccd;
            fill-opacity: 1;
          }
        }
      }
      ._2S4JS {
        flex: 1 1 auto;
        min-height: 12px;
      }
      ._9tCEa {
        flex: 0 0 auto;
        padding-bottom: 8px;
        transition: padding-left 0.3s cubic-bezier(0.69, 0, 0.79, 0.14);
        padding-left: 0;
        display: flex;
        flex-direction: column-reverse;
        .message {
          margin-bottom: 12px;
          position: relative;
          padding-right: 6%;
          padding-left: 6%;
          ._3_7SH {
            box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
            position: relative;
            font-family: "Open Sans", sans-serif;
            font-size: 13.6px;
            line-height: 19px;
            color: #262626;
            border-radius: 7.5px;
            border-top-left-radius: 0;
            &:last-of-type {
              margin-bottom: 0;
            }
            strong {
              font-weight: 700;
              color: #000;
              text-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
            }
            code {
              font-size: 13px;
            }
            ._1fnMt {
              box-sizing: border-box;
              width: 276px;
              padding: 3px 3px 22px;
              ._1vKRe {
                box-sizing: border-box;
                color: inherit;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                width: 100%;
                border: 1px solid rgba(75, 89, 97, 0.1);
                cursor: pointer;
                border-radius: 6px;
                a {
                  text-decoration: none;
                }
                ._2jTyA {
                  height: 100px;
                  background-size: cover;
                  border-bottom: 1px solid rgba(75, 89, 97, 0.1);
                  background-position: 0 0;
                  background-repeat: no-repeat;
                }
                ._12xX7 {
                  border-radius: 6px;
                  border-top-left-radius: 0;
                  border-top-right-radius: 0;
                  align-items: center;
                  display: flex;
                  flex-direction: row;
                  flex: 0 0 auto;
                  text-decoration: none;
                  padding: 13px 19px;
                  cursor: pointer;
                  background-color: #f0f0f0;
                  ._3eW69 {
                    flex: none;
                    margin-top: -1px;
                    .JdzFp {
                      height: 30px;
                      width: 26px;
                      background-size: contain;
                      background-repeat: no-repeat;
                    }
                    .icon-doc-pdf {
                      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAMAAAD8KXLNAAAAvVBMVEUAAAD3d3fxRkbyRkbyRkb3fn74g4PyRUX3lZXzRUXyRUX/RkbyQED3fn7zRUX2iYn/Rkb4lpb0cHDvQEDzRETzRkbzQEDzQkLzOzv////939/yODj2enr4kJD0UVHzPT38xMT92tr5lZX1ZGT+6+v4g4P1bW35m5v7yMj91dX3fn76sLD1XFz6qan//f36trb+5OT0VVX6rKz7vb3yNDT4iIj/9vb0TEz5o6PtTEzlRUXsYWHiNzfyRETsSEiKDgROAAAAFHRSTlMAH+Z5+80n+JHu0AWTv6w6CX3V/BbNCjoAAAHfSURBVHgB7daDsisxGMDxHNtftLZqu+//WDebOu1p08H1f7D8zRoIoferx4djXb4htYvXy7vrI32NXi4U8v6KjzcbFTcKer48bQplS3dYwyhbwhpGRTpGRZpGoNvzjUT6RkU6RkX6RiJNM7dUhE/2NYkVhDU2lCgIa6DxxNr0eathBPoab5re6xjJ1pkaRu2XmP8GiIzKCSrHAW9NiKhiwKnKsFkukRMOZaWCVnWZA7uGxEaZn6UDApBGYjz3ekDFZtrGoig1FRPwZXnoQoXL6p0qwSTjy9pdxdicG41K1ue8g6kwRiWLOPcHQDzO84aoE7N9M2SMWX1eD11hal0zNMQ8tzQ2c0UU75saBXB9zhMmTJthsyc2J7cTuIRSQQ5sxzRZK9oYN4x4VGXCDMtz14IDxiusntiM0SILQ6oG71umMLKcHTCL+rEL+qZe5iWUwta+hUwYz7btoCAHTCdJEgcTgKUxY84NfPwc1AihABhLM+x2N+c6oICXqWa5RJo8bTQ59x04YgJhYMvIMnnvfGtivxmsTdr0RbmNCca0ZjR7FCtGNnCcAd6aEIELyhLFABXb35qg62k59v9d9d/8MnN5fa75QFeXZ5qvN4SezzTPCKEn8et/p9vD49UT+gEdNgrQ89qG8QAAAABJRU5ErkJggg==);
                    }
                    .icon-doc {
                      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAYAAADL94L/AAAByElEQVR4Ae3axdJTQRAFYFyegA3u8ALseCDcicsGhxt3x+G32BXc3X3NBnfXYTqp3sZlhuqpOlXZRL46He9ReJyJxGSTEreaPfEHZiX+1uSJvelVNu+Jvjd7Yk9zI8aSUe0eDpjCIYfNSuw5v/zF5In/6mU27478tXriLJvXjdSwPq1lCDTCmxjiCNav8GZYBVMwWKagX8kWjk9vCcMhYWhEFEw1+oV0wZjdPKY6Vn9EwmBDTYPwBoXCYPLGDQTJjkHQNQRJj0FQtmgs+C8wOHIIkh2DoDu5vD5Xfkz9hsTBWDyxhjDYUDqvLRYSY1JilSQGyyxXOt4QKJPX70NDQmI27gyxHcn9bH/5RFMNAUgoDI4afOAMHBiCdiDNj5woGAhgsCEYudSI1lBCRwoPL957slAoDDYEoPXb/ZVs3FE/y9072fDxsx4BMPVfGOpl1VY/y5++4EWM1Fm9LcCKpy8RpnchDGEIQxjCEIYwhCEMYQhDGMIQhjCEIQxhCEMYwhCGMIQhDGEIQxhYNlXiP+XHXLRDM5thQVpyzIfS2YtLceVEkRmzalsgMArPhp258bA6b/LEb8LqPM930VNdvY/fhMmCxw+Of+4BTcPInBo2AAAAAElFTkSuQmCC);
                    }
                  }
                  .nxILt {
                    -webkit-flex: 1 1 auto;
                    flex: 1 1 auto;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    top: -2px;
                    white-space: nowrap;
                    margin: 0 10px;
                    position: relative;
                  }
                  ._17viz {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: none;
                    width: 34px;
                    height: 34px;
                  }
                }
              }
              ._3cMIj {
                position: absolute;
                color: rgba(0, 0, 0, 0.45);
                font-family: Roboto, sans-serif;
                font-size: 11px;
                height: 15px;
                line-height: 15px;
                bottom: 3px;
                left: 8px;
                .PyPig {
                  &:before {
                    display: inline-block;
                    content: "";
                    margin: 0 4px;
                    color: rgba(0, 0, 0, 0.3);
                    color: #cbd5da;
                  }
                  &:first-child:before {
                    display: none;
                  }
                }
              }
              ._3Lj_s {
                position: absolute;
                right: 7px;
                bottom: 3px;
                ._1DZAH {
                  display: flex;
                  font-family: Roboto, sans-serif;
                  white-space: nowrap;
                  color: rgba(0, 0, 0, 0.45);
                  font-size: 11px;
                  height: 15px;
                  line-height: 15px;
                  .message-status {
                    display: inline-block;
                    margin-left: 3px;
                  }
                }
              }
            }
            ._2N_Df {
              box-sizing: border-box;
              max-width: 100%;
              width: 336px;
              padding: 6px;
              ._2jfIu {
                align-items: center;
                display: -webkit-flex;
                display: flex;
                ._2cfqh {
                  -webkit-flex-grow: 1;
                  flex-grow: 1;
                  margin-top: 2px;
                  padding-right: 8px;
                  padding-left: 8px;
                  ._1QMEq {
                    -webkit-align-items: center;
                    align-items: center;
                    display: -webkit-flex;
                    display: flex;
                    height: 34px;
                    .E5U9C {
                      position: relative;
                      -webkit-flex: none;
                      flex: none;
                      min-height: 34px;
                      min-width: 34px;
                      margin-top: -1px;
                      margin-right: 12px;
                      display: flex;
                      flex-direction: column;
                      ._1UDDE {
                        z-index: 2;
                        -webkit-animation: _2m9Rd 2s linear infinite;
                        animation: _2m9Rd 2s linear infinite;
                        ._3GbTq {
                          stroke-dasharray: 1, 150;
                          stroke-dashoffset: 0;
                          stroke-linecap: round;
                          -webkit-animation: _9ynz9 1.5s ease-in-out infinite;
                          animation: _9ynz9 1.5s ease-in-out infinite;
                          stroke: #acb9bf;
                        }
                        ._37WZ9 {
                          stroke: #849982;
                        }
                      }
                      ._2pQE3 {
                        position: relative;
                        width: 34px;
                        height: 34px;
                      }
                    }
                    ._1_Gu6 {
                      -webkit-flex: 1;
                      flex: 1;
                      .message-audio-duration {
                        color: rgba(0, 0, 0, 0.45);
                        font-size: 11px;
                        line-height: 15px;
                        position: absolute;
                        font-family: Roboto, sans-serif;
                        bottom: 4px;
                      }
                      ._1sLSi {
                        width: 100%;
                        position: relative;
                        display: inline-block;
                        .nDKsM {
                          position: absolute;
                          top: 9px;
                          height: 3px;
                          left: 0;
                          right: 0;
                          width: 0;
                          pointer-events: none;
                          z-index: 10;
                          background-color: #03a9f4;
                        }
                        ._3geJ8 {
                          height: 21px;
                          box-sizing: border-box;
                          outline: 0;
                          border: none;
                          width: 100%;
                          position: relative;
                          background-color: transparent !important;
                          &:-webkit-slider-runnable-track {
                            height: 3px;
                          }
                          &:-webkit-slider-thumb {
                            -webkit-appearance: none;
                            appearance: none;
                            width: 13px;
                            height: 13px;
                            transition: all 0.1s ease;
                            margin-top: -5px;
                            border-radius: 50%;
                            background: #03a9f4;
                            border: none;
                          }
                          &:active::-webkit-slider-thumb {
                            width: 15px;
                            height: 15px;
                            margin-top: -6px;
                          }
                          &:disabled::-webkit-slider-thumb {
                            display: none;
                          }
                          &:-moz-range-track {
                            height: 3px;
                          }
                          &:-moz-range-thumb {
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            width: 13px;
                            height: 13px;
                            transition: all 0.1s ease;
                            margin-top: -5px;
                            border-radius: 50%;
                            background: #03a9f4;
                            border: none;
                          }
                          &:-moz-focus-outer {
                            border: 0;
                          }
                          &:active::-moz-range-thumb {
                            width: 15px;
                            height: 15px;
                            margin-top: -6px;
                          }
                          &:disabled::-moz-range-thumb {
                            display: none;
                          }
                          &:-ms-track {
                            height: 3px;
                            border: none;
                          }
                          &:-ms-thumb {
                            -webkit-appearance: none;
                            appearance: none;
                            width: 13px;
                            height: 13px;
                            margin-top: 0;
                            border-radius: 50%;
                            background: #03a9f4;
                          }
                          &:active::-ms-thumb {
                            width: 15px;
                            height: 15px;
                            margin-top: -1px;
                          }
                          &:disabled::-ms-thumb {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
                ._1mbqw {
                  -webkit-align-self: flex-end;
                  align-self: flex-end;
                  -webkit-flex: none;
                  flex: none;
                  width: 0;
                  z-index: 10;
                  .QnDup {
                    width: 19px;
                    height: 26px;
                    z-index: 100;
                    margin-bottom: -3px;
                    margin-left: -10px;
                  }
                }
                ._2fuJy {
                  flex: none;
                  z-index: 1;
                  ._1WliW {
                    background-color: #dfe5e7;
                    overflow: hidden;
                    border-radius: 50%;
                    position: relative;
                    .Qgzj8 {
                      overflow: hidden;
                      border-radius: 50%;
                      width: 100%;
                      height: 100%;
                      display: block;
                      opacity: 0;
                      position: relative;
                      transition: opacity 150ms ease-out;
                      z-index: 100;
                    }
                    .gqwaM {
                      opacity: 1;
                    }
                    ._3ZW2E {
                      position: absolute;
                      top: 0;
                      z-index: 1;
                      left: 0;
                      right: 0;
                      width: 100%;
                      height: 100%;
                      svg {
                        width: 100%;
                        height: 100%;
                      }
                    }
                  }
                }
              }
              ._27K_5 {
                position: absolute;
                ._1DZAH {
                  display: flex;
                  font-family: Roboto, sans-serif;
                  white-space: nowrap;
                  color: rgba(0, 0, 0, 0.45);
                  font-size: 11px;
                  height: 15px;
                  line-height: 15px;
                  .message-status {
                    display: inline-block;
                    margin-left: 3px;
                    margin-right: 3px;
                  }
                }
              }
            }
            .LKbsn {
              ._27K_5 {
                bottom: 3px;
                right: 7px;
              }
              ._2jfIu {
                -webkit-flex-direction: row-reverse;
                flex-direction: row-reverse;
              }
              ._2cfqh {
                margin-left: 7px;
              }
            }
          }
          .message-in {
            background-color: #fff;
            float: left;
            .tail-container {
              position: absolute;
              top: 0;
              left: -12px;
              width: 12px;
              height: 19px;
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC);
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-size: contain;
              &:after {
                content: "";
              }
            }
            .highlight {
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAClUExURUxpcTU1NcrKyoyMjA4ODgAAAAwMDAAAAAAAANjY2Dc3N7a2tmBgYDg4OMvLywAAALm5ubi4uGRkZMrKymdnZ4yMjM3NzdnZ2b6+vrOzs7Ozs4GBgQAAALy8vImJiQ8PDwAAAGpqasnJyTExMVpaWg8PD8XFxdLS0jQ0NLKystbW1lpaWoaGhszMzM7Ozs/Pz9TU1NDQ0NfX19jY2NPT09HR0dnZ2Si/rzgAAAAtdFJOUwAw6pUSAxQBAv0zwVo26AXFxl7lY5HMfOi+mHYJz40RDAzjLlcQ0Psxv/xBiUTl04kAAACNSURBVCjP7cu5FoIwGEThUcE/ccV9FxV3jQmgvv+jUUE1qWy95XxngLL9bGqqXqbaZfkxFHbzlMN64wGscg8E0ZsDunVLQTDW/CH9Rk4BaA4cB7R6lgOGmoOEk4w/ELQdB9RGloKgo/lD1DajAMQLxwHJIaUgON4tfUA9vxxwul044Hr2gHr4IMS/3ysApV8k8vjzIswAAAAASUVORK5CYII=);
              opacity: 0;
            }
            ._1YNgi {
              box-sizing: border-box;
              width: 336px;
              padding: 6px 7px 8px 9px;

              ._3DZ69 {
                position: relative;
                align-items: center;
                display: flex;
                height: 60px;
                margin-left: 6px;
                margin-right: 8px;
                ._20hTB {
                  flex: none;
                  margin-right: 15px;
                  margin-left: 15px;
                  ._1WliW {
                    overflow: hidden;
                    border-radius: 50%;
                    position: relative;
                    background-color: #dfe5e7;
                    .Qgzj8 {
                      display: block;
                      opacity: 0;
                      position: relative;
                      transition: opacity 150ms ease-out;
                      z-index: 100;
                      overflow: hidden;
                      border-radius: 50%;
                      width: 100%;
                      height: 100%;
                      opacity: 1;
                    }
                    ._3ZW2E {
                      position: absolute;
                      top: 0;
                      z-index: 1;
                      left: 0;
                      right: 0;
                      width: 100%;
                      height: 100%;
                      svg {
                        width: 100%;
                        height: 100%;
                      }
                    }
                  }
                }
                ._1lC8v {
                  -webkit-flex: 1 1 auto;
                  flex: 1 1 auto;
                  overflow: hidden;
                  ._3gkvk {
                    color: #262626;
                    line-height: 22px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    /* text-align: right; */
                    font-size: 14px;
                    font-weight: 600;
                  }
                  .selectable-text {
                    -webkit-user-select: text;
                    -moz-user-select: text;
                    -ms-user-select: text;
                    user-select: text;
                  }
                  html[dir]
                    :not(.selectable-text)
                    > :not(.selectable-text)
                    > :not(.selectable-text)::-moz-selection {
                    background-color: transparent;
                  }
                  html[dir]
                    :not(.selectable-text)
                    > :not(.selectable-text)
                    > :not(.selectable-text)::selection {
                    background-color: transparent;
                  }
                  .invisible-space {
                    &:after {
                      content: "";
                      display: inline-block;
                      width: 0;
                      &:-moz-selection {
                        background-color: transparent;
                      }
                      &:selection {
                        background-color: transparent;
                      }
                    }
                  }
                }
                ._3a5-b {
                  position: absolute;
                  bottom: -4px;
                  right: -7px;
                  ._1DZAH {
                    display: flex;
                    font-family: Roboto, sans-serif;
                    white-space: nowrap;
                    color: rgba(0, 0, 0, 0.45);
                    font-size: 11px;
                    height: 15px;
                    line-height: 15px;
                    .message-status {
                      display: inline-block;
                      margin-left: 3px;
                      margin-right: 3px;
                    }
                  }
                }
              }
              ._6qEXM {
                display: -webkit-flex;
                display: flex;
                width: 100%;
                -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
                border-top: 1px solid rgba(0, 0, 0, 0.06);
                margin-top: 8px;
                .btn-message-send {
                  color: #00a5f4;
                  -webkit-flex-grow: 1;
                  flex-grow: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  padding: 10px 0 4px;
                  text-align: center;
                  margin-left: 10px;
                  margin-right: 10px;
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }
          }
          .message-out {
            background-color: #dcf8c6;
            float: right;
            border-top-right-radius: 0 !important;
            .tail-container {
              position: absolute;
              top: 0;
              right: -12px;
              width: 12px;
              height: 19px;
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAADAUExURUxpcXmHa4maet/4yA0aDRMTE8fhsgAAAAAAAMDXrCsxJeX/z1xzXIiYetPsvGBsVUdPPuH8zOH8zNDrvMvmtrrOpwAAAAAAABUVFRoaGtnyxLTMozQ+MMfftFBeSR8nH5aoh6q/mW9+ZN/4yMjhtRwlHAAAAIOWd+r/06C1kkNLOwsLC9z4xur/0+n/0t76x9v4xeL9y+b/z+j/0d/7yeH8yuX/zeD8ytz5xt76yOP/zeH+y+b/zuD8yd35xuf/0MY9jkkAAAAsdFJOUwBvd/ATDZIBAsMp/At/11c9yPbizHoICQwT4bY1ykkgjahl6s8bBYT6nUAWOLbtFAAAAIhJREFUKM/tzbUWwlAURNFBE9zdg0NecLf//yvKUJyUdDnl7HXXletXqmXl9wPbQ9JCcC+VJsOj2mDwovzj3osjHGNFEVxNRAj7UR1hlx+I4FbuC8HkZBE8OwnRxamdFsEmUxCCGdoI51RLBK9xVwTvjyMEbzlDMJMp7lqseNc8YNc6CGyF/a0vcmwhZbCG+kEAAAAASUVORK5CYII=);
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-size: contain;
              &:after {
                content: "";
              }
            }
            .highlight {
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAClUExURUxpcTU1NcrKyoyMjA4ODgAAAAwMDAAAAAAAANjY2Dc3N7a2tmBgYDg4OMvLywAAALm5ubi4uGRkZMrKymdnZ4yMjM3NzdnZ2b6+vrOzs7Ozs4GBgQAAALy8vImJiQ8PDwAAAGpqasnJyTExMVpaWg8PD8XFxdLS0jQ0NLKystbW1lpaWoaGhszMzM7Ozs/Pz9TU1NDQ0NfX19jY2NPT09HR0dnZ2Si/rzgAAAAtdFJOUwAw6pUSAxQBAv0zwVo26AXFxl7lY5HMfOi+mHYJz40RDAzjLlcQ0Psxv/xBiUTl04kAAACNSURBVCjP7cu5FoIwGEThUcE/ccV9FxV3jQmgvv+jUUE1qWy95XxngLL9bGqqXqbaZfkxFHbzlMN64wGscg8E0ZsDunVLQTDW/CH9Rk4BaA4cB7R6lgOGmoOEk4w/ELQdB9RGloKgo/lD1DajAMQLxwHJIaUgON4tfUA9vxxwul044Hr2gHr4IMS/3ysApV8k8vjzIswAAAAASUVORK5CYII=);
              opacity: 0;
            }

            ._3S8Q- {
              position: absolute;
              top: 50%;
              width: 25px;
              height: 25px;
              margin-top: -13px;
              background-color: rgba(0, 0, 0, 0.14);
              border-radius: 50%;
              left: -32px;
              right: -32px;
            }
          }
          .KYpDv {
            position: relative;
            box-sizing: border-box;
            max-width: 336px;
            z-index: 1;
            padding: 3px;
            ._3v3PK {
              position: relative;
              overflow: hidden;
              display: flex;
              -webkit-align-items: center;
              align-items: center;
              -webkit-justify-content: center;
              justify-content: center;
              max-width: 100%;
              border-radius: 6px;
              cursor: pointer;
              ._34Olu {
                width: 100%;
                height: 100%;
                z-index: 100;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                -webkit-justify-content: center;
                justify-content: center;
                left: 0;
                top: 0;
                position: absolute;
                ._2BzIU {
                  display: inline-block;
                  position: relative;
                  width: 50px;
                  height: 50px;
                  ._2X3l6 {
                    left: 0;
                    top: 0;
                    right: 0;
                    position: absolute;
                    ._1UDDE {
                      z-index: 2;
                      -webkit-animation: _2m9Rd 2s linear infinite;
                      animation: _2m9Rd 2s linear infinite;
                      ._3GbTq {
                        stroke-dasharray: 1, 150;
                        stroke-dashoffset: 0;
                        stroke-linecap: round;
                        -webkit-animation: _9ynz9 1.5s ease-in-out infinite;
                        animation: _9ynz9 1.5s ease-in-out infinite;
                        stroke: #acb9bf;
                      }
                      ._2wGBy {
                        stroke: rgba(255, 255, 255, 0.9);
                      }
                    }
                  }
                  ._1l3ap {
                    position: relative;
                    width: 44px;
                    height: 44px;
                    background-color: rgba(0, 0, 0, 0.07);
                    border-radius: 50%;
                    margin-top: 3px;
                    margin-left: 3px;
                    margin-right: 3px;
                    &:before {
                      content: "";
                      position: absolute;
                      left: -1px;
                      top: -1px;
                      right: -1px;
                      width: 44px;
                      height: 44px;
                      border: 2px solid rgba(255, 255, 255, 0.1);
                      border-radius: 50%;
                    }
                  }
                }
              }
              ._1JVSX {
                flex: none;
                transition: -webkit-filter 0.16s linear;
              }
              ._1i3Za {
                position: absolute;
                bottom: 0;
                height: 28px;
                width: 100%;
                z-index: 2;
                background: linear-gradient(
                  to top,
                  rgba(0, 0, 0, 0.3),
                  transparent
                );
                left: 0;
                right: 0;
              }
            }
            .message-container-legend {
              max-width: 330px;
              box-sizing: border-box;
              padding: 7px 4px 5px 6px;
              ._3zb-j {
                position: relative;
                white-space: normal;
                word-wrap: break-word;
                white-space: pre-line;
                a {
                  text-decoration: underline;
                }
                .selectable-text {
                  -webkit-user-select: text;
                  -moz-user-select: text;
                  -ms-user-select: text;
                  user-select: text;
                }
                html[dir]
                  :not(.selectable-text)
                  > :not(.selectable-text)
                  > :not(.selectable-text)::-moz-selection {
                  background-color: transparent;
                }
                html[dir]
                  :not(.selectable-text)
                  > :not(.selectable-text)
                  > :not(.selectable-text)::selection {
                  background-color: transparent;
                }
                .invisible-space {
                  &:after {
                    content: "";
                    display: inline-block;
                    width: 0;
                    &:-moz-selection {
                      background-color: transparent;
                    }
                    &:selection {
                      background-color: transparent;
                    }
                  }
                }
              }
              .ZhF0n {
                &:after {
                  content: "";
                  display: inline;
                  margin-right: 78px;
                  margin-left: 78px;
                }
                &:lang(hi)::after {
                  margin-right: 96px;
                }
              }
            }
            ._2TvOE {
              position: absolute;
              bottom: 6px;
              z-index: 2;
              right: 8px;
              ._1DZAH {
                display: flex;
                font-family: Roboto, sans-serif;
                white-space: nowrap;
                color: rgba(0, 0, 0, 0.45);
                font-size: 11px;
                height: 15px;
                line-height: 15px;
                .message-status {
                  display: inline-block;
                  margin-left: 3px;
                  margin-right: 3px;
                }
              }
              /* .text-white {
                color: rgba(255, 255, 255, 0.9);
              } */
            }
          }
          ._3S8Q- {
            position: absolute;
            top: 50%;
            width: 25px;
            left: -32px;
            height: 25px;
            margin-top: -13px;
            background-color: rgba(0, 0, 0, 0.14);
            border-radius: 50%;
          }
          .font-style {
            position: relative;
            font-family: "Open Sans", sans-serif;
            font-size: 13.6px;
            line-height: 19px;
            color: #262626;
            border-radius: 7.5px;
            border-top-left-radius: 0;
            box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
            strong {
              text-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
              font-weight: 700;
              color: #000;
            }
            code {
              font-size: 13px;
            }
            &:last-of-type {
              margin-bottom: 0;
            }

            .Tkt2p {
              min-width: 110px;
              box-sizing: border-box;
              padding: 6px 7px 8px 9px;
              &:lang(hi) {
                min-width: 128px;
              }
              ._3fs13 {
                margin: -3px -4px 6px -6px;
                .bODxg {
                  width: 100%;
                  .i8New {
                    text-rendering: optimizeLegibility;
                    -webkit-font-feature-settings: "kern";
                    user-select: text;
                    visibility: visible;
                    color: var(--message-primary);
                    font: inherit;
                    font-size: 100%;
                    vertical-align: initial;
                    outline: none;
                    margin: 0;
                    padding: 0;
                    border: 0;
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    overflow: hidden;
                    border-radius: 7.5px;
                    background-color: #f0f0f0;
                    ._1SmnQ {
                      flex: none;
                      width: 4px;
                      border-top-left-radius: 7.5px;
                      border-bottom-left-radius: 7.5px;
                      background-color: #6bcbef !important;
                    }
                    ._3k8SE {
                      display: flex;
                      flex-grow: 1;
                      align-items: center;
                      min-height: 42px;
                      max-height: 82px;
                      overflow: hidden;
                      padding: 4px 12px 8px 8px;
                      ._1Dook {
                        flex-grow: 1;
                        overflow: hidden;
                        .zLEDC {
                          display: -webkit-box;
                          max-height: 60px;
                          overflow: hidden;
                          color: rgba(0, 0, 0, 0.5);
                          font-size: 13.2px;
                          line-height: 20px;
                          white-space: pre-wrap;
                          text-overflow: ellipsis;
                          word-wrap: break-word;
                          -webkit-line-clamp: 3;
                          -webkit-box-orient: vertical;
                        }
                      }
                    }
                  }
                }
              }
              ._3zb-j {
                position: relative;
                white-space: normal;
                word-wrap: break-word;
                white-space: pre-line;
                a {
                  text-decoration: underline;
                }
                .selectable-text {
                  -webkit-user-select: text;
                  -moz-user-select: text;
                  -ms-user-select: text;
                  user-select: text;
                }
                html[dir]
                  :not(.selectable-text)
                  > :not(.selectable-text)
                  > :not(.selectable-text)::-moz-selection {
                  background-color: transparent;
                }
                html[dir]
                  :not(.selectable-text)
                  > :not(.selectable-text)
                  > :not(.selectable-text)::selection {
                  background-color: transparent;
                }
                .invisible-space {
                  &:after {
                    content: "";
                    display: inline-block;
                    width: 0;
                    &:-moz-selection {
                      background-color: transparent;
                    }
                    &:selection {
                      background-color: transparent;
                    }
                  }
                }
              }
              .ZhF0n {
                &:after {
                  content: "";
                  display: inline;
                  margin-right: 78px;
                }
                &:lang(hi)::after {
                  margin-right: 96px;
                }
              }
              ._2f-RV {
                position: absolute;
                bottom: 3px;
                right: 7px;
                ._1DZAH {
                  display: flex;
                  font-family: Roboto, sans-serif;
                  color: rgba(0, 0, 0, 0.45);
                  font-size: 11px;
                  height: 15px;
                  line-height: 15px;
                  white-space: nowrap;
                  .msg-time {
                    display: inline-block;
                    vertical-align: top;
                    margin-top: 1px;
                  }
                }
              }
            }
          }
          &:after {
            content: "";
            display: table;
            clear: both;
          }
        }
        .focusable-list-item {
          position: relative;
          padding-left: 9%;
          padding-right: 9%;
          margin-bottom: 12px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          ._2et95 {
            position: relative;
            background-color: rgba(225, 245, 254, 0.92);
            border-radius: 7.5px;
            box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
            padding: 5px 12px 6px;
            text-align: center;
            text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
            color: rgba(0, 0, 0, 0.96);
            box-sizing: border-box;
            display: inline-block;
            flex: none;
            font-size: 12.5px;
            line-height: 21px;
            max-width: 100% !important;
            &:last-of-type {
              margin-bottom: 0;
            }
            ._3sKvP {
              border-radius: 7.5px;
              position: relative;
              z-index: 200;
            }
          }
        }
      }
      &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
      }
      &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.08);
      }
    }
  }
  @media (max-width: 1216px) and (min-width: 1152px) {
    .message {
      padding-right: 8.5%;
      padding-left: 8.5%;
    }
  }
  @media (max-width: 1152px) and (min-width: 1088px) {
    .message {
      padding-right: 8%;
      padding-left: 8%;
    }
  }
  @media (max-width: 1088px) and (min-width: 1025px) {
    .message {
      padding-right: 7.5%;
      padding-left: 7.5%;
    }
  }
  @media (max-width: 1024px) {
    .message {
      padding-right: 7%;
      padding-left: 7%;
    }
  }
  @media (max-width: 968px) {
    .message {
      padding-right: 6.5%;
      padding-left: 6.5%;
    }
  }
  @media (max-width: 912px) {
    .message {
      padding-right: 6%;
      padding-left: 6%;
    }
  }
  @media screen and (max-width: 900px) {
    ._3DFk6 {
      max-width: 95%;
    }
  }
  @media screen and (min-width: 901px) and (max-width: 1024px) {
    ._3DFk6 {
      max-width: 85%;
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1300px) {
    ._3DFk6 {
      max-width: 75%;
    }
  }
  @media screen and (min-width: 1301px) {
    ._3DFk6 {
      max-width: 65%;
    }
  }
  @media (max-width: 856px) {
    .message {
      padding-right: 5.5%;
      padding-left: 5.5%;
    }
  }
  @media (max-width: 800px) {
    .message {
      padding-right: 5%;
      padding-left: 5%;
    }
  }
`;
