import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import IsLogged from "./IsLogged";
import ProtectedRoute from "./ProtectedRoute";
import Register from "../pages/Register/index";
import NavBar from "../pages/NavBar";
import Alert from "../components/Alert";
import CLientsAwaitVerification from "../components/ClientsAwaitVerification";
import InitialPage from "../pages/InitialPage";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <IsLogged exact path="/" />
        <Route exact path="/register" component={Register} />
        <ProtectedRoute path="/home">
          <Alert />
          {localStorage.user && <CLientsAwaitVerification />}
          <Route path="/home" component={NavBar} />
        </ProtectedRoute>
        <Route component={InitialPage} />
      </Switch>
    </Router>
  );
}
