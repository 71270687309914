import React from "react";

export default function ({ message }) {
  return (
    <div
      tabIndex="0"
      className="focusable-list-item"
      data-id="true_5511986971267@c.us_7EF7EE0A1AD2007FAD129DC09D1CB6C1"
    >
      <span />
      <div
        className="_2et95 _2nH_- _1E1g0"
        style={{
          backgroundColor: message.color && message.color,
        }}
      >
        <div className="_3sKvP">
          <div role="button">
            <span>
              <span dir="ltr" className="_3Whw5">
                {message.content}
              </span>
            </span>
          </div>
          <span />
        </div>
      </div>
    </div>
  );
}
