import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";
import api from "../../../services/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      justifyContent: "center",
    },
  },
  margin: {
    width: "100%",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    marginLeft: theme.spacing(4),
  },
}));
export default function AlertDialog({ close, data, setData, usersSelected }) {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(usersSelected);
  const [users, setUsers] = React.useState(data);
  const [usersfilter, setUsersfilter] = React.useState(data);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const classes = useStyles();
  const searchingFor = (term) => (x) =>
    x.username.toLowerCase().includes(term.toLowerCase()) || !term;
  const onChange = (ev) => {
    const array = users;
    setUsersfilter(array.filter(searchingFor(ev.currentTarget.value)));
  };
  const confirm = () => {
    setData([...checked]);
    close();
  };

  return (
    <div>
      <Dialog
        open
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {t("WT.dialogs.user.title")}
        </DialogTitle>
        <DialogContent>
          <FormControl className={classes.margin}>
            <Input
              id="input-with-icon-adornment"
              onChange={onChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
          <List className={classes.root}>
            {!!usersfilter.length &&
              usersfilter.map((value, index) => {
                const labelId = `checkbox-list-label-${index}`;

                return (
                  <ListItem
                    key={value.idusers}
                    role={undefined}
                    dense
                    button
                    onClick={handleToggle(value.idusers)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(value.idusers) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${value.username}`} />
                  </ListItem>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={close} autoFocus>
            <Button variant="contained" color="secondary">
              {t("WT.dialogs.user.bt2")}
            </Button>
          </Button>
          <Button color="primary" onClick={confirm} autoFocus>
            <Button
              variant="contained"
              style={{ background: "#34b7f1", color: "white" }}
            >
              {t("WT.dialogs.user.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
