import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import Collapse from "@material-ui/core/Collapse";
import { useTranslation } from "react-i18next";
import PersonIcon from "@material-ui/icons/Person";
import { CircularProgress } from "@material-ui/core";
import api from "../../../services/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: 600,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ActivityUsersTickets({ close, user }) {
  const { t } = useTranslation();
  const [tickets, setTickets] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const handleClose = () => {
    close();
  };
  async function fetchData() {
    setLoading(true);
    await api
      .get(`users/ticketsopen/?idusers=${user.idusers}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setTickets(res.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  }
  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open
        fullWidth
        maxWidth="md"
        className={classes.dialog}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Tickets aceitos
        </DialogTitle>
        <DialogContent dividers>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                style={{ marginLeft: "57px" }}
              >
                Id - Nome - Número - Data
              </ListSubheader>
            }
          >
            {loading ? (
              <CircularProgress />
            ) : (
              tickets.length &&
              tickets.map((ticket) => (
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <ConfirmationNumberIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${ticket.idticket} - ${ticket.client.name} - ${
                      ticket.number
                    }  - ${new Date(
                      ticket.ticket_timelines[
                        ticket.ticket_timelines.length - 1
                      ].timestamp
                    ).toLocaleString()}`}
                  />
                </ListItem>
              ))
            )}
            {}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
          >
            {t("DB.dialogs.activityusers.bt2")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
