import React, { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import api from "../../services/axios";
import { Container } from "./style";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import CancelSyncConfirmation from "./Dialogs/CancelSyncConfirmation";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Logs from "./Dialogs/Logs";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default () => {
  const [openconfirm, setOpenconfirm] = React.useState(false);
  const [opnlogs, setOpenlogs] = React.useState(false);
  const [logsactive, setLogsactive] = React.useState([]);
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState([]);
  const [taskselected, setTaskselected] = useState(null);
  const classes = useStyles();
  const getTasks = () => {
    api
      .get("/tasks", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        setTasks(response.data);
      })
      .catch((e) => console.log(e));
  };
  const cancelTask = () => {
    setOpenconfirm(false);
    api
      .put(
        "/tasks",
        { idtasks: taskselected.idtasks, status: 2 },
        {
          headers: {
            authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((response) => {
        setTaskselected(null);
        getTasks();
      })
      .catch((e) => console.log(e));
  };
  const options = {
    filterType: false,
    print: false,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    filter: false,
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum dado encontrado",
        toolTip: "Sort",

        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        rowsPerPage: "Linhas por Página",
      },
    },
  };
  const getStatus = (key) => {
    switch (key) {
      case 1:
        return "PENDENTE";
      case 2:
        return "CANCELADA";
      case 3:
        return "CONCLUÍDA";
      default:
        return "INDEFINIDO";
    }
  };
  const columns = ["ID", "Descrição", "Data", "Status", "Ação", "Logs"];
  React.useEffect(() => {
    getTasks();
  }, []);
  React.useEffect(() => {
    if (logsactive.length) {
      setOpenlogs(true);
    }
  }, [logsactive]);
  return (
    <>
      {openconfirm && (
        <CancelSyncConfirmation
          cancel={() => {
            setOpenconfirm(false);
          }}
          confirm={cancelTask}
        />
      )}
      {opnlogs && (
        <Logs
          close={() => {
            setLogsactive([]);
            setOpenlogs(false);
          }}
          logs={logsactive}
        />
      )}
      <Container>
        <div className="head">
          <h3 style={{ display: "flex", alignItems: "center" }}>
            Lista de Sincronizações agendadas:
          </h3>
          <Divider />
          <br />
          <MUIDataTable
            title={"Sincronizações"}
            data={tasks.map((task) => {
              return [
                task.idtasks,
                task.description,
                new Date(task.timestamp).toLocaleDateString(),
                getStatus(task.status),
                task.status === 1 && (
                  <Button
                    onClick={() => {
                      setTaskselected(task);
                      setOpenconfirm(true);
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    startIcon={<CancelIcon />}
                  >
                    Cancelar
                  </Button>
                ),
                <Button
                  onClick={() => {
                    setLogsactive(task.tasks_logs);
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                >
                  <AssignmentIcon />
                </Button>,
              ];
            })}
            columns={columns}
            options={options}
          />
        </div>
      </Container>
    </>
  );
};
