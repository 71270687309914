import React, { useEffect, useState } from "react";
import Divider from "@material-ui/core/Divider";
import Person from "@material-ui/icons/Person";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UsersActive } from "./style";
import api from "../../../services/axios";
import socket from "../../../services/socket";
import UsersLog from "../Dialogs/UsersLog";
export default function () {
  const user = JSON.parse(localStorage.user);
  const [loading, setLoading] = useState(true);
  const [showUsersLogs, setShowUsersLogs] = useState(false);
  const [data, setData] = useState([]);
  function fetchData() {
    api
      .get("/usersonline", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        setData(response.data.users);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  }
  useEffect(() => {
    fetchData();
    socket.on(`${user.company.prefix}_client_connection_updated`, fetchData);
  }, []);
  return (
    <>
      {
        showUsersLogs && <UsersLog
          close={() => {
            setShowUsersLogs(false);
          }} />
      }
      <UsersActive>
        <div className="title">
          <h3>Usuários Online</h3>
          <div className="title-btn" onClick={()=>setShowUsersLogs(true)}>Ver logs de acessos</div>
        </div>
        <div className="users">
          {loading ? (
            <CircularProgress />
          ) : (
            data.map((userData) => (
              <>
                <div className="profile">
                  <div className="avatar">
                    <Person />
                  </div>
                  <div className="circle" />
                  <p>{userData.user.username}</p>
                </div>
                <Divider />
              </>
            ))
          )}
        </div>
      </UsersActive>
    </>
  );
}
