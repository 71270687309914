import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import api from "../../services/axios";
import socket from "../../services/socket";
import Can from "../../components/Can";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();
  const [requests, setRequests] = React.useState([]);
  const user = JSON.parse(localStorage.user);
  useEffect(() => {
    console.log(user);
    api
      .get("clients/clientrequest", {
        params: {
          company: user.company,
        },
      })
      .then((res) => {
        setRequests(res.data);
      });
  }, []);
  const updateData = async () => {
    await api
      .get("clients/clientrequest", {
        params: {
          company: user.company,
        },
      })
      .then((res) => {
        setRequests([...res.data]);
      });
  };
  const acceptedRequest = (index) => {
    const request = requests[index];
    request.situation = Number(1);
    api
      .post("clients/clientrequestupdate", request, {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        if (res) {
          updateData();
        }
      });
  };
  const declineRequest = (index) => {
    const request = requests[index];
    request.situation = Number(0);
    api
      .post("clients/clientrequestupdate", request, {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        if (res) {
          updateData();
        }
      });
  };
  const options = {
    filterType: false,
    print: false,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    filter: false,
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: t("RQ.table.noMatch"),
        toolTip: "Sort",

        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        rowsPerPage: "Linhas por Página",
      },
    },
  };
  const columns = [
    "ID",
    t("RQ.table.name"),
    "Whatsapp",
    "CNPJ",
    t("RQ.table.rz"),
    t("RQ.table.codclient"),
    t("RQ.table.accept"),
    t("RQ.table.decline"),
  ];
  React.useEffect(() => {
    socket.on(`${user.company.prefix}_newrequest`, () => {
      updateData();
    });
  }, []);
  return (
    <>
      <MUIDataTable
        title={t("RQ.title")}
        data={requests.map((request, index) => [
          request.idrequests,
          request.name,
          request.number,
          request.cnpj,
          request.rzsocial,
          request.cod_cliente_dislub,
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="requests"
            action="update"
            yes={() => (
              <DoneAllIcon
                style={{ fill: "#9c60ca", cursor: "pointer" }}
                onClick={() => {
                  acceptedRequest(index);
                }}
              />
            )}
          />,
          <Can
            role={JSON.parse(localStorage.user).role.idroles}
            type="requests"
            action="update"
            yes={() => (
              <HighlightOffIcon
                style={{ fill: "#9c60ca", cursor: "pointer" }}
                onClick={() => {
                  declineRequest(index);
                }}
              />
            )}
          />,
          ,
        ])}
        columns={columns}
        options={options}
      />
    </>
  );
};
