import styled from "styled-components";

export const Page = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #e5ddd5;
  transform-origin: left top;
  .YUoyu {
    position: absolute;
    right: 0;
    left: 0;
    height: 100%;
    top: 0;
    width: 100%;
    opacity: 0.06;
    background-repeat: repeat repeat;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 2dppx) {
    .YUoyu {
      background-size: 366.5px 666px;
    }
  }
`;
export const PageLocked = styled.footer`
  position: relative;
  z-index: 1;
  flex: none;
  order: 3;
  box-sizing: border-box;
  min-height: 62px;
  background-color: #f0f0f0;
  ._3ge3i {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-height: 62px;
    color: var(--secondary);
    font-size: 14px;
    line-height: 20px;
    padding: 14px 19px;
    text-align: center;
    background-color: #ededed;
  }
`;
