import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Page } from "./style";
import ActiveChat from "./ActiveChat";
import ActiveGroup from "./ActiveGroup";
import InitialPage from "./Initial/index";
import api from "../../../services/axios";

export default function PanelRight(props) {
  const dispatch = useDispatch();
  const paneldisplay = useSelector((state) => state.panelRight.display);

  function openChat(idclient, number) {
    dispatch({ type: "SET_USER_ACTIVE_CHAT", user: { idclient, number } });
    dispatch({ type: "SET_RENDER", display: "ACTIVECHAT" });
  }
  function getClientInfo(number) {
    api
      .get("/clients/clientinfo", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: { number },
      })
      .then((response) => {
        openChat(response.data.idclient, response.data.number);
      })
      .catch((e) => {
        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: "Cliente não encontrado.",
            idclient: 0,
            read: false,
          },
        });
      });
  }
  React.useEffect(() => {
    if (props.match.params.hasOwnProperty("number")) {
      console.log(props.match.params.number);
      getClientInfo(props.match.params.number);
    }
    return () => {
      dispatch({ type: "SET_RENDER", display: "INITIAL" });
    };
  }, []);
  const setRender = () => {
    switch (paneldisplay) {
      case "INITIAL":
        return <InitialPage />;
      case "ACTIVECHAT":
        return <ActiveChat />;
      case "ACTIVEGROUP":
        return <ActiveGroup />;
      default:
        break;
    }
  };
  return <Page>{setRender()}</Page>;
}
