import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ImportsLogs from "./ImportsLogs";

export default function ImportContactsConfirmation({ data, cancel }) {
  console.log(data, "adata");
  const [openLogs, setOpenLogs] = useState(false);
  function handleCancel() {
    setOpenLogs(false);
    cancel();
  }
  return (
    <div>
      <Dialog
        open
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmar importação dos contatos?
        </DialogTitle>
        <DialogContent>
          Após iniciada a importação não será possível cancelar-la.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              cancel();
            }}
            color="primary"
          >
            <Button variant="contained" color="secondary">
              Cancelar
            </Button>
          </Button>
          <Button
            onClick={() => {
              setOpenLogs(true);
            }}
            color="primary"
            autoFocus
          >
            <Button variant="contained" color="primary">
              Confirmar
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
      {openLogs && <ImportsLogs data={data} cancel={handleCancel} />}
    </div>
  );
}
