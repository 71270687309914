import React from "react";
import { Page } from "./style";
import Snippet from "./Snippet";
import Charts from "./Charts/index";

export default function (props) {
  React.useEffect(() => {
    document.addEventListener("DOMContentLoaded", () => {
      if (!Notification) {
        alert("Navegador não suporta notificações!");
        return;
      }

      if (Notification.permission !== "granted") {
        Notification.requestPermission();
      }
    });
  }, []);
  return (
    <Page>
      <Snippet history={props.history} />
      <Charts />
    </Page>
  );
}
