import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import Divider from "@material-ui/core/Divider";
import api from "../../../services/axios";

import ClientSelect from "./ClientSelect";
import SendMessageConfirmation from "./SendMessageConfirmation";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      minWidth: "65ch",
      justifyContent: "center",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    background: "#34b7f1",
    color: "#f2f2f2",
    marginLeft: "5px",
    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
  colorPrimary: {
    backgroundColor: "rgb(0, 191, 165)",
  },
  barColorPrimary: {
    backgroundColor: "#B2DFDB",
  },
}));
export default function AlertDialog(props) {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState([]);
  const [wallets, setWallets] = React.useState([]);
  const [clientsToSendMessage, setClientsToSendMessage] = React.useState([]);
  const [walletsFilter, setWalletsFilter] = React.useState([]);
  const [walletToFilter, setWalletToFilter] = React.useState(null);
  const classes = useStyles();

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const newData = [...wallets];
    const index = newData.findIndex((x) => x.idwallet === value);
    if (currentIndex === -1) {
      newChecked.push(value);
      newData[index].wallet.clients = newData[index].wallet.clients.map(
        (client) => {
          return { ...client, checked: true };
        }
      );
    } else {
      newChecked.splice(currentIndex, 1);
      newData[index].wallet.clients = newData[index].wallet.clients.map(
        (client) => {
          delete client.checked;
          return client;
        }
      );
    }
    setWalletsFilter([...newData]);
    setChecked(newChecked);
  };
  const handleUncheckAll = () => {
    const newData = [...wallets];
    newData.map((wallet) => {
      wallet.wallet.clients.map((x) => {
        delete x.checked;

        return x;
      });
    });
    setWalletsFilter([...newData]);
    setChecked([]);
  };
  const handleCheckAll = () => {
    const newChecked = [];

    const newData = [...wallets];
    newData.map((wallet) => {
      newChecked.push(wallet.wallet.idwallets);

      wallet.wallet.clients.map((x) => {
        x.checked = true;
        return x;
      });
    });
    setWalletsFilter([...newData]);
    setChecked([...newChecked]);
  };
  const handleClose = () => {
    props.func(false);
  };
  const getWallets = async () => {
    await api
      .get("wallet/clients", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setWallets(res.data);
        setWalletsFilter(res.data);
      });
  };

  const searchingFor = (term) => (x) => {
    term = term.toLowerCase();
    let index;
    return index || x.wallet.name.toLowerCase().indexOf(term) !== -1 || !term;
  };
  const onChange = (ev) => {
    const array = wallets;
    setWalletsFilter(array.filter(searchingFor(ev.currentTarget.value)));
  };
  function handleCheckClients(wallet) {
    const newData = wallets;
    const index = newData.findIndex(
      (x) => x.wallet.idwallets === wallet.idwallets
    );
    newData[index].wallet = wallet;
    setWalletsFilter([...newData]);
  }
  function handleConfirmSend() {
    let clients = [];
    const walletsChecked = wallets.filter(
      (wallet) => checked.indexOf(wallet.wallet.idwallets) !== -1
    );
    if (!walletsChecked.length) return;
    walletsChecked.map(({ wallet }) => {
      const clientsChecked = wallet.clients.filter((client) => client.checked);
      if (clientsChecked.length) {
        clients = [...clients, ...clientsChecked];
      }
    });
    if (!clients.length) return;
    setClientsToSendMessage([...clients]);
  }
  React.useEffect(() => {
    getWallets();
  }, []);
  return (
    <div>
      <Dialog
        open
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("DMT.dialogs.clients.title")}
        </DialogTitle>
        <DialogContent>
          <FormControl className={classes.margin} style={{ width: "100%" }}>
            <Input
              id="input-with-icon-adornment"
              onChange={onChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
          <br />
          <Divider />
          <br />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <a
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                handleCheckAll();
              }}
            >
              {t("DMT.dialogs.clients.a1")}
            </a>
            <a
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                handleUncheckAll();
              }}
            >
              {t("DMT.dialogs.clients.a2")}
            </a>
            <div>
              {" "}
              {t("DMT.dialogs.clients.info")} : {checked.length}
            </div>
          </div>
          <br />
          <Divider />

          <Divider />
          <List className={classes.root}>
            {!!walletsFilter.length &&
              walletsFilter.map((value, index) => {
                const labelId = `checkbox-list-label-${index}`;

                return (
                  <ListItem
                    key={value.wallet.idwallets}
                    role={undefined}
                    dense
                    button
                    onClick={handleToggle(value.wallet.idwallets)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(value.wallet.idwallets) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${value.wallet.name}`}
                    />
                    {`Filtrados: ${
                      value.wallet.clients.filter((x) => x.checked).length
                    }`}
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        setWalletToFilter(value.wallet);
                      }}
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                    >
                      Filtrar Clientes
                    </Button>
                  </ListItem>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose} autoFocus>
            <Button variant="contained" color="secondary">
              {t("DMT.dialogs.clients.bt2")}
            </Button>
          </Button>
          <Button color="primary" onClick={handleConfirmSend} autoFocus>
            <Button variant="contained" className={classes.button}>
              {t("DMT.dialogs.clients.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
      {walletToFilter && (
        <ClientSelect
          close={() => {
            setWalletToFilter(null);
          }}
          update={handleCheckClients}
          wallet={walletToFilter}
        />
      )}
      {clientsToSendMessage.length && (
        <SendMessageConfirmation
          cancel={() => {
            setClientsToSendMessage([]);
          }}
          data={clientsToSendMessage}
          message={props.data}
        />
      )}
    </div>
  );
}
