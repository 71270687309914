import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import Divider from "@material-ui/core/Divider";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { CircularProgress } from "@material-ui/core";
import { TicketsSector } from "./style";
import api from "../../../services/axios";
import SelectPeriod from "../Dialogs/SelectPeriod";
import { useTranslation } from "react-i18next";
import socket from "../../../services/socket";
export default function () {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.user);
  const [selected, setSelected] = React.useState(0);
  const [showloading, setShowloading] = React.useState(true);
  const [data, setData] = React.useState({});
  const [showShowSelectPeriod, setShowShowSelectPeriod] = React.useState(false);
  const dispatch = useDispatch();

  function getColorsByStatus(label) {
    switch (label) {
      case "ACEITO":
        return {
          backgroundColor: "rgba(46, 216, 182, 0.2)",
          borderColor: "rgba(46, 216, 182, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(46, 216, 182, 0.4)",
          hoverBorderColor: "rgba(46, 216, 182, 1)",
        };
      case "FECHADO":
        return {
          backgroundColor: "rgba(64, 153, 255, 0.2)",
          borderColor: "rgba(64, 153, 255, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(64, 153, 255, 0.4)",
          hoverBorderColor: "rgba(64, 153, 255, 1)",
        };
      case "PENDENTE":
        return {
          backgroundColor: "rgba(255, 182, 77, 0.2)",
          borderColor: "rgba(255, 182, 77, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255, 182, 77, 0.4)",
          hoverBorderColor: "rgba(255, 182, 77, 1)",
        };
      case "CANCELADO":
        return {
          backgroundColor: "rgba(255, 83, 112, 0.2)",
          borderColor: "rgba(255, 83, 112, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255, 83, 112, 0.4)",
          hoverBorderColor: "rgba(255, 83, 112, 1)",
        };
      default:
        return {
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
        };
    }
  }
  function ticketsOrganize(arraytickets, date) {
    const filter = [];
    arraytickets.map((ticket) => {
      const index = filter.findIndex((x) => ticket.status === x.label);
      if (index === -1) {
        filter.push({
          label: ticket.status,
          data: [ticket],
        });
      } else {
        filter[index].data.push(ticket);
      }
    });
    switch (selected) {
      case 0:
        const finaldataweek = {
          labels: ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"],
          datasets: [],
        };
        filter.map((x) => {
          const colors = getColorsByStatus(x.label);
          const ticketinfo = {
            label: x.label,
            backgroundColor: colors.backgroundColor,
            borderColor: colors.borderColor,
            borderWidth: 1,
            hoverBackgroundColor: colors.hoverBackgroundColor,
            hoverBorderColor: colors.hoverBorderColor,
            data: [0, 0, 0, 0, 0, 0, 0],
          };
          x.data.map((z) => {
            const ticketdate = new Date(z.timestamp);
            ticketinfo.data[ticketdate.getDay()] =
              ticketinfo.data[ticketdate.getDay()] + 1;
          });
          finaldataweek.datasets.push(ticketinfo);
        });
        setData(finaldataweek);
        break;
      case 1:
        const finaldatamonth = {
          labels: [
            "JAN",
            "FEV",
            "MAR",
            "ABR",
            "MAIO",
            "JUN",
            "JUL",
            "AGO",
            "SET",
            "OUT",
            "NOV",
            "DEZ",
          ],
          datasets: [],
        };
        filter.map((x) => {
          const colors = getColorsByStatus(x.label);
          const ticketinfo = {
            label: x.label,
            backgroundColor: colors.backgroundColor,
            borderColor: colors.borderColor,
            borderWidth: 1,
            hoverBackgroundColor: colors.hoverBackgroundColor,
            hoverBorderColor: colors.hoverBorderColor,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          };
          x.data.map((z) => {
            const ticketdate = new Date(z.timestamp);
            ticketinfo.data[ticketdate.getMonth()] =
              ticketinfo.data[ticketdate.getMonth()] + 1;
          });
          finaldatamonth.datasets.push(ticketinfo);
        });
        setData(finaldatamonth);
        break;
      case 2:
        const finaldatayear = {
          labels: [
            "2020",
            "2021",
            "2022",
            "2023",
            "2024",
            "2025",
            "2026",
            "2027",
            "2028",
            "2029",
            "2030",
          ],
          datasets: [],
        };
        filter.map((x) => {
          const colors = getColorsByStatus(x.label);
          const ticketinfo = {
            label: x.label,
            backgroundColor: colors.backgroundColor,
            borderColor: colors.borderColor,
            borderWidth: 1,
            hoverBackgroundColor: colors.hoverBackgroundColor,
            hoverBorderColor: colors.hoverBorderColor,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          };
          x.data.map((z) => {
            const ticketdate = new Date(z.timestamp);
            const indexticketinfo = finaldatayear.labels.indexOf(
              ticketdate.getFullYear().toString()
            );
            ticketinfo.data[indexticketinfo] =
              ticketinfo.data[indexticketinfo] + 1;
          });
          finaldatayear.datasets.push(ticketinfo);
        });
        setData(finaldatayear);
        break;
      case 3:
        const finaldatacustom = {
          labels: [],
          datasets: [],
        };
        for (
          let i = date.initial.getTime();
          i < date.final.getTime();
          i = date.initial.setDate(date.initial.getDate() + 1)
        ) {
          finaldatacustom.labels.push(date.initial.toLocaleDateString());
        }
        filter.map((x) => {
          const colors = getColorsByStatus(x.label);
          const ticketinfo = {
            label: x.label,
            backgroundColor: colors.backgroundColor,
            borderColor: colors.borderColor,
            borderWidth: 1,
            hoverBackgroundColor: colors.hoverBackgroundColor,
            hoverBorderColor: colors.hoverBorderColor,
            data: [],
          };
          for (let i = 0; i <= finaldatacustom.labels.length; i++) {
            ticketinfo.data.push(0);
          }
          x.data.map((z) => {
            const ticketdate = new Date(z.timestamp);
            const indexticketinfo = finaldatacustom.labels.indexOf(
              ticketdate.toLocaleDateString()
            );
            ticketinfo.data[indexticketinfo] =
              ticketinfo.data[indexticketinfo] + 1;
          });
          finaldatacustom.datasets.push(ticketinfo);
        });
        setData(finaldatacustom);
        break;

      default:
        break;
    }
  }
  const getTickets = async (date) => {
    setShowloading(true);
    const datatime = {
      initial: new Date(`${date.initial} 00:00:00`),
      final: new Date(`${date.final} 23:59:59`),
    };

    await api
      .get("dashboard/tickets", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: datatime,
      })
      .then((res) => {
        ticketsOrganize(res.data, datatime);
        setShowloading(false);
      })
      .catch((e) => {
        setShowloading(false);

        dispatch({
          type: "ADD_NOTIFY",
          notify: {
            type: "error",
            message: e.response.data.error,
            idclient: 0,
            read: false,
          },
        });
      });
  };
  function dateFilter(date) {
    getTickets(date);
  }
  function handleSelection() {
    const now = new Date();
    switch (selected) {
      case 0:
        const firstdayweek = new Date(
          now.setDate(now.getDate() - now.getDay())
        );
        const lastdayweek = new Date(
          now.setDate(now.getDate() - now.getDay() + 6)
        );
        getTickets({
          initial: firstdayweek.toDateInputValue(),
          final: lastdayweek.toDateInputValue(),
        });
        break;
      case 1:
        getTickets({
          initial: new Date(new Date().getFullYear(), 0, 1).toDateInputValue(),
          final: new Date(new Date().getFullYear(), 11, 31).toDateInputValue(),
        });
        break;
      case 2:
        getTickets({
          initial: new Date(2020, 0, 1).toDateInputValue(),
          final: new Date(2030, 11, 31).toDateInputValue(),
        });
        break;
      default:
        break;
    }
  }
  React.useEffect(() => {
    handleSelection();
  }, [selected]);
  useEffect(() => {
    socket.on(`${user.company.prefix}_newticket`, () => {
      handleSelection();
    });
    // socket.on(`${user.company.prefix}_updateticket`, () => {
    //   handleSelection();
    // });
  }, []);
  return (
    <>
      {showShowSelectPeriod && (
        <SelectPeriod
          close={() => {
            setShowShowSelectPeriod(false);
          }}
          func={dateFilter}
        />
      )}
      <TicketsSector>
        <div className="titleperiod">
          {" "}
          <h3>{t("DB.charts.tickets.title")}</h3>
          <div>
            <span
              style={{ textDecoration: selected === 2 ? "none" : "underline" }}
              onClick={() => {
                setSelected(2);
              }}
            >
              {t("DB.charts.period.year")}
            </span>
            <span
              style={{ textDecoration: selected === 1 ? "none" : "underline" }}
              onClick={() => {
                setSelected(1);
              }}
            >
              {t("DB.charts.period.month")}
            </span>
            <span
              style={{ textDecoration: selected === 0 ? "none" : "underline" }}
              onClick={() => {
                setSelected(0);
              }}
            >
              {t("DB.charts.period.week")}
            </span>
            <DateRangeIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelected(3);
                setShowShowSelectPeriod(true);
              }}
            />
          </div>
        </div>
        <Divider />
        <div>
          {showloading && (
            <div className="mask">
              <CircularProgress />
            </div>
          )}
          <Bar data={data} />
        </div>
      </TicketsSector>
    </>
  );
}
