import React from "react";
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Page } from "./style";
import whatsapp from "./img/whatsapp.png";
import Search from "../Search/index";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default function Chats({ data, setData }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [usersfiltred, setUsersFiltred] = React.useState([]);
  const [userselected, setUserselected] = React.useState("");
  const [clientsfiltred, setClientsfiltred] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  function openChat(client) {
    const index = data.findIndex(
      (x) =>
        x.client.idclient === client.idclient &&
        x.user.idusers === userselected.idusers
    );
    dispatch({
      type: "SET_MESSAGES_FILTER",
      messagesfilter: data[index].messages,
    });
    dispatch({
      type: "SET_CLIENT_INFO_FILTER",
      clientinfo: client,
    });
    dispatch({
      type: "SET_USER_ACTIVE_CHAT_FILTER",
      user: {
        id: client.idclient,
        number: client.number,
        idusers: userselected.idusers,
      },
    });
    dispatch({ type: "SET_RENDER_FILTER", display: "ACTIVECHAT" });
  }
  function clearRedux() {
    dispatch({
      type: "SET_MESSAGES_FILTER",
      messagesfilter: [],
    });
    dispatch({
      type: "SET_CLIENT_INFO_FILTER",
      clientinfo: {},
    });
    dispatch({
      type: "SET_USER_ACTIVE_CHAT_FILTER",
      user: {
        id: undefined,
        number: undefined,
      },
    });
    dispatch({ type: "SET_RENDER_FILTER", display: "INITIAL" });
  }
  function removeDuplicate(values) {
    values = values.filter(function (a) {
      return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
    }, Object.create(null));

    return values;
  }
  React.useEffect(() => {
    if (data.length) {
      setUsersFiltred(removeDuplicate([...data.map((users) => users.user)]));
      setUsers(removeDuplicate([...data.map((users) => users.user)]));
      if (data.length === 1) {
        setClientsfiltred(data.map((clients) => clients.client));
        setClients(data.map((clients) => clients.client));
        setUserselected(data[0].user);
      }
    }
  }, []);
  React.useEffect(() => {
    const array = data.filter((x) => x.user.idusers === userselected.idusers);
    setClientsfiltred([...array.map((clients) => clients.client)]);
    setClients(array.map((clients) => clients.client));
  }, [userselected]);
  return (
    <Page>
      <div tabIndex="-1" data-tab="3">
        <div>
          <div className="RLfQR">
            <div className="_210SC">
              <div className="pr5ny">
                {t("FC.chats.pl.init.chats.users")}
                </div>
            </div>
            <Search
              placeholder={t("FC.chats.pl.init.chats.userph")}
              data={users}
              set={setUsersFiltred}
            />
            {!!usersfiltred.length &&
              usersfiltred.map((user, index) => (
                <div id="contacts-messages-list" className="contact-list">
                  <div className="contact-item">
                    <div className="dIyEr">
                      <div className="_1WliW">
                        <img
                          src={whatsapp}
                          className="Qgzj8 gqwaM photo"
                          alt=""
                          style={{ display: "none" }}
                        />
                        <div className="_3ZW2E">
                          <span data-icon="default-user" className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 212 212"
                              width="212"
                              height="212"
                            >
                              <path
                                fill="#DFE5E7"
                                d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"
                              />
                              <g fill="#FFF">
                                <path d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z" />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="_3j7s9">
                      <div className="_2FBdJ">
                        <div className="_25Ooe">
                          <span
                            dir="auto"
                            title="Nome do Contato"
                            className="_1wjpf"
                          >
                            {user.username}
                          </span>
                        </div>
                        <div className="_3Bxar">
                          <span className="_3T2VG">
                            {/* {new Date(ticket.timestamp).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })} */}
                          </span>
                        </div>
                      </div>
                      <div className="_1AwDx">
                        <div className="_itDl">
                          <span className="_2_LEW last-message">
                            <span dir="ltr" className="_1wjpf _3NFp9">
                              {user.cpf}
                            </span>
                            {userselected?.idusers === user.idusers ? (
                              <div role="button" className="buttonaccept">
                                <button
                                  type="button"
                                  style={{ background: "#25d366" }}
                                >
                                  {t("FC.chats.pl.init.chats.bt2")}
                                </button>
                              </div>
                            ) : (
                              <div
                                role="button"
                                className="buttonaccept"
                                onClick={() => {
                                  setUserselected(user);
                                }}
                              >
                                <button type="button">
                                  {t("FC.chats.pl.init.chats.bt1")}
                                </button>
                              </div>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <div className="_210SC">
              <div className="pr5ny">{t("FC.chats.pl.init.chats.clients")}</div>
            </div>
            <Search
              placeholder={t("FC.chats.pl.init.chats.clientsph")}
              data={clients}
              set={setClientsfiltred}
            />

            {!!clientsfiltred.length &&
              clientsfiltred.map((client, index) => (
                <div
                  key={index}
                  id="contacts-messages-list"
                  className="contact-list"
                  onClick={() => {
                    openChat(client);
                  }}
                >
                  <div className="contact-item">
                    <div className="dIyEr">
                      <div className="_1WliW">
                        <img
                          src={whatsapp}
                          className="Qgzj8 gqwaM photo"
                          alt=""
                          style={{ display: "none" }}
                        />
                        <div className="_3ZW2E">
                          <span data-icon="default-user">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 212 212"
                              width="212"
                              height="212"
                            >
                              <path
                                fill="#DFE5E7"
                                d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"
                              />
                              <g fill="#FFF">
                                <path d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z" />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="_3j7s9">
                      <div className="_2FBdJ">
                        <div className="_25Ooe">
                          <span
                            dir="auto"
                            title="Nome do Contato"
                            className="_1wjpf"
                          >
                            {client.name}
                          </span>
                        </div>
                        <div className="_3Bxar">
                          <span className="_3T2VG">
                            {/* {new Date(
                              conversation.messages[0].timestamp
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })} */}
                          </span>
                        </div>
                      </div>
                      <div className="_1AwDx">
                        <div className="_itDl">
                          <span
                            title="digitando…"
                            className="vdXUe _1wjpf typing"
                            style={{ display: "none" }}
                          >
                            digitando…
                          </span>

                          <span className="_2_LEW last-message">
                            <span dir="ltr" className="_1wjpf _3NFp9">
                              {client.number}
                            </span>
                            <div className="_3Bxar">
                              <div className="_15G96">
                                <span
                                  className="OUeyt messages-count-new"
                                  style={{ display: "none" }}
                                >
                                  1
                                </span>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="btnfilter">
        <Button
          onClick={() => {
            setData("");
            clearRedux();
          }}
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
        >
        <SearchIcon/>
        <p>Nova busca</p>
        </Button>
      </div>
    </Page>
  );
}
