import React, { useEffect, useState, useRef } from "react";
import ReactFlow, {
  ReactFlowProvider,
  removeElements,
  addEdge,
  Controls,
  MiniMap,
} from "react-flow-renderer";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "./dialog";
import api from "../../services/axios";
import { Container } from "./style";
import actionNode from "./actionNode";
import choiceNode from "./choiceNode";
import Sidebar from "./sideBar";
import ConnectionLine from "./connectionLine";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));

const nodeTypes = {
  choice: choiceNode,
  action: actionNode,
};

const getId = () => `dndnode_${Math.random()}`;

export default () => {
  const [elementupdate, setElementupdate] = useState("");
  const classes = useStyles();
  const reactFlowWrapper = useRef(null);
  const [disabled, setDisabled] = useState(false);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [elements, setElements] = useState([]);
  const [openinfodialog, setOpeninfodialog] = useState(false);
  const [removenode, setRemovenode] = useState(null);

  const initialElements = [
    {
      id: "1",
      type: "choice",
      position: { x: 250, y: 5 },
      data: {
        setElementupdate,
        removenode: setRemovenode,
        id: "1",
        index: 0,
        startmessage: true,
        valueDefault: "Menu inicial:\n\n*1* - Opção 1\n*2* - Opção 2",
        valueDefaultcallback: "Menu inicial:\n\n*1* - Opção 1\n*2* - Opção 2",
        tagsDefault: [],
        nodeFunctions: [],
      },
    },
  ];
  function parseData(data) {
    const newData = [];
    data.map((node) => {
      if (node.type !== "smoothstep") {
        newData.push({
          id: node.id,
          type: node.type,
          startmessage: node.data.startmessage,
          message: node.data.valueDefault,
          file: node.data.fileData,
          originalname: node.data.originalname,
          callback: node.data.valueDefaultcallback,
          nodeFunctions: node.data.nodeFunctions,
          tags: node.data.tagsDefault.join(","),
          x: node.position.x,
          y: node.position.y,
          nodes: [],
        });
      }
    });
    data.map((node) => {
      if (node.type === "smoothstep") {
        const index = newData.findIndex((x) => x.id === node.source);

        newData[index].nodes.push({
          type: node.type,
          source: node.source,
          target: node.target,
        });
      }
    });
    return newData;
  }
  function parseDataToFlow(data) {
    const nodes = [];
    data.map((node, index) => {
      nodes.push({
        id: `${node.idchatbots}`,
        type: node.type,
        selectable: true,
        data: {
          setElementupdate,
          removenode: setRemovenode,
          id: `${node.idchatbots}`,
          index,
          startmessage: node.startmessage,
          valueDefault: node.message,
          fileData: node.file,
          originalname: node.originalname,
          valueDefaultcallback: node.callback,
          tagsDefault: node.tags ? node.tags.split(",") : [],
          nodeFunctions: node.nodeFunctions,
        },
        position: { x: node.x, y: node.y },
      });
      if (node.nodes.length) {
        node.nodes.map((x) => {
          nodes.push({
            arrowHeadType: "arrow",
            id: `reactflow__edge-${x.source}a-${x.target}b`,
            source: `${x.source}`,
            sourceHandle: "b",
            target: `${x.target}`,
            targetHandle: "a",
            type: x.type,
            style: {
              stroke: "#889FAC",
              strokeWidth: "4px",
            },
          });
        });
      }
    });
    return nodes;
  }
  const getFlow = () => {
    api
      .get("whatsapp/chatbot", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        if (!response.data || !response.data.length) {
          return setElements(initialElements);
        }
        const data = parseDataToFlow(response.data);

        setElements(data);
      });
  };
  const onSave = () => {
    setDisabled(true);
    const dataToSend = parseData(elements);
    const dataform = new FormData();
    dataToSend.forEach((node) => {
      if (node.file && typeof node.file !== "string") {
        dataform.append("file", node.file);
      }
    });
    dataform.append("data", JSON.stringify(dataToSend));

    api
      .post("whatsapp/chatbot", dataform, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .finally(() => setDisabled(false));
  };
  const onConnect = (params) =>
    setElements((els) =>
      addEdge(
        {
          ...params,
          type: "smoothstep",
          arrowHeadType: "arrow",
          style: {
            stroke: "#889FAC",
            strokeWidth: "4px",
          },
        },
        els
      )
    );

  const onElementsRemove = (elementsToRemove) => {
    for (const value of elementsToRemove) {
      if (value.data) {
        if (value.data.startmessage) {
          return setOpeninfodialog(true);
        }
      }
    }
    return setElements((els) => removeElements(elementsToRemove, els));
  };

  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = (event) => {
    const types = ["action", "choice"];
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");
    if (!types.includes(type)) return;
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    const id = getId();
    const newNode = {
      id,
      type,
      position,
      data: {
        setElementupdate,
        id,
        removenode: setRemovenode,
        valueDefault: "",
        valueDefaultcallback: "",
        fileData: "",
        originalname: "",
        tagsDefault: [],
        nodeFunctions: [],
      },
    };

    setElements((es) => es.concat(newNode));
  };
  const onNodeDragStop = (event, node) => {
    const elementsToUpdate = elements;
    const index = elementsToUpdate.findIndex((els) => els.id === node.id);
    elementsToUpdate[index] = node;
    setElements([...elementsToUpdate]);
  };

  useEffect(() => {
    if (!removenode) return;
    const nodes = elements;
    const index = nodes.findIndex((x) => x.id === removenode.id);

    const element = nodes[index];
    if (element.data.startmessage === 1) {
      return setOpeninfodialog(true);
    }
    nodes.splice(index, 1);
    const indexSource = nodes.findIndex(
      (x) =>
        x.source === removenode.id || x.source === `dndnode_${removenode.id}`
    );
    if (indexSource !== -1) {
      nodes.splice(indexSource, 1);
    }
    const indexTarget = nodes.findIndex(
      (x) =>
        x.target === removenode.id || x.target === `dndnode_${removenode.id}`
    );
    if (indexTarget !== -1) {
      nodes.splice(indexTarget, 1);
    }
    setElements([...nodes]);
  }, [removenode]);
  useEffect(() => {
    getFlow();
  }, []);
  useEffect(() => {
    if (elementupdate) {
      const elementsToUpdate = elements;
      const index = elementsToUpdate.findIndex(
        (els) => els.id === elementupdate.id
      );
      elementsToUpdate[index].data = {
        ...elementsToUpdate[index].data,
        ...elementupdate,
      };
      setElements([...elementsToUpdate]);
    }
  }, [elementupdate]);
  return (
    <>
      <Dialog open={openinfodialog} confirm={() => setOpeninfodialog(false)} />
      <Container>
        <div className="header">
          <h2>Crie seu ChatBot</h2>{" "}
          <Button
            onClick={onSave}
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            disabled={disabled}
          >
            {disabled ? <div className="loading" /> : "Salvar"}
          </Button>
        </div>
        <div className="make-area">
          <ReactFlowProvider>
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow
                connectionLineType="smoothstep"
                elements={elements}
                connectionLineComponent={ConnectionLine}
                onConnect={onConnect}
                onElementsRemove={onElementsRemove}
                onLoad={onLoad}
                nodeTypes={nodeTypes}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onNodeDragStop={onNodeDragStop}
              >
                <MiniMap
                  style={{
                    background: "lightblue",
                  }}
                />
                <Controls
                  style={{
                    top: "10px",
                    bottom: "100%",
                  }}
                />
              </ReactFlow>
            </div>
            <Sidebar />
          </ReactFlowProvider>
        </div>
      </Container>
    </>
  );
};
