import React from "react";
import { Page } from "./style";

export default class MenuDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transform: "translateY(-300px) scaleX(0) scaleY(0)",
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ transform: "none" });
    }, 100);
  }

  render() {
    const { transform } = this.state;
    return (
      <Page transform={transform}>
        <div className="menu-dropdown" id="menu-attach">
          <div
            tabIndex="-1"
            className="_1txFK"
            style={{ transformOrigin: "left top 0px" }}
          >
            <ul className="_3s1D4">
              <li
                tabIndex="-1"
                className="_10anr vidHz _3asN5"
                data-animate-dropdown-item="true"
              >
                <button
                  className="btn-attach"
                  type="button"
                  id="btn-attach-photo"
                >
                  <span data-icon="image">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 53 53"
                      enableBackground="new 0 0 53 53"
                      width="53"
                      height="53"
                    >
                      <filter height="130%" id="image-dropshadow">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
                        <feOffset dy="2" result="offsetblur" />
                        <feComponentTransfer>
                          <feFuncA slope=".08" type="linear" />
                        </feComponentTransfer>
                        <feMerge>
                          <feMergeNode />
                          <feMergeNode in="SourceGraphic" />
                        </feMerge>
                      </filter>
                      <defs>
                        <circle cx="26.5" cy="26.5" r="25.5" />
                      </defs>
                      <clipPath>
                        <use href="#image-SVGID_1_" overflow="visible" />
                      </clipPath>
                      <g clipPath="url(#image-SVGID_2_)">
                        <path
                          fill="#8333A3"
                          d="M54.1 23.5H35.3v-2.4c0-1-.8-1.8-1.8-1.8H19.2c-.9 0-1.7.8-1.7 1.8v10.7c0 1 .8 1.8 1.8 1.8h14.2c.4 0 .8-.2 1.2-.4L42 48.8c6.7-3.6 12.1-13.2 12.1-25.3z"
                        />
                        <path
                          fill="#702A8C"
                          d="M33.6 33.6l-7-.1-10.6 18c4.4 2.4 17 4.5 27-2.6l-8.3-15.7c-.3.3-.7.4-1.1.4z"
                        />
                        <path
                          fill="#9A37A3"
                          d="M19.3 33.6c-1 0-1.8-.8-1.8-1.8l.1-1.5-18.7-.3c1.5 9.4 8.9 18.6 17.5 22l10.7-18.4h-7.8z"
                        />
                        <path
                          fill="#CE64DE"
                          d="M17.6 21.2c0-.9 1.1-1.7 2-1.8L11.7 4.2C6.4 7.1-.8 12.9-1.1 30.8l18.7-.1v-9.5z"
                        />
                        <path
                          fill="#BF59CF"
                          d="M19.3 19.4l9.5.4 9.8-17.3C33.3-.7 21.4-3 10.7 4.6l8.6 14.8z"
                        />
                        <path
                          fill="#AC44CF"
                          d="M38.5 1.8L28.4 19.4h5.2c1 0 1.8.8 1.8 1.8l-.4 2.7 19.1-.1c-1-9.2-7.6-18.1-15.6-22z"
                        />
                      </g>
                      <path
                        fill="#F5F5F5"
                        filter="url(#image-dropshadow)"
                        d="M33.9 33.9H19.1c-1 0-1.9-.8-1.9-1.9V21c0-1 .8-1.9 1.9-1.9h14.8c1 0 1.9.8 1.9 1.9v11c-.1 1.1-.9 1.9-1.9 1.9zm-2.2-12c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.8-1.8-1.8-1.8zm1.9 8L28.9 27l-2.4 1.2-4.7-3.5-2.4 2.3v4.7h14.2v-1.8z"
                      />
                    </svg>
                  </span>
                </button>
                <input
                  type="file"
                  accept="image/*"
                  multiple=""
                  style={{ display: "none" }}
                  id="input-photo"
                />
              </li>
              <li
                tabIndex="-1"
                className="_10anr vidHz _3asN5"
                data-animate-dropdown-item="true"
              >
                <button
                  className="btn-attach"
                  type="button"
                  id="btn-attach-camera"
                >
                  <span data-icon="camera">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 53 53"
                      enableBackground="new 0 0 53 53"
                      width="53"
                      height="53"
                    >
                      <filter height="130%" id="camera-dropshadow">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
                        <feOffset dy="2" result="offsetblur" />
                        <feComponentTransfer>
                          <feFuncA slope=".08" type="linear" />
                        </feComponentTransfer>
                        <feMerge>
                          <feMergeNode />
                          <feMergeNode in="SourceGraphic" />
                        </feMerge>
                      </filter>
                      <defs>
                        <circle cx="26.5" cy="26.5" r="25.5" />
                      </defs>
                      <clipPath>
                        <use href="#camera-SVGID_1_" overflow="visible" />
                      </clipPath>
                      <g clipPath="url(#camera-SVGID_2_)">
                        <path
                          fill="#4E5DA9"
                          d="M33.7 33.7l-6.9-.3-11.9 18.4c5.9 3.6 19.8 4.4 28.8-3.2L35 32.8c-.3.2-.8.9-1.3.9z"
                        />
                        <path
                          fill="#92C654"
                          d="M19.2 33.7c-1 0-1.8-.8-1.8-1.8v-1.6l-19-.5c.1 12 8.7 20 17.4 23.5l11.3-19.5h-7.9z"
                        />
                        <path
                          fill="#FEC226"
                          d="M17.4 21.1c0-.9 1-1.8 1.9-1.9L10.9 4.1C4.2 7.1-1.8 16.9-1.7 30.9h19.1v-9.8z"
                        />
                        <path
                          fill="#F47B34"
                          d="M19.2 19.3l9.2.4L39.6 1.8C36.4-.7 20.9-4.6 10.1 3.4l9.1 15.9z"
                        />
                        <path
                          fill="#DE5144"
                          d="M39 .8L28.4 19.3h5.3c1 0 1.8.8 1.8 1.8l-.3 3.1h19.5C54.2 12.6 47.2 4.8 39 .8z"
                        />
                        <path
                          fill="#5D84C3"
                          d="M54.7 23.5H35.5v-2.4c0-1-.8-1.8-1.8-1.8H19.1c-.9 0-1.7.8-1.7 1.8V32c0 1 .8 1.8 1.8 1.8h14.5c.4 0 .8-.2 1.2-.4l8.4 15.7c11.5-8.2 11.5-24.5 11.4-25.6z"
                        />
                      </g>
                      <path
                        fill="#F5F5F5"
                        filter="url(#camera-dropshadow)"
                        d="M33.8 34H18c-.9 0-1.6-.7-1.6-1.6v-11c0-.3.2-.7.4-.9l2.2-2.4c.2-.2.6-.4.9-.4h2.6c.3 0 .7.2.9.4l1.4 1.5h9c.9 0 1.6.7 1.6 1.6v11.2c0 .9-.7 1.6-1.6 1.6zm-7.9-11.9c-2.6 0-4.7 2.1-4.7 4.7s2.1 4.7 4.7 4.7 4.7-2.1 4.7-4.7-2.1-4.7-4.7-4.7zm0 8c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3 3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3z"
                      />
                    </svg>
                  </span>
                </button>
              </li>
              <li
                tabIndex="-1"
                className="_10anr vidHz _3asN5"
                data-animate-dropdown-item="true"
              >
                <button
                  className="btn-attach"
                  type="button"
                  id="btn-attach-document"
                >
                  <span data-icon="document">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 53 53"
                      enableBackground="new 0 0 53 53"
                      width="53"
                      height="53"
                    >
                      <filter height="130%" id="document-dropshadow">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
                        <feOffset dy="2" result="offsetblur" />
                        <feComponentTransfer>
                          <feFuncA slope=".08" type="linear" />
                        </feComponentTransfer>
                        <feMerge>
                          <feMergeNode />
                          <feMergeNode in="SourceGraphic" />
                        </feMerge>
                      </filter>
                      <defs>
                        <circle cx="26.5" cy="26.5" r="25.5" />
                      </defs>
                      <clipPath>
                        <use href="#document-SVGID_1_" overflow="visible" />
                      </clipPath>
                      <g clipPath="url(#document-SVGID_2_)">
                        <path
                          fill="#5157AE"
                          d="M26.5-1.1C11.9-1.1-1.1 5.6-1.1 27.6h55.2c-.1-19-13-28.7-27.6-28.7z"
                        />
                        <path
                          fill="#5F66CD"
                          d="M53 26.5H-1.1c0 14.6 13 27.6 27.6 27.6s27.6-13 27.6-27.6H53z"
                        />
                      </g>
                      <path
                        fill="#F5F5F5"
                        filter="url(#document-dropshadow)"
                        d="M21.4 16.3c-1.1 0-2 .9-2 2v15.6c0 1.1.9 2 2 2h11.7c1.1 0 2-.9 2-2V22.1l-5.9-5.9-7.8.1zm6.8 6.1v-4.7l5.4 5.4h-4.8c-.4 0-.6-.3-.6-.7z"
                      />
                    </svg>
                  </span>
                </button>
                <input
                  type="file"
                  accept="*"
                  style={{ display: "none" }}
                  id="input-document"
                />
              </li>
              <li
                tabIndex="-1"
                className="_10anr vidHz _3asN5"
                data-animate-dropdown-item="true"
              >
                <button
                  className="btn-attach"
                  type="button"
                  id="btn-attach-contact"
                >
                  <span data-icon="contact">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 53 53"
                      enableBackground="new 0 0 53 53"
                      width="53"
                      height="53"
                    >
                      <filter height="130%" id="contact-dropshadow">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
                        <feOffset dy="2" result="offsetblur" />
                        <feComponentTransfer>
                          <feFuncA slope=".08" type="linear" />
                        </feComponentTransfer>
                        <feMerge>
                          <feMergeNode />
                          <feMergeNode in="SourceGraphic" />
                        </feMerge>
                      </filter>
                      <defs>
                        <circle cx="26.5" cy="26.5" r="25.5" />
                      </defs>
                      <clipPath>
                        <use xlinkHref="#contact-SVGID_1_" overflow="visible" />
                      </clipPath>
                      <g clipPath="url(#contact-SVGID_2_)">
                        <path
                          fill="#0A7BBF"
                          d="M26.5-1.1C11.9-1.1-1.1 5.6-1.1 27.6h55.2c-.1-19-13-28.7-27.6-28.7z"
                        />
                        <path
                          fill="#09ABF4"
                          d="M53 26.5H-1.1c0 14.6 13 27.6 27.6 27.6s27.6-13 27.6-27.6H53z"
                        />
                      </g>
                      <path
                        fill="#F5F5F5"
                        filter="url(#contact-dropshadow)"
                        d="M26.5 26.1c-2.5 0-4.6-2-4.6-4.6 0-2.5 2-4.6 4.6-4.6 2.5 0 4.6 2 4.6 4.6s-2.1 4.6-4.6 4.6zm-.2 8.4h-8.9V32c0-.4.1-.8.4-1.1 1.3-1.3 4.6-2.4 8.5-2.4s7.3 1.1 8.7 2.4c.3.3.6.7.6 1.1v2.5h-9.3z"
                      />
                    </svg>
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Page>
    );
  }
}
