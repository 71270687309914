import styled from "styled-components";

export const Page = styled.div`
  margin: 10px;
  .btnconfirm {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
  }
`;
