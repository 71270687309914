import { combineReducers } from "redux";
import crypto from "crypto";

function decrypt(text) {
  const iv = Buffer.from(text["aXY="], "hex");
  const encryptedText = Buffer.from(text["ZW5jcnlwdGVkRGF0YQ=="], "hex");
  const decipher = crypto.createDecipheriv(
    "aes-256-cbc",
    Buffer.from(process.env.REACT_APP_KEY),
    iv
  );
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}
const INITIAL_STATE = {
  display: "INITIAL",
  displayfilter: "INITIAL",
  displayclientinfo: false,
  displayclientinfofilter: false,
  userchat: {
    idclient: undefined,
    number: undefined,
  },
  userchatfilter: {
    id: undefined,
    number: undefined,
  },
  messagesfilter: [],
  clientinfo: {},
  clientinfofilter: {},
};
const INITIAL_STATE_RULES = localStorage["cnVsZXM="]
  ? JSON.parse(decrypt(JSON.parse(localStorage["cnVsZXM="])))
  : [];
function panelRight(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_RENDER_FILTER":
      return {
        ...state,
        displayfilter: action.display,
      };
    case "SET_RENDER":
      return {
        ...state,
        display: action.display,
      };
    case "SET_USER_ACTIVE_CHAT":
      return {
        ...state,
        userchat: action.user,
      };
    case "SET_USER_ACTIVE_CHAT_FILTER":
      return {
        ...state,
        userchatfilter: action.user,
      };
    case "SET_MESSAGES_FILTER":
      return {
        ...state,
        messagesfilter: action.messagesfilter,
      };
    case "SET_RENDER_CLIENT_INFO":
      return {
        ...state,
        displayclientinfo: action.displayclientinfo,
      };
    case "SET_RENDER_CLIENT_INFO_FILTER":
      return {
        ...state,
        displayclientinfofilter: action.displayclientinfo,
      };
    case "SET_CLIENT_INFO":
      return {
        ...state,
        clientinfo: action.clientinfo,
      };
    case "SET_CLIENT_INFO_FILTER":
      return {
        ...state,
        clientinfofilter: action.clientinfo,
      };
    default:
      return state;
  }
}

function notify(state = [], action) {
  switch (action.type) {
    case "ADD_NOTIFY":
      return [...state, action.notify];
    case "REPLACE_ALL_NOTIFICATIONS":
      return [...action.notifications];
    default:
      return state;
  }
}

function rules(state = INITIAL_STATE_RULES, action) {
  switch (action.type) {
    case "SET_RULES":
      return [...action.rules];
    default:
      return state;
  }
}
function chats(state = [], action) {
  switch (action.type) {
    case "GET_CHATS":
      return state;
    case "SET_CHATS":
      state = [...action.payload];
      return [...action.payload];
    case "ADD_NEW_CHAT":
      const index = state.findIndex(
        (x) =>
          x.conversations_idconversations ===
          action.payload.conversations_idconversations
      );
      if (index === -1) {
        state.unshift(action.payload);
      } else {
        state.splice(index, 1);
        state.unshift(action.payload);
      }
      return [...state];
    case "UPDATE_CLIENT_INFO_CHAT":
      const indexUpdateChat = state.findIndex(
        (x) => x.idclient === action.payload.idclient
      );
      if (indexUpdateChat !== -1) {
        let oldChat = state[indexUpdateChat];
        oldChat = { ...oldChat, ...action.payload };
        oldChat.conversation.client = {
          ...oldChat.conversation.client,
          ...action.payload,
        };
        state.splice(indexUpdateChat, 1);
        state.unshift(oldChat);
      }
      return [...state];
    case "UPDATE_CHAT_MESSAGE":
      const indexUpdateChatMessage = state.findIndex(
        (x) => x.number === action.payload.number
      );
      if (indexUpdateChatMessage !== -1) {
        const oldChatUpdateChatMessage = state[indexUpdateChatMessage];
        oldChatUpdateChatMessage.conversation.messages[0] = {
          ...oldChatUpdateChatMessage.conversation.messages[0],
          ...action.payload,
        };
        state.splice(indexUpdateChatMessage, 1);
        state.unshift(oldChatUpdateChatMessage);
      }
      return [...state];
    case "READ_MESSAGE_CHAT":
      const indexReadChatMessage = state.findIndex(
        (x) => x.number === action.payload.number
      );
      if (indexReadChatMessage !== -1) {
        state[indexReadChatMessage].conversation.messages[0] = {
          ...state[indexReadChatMessage].conversation.messages[0],
          read: 1,
        };
      }
      return [...state];
    case "REMOVE_CHAT":
      const indexRemoveChatMessage = state.findIndex(
        (x) => x.number === action.payload.number
      );
      if (indexRemoveChatMessage !== -1) {
        state.splice(indexRemoveChatMessage, 1);
      }
      return [...state];
    case "RESET_CHAT":
      state = [];
      return [];
    default:
      return state;
  }
}
function chatsHistory(state = [], action) {
  switch (action.type) {
    case "GET_CHATS_HISTORY":
      return state;
    case "SET_CHATS_HISTORY":
      return [...action.payload];
    case "ADD_NEW_CHAT_HISTORY":
      const index = state.findIndex(
        (x) =>
          x.idconversations === action.payload.conversations_idconversations
      );
      if (index === -1) {
        action.payload.idconversations =
          action.payload.conversations_idconversations;
        action.payload.messages = action.payload.conversation.messages;
        state.unshift({
          idconversations: action.payload.conversations_idconversations,
          messages: action.payload.conversation.messages,
          ...action.payload,
        });
      } else {
        state.splice(index, 1);
        state.unshift({
          idconversations: action.payload.conversations_idconversations,
          messages: action.payload.conversation.messages,
          ...action.payload,
        });
        //state.unshift(action.payload);
      }
      return [...state];
    case "UPDATE_CLIENT_INFO_CHAT_HISTORY":
      const indexUpdateChat = state.findIndex(
        (x) => x.idclient === action.payload.idclient
      );
      if (indexUpdateChat !== -1) {
        let oldChat = state[indexUpdateChat];
        oldChat = { ...oldChat, ...action.payload };
        oldChat.client = {
          ...oldChat.client,
          ...action.payload,
        };
        state.splice(indexUpdateChat, 1);
        state.unshift(oldChat);
      }
      return [...state];
    case "UPDATE_CHAT_HISTORY_MESSAGE":
      const indexUpdateChatMessage = state.findIndex(
        (x) => x.number === action.payload.number
      );
      if (indexUpdateChatMessage !== -1) {
        const oldChatUpdateChatMessage = state[indexUpdateChatMessage];
        oldChatUpdateChatMessage.messages[0] = {
          ...oldChatUpdateChatMessage.messages[0],
          ...action.payload,
        };
        state.splice(indexUpdateChatMessage, 1);
        state.unshift(oldChatUpdateChatMessage);
      }
      return [...state];
    case "READ_MESSAGE_CHAT_HISTORY":
      const indexReadChatMessage = state.findIndex(
        (x) => x.number === action.payload.number
      );
      if (indexReadChatMessage !== -1) {
        state[indexReadChatMessage].messages[0] = {
          ...state[indexReadChatMessage].messages[0],
          read: 1,
        };
      }
      return [...state];
    case "RESET_CHAT_HISTORY":
      state = [];
      return [];
    default:
      return state;
  }
}
export default combineReducers({
  panelRight,
  notify,
  rules,
  chats,
  chatsHistory,
});
