import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import GetAppIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "rgb(0, 191, 165)",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function FullScreenDialog({ close, mediasrc }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [bgsize, setBgsize] = React.useState("contain");
  const [bgposition, setBgposition] = React.useState("center");
  const [cursor, setCursor] = React.useState("zoom-in");
  const [zoom, setZoom] = React.useState(false);
  const handleClose = () => {
    close();
  };
  function downloadMidia() {
    fetch(mediasrc).then((t) =>
      t.blob().then((b) => {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", "file");
        a.click();
      })
    );
  }
  return (
    <div>
      <Dialog
        fullScreen
        open
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("HOME.pr.active.dialogs.sendfile.bt2")}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                downloadMidia();
              }}
              startIcon={<GetAppIcon />}
            >
              {t("HOME.pr.active.dialogs.sendfile.bt1")}
            </Button>
          </Toolbar>
        </AppBar>
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
            padding: "20px",
          }}
        >
          <div
            id="imagepreview"
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
              backgroundImage: `url(${mediasrc})`,
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              backgroundPosition: bgposition,
              backgroundSize: bgsize,
              cursor: cursor,
            }}
            onClick={() => {
              if (zoom) {
                setBgsize("contain");
                setCursor("zoom-in");
                setZoom(false);
                setBgposition("center");
              } else {
                setCursor("zoom-out");
                setBgsize("150%");
                setZoom(true);
              }
            }}
            onMouseMove={(e) => {
              if (!zoom) {
                return;
              }
              const imagepreview = document.getElementById("imagepreview");
              const dimentions = imagepreview.getBoundingClientRect();
              const x = e.clientX - dimentions.left;
              const y = e.clientY - dimentions.top;
              const xpercent = Math.round(100 / (dimentions.width / x));
              const ypercent = Math.round(100 / (dimentions.height / y));
              setBgposition(xpercent + "% " + ypercent + "%");
            }}
          ></div>
        </div>
      </Dialog>
    </div>
  );
}
