import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import api from "../../../services/axios";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "60ch",
      justifyContent: "center",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    marginLeft: theme.spacing(4),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default function AlertDialog({ close, setWallet, wallet }) {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(wallet ? wallet.idwallets : "");
  const [walletsdata, setWalletsdata] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const handleToggle = (value) => () => {
    let newChecked = "";
    if (checked !== value) {
      newChecked = value;
    }

    setChecked(newChecked);
  };

  const classes = useStyles();
  const getWallets = async () => {
    setLoading(true);
    await api
      .get("wallet", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setWalletsdata([...res.data]);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  const confirm = () => {
    const walletfind = walletsdata.find((x) => {
      return x.idwallets === checked;
    });
    setWallet(walletfind);
    close();
  };

  React.useEffect(() => {
    getWallets();
  }, []);
  return (
    <div>
      <Dialog
        open
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("CT.newclient.titledialog")}
        </DialogTitle>
        <DialogContent>
          {!!walletsdata.length ? (
            <List className={classes.root}>
              {!!walletsdata.length &&
                walletsdata.map((value, index) => {
                  const labelId = `checkbox-list-label-${index}`;

                  return (
                    <>
                      <ListItem
                        key={index}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggle(value.idwallets)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked === value.idwallets}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value.name}`} />
                      </ListItem>
                    </>
                  );
                })}
            </List>
          ) : loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>Nenhum dado encontrado.</div>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={close} autoFocus>
            <Button variant="contained" color="secondary">
              {t("WT.dialogs.client.bt2")}
            </Button>
          </Button>
          <Button color="primary" onClick={confirm} autoFocus>
            <Button variant="contained" className={classes.button}>
              {t("WT.dialogs.client.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
