import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CPFValidation from "../../../components/CPFValidation";
import api from "../../../services/axios";
import { useTranslation } from "react-i18next";
import SectorSelect from "./SectorSelect";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));
export default function AlertDialog(props) {
  const { t } = useTranslation();
  const [type, setType] = React.useState("");
  const [roles, setRoles] = React.useState([]);
  const [name, setName] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [cpf, setCpf] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [sectors, setSectors] = React.useState([]);
  const [subsectors, setSubsectors] = React.useState([]);
  const [sectorsdata, setSectorsdata] = React.useState([]);
  const [selectsector, setSelectsector] = React.useState(false);
  const classes = useStyles();
  const getSectors = async () => {
    await api
      .get("sectors", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setSectorsdata([...res.data]);
      });
  };
  const handleClose = () => {
    props.func(false);
  };

  React.useEffect(() => {
    api
      .get("users/roles", {
        headers: {
          authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        setRoles(response.data);
      });
  }, []);
  React.useEffect(() => {
    setName(props.data.username);
    setNumber(props.data.number);
    setEmail(props.data.email);
    setCpf(props.data.cpf);
    setType(props.data.idrole);
    if (props.data.users_has_sectors && props.data.users_has_sectors.length) {
      const newsectors = sectors;
      const newsubsectors = subsectors;
      props.data.users_has_sectors.map((sector) => {
        newsectors.push(sector.idsector);
        if (
          sector.users_has_sectors_has_sub_sectors &&
          sector.users_has_sectors_has_sub_sectors.length
        ) {
          sector.users_has_sectors_has_sub_sectors.map((sub) => {
            subsectors.push(sub.idsubsector);
          });
        }
      });
      setSectors(newsectors);
      setSubsectors(newsubsectors);
    }
  }, [props.data]);

  const confirm = () => {
    console.log(cpf, "tem ou n sa phoa");
    if (!name.length || !email.length || !cpf || !type) {
      return window.alert(t("US.dialogs.edituser.alert1"));
    }
    // if (!CPFValidation(cpf.replace(/([^\d])+/gim, ""))) {
    //   return window.alert("CPF inválido !");
    // }
    if (!sectors.length) {
      return window.alert(t("US.dialogs.newuser.alert3"));
    }
    const arraysectorsdata = [];
    sectors.map((sectorid) => {
      const index = sectorsdata.findIndex((sectordata) => {
        return sectordata.idsectors === sectorid;
      });
      if (index !== -1) {
        const { sub_sectors, ...newsector } = sectorsdata[index];
        const indexnewsector = arraysectorsdata.push(newsector);
        if (sectorsdata[index].sub_sectors.length) {
          sectorsdata[index].sub_sectors.map((sub) => {
            const indexsub = subsectors.indexOf(sub.idsubsectors);
            if (indexsub !== -1) {
              if (!arraysectorsdata[indexnewsector - 1].sub_sectors) {
                arraysectorsdata[indexnewsector - 1].sub_sectors = [{ ...sub }];
              } else {
                arraysectorsdata[indexnewsector - 1].sub_sectors.push(sub);
              }
            }
          });
        }
      }
    });

    const data = {
      idusers: props.data.idusers,
      username: name,
      cpf,
      email,
      number,
      idrole: type,
      password: password || undefined,
      sectors: arraysectorsdata,
    };
    api
      .put("users", data, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        props.update();
        handleClose();
      })
      .catch((err) => window.alert(err.response.data.error));
  };
  React.useEffect(() => {
    getSectors();
  }, []);
  return (
    <div>
      {selectsector && (
        <SectorSelect
          close={() => {
            setSelectsector(false);
          }}
          setSectors={setSectors}
          setSubsectors={setSubsectors}
          sectors={sectors}
          subsectors={subsectors}
          setData={setSectorsdata}
        />
      )}
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("US.dialogs.edituser.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-basic"
                label={t("US.dialogs.edituser.name")}
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
                required
              />
              <TextField
                id="outlined-basic"
                label={t("US.dialogs.edituser.number")}
                variant="outlined"
                value={number}
                onChange={(e) => {
                  setNumber(e.currentTarget.value);
                }}
              />
              <InputMask
                mask="999.999.999-99"
                value={cpf}
                onChange={(e) => {
                  setCpf(e.currentTarget.value);
                }}
              >
                {() => <TextField label="CPF" variant="outlined" required />}
              </InputMask>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
                required
              />
              <TextField
                id="outlined-basic"
                type="password"
                label={t("US.dialogs.edituser.password")}
                variant="outlined"
                value={password}
                onChange={(e) => {
                  setPassword(e.currentTarget.value);
                }}
              />
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t("US.dialogs.edituser.access")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={type}
                  onChange={(ev) => {
                    setType(ev.target.value);
                  }}
                  label={t("US.dialogs.edituser.access")}
                >
                  <MenuItem value="">
                    <em>{t("US.dialogs.edituser.select")}</em>
                  </MenuItem>
                  {!!roles.length &&
                    roles.map((role, index) => (
                      <MenuItem key={index} value={role.idroles}>
                        {role.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Divider variant="middle" />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectsector(true);
                }}
              >
                {t("US.dialogs.newuser.bt3")}
              </Button>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Button variant="contained" color="secondary">
              {t("US.dialogs.edituser.bt2")}
            </Button>
          </Button>
          <Button color="primary" onClick={confirm} autoFocus>
            <Button variant="contained" color="primary">
              {t("US.dialogs.edituser.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
