import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import ListIcon from "@material-ui/icons/List";
import DeleteWallet from "./Dialogs/DeleteWallet";
import EditWallet from "./Dialogs/EditWallet";
import api from "../../services/axios";
import NewWallet from "./Dialogs/NewWallet";
import WalletDetails from "./Dialogs/WalletsDetails";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    background: "#34b7f1",
    color: "#f2f2f2",

    "&:hover": {
      background: "#f2f2f2",
      color: "#34b7f1",
    },
  },
}));
export default () => {
  const { t } = useTranslation();
  const [openeditwallet, setOpeneditwallet] = React.useState(false);
  const [opennewwallet, setOpennewwallet] = React.useState(false);
  const [openwalletdetails, setOpenwalletdetails] = React.useState(false);
  const [opendeletewallet, setOpendeletewallet] = React.useState(false);
  const [wallets, setWallet] = React.useState([]);
  const [walletselected, setWalletselected] = React.useState("");
  const classes = useStyles();
  const updateData = async () => {
    await api
      .get("wallet", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        setWallet([...res.data]);
      });
  };

  useEffect(() => {
    updateData();
  }, []);

  const options = {
    filterType: false,
    print: false,
    viewColumns: false,
    serverSideFilterList: false,
    download: false,
    filter: false,
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: t("WT.table.noMatch"),
        toolTip: "Sort",

        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        rowsPerPage: "Linhas por Página",
      },
    },
  };

  const columns = [
    "ID",
    t("WT.table.name"),
    t("WT.table.edit"),
    t("WT.table.delete"),
    "",
  ];
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "auto",
      }}
    >
      {opennewwallet && (
        <NewWallet func={setOpennewwallet} update={updateData} />
      )}
      {opendeletewallet && (
        <DeleteWallet
          data={walletselected}
          func={setOpendeletewallet}
          update={updateData}
        />
      )}
      {openeditwallet && (
        <EditWallet
          data={walletselected}
          func={setOpeneditwallet}
          update={updateData}
        />
      )}
      {openwalletdetails && (
        <WalletDetails
          data={walletselected}
          func={setOpenwalletdetails}
          update={updateData}
        />
      )}
      <MUIDataTable
        title={
          <Button
            onClick={() => {
              setOpennewwallet(true);
            }}
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<AddCircleIcon />}
          >
            {t("WT.table.bt1")}
          </Button>
        }
        data={wallets.map((wallet, index) => [
          wallet.idwallets,
          wallet.name,
          <EditIcon
            style={{ fill: "#34b7f1" }}
            onClick={() => {
              setOpeneditwallet(true);
              setWalletselected(wallets[index]);
            }}
          />,
          <RemoveCircleOutlineIcon
            style={{ fill: "#34b7f1" }}
            onClick={() => {
              setOpendeletewallet(true);
              setWalletselected(wallets[index]);
            }}
          />,
          <Button
            onClick={(ev) => {
              ev.stopPropagation();
              setOpenwalletdetails(true);
              setWalletselected(wallets[index]);
            }}
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<ListIcon />}
          >
            {t("WT.table.bt2")}
          </Button>,
        ])}
        columns={columns}
        options={options}
      />
    </div>
  );
};
