import React from "react";

export default function ({ message }) {
  return (
    <div className="message">
      <div
        className={`font-style _3DFk6 message-${
          message.client_message > 0 ? "in" : "out"
        } tail`}
      >
        <span className="tail-container" />
        <span className="tail-container highlight" />
        <div className="Tkt2p">
          <div className="_3zb-j ZhF0n">
            <span
              dir="ltr"
              className="selectable-text invisible-space message-text"
            >
              {message.content}
            </span>
          </div>
          <div className="_2f-RV">
            <div className="_1DZAH">
              <span className="msg-time">
                {" "}
                {new Date(message.timestamp).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
