import styled, { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
.rc-mentions-dropdown{
  position: absolute;
  border: 1px solid #999;
    border-radius: 3px;
    background: #FFF;
&-menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &-item {
    cursor: pointer;
    padding: 4px 8px;
    &-active {
          background: #a9a8a7;
        }
        &-disabled {
          opacity: 0.5;
        }
  }
}
}
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  margin-top: -64px;
  .head {
    margin-left: 5px;
  }

  .connection {
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .helparea {
      .landing-title {
        margin-bottom: 28px;
        font-size: 24px;
        line-height: 1;
      }
      ._1155t {
        padding: 0 0 0 24px;
        margin: 0;
        list-style-type: decimal;
        ._38sqg {
          margin-top: 18px;
          font-size: 16px;
          line-height: 24px;

          strong {
            display: inline-block;
            font-weight: 500;
            line-height: 24px;
            ._1VzZY {
              ._2ezGC {
                display: inline-block;
                vertical-align: top;
              }
            }
          }
        }
      }
    }
    .connection-btn {
      margin: 0px 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .qr {
        width: 350px;
        height: 350px;
        svg {
          width: 100%;
          height: 100%;
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }
  }
  .messages-actions {
    margin-bottom: 10px;
    div {
      .rc-mentions {
        display: inline-block;
        position: relative;
        white-space: pre-wrap;
        width: 100%;
        textarea {
          border: 1px solid gray;
          outline: none;
          width: 100%;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          resize: none;
        }
        &-measure {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          pointer-events: none;
          color: transparent;
          z-index: -1;
        }
        &-dropdown {
          position: absolute;

          &-menu {
            list-style: none;
            margin: 0;
            padding: 0;

            &-item {
              cursor: pointer;
            }
          }
        }
      }

      textarea {
        width: 100%;
        height: 80px;
      }
      h3 {
        display: flex;
        align-items: center;
        margin-right: 20px;
        flex: 20;
      }
      .MuiTextField-root {
        width: 70%;
      }
    }
  }
`;
export const InfoContent = styled.div`
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .info {
    margin-top: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
`;
export const GetContactsContent = styled.div`
  margin-top: 64px;
  .content {
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .action {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  .loading {
    display: flex;
    line-height: 4;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
`;
export const ImportAreaContent = styled.div`
  margin-top: 64px;
  .content {
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .action {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  .loading {
    display: flex;
    line-height: 4;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
`;
