import styled, { keyframes } from "styled-components";
import bg from "./bg.png";

const button_loading_spinner = keyframes`
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  .header {
    display: flex;
    justify-content: space-around;
    button {
      min-width: 100px;
    }
    .loading {
      &::after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;
        border-top-color: #ffffff;
        border-radius: 50%;
        animation: ${button_loading_spinner} 1s ease infinite;
      }
    }
  }
  .make-area {
    background-image: url("https://storage.googleapis.com/static.helloumi.com/daisho/img/grid.png");
    background-color: #cecece;
    background-position: 0 0;
    background-size: 80px;
    width: 100%;
    height: 100vh;
    display: flex;
    .reactflow-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
  h2 {
    color: #616363;
  }
`;
export const Node = styled.div`
  width: 300px;
  text-align: left;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
  span {
    font-weight: bold;
  }
  .header-node {
    color: #616363;
    font-weight: bold;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 0;
    border-bottom: 1px solid lightgray;
  }
  .tag-container {
    border: 2px solid #ccc;
    border-radius: 3px;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 6px;
    overflow-x: scroll;
    margin-bottom: 25px;
    input {
      padding: 5px;
      font-size: 16px;
      border: 0;
      outline: none;
      font-family: "Rubik";
      color: #333;
      flex: 1;
    }
    .tag {
      height: 30px;
      margin: 5px;
      padding: 5px 6px;
      border: 1px solid #ccc;
      border-radius: 3px;
      background: #eee;
      display: flex;
      align-items: center;
      color: #333;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), inset 0 1px 1px #fff;
      cursor: default;
      svg {
        font-size: 16px;
        color: #666;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
  .MuiTextField-root {
    width: 100%;
  }
  .function-area {
    width: 100%;
    min-height: 70px;
    //overflow: scroll;
    background: ${(props) => props.functionAreaBg};
    border-radius: 5px;
  }
  .btn-message {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: center;
    label {
      margin: 5px 15px;
      cursor: pointer;
    }
  }
  .title {
    h4 {
      padding: 0;
      margin: 0;
      margin-right: 5px;
    }
    display: flex;
    align-items: center;
  }
  .file-preview {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    margin-top: 10px;
    p {
      margin-left: 10px;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    img {
      width: 60px;
      height: 100%;
    }
  }
`;
export const RightMessage = styled.div`
  min-width: 330px;
  padding: 15px;
  .actions {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
`;
export const SideBar = styled.aside`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
  .dndflow {
    flex-direction: column;
    display: flex;
    height: 100%;
  }

  .description {
    margin-bottom: 10px;
  }

  .dndnode {
    color: #3b3b3b;
    height: 50px;
    padding: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: grab;

    svg {
      flex: 20%;
      margin-right: 5px;
    }
    div {
      flex: 80%;
      font-weight: bold;
      color: #3b3b3b;
    }
  }

  .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 20%;
    max-width: 250px;
  }
  .functions-area {
    display: flex;
    .function-node {
      margin: 5px;
      text-align: center;
      color: rgb(0 173 255);
      /* color: #7a7a7a; */
      //border: solid 1px #7a7a7a;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
        rgba(0, 0, 0, 0.23) 0px 3px 6px;
      cursor: grab;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all 0.5s;
      min-height: 50px;
      background-color: #f2f2f2;
      border-radius: 5px;
      padding: 5px;
      &:hover {
        /* color: #3b3b3b; */
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
          0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        background-color: #bdbcbc;
      }
      svg {
        transform: scaleX(-1);
      }
    }
  }
`;
